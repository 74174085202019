import {
	Dialog,
	dialogClasses,
	DialogContent,
	DialogTitle,
	outlinedInputClasses, TextField,
	Typography,
	useMediaQuery
} from "@mui/material";
import Stack from "@mui/material/Stack";
import ConversationTextField from "../ConversationTextField";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import theme from "../../lib/theme.const";
import Box from "@mui/material/Box";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import HelpUserPopup from "../../pages/components/messageButton/HelpUserPopup";
import SaveNotePopup from "./SaveNotePopup";

interface IProps {
	open: boolean,
	onClose: () => void,
	selectedSubject: string | undefined,
	planId: number | undefined,
	checkedAvailableOrSelectedResearcher: number | undefined,
	setCheckedAvailableOrSelectedResearcher: Dispatch<SetStateAction<number | undefined>>
}

export default function SetQuestionPopup({
	open ,
	onClose ,
	selectedSubject ,
	planId,
	setCheckedAvailableOrSelectedResearcher ,
	checkedAvailableOrSelectedResearcher
}:IProps) {
	const [noteContent , setNoteContent] = useState<string>();
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const [openHelpMessage , setOpenHelpMessage] = useState(false);
	const [saveNotePopup , setSaveNotePopup] = useState<boolean>(false);
	const [titleNote , setTitleNote] = useState<string>();
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	useEffect(() => {
		if(checkedAvailableOrSelectedResearcher === 3) {
			onClose()
		}
	}, [checkedAvailableOrSelectedResearcher]);

	const handleCloseHelpMessage = () => {
		setOpenHelpMessage(false)
	};

	function handleClickSaveNote() {
		setSaveNotePopup(true)
	}

	function handleCloseSaveNotePopup() {
		setSaveNotePopup(false)
	}

	return(
		<>
			<Dialog
				open={open}
				onClose={onClose}
				sx={{
					[`& .${dialogClasses.paper}`]: {
						borderRadius:5,
						width:'100%',
						minWidth: (downOflg)?undefined:950,
						maxHeight: 610
					},
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor:'#D9D9D9',
						display:'flex',
						justifyContent:'center',
						alignItems:'center'
					}}
				>
					<Typography>
						پرسیدن سوال
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack
						sx={{
							px:(downOfsm)?0:8,
							pt:4
						}}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<TextField
							onChange={(event) => {setTitleNote(event.target.value)}}
							label={"عنوان سوال"}
							value={titleNote}
							sx={{
								width: '100%',
								mb: 3,
								[`& .${outlinedInputClasses.root}`]: {
									borderRadius: 8,
									height: 40
								},
								"& label": {
									fontSize: 15,
									transform: `translate(14px, 9px) scale(1)`,
									"&.MuiInputLabel-shrink"  : {
										fontSize: 12,
										transform: `translate(14px, -6px) scale(1)`
									},
								},
							}}
						/>
						<Stack width={'100%'} height={'100%'} sx={{pt:5}}>
							<ConversationTextField
								setNoteContent={setNoteContent}
								handleClickSaveNote={handleClickSaveNote}
								isExpanded={isExpanded}
								setIsExpanded={setIsExpanded}
							/>
						</Stack>
						<Stack
							mt={2}
							alignSelf={'flex-end'}
							justifyContent={"center"}
							alignItems={"center"}
							sx={{
								borderRadius: "50%",
								width: 65,
								height: 65,
								backgroundColor: "#E86942",
								cursor: "pointer",
								'&:hover': {
									backgroundColor: "#8f3e04"
								},
							}}
						>
							<Stack
								justifyContent={"center"}
								alignItems={"center"}
								onClick={() => setOpenHelpMessage(true)}
							>
								<Box mt={0.5}>
									<img src={"./message.png"} width={43} height={43}/>
								</Box>
								<Box mt={-6.3}>
									<MoreHorizSharpIcon
										sx={{
											color: "white",
											fontSize: 35
										}}
									/>
								</Box>
							</Stack>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
			<HelpUserPopup open={openHelpMessage} onClose={handleCloseHelpMessage}/>
			<SaveNotePopup
				open={saveNotePopup}
				onClose={handleCloseSaveNotePopup}
				selectedSubject={selectedSubject}
				noteContent={noteContent}
				planId={planId}
				titleNote={titleNote}
				checkedAvailableOrSelectedResearcher={checkedAvailableOrSelectedResearcher}
				setCheckedAvailableOrSelectedResearcher={setCheckedAvailableOrSelectedResearcher}
			/>
		</>
	)
}