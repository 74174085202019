import {
	Autocomplete,
	autocompleteClasses,
	outlinedInputClasses,
	TextField,
	useMediaQuery
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import {memo, PropsWithChildren, useCallback, useEffect, useState} from "react";
import theme from "../../../../../lib/theme.const";
import IQuestionsFullTextSearchResult from "../../../../../interface/IQuestionsFullTextSearchResult";
import SearchPaperResult from "./SearchPaperResult";

interface IProps {
	searchData: IQuestionsFullTextSearchResult | undefined,
	inputValue: string | undefined,
	selectedOption: Option | null,
	handleChange: (event: any, newValue: Option | null) => void,
	handleInputChange: (_: any, newValue: any) => void,
	openAutocomplete?: boolean,
	setOpenAutocomplete?: (open: boolean) => void,
	topic: string | undefined
}

interface Option {
	question_id: number,
	result_chunk: string,
}

export default function SearchBox({
	searchData ,
	inputValue ,
	selectedOption ,
	handleChange ,
	handleInputChange ,
	openAutocomplete,
	setOpenAutocomplete,
	topic
}:IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const [options, setOptions] = useState<Option[]>([]);

	useEffect(() => {
		if (!searchData) {
			return;
		}

		if (searchData.status === "OK") {
			let newOptions: any[] = [];

			newOptions = [...newOptions, ...searchData.msg].filter(
				(val, idx, self) =>
					self.map((x, index) => {
						if (val.title === x.title) {
							if (idx === index) {
								return true
							}
						} else {
							return false
						}
					})
			);


			if(topic && inputValue === "") {
				setOptions(newOptions);
			}
			if (inputValue === "") {
				setTimeout(() => setOptions([]), 500);
			}
			else {
				setOptions(newOptions);
			}
		}
	}, [searchData, inputValue , topic]);

	const handleSearchClick = useCallback(() => {
		if (setOpenAutocomplete) setOpenAutocomplete(true);
	}, [setOpenAutocomplete]);

	const InputEndAdornment = memo(
		(props: PropsWithChildren<{ reactNode: React.ReactNode }>) => {
			const root = props.reactNode as JSX.Element;
			const elements = React.Children.toArray(root.props.children);

			React.Children.forEach(props.children, (child, key) => {
				if (React.isValidElement(child))
					elements.push(React.cloneElement(child, { key }));
			});

			return React.cloneElement(root, {}, elements);
		}
	);

	return (
		<>
			<Autocomplete
				autoComplete
				value={selectedOption}
				options={options}
				popupIcon={false}
				includeInputInList
				open={false}
				// onOpen={() => setOpenAutocomplete && setOpenAutocomplete(true)}
				// onClose={() => setOpenAutocomplete && setOpenAutocomplete(false)}
				// onChange={handleChange}
				inputValue={inputValue}
				onInputChange={handleInputChange}
				disablePortal
				getOptionLabel={(option) => option.result_chunk}
				isOptionEqualToValue={(option, value) => option.result_chunk === value.result_chunk}
				noOptionsText={"کلیدواژه ای با این نام پیدا نشد"}
				sx={{
					// width: downOfsm ? 200 : 400,
					width: "95%",
					[`& .${autocompleteClasses.endAdornment}`]: {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						variant={"outlined"}
						placeholder={"جستجو"}
						onClick={() => setOpenAutocomplete && setOpenAutocomplete(true)}
						sx={{
							[`& .${outlinedInputClasses.root}`]: {
								height: 45,
								borderRadius: 8,
							}
						}}
						color={'warning'}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<InputEndAdornment reactNode={params.InputProps.endAdornment}>
									<IconButton
										size={'small'}
										sx={{ mt: -0.5 }}
										onClick={handleSearchClick}
									>
										<SearchIcon />
									</IconButton>
								</InputEndAdornment>
							)
						}}
					/>
				)}
			/>
			{openAutocomplete && (
				<SearchPaperResult
					options={options}
					handleChange={handleChange}
					inputValue={inputValue}
				>
					{openAutocomplete}
				</SearchPaperResult>
			)}
		</>
	);
}