import {Avatar, outlinedInputClasses, Paper, Select, TextField, Typography} from "@mui/material";
import IGetAdminPageDataResult from "../../../interface/IGetAdminPageDataResult";
import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";
import {useLazySearchUsersQuery} from "../../../features/apiSlice";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import IUsersAdminDataType from "../../../interface/IUsersAdminDataType";
import MenuItem from "@mui/material/MenuItem";

interface IProps {
	adminData: IGetAdminPageDataResult | undefined
}

const CustomScrollbarRoot = styled('div')({
	position: 'relative',
	width: '100%',
	height: "100%", // Set a specific height to ensure scrolling
	overflowY: 'auto',
	overflowX: 'hidden', // Disable horizontal scroll
	direction: 'rtl',
	'& > *': {
		direction: 'ltr',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: '#D9D9D9',
		borderRadius: '10px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#a4a4a4',
		borderRadius: '10px',
	},
});

export default function UsersPart({adminData}:IProps) {
	const [searchUserTrigger , {data:searchUserData}] = useLazySearchUsersQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const navigate = useNavigate();
	const [numberOfQuestion , setNumberOfQuestion] = useState<string>();
	const [day , setDay] = useState<string>();
	const [inputValue, setInputValue] = useState<string>("");
	const [roleForFilterUser , setRoleForFilterUser] = useState<string>('all');
	const [mainData , setMainData] = useState<IUsersAdminDataType[]>();

	useEffect(() => {
		if(!token) return;

		if (numberOfQuestion && day) {
			searchUserTrigger({
				token: token,
				question_count: numberOfQuestion,
				days: day,
				search: inputValue
			})
		}
		else if (numberOfQuestion) {
			searchUserTrigger({
				token: token,
				question_count: numberOfQuestion,
				search: inputValue
			})
		}
		else if (day) {
			searchUserTrigger({
				token: token,
				days: day,
				search: inputValue
			})
		}
		else {
			searchUserTrigger({
				token: token,
				search: inputValue
			})
		}
	}, [numberOfQuestion , day , token , inputValue]);

	useEffect(() => {
		if(!adminData) return;

		const data =
			(searchUserData)?
				adminData.msg.users.filter((user) => searchUserData.msg.users.some((searchUser:any) => searchUser.id === user.id))
				:adminData?.msg.users

		if(roleForFilterUser === 'researcher') {
			const researcherData = data?.filter((user) => user.role === 'researcher')
			setMainData(researcherData)
		}
		else if(roleForFilterUser === 'questioner') {
			const questionerData = data?.filter((user) => user.role === 'questioner')
			setMainData(questionerData)
		}
		else {
			setMainData(data)
		}

	}, [searchUserData , roleForFilterUser , adminData]);


	function handleInputChange(event: string) {
		setInputValue(event)
	}

	function handlePageUser(content: IUsersAdminDataType) {
		if(content.role === 'researcher') {
			navigate("/general-researcher-page" , {state:content.id})
		}
		else if(content.role === 'questioner') {
			console.log(content)
			navigate('/general-questioner-page' ,
				{
					state: {
						name: content.fname + content.lname,
						username: content.username,
						created_at: content.created_at,
						education: content.education,
						bio: content.bio,
						photo: content.photo
					}
				}
			)
		}
	}

	return(
		<>
			<Paper
				sx={{
					minHeight: 800,
					backgroundColor: "#D9D9D9",
					borderRadius:4,
					py:3,
					pl:1,
					pr:2
				}}
			>
				<Stack spacing={2}>
					<Stack
						direction={'row'}
						justifyContent={'space-between'}
						alignItems={'center'}
					>
						<Stack width={'35%'}>
							<Select
								color={"warning"}
								IconComponent={(props) => (
									<ExpandMoreIcon {...props} sx={{ color: 'black', left: '10px' }} />
								)}
								MenuProps={{
									PaperProps: {
										sx: {
											'& .MuiMenuItem-root': {
												justifyContent: 'flex-start',
											}
										}
									}
								}}
								sx={{
									ml: 1,
									// width: 90,
									height:40,
									borderRadius: 8,
									backgroundColor: "white",
									'& .MuiSelect-icon': {
										right: '10px',
										color: 'black'
									}
								}}
							>
								<Box>
									<MenuItem onClick={() => setRoleForFilterUser('researcher')}>
										پژوهشگر
									</MenuItem>
									<MenuItem onClick={() => setRoleForFilterUser('questioner')}>
										پرسشگر
									</MenuItem>
									<MenuItem onClick={() => setRoleForFilterUser('all')}>
										همه
									</MenuItem>
								</Box>
							</Select>
						</Stack>
						<Stack width={'65%'} alignItems={'center'}>
							<TextField
								value={inputValue}
								onChange={(event) => handleInputChange(event.target.value)}
								color={'warning'}
								placeholder={'جستجو'}
								sx={{
									[`& .${outlinedInputClasses.root}`]: {
										height: 40,
										borderRadius: 8,
										backgroundColor: "white",
									},
								}}
							/>
						</Stack>
					</Stack>
					<Stack justifyContent={'flex-end'} direction={'row'} spacing={1}>
						<Stack width={'37%'}>
							<TextField
								label={'تعداد سوال'}
								color={'warning'}
								onChange={(event) => setNumberOfQuestion(event.target.value)}
								sx={{
									[`& .${outlinedInputClasses.root}`]: {
										borderRadius:20,
										backgroundColor:'white',
										height: 40
									},
									"& label": {
										fontSize: 15,
										transform: `translate(14px, 9px) scale(1)`,
										"&.MuiInputLabel-shrink"  : {
											fontSize: 13,
											transform: `translate(14px, -9px) scale(1)`,
											pt:0.2
										},
									},
								}}
							/>
						</Stack>
						<Stack width={'25%'}>
							<TextField
								label={'روز'}
								color={'warning'}
								onChange={(event) => setDay(event.target.value)}
								sx={{
									[`& .${outlinedInputClasses.root}`]: {
										borderRadius:20,
										backgroundColor:'white',
										height: 40
									},
									"& label": {
										fontSize: 15,
										transform: `translate(14px, 7px) scale(1)`,
										"&.MuiInputLabel-shrink"  : {
											fontSize: 13,
											transform: `translate(14px, -12px) scale(1)`,
											pt:0.2
										},
									},
								}}
							/>
						</Stack>
					</Stack>
					<Stack
						sx={{
							maxHeight: 730,
							overflowY: 'auto',
						}}
					>
						<CustomScrollbarRoot>
							<Stack spacing={2} pl={1}>
								{mainData?.map((content , index) => {
									return(
										<Stack key={index}>
											<Paper sx={{py:2 , px:1}}>
												<Stack direction={'row'} sx={{minHeight:100}}>
													<Stack alignItems={'center'} justifyContent={'center'} width={'22%'}>
														<Avatar
															src={`https://api.matnavis.com/files/${content.photo}`}
															sx={{
																width:"60px",
																height:"60px",
																transition: 'width 0.3s ease',
															}}
														/>
													</Stack>
													<Stack width={'58%'} height={'100%'} pl={0.5}>
														<Box>
															<Typography>
																{content.fname + ' ' + content.lname}
															</Typography>
														</Box>
														<Box>
															<Typography>
																{content.username}
															</Typography>
														</Box>
														<Box>
															<Typography>
																{content.email}
															</Typography>
														</Box>
														<Box>
															<Typography>
																{content.sheba}
															</Typography>
														</Box>
													</Stack>
													<Stack
														alignSelf={'flex-end'}
														width={'22%'}
														onClick={() => handlePageUser(content)}
													>
														<Stack
															alignItems={'center'}
															sx={{
																backgroundColor:'#E86942',
																borderRadius:4,
																height:27,
																width:'100%',
																px:1
															}}
														>
															<PersonIcon
																sx={{
																	color:'white',
																	display:'flex',
																	alignSelf:'flex-end'
																}}
															/>
														</Stack>
													</Stack>
												</Stack>
											</Paper>
										</Stack>
									)
								})}
							</Stack>
						</CustomScrollbarRoot>
					</Stack>
				</Stack>
			</Paper>
		</>
	)
}