import {
	Button,
	Dialog,
	DialogContent,
	Typography
} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useState} from "react";
import {useLazyInitTransactionQuery} from "../../features/apiSlice";
import WaysToPayment from "../WaysToPayment";

interface IProps {
	open: boolean,
	onClose: () => void,
	planId: number | undefined
}

export default function NotEnoughTokenPopup({open , onClose , planId}:IProps) {
	const [initTransactionTrigger , {data: initTransactionData}] = useLazyInitTransactionQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const [paymentWay , setPaymentWay] = useState<number>();

	function handleClickPayment() {
		if(!token || !paymentWay || !planId) return;

		initTransactionTrigger({
			token: token,
			gateway_id: paymentWay,
			plan_id: planId
		})
	}

	function handleSetPaymentWay(value: number) {
		setPaymentWay(value)
	}

	return(
		<Dialog
			open={open}
			onClose={onClose}
		>
			<DialogContent>
				<Stack spacing={5}>
					<Stack justifyContent={'center'} alignItems={'center'} pt={3}>
						<Typography fontSize={20} color={'#E86942'}>
							موجودی توکن شما کافی نمی‌باشد
						</Typography>
					</Stack>
					<WaysToPayment handleSetPaymentWay={handleSetPaymentWay}/>
					<Stack justifyContent={'center'} alignItems={'center'}>
						<Button
							onClick={handleClickPayment}
							variant={'contained'}
							sx={{
								width:'25%',
								backgroundColor:'#E86942',
								color:'white',
								'&:hover': {
									backgroundColor: "#8f3e04"
								},
							}}
						>
							پرداخت
						</Button>
					</Stack>
				</Stack>
			</DialogContent>
		</Dialog>
	)
}