import React, {useState} from 'react';
import {Box, Button, TextField, Typography, IconButton, useMediaQuery} from '@mui/material';
import { HiOutlineHome } from 'react-icons/hi';
import { BsTelephone } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import theme from "../../../lib/theme.const";
import Stack from "@mui/material/Stack";
import {useLazySendContactToUsMessageQuery} from "../../../features/apiSlice";

const ContactForm = () => {
	const [sendMessageTrigger , {data:sendMessageData}] = useLazySendContactToUsMessageQuery();
	const [name , setName] = useState<string>();
	const [email , setEmail] = useState<string>();
	const [text , setText] = useState<string>();

	function handleSendMassage() {
		if(!name || !email || !text) return;

		sendMessageTrigger({
			token:'',
			name: name,
			email: email,
			message: text
		}).then(() => {
			setName('')
			setEmail('')
			setText('')
		})
	}

	return(
		<Box
			component="form"
			sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' , flex:2 }}
		>
			<TextField
				color="warning"
				sx={{backgroundColor:'white'}}
				onChange={(event) => setName(event.target.value)}
				value={name}
				dir="rtl"
				variant="outlined"
				name="name"
				placeholder="نام و نام خانوادگی شما"
				required
				fullWidth
			/>
			<TextField
				color="warning"
				sx={{backgroundColor:'white'}}
				onChange={(event) => setEmail(event.target.value)}
				value={email}
				dir="rtl"
				variant="outlined"
				type="email"
				name="email"
				placeholder="ایمیل شما"
				required
				fullWidth
			/>
			{/*<Box*/}
			{/*	component="textarea"*/}
			{/*	placeholder="پیامتان را اینجا بنویسید"*/}
			{/*	name="message"*/}
			{/*	required*/}
			{/*	dir="rtl"*/}
			{/*	// fullWidth*/}
			{/*	sx={{*/}
			{/*		minHeight: '110px',*/}
			{/*		padding: '10px',*/}
			{/*		border: '1px solid #ccc',*/}
			{/*		borderRadius: '4px',*/}
			{/*		resize: 'vertical',*/}
			{/*		overflow: 'auto',*/}
			{/*		fontSize: '16px',*/}
			{/*		borderColor: '#E86942'*/}
			{/*	}}*/}
			{/*/>*/}
			<TextField
				// ref={textareaRef}
				placeholder="پیامتان را اینجا بنویسید"
				onChange={(event) => setText(event.target.value)}
				value={text}
				sx={{
					minHeight: '100px',
					overflow: 'auto',
					backgroundColor:'white'
				}}
				color="warning"
				rows={3}
				multiline
				dir="rtl"
				name="message"
				required
				fullWidth
			/>
			<Button
				// type="submit"
				variant="contained"
				fullWidth
				onClick={handleSendMassage}
				sx={{
					backgroundColor: "#007FA8",
					color: 'white',
					padding: '5px',
					borderRadius: '4px',
					':hover': {
						boxShadow: 'rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px',
						backgroundColor: "#06485d"
					},
				}}
			>
				ارسال پیام
			</Button>
		</Box>
	)
}

const ContactInfo = () => (
	<Stack width={'40%'} sx={{  gap: '1rem' }}>
		<Stack direction={'row'} spacing={2} >
			<IconButton
				component="a"
				href="https://goo.gl/maps/zNyxAxjTnk2LsvAh6"
				target="_blank"
				rel="noreferrer"
				sx={{
					fontSize: '34px',
					':hover': {
						borderRadius: '5px',
					},
				}}
			>
				<HiOutlineHome />
			</IconButton>
			<Stack>
				<Typography
					sx={{
						fontSize: '16px',
						lineHeight: '40px' ,
						// "&..fttx-1nnu5x2-MuiTypography-root": {
						// 	textAlign:'right',
						// },
					}}
				>
					تهران، ولنجک، میدان شهید شهریاری، بلوار دانشجو، دانشگاه شهید بهشتی، دانشکدهٔ معماری و شهرسازی، مرکز نوآوری
				</Typography>
			</Stack>
		</Stack>
		<Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={2}>
			<IconButton
				component="a"
				href="tel:+982129902870"
				sx={{
					fontSize: '34px',
					padding: '2.5px 5px',
					':hover': {
						borderRadius: '5px'
					},
				}}
			>
				<BsTelephone />
			</IconButton>
			<Typography sx={{ fontSize: '16px', lineHeight: '50px' , direction:'rtl' }}>
				(+۹۸) ۰۲۱-۲۹۹۰۲۸۷۰
			</Typography>
		</Stack>
		<Stack direction={'row'} sx={{ alignItems: 'center' }} spacing={2}>
			<IconButton
				component="a"
				href="mailto:info@matnavis.com"
				sx={{
					fontSize: '34px',
					padding: '2.5px 5px',
					':hover': {
						borderRadius: '5px'
					},
				}}
			>
				<AiOutlineMail />
			</IconButton>
			<Typography sx={{ fontSize: '16px', lineHeight: '50px' }}>
				info@matnavis.com
			</Typography>
		</Stack>
	</Stack>
);

const Contact = () => {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<section id="contact">
			<Box>
				<Stack
					sx={{
						backgroundColor:'#D6DFEA',
						padding: '50px 20px',
					}}
					spacing={4}
				>
					<Typography variant="h2" sx={{ fontSize: '24px', fontWeight: 600, lineHeight: '40px' }}>
						تماس با ما
					</Typography>
					<Stack direction={(downOfsm) ? 'column' : 'row'} spacing={'14%'}>
						<ContactInfo />
						<ContactForm />
					</Stack>
				</Stack>
			</Box>
		</section>
	);
};

export default Contact;
