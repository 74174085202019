import Stack from "@mui/material/Stack";
import {
	Button,
	outlinedInputClasses,
	TextField, useMediaQuery
} from "@mui/material";
import * as React from "react";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useLazyAddNoteQuery, useLazyEditNoteQuery} from "../../../../../features/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../app/store";
import theme from "../../../../../lib/theme.const";
import INotestype from "../../../../../interface/INotestype";
import NoteTextField from "./NoteTextField";
import {refreshSettingDataChanged} from "../../../../../features/appSlice";

interface IProps {
	noteForEdit?: INotestype,
	setNoteForEdit?: Dispatch<SetStateAction<INotestype | undefined>>
}

export default function NewNotes({noteForEdit , setNoteForEdit}:IProps) {
	const [addNoteTrigger, { data: addNoteData }] = useLazyAddNoteQuery();
	const [editNoteTrigger, { data: editNoteData }] = useLazyEditNoteQuery();
	const { token } = useSelector((state: RootState) => state.app);
	const [titleNote , setTitleNote] = useState<string>();
	const [noteContent , setNoteContent] = useState<string>();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const dispatch = useDispatch();

	useEffect(() => {
		if(!addNoteData) return;
		dispatch(refreshSettingDataChanged(addNoteData))
	}, [addNoteData]);

	useEffect(() => {
		if(!editNoteData) return;
		dispatch(refreshSettingDataChanged(editNoteData))
	}, [editNoteData]);

	console.log(noteContent)

	useEffect(() => {
		if(!noteForEdit) return;

		setTitleNote(noteForEdit?.title)
	}, [noteForEdit]);

	function handleClickSaveNote() {
		if(!token || !titleNote || !noteContent || noteContent === "\n") return;

		if(noteForEdit && setNoteForEdit) {
			editNoteTrigger({
				token: token,
				note_id: noteForEdit.id,
				title: titleNote,
				body: noteContent
			}).then(() => {
				setNoteContent('')
				setTitleNote('')
				setNoteForEdit(undefined)
			})
		}
		else {
			addNoteTrigger({
				token:token,
				title: titleNote,
				body: noteContent
			}).then(() => {
				setNoteContent('')
				setTitleNote('')
			})
		}
	}

	return(
		<>
			<Stack
				sx={{
					backgroundColor: "#D9D9D9",
					pt:5,
					pb:2,
					borderBottomLeftRadius: 15,
					borderBottomRightRadius: 15
				}}
				alignItems={"center"}
			>
				<Stack
					width={'90%'}
					alignItems={"center"}
					sx={{
						backgroundColor: "white",
						borderRadius: 5,
						height:425,
						pt: 2
					}}
				>
					<TextField
						onChange={(event) => {setTitleNote(event.target.value)}}
						label={"عنوان یادداشت"}
						value={titleNote}
						sx={{
							width: (downOfsm)?"90%":"50%",
							mb: 3,
							[`& .${outlinedInputClasses.root}`]: {
								borderRadius: 8,
								height: 40
							},
							"& label": {
								fontSize: 15,
								transform: `translate(14px, 9px) scale(1)`,
								"&.MuiInputLabel-shrink"  : {
									fontSize: 12,
									transform: `translate(14px, -6px) scale(1)`
								},
							},
						}}
					/>
					<NoteTextField setNoteContent={setNoteContent} noteForEdit={noteForEdit} noteContent={noteContent}/>
				</Stack>
				<Stack alignSelf={"flex-end"} pt={2} pr={1}>
					<Button
						onClick={handleClickSaveNote}
						variant={"contained"}
						sx={{
							backgroundColor: "#EE8D6F",
							minWidth: 120,
							'&:hover': {
								backgroundColor: "#8f3e04"
							},
						}}
					>
						ذخیره
					</Button>
				</Stack>
			</Stack>
		</>
	)
}