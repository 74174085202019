import * as React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {Dispatch, SetStateAction} from "react";

interface IOption {
	id:number,
	type:string,
	title:string
}

interface IProps {
	handleChange?: (event: any, newValue: any | null) => void;
	options?: IOption[];
	inputValue: string | undefined,
	setTypeOfSearch: Dispatch<SetStateAction<string | undefined>>,
	typeOfSearch: string | undefined
}

const highlightText = (text: string, keyword: string) => {
	const parts = text.split(new RegExp(`(${keyword})`, 'gi'));
	return parts.map((part, index) =>
		part.toLowerCase() === keyword.toLowerCase() ? (
			<span key={index} style={{ color: 'orange', fontWeight: 'bold' }}>{part}</span>
		) : (
			part
		)
	);
};

const getSurroundingText = (text: string, keyword: string, before = 2, after = 2) => {
	if(!text || !keyword) return;
	const words = text.split(' ');
	const keywordIndex = words.findIndex(word => word.toLowerCase().includes(keyword.toLowerCase()));

	if (keywordIndex === -1) return text;

	const start = Math.max(0, keywordIndex - before);
	const end = Math.min(words.length, keywordIndex + after + 1);
	const surroundingText = words.slice(start, end).join(' ');

	return highlightText(surroundingText, keyword);
};

export default function SearchPaperHeaderResult({handleChange , options , inputValue , setTypeOfSearch , typeOfSearch}:IProps) {
	return(
		<>
			<Stack spacing={3}>
				<Stack px={3}>
					<Box>
						<Typography>
							جستجو در:
						</Typography>
					</Box>
					<Stack spacing={1} pt={3}>
						<Box
							sx={{
								border: 1,
								cursor:'pointer',
								borderColor: '#7B7D82',
								borderRadius:4,
								backgroundColor: (typeOfSearch === 'researcher')?"#8e8e8e":undefined,
								'&:hover': {
									backgroundColor: "#8e8e8e"
								},
							}}
							p={2}
							onClick={() => {
								if(typeOfSearch === 'researcher') {
									setTypeOfSearch(undefined)
								}
								else {
									setTypeOfSearch('researcher')
								}
							}}
						>
							<Typography>
								نام پژوهشگر
							</Typography>
						</Box>
						<Box
							sx={{
								border: 1,
								cursor:'pointer',
								borderColor: '#7B7D82',
								borderRadius:4,
								backgroundColor: (typeOfSearch === 'questioner')?"#8e8e8e":undefined,
								'&:hover': {
									backgroundColor: "#8e8e8e"
								},
							}}
							p={2}
							onClick={() => {
								if(typeOfSearch === 'questioner') {
									setTypeOfSearch(undefined)
								}
								else {
									setTypeOfSearch('questioner')
								}
							}}
						>
							<Typography>
								نام پرسشگر
							</Typography>
						</Box>
						<Box
							sx={{
								cursor:'pointer',
								border: 1,
								borderColor: '#7B7D82',
								borderRadius:4,
								backgroundColor: (typeOfSearch === 'question')?"#8e8e8e":undefined,
								'&:hover': {
									backgroundColor: "#8e8e8e"
								},
							}}
							p={2}
							onClick={() => {
								if(typeOfSearch === 'question') {
									setTypeOfSearch(undefined)
								}
								else {
									setTypeOfSearch('question')
								}
							}}
						>
							<Typography>
								گفتگوها
							</Typography>
						</Box>
					</Stack>
				</Stack>
				{(inputValue === '')?null:
					<Stack px={3}>
						<Box>
							<Typography>نتایج:</Typography>
						</Box>
						<Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
							{options?.map((option:IOption , index:number) => {
								return(
									<>
										<Stack
											key={index}
											sx={{
												border: 1,
												mt: 2,
												borderRadius: 5,
												borderColor: "rgba(128, 128, 128, 0.5)",
												'&:hover': {
													backgroundColor: "rgba(128, 128, 128, 0.5)"
												}
											}}
										>
											<li
												onClick={() => {
													if(handleChange) {
														handleChange(null, option)
													}
												}}
												style={{ cursor: "pointer", marginBottom: "8px", marginTop: "8px" }}
											>
												<Typography sx={{ px: 1 }}>
													{/*{option.result_chunk}*/}
													{getSurroundingText(option.title, inputValue!)}
												</Typography>
											</li>
										</Stack>
									</>
								)
							})}
						</Box>
					</Stack>
				}
			</Stack>
		</>
	)
}