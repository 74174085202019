import Box from "@mui/material/Box";
import {
	Button,
	outlinedInputClasses,
	TextField,
	Typography,
	useMediaQuery
} from "@mui/material";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import RemoveTwoToneIcon from "@mui/icons-material/RemoveTwoTone";
import * as React from "react";
import {useEffect, useState} from "react";
import theme from "../../../../../../lib/theme.const";
import IGetSettingPageData from "../../../../../../interface/IGetSettingPageData";
import RemoveSourcePopup from "./RemoveSourcePopup";
import EditIcon from "@mui/icons-material/Edit";
import {
	useAddSourceMutation,
	useLazyEditSourceQuery,
} from "../../../../../../features/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../app/store";
import {refreshSettingDataChanged} from "../../../../../../features/appSlice";

export default function AddNewSource({settingPageData}: { settingPageData:IGetSettingPageData |undefined}) {
	const [addSourceTrigger, { data: addSourceData }] = useAddSourceMutation();
	const [editSourceTrigger, { data: editSourceData }] = useLazyEditSourceQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const [open , setOpen] = useState<boolean>(false);
	const [idForRemove , setIdForRemove] = useState<number>();
	const [contentForEdit , setContentForEdit] = useState<{id:number , name:string}>();
	const [sourceTextForSave , setSourceTextForSave] = useState<string>();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const dispatch = useDispatch();

	useEffect(() => {
		if(addSourceData) {
			dispatch(refreshSettingDataChanged(addSourceData))
		}
	}, [addSourceData]);

	useEffect(() => {
		if(editSourceData) {
			dispatch(refreshSettingDataChanged(editSourceData))
		}
	}, [editSourceData]);

	useEffect(() => {
		if(!contentForEdit) return;

		setSourceTextForSave(contentForEdit.name)
	}, [contentForEdit]);

	function handleClose() {
		setOpen(false)
	}

	function handleClickOpenRemovePopup(id: number) {
		setIdForRemove(id)
		setOpen(true)
	}

	function handleClickSaveSource() {
		if(!token || !sourceTextForSave) return;

		if(contentForEdit) {
			editSourceTrigger({
				token: token,
				name: sourceTextForSave,
				source_id: contentForEdit.id
			})
		}
		else {
			addSourceTrigger({
				token: token,
				name: sourceTextForSave,
			})
		}
	}

	return(
		<>
			<Stack
				width={(downOfsm)?370:(downOfmd)?600:(downOflg)?470:650}
				alignItems={"center"}
				sx={{
					backgroundColor:"#808080",
					pt:3,
					pb:2,
					borderBottomLeftRadius: 15,
					borderBottomRightRadius: 15
				}}
				spacing={2}
			>
				<Stack
					direction={'row'}
					justifyContent={'space-between'}
					alignItems={'center'}
					spacing={1}
				>
					<TextField
						color={"warning"}
						value={sourceTextForSave}
						onChange={(event) => setSourceTextForSave(event.target.value)}
						sx={{
							[`& .${outlinedInputClasses.root}`]: {
								minWidth:(downOflg)?250:500,
								height:40,
								borderRadius:4,
								backgroundColor: "white",
							},
						}}
					/>
					<Button
						onClick={handleClickSaveSource}
						variant={'contained'}
						sx={{
							minWidth: 70,
							height:30,
							backgroundColor: "#E86942",
							'&:hover': {
								backgroundColor: "#8f3e04"
							},
						}}
					>
						ارسال
					</Button>
				</Stack>
				<Stack
					sx={{
						backgroundColor: "#D9D9D9",
						borderRadius: 3,
						width: "90%"
					}}
					spacing={2}
					p={2}
				>
					{settingPageData?.msg.sources.map((content , index) => {
						return(
							<Stack key={index} justifyContent={"center"} alignItems={"center"}>
								<Stack
									direction={"row"}
									justifyContent={"space-between"}
									alignItems={"center"}
									sx={{
										backgroundColor:"white",
										borderRadius:2,
										width:"95%"
									}}
									p={1}
								>
									<Stack direction={"row"} spacing={1} alignItems={"center"}>
										<IconButton
											sx={{width:25}}
											onClick={() => handleClickOpenRemovePopup(content.id)}
										>
											<RemoveTwoToneIcon color={"warning"}/>
										</IconButton>
										<IconButton
											sx={{width:25}}
											onClick={() => setContentForEdit(content)}
										>
											<EditIcon color={"warning"}/>
										</IconButton>
										<Box>
											<Typography>
												{content.name}
											</Typography>
										</Box>
									</Stack>
									<Stack direction={"row"}>
										<Box
											sx={{
												width: 0,
												height: 0,
												borderTop: '15px solid transparent',
												borderBottom: '15px solid transparent',
												borderRight: '8px solid #D9D9D9', // تغییر رنگ مثلث
												// margin: '20px auto' // مرکز کردن مثلث
											}}
										/>
										<Box
											sx={{
												backgroundColor: "#D9D9D9",
												width:25,
												height:30,
												borderBottomRightRadius:6,
												borderTopRightRadius:6
											}}
										/>
									</Stack>
								</Stack>
							</Stack>
						)
					})}
				</Stack>
			</Stack>
			<RemoveSourcePopup open={open} handleClose={handleClose} idForRemove={idForRemove}/>
		</>
	)
}