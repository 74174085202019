import Stack from "@mui/material/Stack";
import * as React from "react";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useMediaQuery} from "@mui/material";
import theme from "../../lib/theme.const";
import ProfileCards from "../../components/ProfileCards";
import PersonalPageImageStepper from "./components/PersonalPageImageStepper";
import FollowingBoxQuestioner from "./components/FollowingBoxQuestioner";
import NotesBox from "./components/notesBox/NotesBox";
import LikedAndMarkedQuestion from "./components/LikedAndMarkedQuestion/LikedAndMarkedQuestion";
import PopupNotification from "./components/PopupNotification";
import AuthenticationProvider from "../../components/AuthenticationProvider";
import FollowingsOrRecommandationForResearcher from "./components/FollowingsOrRecommendationsForResearcher/FollowingsOrRecommendationForResearcher";
import Footer from "../components/Footer/Footer";

function Page() {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const [notificationOpen , setNotificationOpen] = useState(false);
	const {personalPageData , role} = useSelector((state: RootState) => state.app);

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	const handleClickNotificationOpen = () => {
		setNotificationOpen(true)
	}

	const handleClose = () => {
		if(notificationOpen) {
			setNotificationOpen(false);
		}
	};

	const cardsData = {
		name: personalPageData?.msg.profile?.name ,
		username: personalPageData?.msg.profile.username ,
		created_at: personalPageData?.msg.profile.created_at ,
		education: personalPageData?.msg.profile?.education ,
		questions: personalPageData?.msg.asked_questions_count ,
		bio: personalPageData?.msg.profile?.bio ,
		picture: personalPageData?.msg.profile.photo
	}

	// const data = personalPageData?.msg.following?.concat(new Array(2).fill({
	// 	id: 7408203040 ,
	// 	name: "الهه وثوقی" ,
	// 	photo: "default" ,
	// 	username: "test5"
	// }))

	// const notes = personalPageData?.msg.notes?.concat(new Array(2).fill({
	// 	body: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ و با استفاده از طراحان گرافیک است چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است و برای شرایط فعلی تکنولوژی مورد نیاز و کاربردهای متنوع با هدف بهبود ابزارهای کاربردی می باشد کتابهای زیادی در شصت و سه درصد گذشته حال و آینده شناخت فراوان جامعه و متخصصان را می طلبد تا با نرم افزارها شناخت بیشتری را برای طراحان رایانه ای علی الخصوص طراحان خلاقی و فرهنگ پیشرو در زبان فارسی ایجاد کرد در این صورت می توان امید داشت که تمام و دشواری موجود در ارائه راهکارها و شرایط سخت تایپ به پایان رسد و زمان مورد نیاز شامل حروفچینی دستاوردهای اصلی و جوابگوی سوالات پیوسته اهل دنیای موجود طراحی اساسا مورد استفاده قرار گیرد.",
	// 	id: 1147108847,
	// 	title: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ"
	// }))

	return(
		<>
			<Stack sx={{backgroundColor:'white'}}>
				<Stack mb={'40px'}>
					<Stack>
						<ProfileCards data={cardsData} handleNotificationClick={handleClickNotificationOpen} personalData={personalPageData}/>
						<PersonalPageImageStepper personalPageData={personalPageData}/>
					</Stack>
					<Stack sx={{backgroundColor: "#F9F9FF"}}>
						<Stack
							direction={(downOfmd)?"column":"row"}
							justifyContent={(downOfmd)?"center":"space-between"}
							alignItems={"center"}
							px={(downOfsm)?2:5}
							width={'100%'}
							spacing={'10%'}
						>
							<Stack
								width={(downOfsm)?'100%':(downOfmd)?'60%':'30%'}
								alignSelf={(downOfmd)?'center':'flex-start'}
								pt={'40px'}
							>
								{(role === 'researcher')?
									<FollowingsOrRecommandationForResearcher personalPageData={personalPageData}/>:
									<FollowingBoxQuestioner data={personalPageData?.msg.following}/>
								}
							</Stack>
							<Stack
								width={(downOfmd)?'100%':'60%'}
							>
								<NotesBox notes={personalPageData?.msg.notes}/>
							</Stack>
						</Stack>
					</Stack>
					<Stack sx={{backgroundColor:'#D6DFEA'}}>
						<LikedAndMarkedQuestion personalPageData={personalPageData}/>
					</Stack>
				</Stack>
			</Stack>
			<PopupNotification
				open={notificationOpen}
				onClose={handleClose}
				personalPageData={personalPageData}
			/>
		</>
	)
}

export default function PersonalPage() {
	return(
		<AuthenticationProvider>
			<Page/>
		</AuthenticationProvider>
	)
}