import React from "react";
import { AiFillInstagram, AiOutlineTwitter, AiFillLinkedin } from "react-icons/ai";
import {Box, Typography, Link, useMediaQuery} from "@mui/material";
import Stack from "@mui/material/Stack";
import theme from "../../../lib/theme.const";

export default function Footer() {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Stack height={(downOfsm)?1600:"100%"}>
			<Stack
				height={"50%"}
				component="footer"
				sx={{
					backgroundColor: "#D9D9D9",
					fontSize: "16px",
					padding: "20px 0"
				}}
			>
				<Stack alignItems={"center"}>
					<Link href="/" underline="none" color="inherit" sx={{ fontSize: "30px", fontWeight: 600, marginBottom: "1rem", display: "inline-block" }}>
						متن آویس
					</Link>
					<Box>
						<img
							src={"./logoHeader.png"}
							width={(downOfsm)?"55px":"80px"}
							height={(downOfsm)?"55px":"80px"}
							alt={'matnavis icon'}
						/>
					</Box>
					<Box component="ul" sx={{ display: "flex", justifyContent: "center", gap: "2rem", padding: 0, listStyle: "none" }}>
						<Stack component="li" sx={{flexDirection: "row", gap: "1rem", alignItems: "center" }}>
							{/*<Link href="/#" color="inherit">خانه</Link>*/}
							<Link href="https://matnavis.com/" color="inherit">وب سایت متن آویس</Link>
							{/*<Link color="inherit">پلتفرم پژوهش</Link>*/}
							{/*<Link href="/#about" color="inherit">درباره ما</Link>*/}
							<Link href="/#contact" color="inherit">ارتباط با ما</Link>
							{/*<Link href="/#services" color="inherit">خدمات</Link>*/}
							{/*<Link href="/#projects" color="inherit">پروژه‌ها</Link>*/}
						</Stack>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "center", gap: "1rem", marginBottom: "20px" }}>
						<Link href="https://www.instagram.com/matn.avis?igsh=MTlsZzYxODY2N2FzaQ==" target="_blank" color="inherit" sx={{ display: "flex", fontSize: "28px" }}>
							<AiFillInstagram />
						</Link>
						<Link href="https://twitter.com" target="_blank" color="inherit" sx={{ display: "flex", fontSize: "28px" }}>
							<AiOutlineTwitter />
						</Link>
						<Link href="https://linkedin.com" target="_blank" color="inherit" sx={{ display: "flex", fontSize: "28px" }}>
							<AiFillLinkedin />
						</Link>
					</Box>
					<Stack alignItems={"center"} sx={{ marginBottom: "20px" }}>
						<Typography>تهران، ولنجک، میدان شهید شهریاری، بلوار دانشجو،</Typography>
						<Typography>دانشگاه شهید بهشتی، دانشکدهٔ معماری و شهرسازی، مرکز نوآوری</Typography>
						<Typography>۰۲۱-۲۹۹۰۲۸۷۰</Typography>
						<Typography>info@matnavis.com</Typography>
					</Stack>
					<Box>
						<Typography sx={{ color: "var(--color-primary)" }}>
							همه حقوق متعلق به شرکت مان تحقیق و نظر آویس است &copy;
						</Typography>
					</Box>
					{/*<Box>*/}
					{/*	<a*/}
					{/*		referrerPolicy='origin'*/}
					{/*		target='_blank'*/}
					{/*		href='https://trustseal.enamad.ir/?id=348001&Code=KuKJRNUS0rCvVuOJvHe6OsWCY9wZ20Ud'*/}
					{/*	>*/}
					{/*		<img*/}
					{/*			referrerPolicy='origin'*/}
					{/*			src='https://trustseal.enamad.ir/logo.aspx?id=348001&Code=KuKJRNUS0rCvVuOJvHe6OsWCY9wZ20Ud'*/}
					{/*			alt=''*/}
					{/*			// style='cursor:pointer'*/}
					{/*			width={300}*/}
					{/*			height={300}*/}
					{/*			// code='KuKJRNUS0rCvVuOJvHe6OsWCY9wZ20Ud'*/}
					{/*			// code={'KuKJRNUS0rCvVuOJvHe6OsWCY9wZ20Ud'}*/}
					{/*		/>*/}
					{/*	</a>*/}
					{/*</Box>*/}
				</Stack>
			</Stack>
		</Stack>
	);
}