import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LanguageType } from "../types/LanguageType";
import IGetPersonalPageData from "../interface/IGetPersonalPageData";
import IValuesProfileSetting from "../interface/IValuesProfileSetting";
import IValuesNotifsSetting from "../interface/IValuesNotifsSetting";
import IGetFinanceContextResult from "../interface/IGetFinanceContextResult";
import IGetGeneralBaseData from "../interface/IGetGeneralBaseData";

interface ISearchedResearcher {
  id: number;
  name: string;
  photo: string;
  username: string;
}

interface IState {
  language: LanguageType;
  token: string | undefined | null;
  role: string;
  sectionSettingQuestioner?: string;
  searchedResearcher?: ISearchedResearcher,
  generalData ?: IGetGeneralBaseData,
  personalPageData?: IGetPersonalPageData | undefined,
  profileSettingData?: IValuesProfileSetting,
  notifsSettingData?: IValuesNotifsSetting,
  topicSearchHeader?: string,
  openConversationDrawer: boolean,
  invalidToken: boolean,
  openPopupSearchType: boolean,
  financeContext?: IGetFinanceContextResult | undefined,
  financeStatus?: string,
  checkRegulations: string | undefined,
  refreshSettingData?: any,
  refreshGeneralData?: any,
  openMatnavisHeaderDrawer: boolean
}

const initialState: IState = {
  token: undefined,
  language: "fa",
  role: "questioner",
  openConversationDrawer: true,
  invalidToken: false,
  openPopupSearchType: false,
  checkRegulations: undefined,
  openMatnavisHeaderDrawer: false
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    languageChanged(state, action: PayloadAction<LanguageType>) {
      state.language = action.payload;
    },
    tokenChanged(state, action: PayloadAction<string | undefined | null>) {
      state.token = action.payload;
    },
    roleChanged(state, action: PayloadAction<string>) {
      state.role = action.payload;
    },
    sectionSettingQuestionerChanged(state, action: PayloadAction<string>) {
      state.sectionSettingQuestioner = action.payload;
    },
    searchedResearcherChanged(state, action: PayloadAction<ISearchedResearcher | undefined>) {
      state.searchedResearcher = action.payload;
    },
    personalPageDataChanged(state, action: PayloadAction<IGetPersonalPageData | undefined>) {
      state.personalPageData = action.payload;
    },
    profileSettingDataChanged(state, action: PayloadAction<IValuesProfileSetting | undefined>) {
      state.profileSettingData = action.payload;
    },
    notifsSettingDataChanged(state, action: PayloadAction<IValuesNotifsSetting | undefined>) {
      state.notifsSettingData = action.payload;
    },
    topicSearchHeaderChanged(state, action: PayloadAction<string | undefined>) {
      state.topicSearchHeader = action.payload;
    },
    openConversationDrawerChanged(state, action: PayloadAction<boolean>) {
      state.openConversationDrawer = action.payload;
    },
    invalidTokenChanged(state, action: PayloadAction<boolean>) {
      state.invalidToken = action.payload;
    },
    openPopupSearchTypeChanged(state, action: PayloadAction<boolean>) {
      state.openPopupSearchType = action.payload;
    },
    financeContextChanged(state, action: PayloadAction<IGetFinanceContextResult | undefined>) {
      state.financeContext = action.payload;
    },
    financeStatusChanged(state, action: PayloadAction<string>) {
      state.financeStatus = action.payload;
    },
    checkRegulationsChanged(state, action: PayloadAction<string | undefined>) {
      state.checkRegulations = action.payload;
    },
    refreshSettingDataChanged(state, action: PayloadAction<any>) {
      state.refreshSettingData = action.payload;
    },
    openMatnavisHeaderDrawerChanged(state, action: PayloadAction<boolean>) {
      state.openMatnavisHeaderDrawer = action.payload;
    },
    refreshGeneralDataChanged(state, action: PayloadAction<any>) {
      state.refreshGeneralData = action.payload;
    },
    generalDataChanged(state, action: PayloadAction<IGetGeneralBaseData | undefined>) {
      state.generalData = action.payload;
    }
  },
});

export const {
  languageChanged ,
  tokenChanged ,
  roleChanged ,
  sectionSettingQuestionerChanged,
  searchedResearcherChanged,
  personalPageDataChanged ,
  profileSettingDataChanged ,
  notifsSettingDataChanged ,
  topicSearchHeaderChanged ,
  openConversationDrawerChanged ,
  invalidTokenChanged,
  openPopupSearchTypeChanged,
  financeContextChanged ,
  financeStatusChanged ,
  checkRegulationsChanged ,
  refreshSettingDataChanged ,
  openMatnavisHeaderDrawerChanged ,
  refreshGeneralDataChanged ,
  generalDataChanged
} = appSlice.actions;
export default appSlice.reducer;
