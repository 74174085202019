import {
	Avatar,
	Button,
	Dialog,
	dialogClasses,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	useMediaQuery
} from "@mui/material";
import Stack from "@mui/material/Stack";
import theme from "../../../../lib/theme.const";
import {styled} from "@mui/material/styles";
import {useLazyGetResearcherRequestQuery} from "../../../../features/apiSlice";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import IRequestResearcherData from "../../../../interface/IRequestResearcherData";
import ApprovalCVResearcherPopup from "./ApprovalCVResearcherPopup";

interface IProps {
	open: boolean,
	onClose: () => void;
	setOpenResearcherPopup: Dispatch<SetStateAction<boolean>>
}

const CustomScrollbarRoot = styled('div')({
	position: 'relative',
	width: '100%',
	height: "100%",
	overflowY: 'auto',
	overflowX: 'hidden',
	direction: 'rtl',
	mr:3,
	'& > *': {
		direction: 'ltr',
	},
	'&::-webkit-scrollbar': {
		width: '10px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: "#D9D9D9",
		borderRadius: '10px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#a4a4a4',
		// border:1,
		// borderColor:"#4f4f4f",
		borderRadius: '10px',
	},
});

export default function ApprovalResearcherPopup({open , onClose , setOpenResearcherPopup}:IProps) {
	const [researcherRequestTrigger , {data: researcherRequestData}] = useLazyGetResearcherRequestQuery();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const {token} = useSelector((state: RootState) => state.app);
	const [openCVResearcherPopup , setOpenCVResearcherPopup] = useState<boolean>(false);
	const [selectedResearcherRequest , setSelectedResearcherRequest] = useState<IRequestResearcherData>();
	const [updateResearcherRequestData , setUpdateResearcherRequestData] = useState<any>();

	useEffect(() => {
		if(!token) return;

		researcherRequestTrigger({
			token: token
		})
	}, [token , updateResearcherRequestData]);

	function handleOpenRequestPage(user:IRequestResearcherData) {
		handleOpenCVResearcherPopup(user)
	}

	function handleOpenCVResearcherPopup(user:IRequestResearcherData) {
		setSelectedResearcherRequest(user)
		setOpenCVResearcherPopup(true)
		setOpenResearcherPopup(false)
	}

	function handleCloseCVResearcherPopup() {
		setOpenCVResearcherPopup(false)
		setSelectedResearcherRequest(undefined)
	}

	console.log(researcherRequestData)

	return(
		<>
			<Dialog
				open={open}
				onClose={onClose}
				sx={{
					[`& .${dialogClasses.paper}`]: {
						borderRadius: 6,
						top: (downOfsm)?-95:0,
						height: (downOfsm)?650:"100%",
					},
				}}
			>
				<DialogTitle sx={{backgroundColor: "#D9D9D9"}}>
					<Stack p={1} justifyContent={"center"} alignItems={"center"}>
						<Typography sx={{color:"#989898"}}>
							تایید پژوهشگر
						</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent sx={{backgroundColor: "#D9D9D9"}}>
					<CustomScrollbarRoot>
						<Stack justifyContent={"center"} alignItems={"center"} ml={0}>
							<Grid container spacing={2}>
								{researcherRequestData?.msg.users?.map((user:IRequestResearcherData , index:number) => {
									return(
										<Grid item xs={(downOfsm || researcherRequestData?.msg.users.length === 1)?12:12/2} key={index}>
											<Stack
												direction={"row"}
												justifyContent={"space-between"}
												spacing={6}
												sx={{
													backgroundColor:"white",
													p:1.5,
													borderRadius:2,
													width: "100%"
												}}
											>
												<Stack>
													<Avatar
														src={`https://api.matnavis.com/files/${user.profile.photo}`}
														sx={{
															width:75,
															height:75
														}}
													/>
												</Stack>
												<Stack alignSelf={"flex-end"}>
													<Stack alignSelf={"flex-end"}>
														<Typography>
															{user.profile.name}
														</Typography>
													</Stack>
													<Stack alignSelf={"flex-end"}>
														<Button
															variant={"contained"}
															sx={{
																height:30 ,
																minWidth:75,
																backgroundColor: "#E86942",
																'&:hover': {
																	backgroundColor: "#8f3e04"
																},
																boxShadow: "0px 5px 10px 3px #656464"
															}}
															onClick={() => handleOpenRequestPage(user)}
														>
															دیدن
														</Button>
													</Stack>
												</Stack>
											</Stack>
										</Grid>
									)
								})}
							</Grid>
						</Stack>
					</CustomScrollbarRoot>
				</DialogContent>
			</Dialog>
			<ApprovalCVResearcherPopup
				open={openCVResearcherPopup}
				onClose={handleCloseCVResearcherPopup}
				selectedResearcherRequest={selectedResearcherRequest}
				setUpdateResearcherRequestData={setUpdateResearcherRequestData}
			/>
		</>
	)
}