import {
	Dialog,
	dialogClasses,
	DialogContent,
	DialogTitle, Typography, useMediaQuery
} from "@mui/material";
import {useEffect, useState} from "react";
import DefaultContent from "./DefaultContent";
import SceneContent from "./SceneContent";
import CityContent from "./CityContent";
import ArchitectureContent from "./ArchitectureContent";
import ArtContent from "./ArtContent";
import Stack from "@mui/material/Stack";
import theme from "../../../../lib/theme.const";
import ChooseTypeOfQuestionPopup from "../../../../components/SetQuestion/ChooseTypeOfQuestionPopup";

export interface SimpleDialogProps {
	open: boolean;
	onClose: (value: string) => void;
}

export default function SubjectPopup({open , onClose}: SimpleDialogProps) {
	const [subject , setSubject] = useState<string>();
	const [openChooseTypeOfQuestion , setOpenChooseTypeOfQuestion] = useState<boolean>(false);
	const [selectedSubject , setSelectedSubject] = useState<string>();
	const [checkedAvailableOrSelectedResearcher, setCheckedAvailableOrSelectedResearcher] = useState<number>();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if(checkedAvailableOrSelectedResearcher === 3) {
			setCheckedAvailableOrSelectedResearcher(undefined)
		}
	}, [checkedAvailableOrSelectedResearcher]);

	useEffect(() => {
		if(!open) {
			setSubject(undefined)
		}
	}, [open]);

	function handleOpenChooseTypeOfQuestion(value:string) {

		setSelectedSubject(value)
		setOpenChooseTypeOfQuestion(true)
	}

	function handleCloseChooseTypeOfQuestion() {
		setOpenChooseTypeOfQuestion(false)
	}

	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					[`& .${dialogClasses.paper}`]: {
						borderRadius:5,
					},
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor: "#0067A5",
						color: "white",
						display: "flex",
						justifyContent: "center"
					}}
				>
					{(subject === 'scene')?
						<Typography fontWeight={'bold'} fontSize={20}>
							تاریخ منظر
						</Typography>:
						(subject === 'city')?
							<Typography fontWeight={'bold'} fontSize={20}>
								تاریخ شهر
							</Typography>:
							(subject === 'architecture')?
								<Typography fontWeight={'bold'} fontSize={20}>
									تاریخ معماری
								</Typography>:
								(subject === 'art')?
									<Typography fontWeight={'bold'} fontSize={20}>
										تاریخ هنر
									</Typography>:
									<Typography fontWeight={'bold'} fontSize={20}>
										موضوعی
									</Typography>
					}
				</DialogTitle>
				<DialogContent
					sx={{
						backgroundColor: "#EDEDED",
						width: "100%",
						height: "100%",
					}}
				>
					<Stack py={0} alignItems={"center"} justifyContent={"center"} width={"100%"}>
						{(!subject)?
							<DefaultContent setSubject={setSubject}/>:
							(subject === "scene")?
								<SceneContent handleOpenChooseTypeOfQuestion={handleOpenChooseTypeOfQuestion}/>:
								(subject === "city")?
									<CityContent handleOpenChooseTypeOfQuestion={handleOpenChooseTypeOfQuestion}/>:
									(subject === "architecture")?
										<ArchitectureContent handleOpenChooseTypeOfQuestion={handleOpenChooseTypeOfQuestion}/>:
										(subject === "art")?
											<ArtContent handleOpenChooseTypeOfQuestion={handleOpenChooseTypeOfQuestion}/>:
											null
						}
					</Stack>
				</DialogContent>
			</Dialog>
			<ChooseTypeOfQuestionPopup
				open={openChooseTypeOfQuestion}
				onClose={handleCloseChooseTypeOfQuestion}
				selectedSubject={selectedSubject}
				checkedAvailableOrSelectedResearcher={checkedAvailableOrSelectedResearcher}
				setCheckedAvailableOrSelectedResearcher={setCheckedAvailableOrSelectedResearcher}
			/>
		</>
	)
}