import {
	Dialog,
	DialogContent,
	DialogTitle,
	paperClasses, Typography, useMediaQuery,
} from "@mui/material";
import * as React from "react";
import SearchBox from "./search/SearchBox";
import Stack from "@mui/material/Stack";
import {useCallback, useEffect, useState} from "react";
import {useLazyFetchSearchQuestionQuery, useLazyQuestionsFullTextSearchQuery} from "../../../../features/apiSlice";
import {useNavigate} from "react-router-dom";
import Box from "@mui/material/Box";
import theme from "../../../../lib/theme.const";

export interface SimpleDialogProps {
	open: boolean;
	onClose: (value: string) => void;
}

interface Option {
	question_id: number,
	result_chunk: string,
}

export default function TopicsPopup({open , onClose}: SimpleDialogProps) {
	const [searchTrigger, { data: searchData }] = useLazyFetchSearchQuestionQuery();
	const [searchQuestionTrigger, { data: searchQuestionData }] = useLazyQuestionsFullTextSearchQuery();
	const [topic, setTopic] = useState<string>();
	const [inputValue, setInputValue] = useState<string>("");
	const [selectedOption, setSelectedOption] = useState<Option | null>(null);
	const navigate = useNavigate();
	const [openAutocomplete, setOpenAutocomplete] = useState(false);
	const [typing, setTyping] = useState(false);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if (!open) {
			setTopic(undefined);
			setInputValue("");
			setOpenAutocomplete(false);
		}
	}, [open]);

	useEffect(() => {
		if (inputValue.length === 0) return;

		searchQuestionTrigger({
			token: '',
			search: inputValue
		})

	}, [inputValue]);

	function handleClick(value: string) {
		setTyping(false);
		setTopic(value);
		setInputValue(value);
		setOpenAutocomplete(true);
	}

	useEffect(() => {
		if(!selectedOption) return;

		searchTrigger({
			token: '',
			question_id: String(selectedOption.question_id)
		}).then((res) => {
			navigate("/allComments", { state: {data:res.data?.msg.questions[0] , id:1} });
		})
	}, [selectedOption]);

	const handleChange = useCallback(
		(event: any, newValue: Option | null) => {
			if (newValue) {
				setInputValue(newValue.result_chunk);
				setSelectedOption(newValue);
				setOpenAutocomplete(false);
			}
		}, []);

	const handleInputChange = useCallback((_: any, newValue: any) => {

		if (newValue.length > 0 && !typing) {
			setTyping(true);
			// console.log('User started typing...');
			setInputValue(newValue)
		}
		else if (newValue.length === 0 && typing && topic) {
			setTyping(false);
			// console.log('User stopped typing.');
			setTimeout(() => {
				setInputValue(newValue)
			}, 300)
		}
		else if(typing && topic && newValue.length > 0) {
			setInputValue(newValue)
		}
		else if(typing && !topic && newValue.length > 0) {
			setInputValue(newValue)
		}
		else if(!topic && typing && newValue.length === 0) {
			setTimeout(() => {
				setInputValue(newValue)
			}, 300)
		}

	}, [searchQuestionData , topic , typing]);

	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					[`& .${paperClasses.root}`]: {
						height: "100%",
						width: (downOfsm)?"100%":"40%",
						borderRadius:3
					}
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor: "#0067A5",
						color: "white",
						display:'flex',
						justifyContent:'center'
					}}
				>
					<Typography fontWeight={'bold'} fontSize={20}>
						جستجوی کلید واژه
					</Typography>
				</DialogTitle>
				<DialogContent sx={{backgroundColor: "#EDEDED"}}>
					<Stack alignItems={"center"} pt={3}>
						<SearchBox
							searchData={searchQuestionData}
							inputValue={inputValue}
							handleChange={handleChange}
							selectedOption={selectedOption}
							handleInputChange={handleInputChange}
							openAutocomplete={openAutocomplete}
							setOpenAutocomplete={setOpenAutocomplete}
							topic={topic}
						/>
					</Stack>
					<Stack px={4}>
						<Box pt={4} pb={2.5}>
							<Typography>
								کلیدواژه‌های پیشنهادی:
							</Typography>
						</Box>
						<Stack>
							<Box
								onClick={() => handleClick("چاپ1")}
								sx={{
									p:0.7,
									cursor: "pointer",
									'&:hover': {
										backgroundColor: "rgba(128, 128, 128, 0.5)"
									},
									backgroundColor: (topic === "چاپ1")?"rgba(128, 128, 128, 0.5)":undefined
								}}
							>
								<Typography fontWeight={"bold"}>
									چاپ1
								</Typography>
							</Box>
							<Box
								onClick={() => handleClick("معماری منظر")}
								sx={{
									p:0.7,
									cursor: "pointer",
									'&:hover': {
										backgroundColor: "rgba(128, 128, 128, 0.5)"
									},
									backgroundColor: (topic === "معماری منظر")?"rgba(128, 128, 128, 0.5)":undefined
								}}
							>
								<Typography fontWeight={"bold"}>
									معماری منظر
								</Typography>
							</Box>
							<Box
								onClick={() => handleClick("شهرسازی")}
								sx={{
									p:0.7,
									cursor: "pointer",
									'&:hover': {
										backgroundColor: "rgba(128, 128, 128, 0.5)"
									},
									backgroundColor: (topic === "شهرسازی")?"rgba(128, 128, 128, 0.5)":undefined
								}}
							>
								<Typography fontWeight={"bold"}>
									شهرسازی
								</Typography>
							</Box>
							<Box
								onClick={() => handleClick("باغ سازی")}
								sx={{
									p:0.7,
									cursor: "pointer",
									'&:hover': {
										backgroundColor: "rgba(128, 128, 128, 0.5)"
									},
									backgroundColor: (topic === "باغ سازی")?"rgba(128, 128, 128, 0.5)":undefined
								}}
							>
								<Typography fontWeight={"bold"}>
									باغ سازی
								</Typography>
							</Box>
							<Box
								onClick={() => handleClick("تاریخ هنر")}
								sx={{
									p:0.7,
									cursor: "pointer",
									'&:hover': {
										backgroundColor: "rgba(128, 128, 128, 0.5)"
									},
									backgroundColor: (topic === "تاریخ هنر")?"rgba(128, 128, 128, 0.5)":undefined
								}}
							>
								<Typography fontWeight={"bold"}>
									تاریخ هنر
								</Typography>
							</Box>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	)
}