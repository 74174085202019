import {useLazySubjectiveSearchResearcherQuery} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import React, {useEffect} from "react";
import Stack from "@mui/material/Stack";
import AllQuestionOthers from "./AllQuestionOthers";
import Box from "@mui/material/Box";
import {Avatar, Divider, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

interface IProps {
	handleOpenChooseTypeOfQuestion: (value:string) => void;
}

export default function ArtContent({handleOpenChooseTypeOfQuestion}:IProps) {
	const [searchTrigger, { data: searchData }] = useLazySubjectiveSearchResearcherQuery();
	const { token } = useSelector((state: RootState) => state.app);
	const navigate = useNavigate();

	useEffect(() => {

		searchTrigger({
			token: '',
			subject: "تاریخ هنر"
		})
	}, []);

	const sortedResearchersData = (Array.isArray(searchData?.msg))?searchData?.msg.slice().sort((a , b) => {
		if (a.name.split(" ")[1].toLowerCase() < b.name.split(" ")[1].toLowerCase()) {
			return -1;
		}
		if (a.name.split(" ")[1].toLowerCase() > b.name.split(" ")[1].toLowerCase()) {
			return 1;
		}
		return 0;
	}):[]

	return(
		<>
			<Stack justifyContent={"space-between"} pt={3} width={"100%"}>
				<Stack alignItems={"center"} justifyContent={"center"} spacing={1}>
					<Stack
						onClick={() => handleOpenChooseTypeOfQuestion("تاریخ هنر")}
						spacing={3}
						direction={"row"}
						alignItems={"center"}
						alignSelf={"flex-start"}
						px={2}
						sx={{cursor:"pointer"}}
					>
						<Stack
							width={"20%"}
							alignItems={"center"}
							justifyContent={"center"}
							sx={{
								height: 45,
								width: 45,
								borderRadius: "50%",
								backgroundColor: "#E86942"
							}}
						>
							<img
								height={75}
								width={75}
								src={"./manzarIcon.png"}
							/>
						</Stack>
						<Stack width={"80%"}>
							<Typography>
								اینجا بپرسید...
							</Typography>
						</Stack>
					</Stack>
					<Divider sx={{width: "95%"}}/>
				</Stack>
				<Stack px={4}>
					<Stack
						direction={"row"}
						justifyContent={"center"}
						alignItems={"center"}
						width={"100%"}
						pt={2}
						spacing={2}
					>
						<Stack width={"10%"}>
							<Box
								sx={{
									backgroundColor: "#E86942",
									borderRadius: "50%",
									width: 30,
									height: 30
								}}
							/>
						</Stack>
						<Box width={"80%"}>
							<Typography>
								پژوهشگران حوزه تاریخ منظر به ترتیب الفبا
							</Typography>
						</Box>
					</Stack>
					<Stack pt={2} width={"100%"}>
						<Stack width={"100%"}>
							{sortedResearchersData?.map((researcher:any , index:number) => {
								return(
									<Stack
										justifyContent={"center"}
										alignItems={"center"}
										direction={"row"}
										pt={2}
										key={index}
										onClick={() => {navigate("/general-researcher-page" , {state: researcher.id})}}
										sx={{cursor: "pointer"}}
									>
										<Stack width={"15%"}>
											<Avatar
												src={`https://api.matnavis.com/files/${researcher.photo}`}
												sx={{
													width:30,
													height:30,
													transition: 'width 0.3s ease',
												}}
											/>
										</Stack>
										<Box width={"70%"}>
											<Typography>
												{researcher.name}
											</Typography>
										</Box>
									</Stack>
								)
							})}
						</Stack>
						{/*<Stack pt={3}>*/}
						{/*	{(index < researchersData.msg.length - 1)?*/}
						{/*		<Divider sx={{width: 250}}/>: null*/}
						{/*	}*/}
						{/*</Stack>*/}
					</Stack>
				</Stack>
				<Stack pt={3} width={"100%"}>
					<AllQuestionOthers subject={"تاریخ منظر"}/>
				</Stack>
			</Stack>
		</>
	)
}