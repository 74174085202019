import {
	Dialog,
	DialogContent, Divider,
	Paper,
	paperClasses, Typography, useMediaQuery,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import IosShareIcon from '@mui/icons-material/IosShare';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import TurnedInNotIcon from '@mui/icons-material/TurnedInNot';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import theme from "../../lib/theme.const";
import {
	useLazyAddLikeQuery,
	useLazyDeleteLikeQuery,
	useLazyMarkQuestionQuery,
	useLazyUnMarkQuestionQuery
} from "../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import SetCommentPopup from "../SetCommentPopup";
import QuestionCitationPopup from "./QuestionCitationPopup";

export interface SimpleDialogProps {
	open: boolean;
	onClose: (value: string) => void;
	questionId: number | undefined;
}

export default function MoreIconPopup({open , onClose , questionId}: SimpleDialogProps) {
	const [markQuestionTrigger, { data: markQuestionData }] = useLazyMarkQuestionQuery();
	const [unMarkQuestionTrigger, { data: unMarkQuestionData }] = useLazyUnMarkQuestionQuery();
	const [addLikeTrigger, { data: addLikeData }] = useLazyAddLikeQuery();
	const [deleteLikeTrigger, { data: deleteLikeData }] = useLazyDeleteLikeQuery();
	const { token } = useSelector((state: RootState) => state.app);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const [saved , setSaved] = useState<boolean>(false);
	const [download , setDownload] = useState<boolean>(false);
	const [share , setShare] = useState<boolean>(false);
	const [favorite , setFavorite] = useState<boolean>(false);
	const [quotation , setQuotation] = useState<boolean>(false);
	const [comment , setComment] = useState<boolean>(false);
	const [commentOpen , setCommentOpen] = useState(false);
	const [citationPopup , setCitationPopup] = useState<boolean>(false);

	useEffect(() => {
		if(quotation) {
			setCitationPopup(true)
		}
	}, [quotation]);

	useEffect(() => {
		if(share) {
			navigator.share({
				title: "",
				text: "Learn web development on MDN!",
				url: window.location.href,
			}).then(() => {
				setShare(false)
			})
		}
	}, [share , window]);

	useEffect(() => {
		if(!token || !questionId) return;

		if(!saved) {
			markQuestionTrigger({
				token: token,
				question_id: questionId
			})
		}
		else {
			unMarkQuestionTrigger({
				token: token,
				question_id: questionId
			})
		}
	}, [token , questionId , saved]);

	useEffect(() => {
		if(!token || !questionId) return;

		if(!favorite) {
			addLikeTrigger({
				token: token,
				question_id: questionId
			})
		}
		else {
			deleteLikeTrigger({
				token: token,
				question_id: questionId
			})
		}
	}, [token , questionId , favorite]);

	useEffect(() => {
		if(!comment) {
			setCommentOpen(false)
		}
	}, [comment]);

	function handleClosePopup() {
		onClose("");
		setSaved(false)
		setDownload(false)
		setShare(false)
		setFavorite(false)
		setQuotation(false)
		setComment(false)
	}

	function handleClickMarkQuestion() {
		setSaved(!saved)
	}

	function handleClickAddLike() {
		setFavorite(!favorite)
	}

	const handleClickCommentOpen = (value: any) => {
		setComment(!comment)
		setCommentOpen(true)
	}

	const handleClose = () => {
		setComment(false)
		setCommentOpen(false)
	};

	function handleCitation() {
		setQuotation(!quotation)
	}

	const handleCloseCitation = () => {
		setQuotation(false)
		setCitationPopup(false)
	};

	function handleClickShare() {
		setShare(!share)
	}

	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					mt: 18,
					[`& .${paperClasses.root}`]: {
						height: (downOfsm)?"60%":"100%",
						width: (downOfsm)?350:600,
						borderRadius:3
					}
				}}
			>
				<DialogContent
					sx={{
						backgroundColor: "#D9D9D9",
						p:0,
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<Stack alignItems={"center"}>
						<Paper
							sx={{
								backgroundColor: "#D9D9D9",
								p:2,
								maxWidth: "80%",
								boxShadow: "10px 30px 40px 10px #949494"
							}}
						>
							<Stack>
								<Stack>
									<Box
										alignSelf={"flex-end"}
										py={0.5}
									>
										<IconButton onClick={handleClosePopup}>
											<CloseIcon/>
										</IconButton>
									</Box>
									<Divider
										sx={{
											borderWidth: "2px 0px 0px thin",
											backgroundColor: "#949494"
										}}
									/>
								</Stack>
								<Stack>
									<Stack direction={'row'} alignItems={'center'} spacing={2}>
										<Box py={0.5} pl={0.5}>
											<IconButton onClick={() => setDownload(!download)}>
												<DownloadIcon color={(download)?"warning":undefined}/>
											</IconButton>
										</Box>
										<Box>
											<Typography fontWeight={'bold'}>
												دانلود پی دی اف
											</Typography>
										</Box>
									</Stack>
									<Divider
										sx={{
											borderWidth: "2px 0px 0px thin",
											backgroundColor: "#949494"
										}}
									/>
								</Stack>
								<Stack>
									<Stack direction={'row'} alignItems={'center'} spacing={2}>
										<Box py={0.5} pl={0.5}>
											<IconButton onClick={handleClickShare}>
												<IosShareIcon color={(share)?"warning":undefined}/>
											</IconButton>
										</Box>
										<Box>
											<Typography fontWeight={'bold'}>
												اشتراک گذاری
											</Typography>
										</Box>
									</Stack>
									<Divider
										sx={{
											borderWidth: "2px 0px 0px thin",
											backgroundColor: "#949494"
										}}
									/>
								</Stack>
								<Stack>
									<Stack direction={'row'} alignItems={'center'} spacing={2}>
										<Box py={0.5} pl={0.5}>
											<IconButton onClick={handleClickMarkQuestion}>
												{(saved)?
													<TurnedInIcon color={"warning"}/>
													: <TurnedInNotIcon/>
												}
											</IconButton>
										</Box>
										<Box>
											<Typography fontWeight={'bold'}>
												بوک مارک
											</Typography>
										</Box>
									</Stack>
									<Divider
										sx={{
											borderWidth: "2px 0px 0px thin",
											backgroundColor: "#949494"
										}}
									/>
								</Stack>
								<Stack>
									<Stack direction={'row'} alignItems={'center'} spacing={2}>
										<Box py={0.5} pl={0.5}>
											<IconButton onClick={handleClickAddLike}>
												<FavoriteIcon color={(favorite)?"warning":undefined}/>
											</IconButton>
										</Box>
										<Box>
											<Typography fontWeight={'bold'}>
												لایک
											</Typography>
										</Box>
									</Stack>
									<Divider
										sx={{
											borderWidth: "2px 0px 0px thin",
											backgroundColor: "#949494"
										}}
									/>
								</Stack>
								<Stack>
									<Stack direction={'row'} alignItems={'center'} spacing={2}>
										<Box>
											<IconButton onClick={handleCitation}>
												<FormatQuoteIcon
													color={(quotation)?"warning":undefined}
													sx={{
														width: 32,
														height: 32
													}}
												/>
											</IconButton>
										</Box>
										<Box>
											<Typography fontWeight={'bold'}>
												بیبلوگرافی
											</Typography>
										</Box>
									</Stack>
									<Divider
										sx={{
											borderWidth: "2px 0px 0px thin",
											backgroundColor: "#949494"
										}}
									/>
								</Stack>
								<Box pl={0.5} pt={0.5}>
									<Stack direction={'row'} alignItems={'center'} spacing={2}>
										<IconButton onClick={handleClickCommentOpen}>
											{(comment)?
												<img
													src={"./massageIconColorFull.png"}
													style={{
														width:22,
														height:22
													}}
												/>:
												<img
													src={"./massageIcon.png"}
													style={{
														width:22,
														height:22
													}}
												/>
											}
										</IconButton>
										<Box>
											<Typography fontWeight={'bold'}>
												کامنت
											</Typography>
										</Box>
									</Stack>
								</Box>
							</Stack>
						</Paper>
					</Stack>
				</DialogContent>
			</Dialog>
			<SetCommentPopup
				open={commentOpen}
				onClose={handleClose}
				questionId={questionId}
				subCommentId={undefined}
			/>
			<QuestionCitationPopup
				onClose={handleCloseCitation}
				open={citationPopup}
				questionId={questionId}
			/>
		</>
	)
}