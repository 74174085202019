import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {Autocomplete, autocompleteClasses, outlinedInputClasses, TextField, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import * as React from "react";
import {memo, PropsWithChildren, useCallback, useEffect, useState} from "react";
import theme from "../../lib/theme.const";
import {useLazyGeneralSearchQuery} from "../../features/apiSlice";
import SearchPaperHeaderResult from "./SearchPaperHeaderResult";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useNavigate} from "react-router-dom";

interface IOption {
	id:number,
	type:string,
	title:string
}

export default function SearchHeader() {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const [generalSearchTrigger , {data: generalSearchData}] = useLazyGeneralSearchQuery();
	const [options , setOptions] = useState<IOption[]>([]);
	const [inputValue , setInputValue] = useState<string>("");
	const [selectedOption , setSelectedOption] = useState<IOption | null>(null);
	const [openAutocomplete, setOpenAutocomplete] = useState<boolean>(false);
	const [typeOfSearch , setTypeOfSearch] = useState<string>();
	const navigate = useNavigate();

	useEffect(() => {

		if(typeOfSearch === 'researcher') {
			generalSearchTrigger({
				token: "",
				query: inputValue,
				researcher_only: true
			})
		}
		else if(typeOfSearch === 'questioner') {
			generalSearchTrigger({
				token: "",
				query: inputValue,
				questioner_only: true
			})
		}
		else if(typeOfSearch === 'question') {
			generalSearchTrigger({
				token: "",
				query: inputValue,
				question_only: true
			})
		}
		else {
			generalSearchTrigger({
				token: "",
				query: inputValue
			})
		}

	}, [inputValue , typeOfSearch]);

	useEffect(() => {
		if (!generalSearchData) {
			return;
		}

		if (generalSearchData.status === "OK") {
			let newOptions: any[] = [];

			newOptions = [...newOptions, ...generalSearchData.msg].filter(
				(val, idx, self) =>
					self.map((x, index) => {
						if (val.result_chunk === x.result_chunk) {
							if (idx === index) {
								return true;
							}
						} else {
							return false;
						}
					})
			);

			setOptions(newOptions);

			// if (inputValue === "") {
			// 	setOptions([]);
			// } else {
			// 	setOptions(newOptions);
			// }
		}
	}, [generalSearchData, inputValue]);

	console.log(selectedOption)

	useEffect(() => {
		if(selectedOption?.type === 'question') {
			navigate("/allComments", { state: {data:selectedOption?.title , id:0} })
		}
		else if(selectedOption?.type === 'questioner') {
			navigate("/general-questioner-page" , {state: selectedOption.id})
		}
		else if(selectedOption?.type === 'researcher') {
			navigate("/general-researcher-page" , {state: selectedOption?.id})
		}

	}, [selectedOption]);

	const handleSearchClick = useCallback(() => {
		setOpenAutocomplete(true);
	}, []);

	const handleAutocompleteClose = useCallback(() => {
		setOpenAutocomplete(false);
		setTypeOfSearch(undefined)
	}, []);

	const handleChange = useCallback(
		(event: any, newValue: IOption | null) => {
			if (newValue) {
				setInputValue(newValue.title);
				setSelectedOption(newValue);
			}
		},
		[]
	);

	const handleInputChange = useCallback((_: any, newValue: any) => {
		setInputValue(newValue)
		setSelectedOption(null);
	}, [generalSearchData]);


	const InputEndAdornment = memo(
		(props: PropsWithChildren<{ reactNode: React.ReactNode }>) => {
			const root = props.reactNode as JSX.Element;
			const elements = React.Children.toArray(root.props.children);

			React.Children.forEach(props.children, (child, key) => {
				if (React.isValidElement(child))
					elements.push(React.cloneElement(child, { key }));
			});

			return React.cloneElement(root, {}, elements);
		}
	);

	return(
		<>
			<Autocomplete
				autoComplete
				value={selectedOption}
				options={options}
				popupIcon={false}
				includeInputInList
				filterSelectedOptions
				inputValue={inputValue}
				onChange={handleChange}
				onInputChange={handleInputChange}
				filterOptions={(x) => x}
				getOptionLabel={(option) => option.title}
				open={openAutocomplete}
				onOpen={handleSearchClick}
				onClose={handleAutocompleteClose}
				noOptionsText={<Box></Box>}
				ListboxComponent={({ children, ...listboxProps }) => (
					<Stack
						{...listboxProps}
						sx={{
							backgroundColor: "#EDEDED",
							minHeight: 500,
						}}
					>
						<SearchPaperHeaderResult
							options={options}
							handleChange={handleChange}
							inputValue={inputValue}
							setTypeOfSearch={setTypeOfSearch}
							typeOfSearch={typeOfSearch}
						/>
					</Stack>
				)}
				sx={{
					width: downOfsm ? 200 : 400,
					[`& .${autocompleteClasses.endAdornment}`]: {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={'جست‌وجو'}
						sx={{
							[`& .${outlinedInputClasses.root}`]: {
								height: 45,
								borderRadius: 8,
							}
						}}
						color={'warning'}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<InputEndAdornment reactNode={params.InputProps.endAdornment}>
									<IconButton
										size={'small'}
										sx={{ mt: -0.5 }}
										onClick={handleSearchClick}
									>
										<SearchIcon />
									</IconButton>
								</InputEndAdornment>
							),
						}}
					/>
				)}
			/>
		</>
	)
}