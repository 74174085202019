import Stack from "@mui/material/Stack";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {useLazyGetSettingPageDataQuery, useLazySaveProfileSettingQuery} from "../../../features/apiSlice";
import SettingPageResearcher from "./components/settingPageResearcher/SettingPageResearcher";
import AuthenticationProvider from "../../../components/AuthenticationProvider";
import SettingPageQuestioner from "./components/settingPageQuestioner/SettingPageQuestioner";

function Page() {
	const [settingTrigger, { data: settingPageData }] = useLazyGetSettingPageDataQuery();
	const [saveProfileTrigger, { data: saveProfilePageData }] = useLazySaveProfileSettingQuery();
	const {
		sectionSettingQuestioner,
		token,
		profileSettingData,
		notifsSettingData,
		role,
		refreshSettingData
	} = useSelector((state: RootState) => state.app);
	const dataForSaving = {...profileSettingData , notif_pref:notifsSettingData , token:token!};

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	useEffect(() => {
		if(!token) return;

		settingTrigger({
			token: token
		})
	}, [token , refreshSettingData]);

	useEffect(() => {
		if(!sectionSettingQuestioner) return;
		const sectionElement = document.getElementById(sectionSettingQuestioner);
		if (sectionElement) {
			sectionElement.scrollIntoView({ behavior: "smooth" });
		}
	}, [sectionSettingQuestioner]);

	const handleItemClick = (sectionId: string) => {
		const sectionElement = document.getElementById(sectionId);
		if (sectionElement) {
			sectionElement.scrollIntoView({ behavior: "smooth" });
		}
	};

	function handleClickSaveProfile() {
		saveProfileTrigger(dataForSaving)
	}

	return(
		<>
			<Stack>
				{(role === 'researcher')?
					<SettingPageResearcher
						settingPageData={settingPageData}
						handleItemClick={handleItemClick}
						handleClickSaveProfile={handleClickSaveProfile}
					/>:
					<SettingPageQuestioner
						settingPageData={settingPageData}
						handleItemClick={handleItemClick}
						handleClickSaveProfile={handleClickSaveProfile}
					/>
				}
			</Stack>
		</>
	)
}

export default function Setting() {
	return(
		<AuthenticationProvider>
			<Page/>
		</AuthenticationProvider>
	)
}