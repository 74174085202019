import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {Avatar, Grid, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import IGetResearcherPageData from "../../../interface/IGetResearcherPageData";
import theme from "../../../lib/theme.const";
import {useLazyFetchSearchQuestionQuery} from "../../../features/apiSlice";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {useNavigate} from "react-router-dom";

interface IProps {
	researcherData: IGetResearcherPageData | undefined
}

export default function SecondPart({researcherData}:IProps) {
	const [searchTrigger, { data: searchData , error }] = useLazyFetchSearchQuestionQuery();
	const { token } = useSelector((state: RootState) => state.app);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const navigate  = useNavigate();
	// const data = researcherData?.msg.subjects.concat(new Array(4).fill('kmgghjhuj'))
	const data = researcherData?.msg.subjects

	useEffect(() => {
		//دیتایی که داری میفرستی رم بعدا باید اصلاح کنی
		if(!searchData) return;

		navigate("/allComments", { state: {data:searchData.msg.questions[0].title , id:1} });
	}, [searchData]);

	function handleClickSelectQuestion(question: string | undefined) {
		// این سرچ باید عوض بشه و بجاش آیدی سوال قرار بگیره

		searchTrigger({
			token: '',
			search: question
		})
	}

	return(
		<>
			<Stack
				direction={(downOfsm)?'column':"row"}
				pt={9}
				pb={5}
				justifyContent={'center'}
				spacing={10}
			>
				<Stack
					onClick={() => handleClickSelectQuestion(researcherData?.msg.last_question.title)}
					width={(downOfsm)?'100%':'40%'}
					maxWidth={360}
					sx={{
						backgroundColor: "#E86942",
						borderBottomRightRadius: 14,
						borderBottomLeftRadius: 14,
						height: 255,
						// width:(downOfsm)?300:360
					}}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Box
						width={"100%"}
						sx={{
							px:1.5,
							mt:-20,
							zIndex:10,
							backgroundColor: "#D6DFEA"
						}}
					>
						<Box
							sx={{
								backgroundColor: "#b1b1b1",
								height: 50,
								borderTopRightRadius: 14,
								borderTopLeftRadius: 14
							}}
						/>
					</Box>
					<Stack
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Box
							sx={{
								backgroundColor: "#b1b1b1",
								borderRadius: "50%",
								p:1.5,
								mt:-4,
								zIndex:12
							}}
						>
							<Avatar
								alt={'questioner picture'}
								src={`https://api.matnavis.com/files/${researcherData?.msg.last_question.questioner_profile.photo}`}
								sx={{width: 45, height: 45}}
							/>
						</Box>
					</Stack>
					<Stack px={4} pt={1} pb={3} alignItems={'center'} justifyContent={'center'}>
						<Typography
							fontWeight={'bold'}
							fontSize={20}
							sx={{color: "white"}}
						>
							{researcherData?.msg.last_question.title}
						</Typography>
					</Stack>
				</Stack>
				<Stack
					width={(downOfsm)?'100%':'45%'}
					alignItems={'center'}
					sx={{
						maxHeight: 295,
						overflowY: 'auto',
					}}
				>
					<Grid
						container
						spacing={3}
						rowSpacing={'4.5%'}
						width={'100%'}
					>
						{data?.map((content , index) => {
							return(
								<Grid item xs={12/2} key={index}>
									<Stack
										alignItems={'center'}
										justifyContent={'center'}
										sx={{
											backgroundColor: '#D9D9D9',
											p:5,
											borderRadius: 4,
											height: (data.length > 4)?'90%':135,
											// maxWidth:240
										}}
									>
										<Typography>
											{content}
										</Typography>
									</Stack>
								</Grid>
							)
						})}
					</Grid>
				</Stack>
			</Stack>
		</>
	)
}