import Stack from "@mui/material/Stack";
import {Grid, Paper, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import * as React from "react";
import {useState} from "react";
import IGetResearcherPageData from "../../../interface/IGetResearcherPageData";
import {useNavigate} from "react-router-dom";
import theme from "../../../lib/theme.const";

interface IProps {
	researcherData: IGetResearcherPageData | undefined
}

export default function ThirdPart({researcherData}:IProps) {
	const [noteBoxValue , setNoteBoxValue] = useState<number>(1);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const navigate = useNavigate();
	const cards = (noteBoxValue === 1)?
		researcherData?.msg.recommending:
		researcherData?.msg.recommenders
		// personalPageData?.msg.recommenders.concat(new Array(9).fill({
		// 	profile: {
		// 		id: 2957612610,
		// 		name: "نیلوفر رضوی",
		// 		photo: "default",
		// 		username: "test10"
		// 	}
		// }))

	return(
		<>
			<Stack
				width={"100%"}
				direction={"row"}
			>
				<Stack
					onClick={() => {
						setNoteBoxValue(1)
					}}
					width={"50%"}
					height={(noteBoxValue === 1)?75:50}
					sx={{
						backgroundColor: (noteBoxValue === 1)?"#D6DFEA":"#E86942",
						borderTopRightRadius: (noteBoxValue === 1)?15:undefined,
						borderTopLeftRadius: 15
					}}
					alignSelf={(noteBoxValue === 1)?undefined:"flex-end"}
					justifyContent={"center"}
					alignItems={"center"}
					px={3}
				>
					<Typography sx={{color:(noteBoxValue === 1)?'#0068A6':"white"}} fontWeight={'bold'}>
						پژوهشگرانی که پیشنهاد می‌کنید
					</Typography>
				</Stack>
				<Stack
					onClick={() => {
						setNoteBoxValue(2)
					}}
					width={"50%"}
					height={(noteBoxValue === 2)?75:50}
					sx={{
						backgroundColor: (noteBoxValue === 2)?"#D6DFEA":"#E86942",
						borderTopRightRadius: 15,
						borderTopLeftRadius: (noteBoxValue === 2)?15:undefined
					}}
					alignSelf={(noteBoxValue === 2)?undefined:"flex-end"}
					justifyContent={"center"}
					alignItems={"center"}
					px={3}
				>
					<Typography sx={{color:(noteBoxValue === 2)?'#0068A6':"white"}} fontWeight={'bold'}>
						پژوهشگرانی که پیشنهاد میکنند
					</Typography>
				</Stack>
			</Stack>
			<Stack
				// sx={{boxShadow: "0px 70px 80px 20px #D9D9D9"}}
			>
				<Stack
					direction={"row"}
					sx={{
						background: 'linear-gradient(to top, #DFD6EA, #D6DFEA)',
						px:(!cards || cards.length === 0)?0:5,
						py:(!cards || cards.length === 0)?0:15,
						borderBottomLeftRadius: 15,
						borderBottomRightRadius: 15
					}}
					justifyContent={"center"}
					alignItems={"center"}
				>
					{(!cards || cards.length === 0)?
						<Stack justifyContent={'center'} alignItems={'center'}>
							<img src={'./png/2.png'} width={500} height={600}/>
						</Stack>:
						<Grid container spacing={2}>
							{cards?.map((content, index) => {
								return(
									<Grid item xs={(downOfsm)?12/2:12/4} key={index}>
										<Paper
											onClick={() => {
												navigate(`/general-researcher-page`, { state: content.profile.id })
												window.location.reload()
											}}
											elevation={6}
											sx={{
												backgroundColor: "white",
												borderRadius: 3,
												p:2,
												padding: '16px',
												textAlign: 'center'
											}}
										>
											<Stack
												alignItems={"center"}
												justifyContent={'center'}
												sx={{
													// backgroundColor: "#afafaf",
													borderRadius: 2,
													width: '100%',
													height: 180,
												}}
											>
												<img
													alt={'researchers profile'}
													src={`https://api.matnavis.com/files/${content.profile.photo}`}
													width={"100%"}
													height={'100%'}
													style={{
														color: "white",
														padding:5,
													}}
												/>
											</Stack>
											<Box>
												<Typography sx={{color:'#0068A6'}}>
													{content.profile.name}
												</Typography>
											</Box>
										</Paper>
									</Grid>
								)
							})}
						</Grid>
					}
				</Stack>
			</Stack>
		</>
	)
}