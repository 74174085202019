import {Button, Dialog, dialogClasses, DialogContent, DialogTitle, Typography} from "@mui/material";
import {useLazyInitTransactionQuery} from "../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import {useEffect} from "react";

interface IProps {
	open: boolean,
	onClose: () => void,
	selectedToken: number | undefined,
	paymentWay: number | undefined
}

export default function SaveChangesPopup({open , onClose , selectedToken , paymentWay}:IProps) {
	const [initTransactionTrigger , {data: initTransactionData}] = useLazyInitTransactionQuery();
	const { token } = useSelector((state: RootState) => state.app);

	useEffect(() => {
		if(!initTransactionData) return;

		window.open(initTransactionData.msg)
		onClose()
	}, [window , initTransactionData]);

	function handleClickPayment() {
		if(!token || !paymentWay) return;

		initTransactionTrigger({
			token: token,
			gateway_id: paymentWay,
			tokens: selectedToken
		})
	}

	return(
		<>
			<Dialog
				open={open}
				onClose={onClose}
				sx={{
					[`& .${dialogClasses.paper}`]: {
						borderRadius:5
					},
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor: "#D9D9D9",
						height:'40%'
					}}
				>
					<Stack justifyContent={'center'} alignItems={'center'}>
						<img src={"./logoHeader.png"} width={"150px"} height={"150px"}/>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Stack justifyContent={'center'} alignItems={'center'} spacing={5}>
						<Box>
							<Typography fontSize={28} fontWeight={'bold'}>
								ذخیره تغییرات
							</Typography>
						</Box>
						<Box>
							<Typography>
								آیا می خواهید تغییرات ذخیره شود؟
							</Typography>
						</Box>
						<Stack direction={'row'} spacing={4}>
							<Button
								onClick={handleClickPayment}
								variant={'contained'}
								sx={{
									backgroundColor:'#E86942',
									color:'white',
									minWidth:90,
									'&:hover': {
										backgroundColor: "#8f3e04"
									},
								}}
							>
								بله
							</Button>
							<Button
								onClick={() => onClose()}
								variant={'contained'}
								sx={{
									backgroundColor:'#4f4f4f',
									color:'white',
									minWidth:90,
									'&:hover': {
										backgroundColor: "#9f9f9f"
									},
								}}
							>
								خیر
							</Button>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	)
}