import {useLazyGetResearcherPageDataQuery} from "../../features/apiSlice";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import Stack from "@mui/material/Stack";
import * as React from "react";
import Profile from "./components/Profile";
import SecondPart from "./components/SecondPart";
import ThirdPart from "./components/ThirdPart";
import Fourth from "./components/Fourth";
import {useMediaQuery} from "@mui/material";
import theme from "../../lib/theme.const";
import Books from "./components/Books";
import Footer from "../components/Footer/Footer";

function Page() {
	const [researcherTrigger, { data: researcherData , error }] = useLazyGetResearcherPageDataQuery();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const location = useLocation();
	const researcherId = location.state;

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	useEffect(() => {
		if(!researcherId) return;

		researcherTrigger({
			token: '',
			user_id: Number(researcherId)
		})
	}, [researcherId]);

	return(
		<>
			<Stack
				sx={{backgroundColor:'white'}}
				// sx={{background: 'linear-gradient(to top, #ffffff 30%, #f7f7f7 , #b5b5b5)'}}
				alignItems={(downOfsm)?"center":undefined}
				width={"100%"}
				overflow={"hidden"}
			>
				<Profile researcherData={researcherData}/>
				<Stack justifyContent={"space-around"} px={10} sx={{backgroundColor:'#D6DFEA'}}>
					<SecondPart researcherData={researcherData}/>
				</Stack>
				<Stack
					py={7}
					px={(downOfsm)?5:10}
					sx={{backgroundColor:'#F9F9FF'}}
				>
					<ThirdPart researcherData={researcherData}/>
				</Stack>
				<Stack px={10} py={5} sx={{background: 'linear-gradient(to top, #ffffff 30%, #f7f7f7 , #D9D9D9)'}}>
					<Fourth researcherData={researcherData}/>
					<Books/>
				</Stack>
			</Stack>
		</>
	)
}

export default function GeneralResearcherPage() {
	return(
		<Page/>
	)
}