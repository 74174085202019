import {Box, Paper, Typography, useMediaQuery} from "@mui/material";
import Stack from "@mui/material/Stack";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import theme from "../../../../lib/theme.const";

interface IProps {
	setSubject: (subject: string) => void;
}

export default function DefaultContent({ setSubject }:IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	return(
		<>
			<Stack
				spacing={4}
				py={2}
				px={3}
			>
				<Paper
					onClick={() => setSubject("scene")}
					sx={{
						backgroundColor: "#EDEDED",
						px:4,
						py:2,
						width: "100%",
						boxShadow: "0px 2px 3px 3px #9c9c9c",
						borderRadius: 5,
						cursor: "pointer"
					}}
				>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
						spacing={(downOfsm)?5:10}
					>
						<Box width={"20%"}>
							<Stack
								alignItems={"center"}
								justifyContent={"center"}
								sx={{
									height: 45,
									width: 45,
									borderRadius: "50%",
									backgroundColor: "#E86942"
								}}
							>
								<img
									height={75}
									width={75}
									src={"./manzarIcon.png"}
								/>
							</Stack>
						</Box>
						<Box width={"40%"}>
							<Typography>
								تاریخ منظر
							</Typography>
						</Box>
						<Box>
							<ChevronLeftIcon sx={{mt:1}}/>
						</Box>
					</Stack>
				</Paper>
				<Paper
					onClick={() => setSubject("city")}
					sx={{
						backgroundColor: "#EDEDED",
						px:4,
						py:2,
						width: "100%",
						boxShadow: "0px 2px 3px 3px #9c9c9c",
						borderRadius: 5,
						cursor: "pointer"
					}}
				>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
						spacing={(downOfsm)?5:10}
					>
						<Box width={"20%"}>
							<Stack
								alignItems={"center"}
								justifyContent={"center"}
								sx={{
									height: 45,
									width: 45,
									borderRadius: "50%",
									backgroundColor: "#E86942"
								}}
							>
								<img
									height={65}
									width={65}
									style={{marginBottom:4}}
									src={"./shahrIcon.png"}
								/>
							</Stack>
						</Box>
						<Box width={"40%"}>
							<Typography>
								تاریخ شهر
							</Typography>
						</Box>
						<Box>
							<ChevronLeftIcon sx={{mt:1}}/>
						</Box>
					</Stack>
				</Paper>
				<Paper
					onClick={() => setSubject("architecture")}
					sx={{
						backgroundColor: "#EDEDED",
						px:4,
						py:2,
						width: "100%",
						boxShadow: "0px 2px 3px 3px #9c9c9c",
						borderRadius: 5,
						cursor: "pointer"
					}}
				>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
						spacing={(downOfsm)?5:10}
					>
						<Box width={"20%"}>
							<Stack
								alignItems={"center"}
								justifyContent={"center"}
								sx={{
									height: 45,
									width: 45,
									borderRadius: "50%",
									backgroundColor: "#E86942"
								}}
							>
								<img
									height={60}
									width={60}
									src={"./memariIcon.png"}
								/>
							</Stack>
						</Box>
						<Box width={"40%"}>
							<Typography>
								تاریخ معماری
							</Typography>
						</Box>
						<Box>
							<ChevronLeftIcon sx={{mt:1}}/>
						</Box>
					</Stack>
				</Paper>
				<Paper
					onClick={() => setSubject("art")}
					sx={{
						backgroundColor: "#EDEDED",
						px:4,
						py:2,
						width: "100%",
						boxShadow: "0px 2px 3px 3px #9c9c9c",
						borderRadius: 5,
						cursor: "pointer"
					}}
				>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
						alignItems={"center"}
						spacing={(downOfsm)?5:10}
					>
						<Box width={"20%"}>
							<Stack
								alignItems={"center"}
								justifyContent={"center"}
								sx={{
									height: 45,
									width: 45,
									borderRadius: "50%",
									backgroundColor: "#E86942"
								}}
							>
								<img
									height={60}
									width={60}
									src={"./honarIcon.png"}
								/>
							</Stack>
						</Box>
						<Box width={"40%"}>
							<Typography>
								تاریخ هنر
							</Typography>
						</Box>
						<Box>
							<ChevronLeftIcon sx={{mt:1}}/>
						</Box>
					</Stack>
				</Paper>
			</Stack>
		</>
	)
}