import Stack from "@mui/material/Stack";
import {
	Button,
	inputClasses,
	outlinedInputClasses,
	Paper,
	TextField,
	Typography,
	useMediaQuery
} from "@mui/material";
import theme from "../../../lib/theme.const";
import Box from "@mui/material/Box";
import * as React from "react";
import {useLazyNotifyUsersQuery} from "../../../features/apiSlice";
import {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import NotificationsIcon from '@mui/icons-material/Notifications';
import {styled} from "@mui/material/styles";
import IGetAdminPageDataResult from "../../../interface/IGetAdminPageDataResult";

interface IProps {
	adminData: IGetAdminPageDataResult | undefined
}

const CustomScrollbarRoot = styled('div')({
	position: 'relative',
	width: '100%',
	height: "100%", // Set a specific height to ensure scrolling
	overflowY: 'auto',
	overflowX: 'hidden', // Disable horizontal scroll
	direction: 'rtl',
	'& > *': {
		direction: 'ltr',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: '#D9D9D9',
		borderRadius: '10px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#a4a4a4',
		borderRadius: '10px',
	},
});

export default function FirstPart({adminData}:IProps) {
	const [notifyUsersTrigger , {data:notifyUsersData}] = useLazyNotifyUsersQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const [title , setTitle] = useState<string>();
	const [contentMassage , setContentMassage] = useState<string>();

	function handleClickSaveChanges() {
		if(!token || !contentMassage) return;

		notifyUsersTrigger({
			token: token,
			msg: contentMassage
		})
	}

	return(
		<>
			<Stack direction={(downOfmd)?"column":"row"} justifyContent={"space-between"} spacing={(downOfmd)?2:undefined}>
				<Stack width={(downOfmd)?"100%":"32%"}>
					<Paper
						sx={{
							backgroundColor: "#D9D9D9",
							height:210,
							borderRadius:4
						}}
					></Paper>
				</Stack>
				<Stack width={(downOfmd)?"100%":"32%"}>
					<Paper
						sx={{
							backgroundColor: "#D9D9D9",
							height:210,
							borderRadius:4,
							py:2,
							pr:2,
							pl:1
						}}
					>
						<Stack>
							<Stack direction={'row'} justifyContent={'space-between'}>
								<Box pl={1}>
									<Typography>اخطارهای سیستم</Typography>
								</Box>
								<Box>
									<NotificationsIcon color={'warning'}/>
								</Box>
							</Stack>
							<Stack
								sx={{
									pt:1,
									maxHeight: 140,
									overflowY: 'auto',
								}}
							>
								<CustomScrollbarRoot>
									{adminData?.msg.warnings?.map((content , index) => {
										return(
											<Paper
												sx={{
													borderRadius:4,
													p:1,
													ml:1,
													mb:1
												}}
												key={index}
											>
												<Stack>
													<Typography>
														{content.text}
													</Typography>
												</Stack>
											</Paper>
										)
									})}
								</CustomScrollbarRoot>
							</Stack>
						</Stack>
					</Paper>
				</Stack>
				<Stack width={(downOfmd)?"100%":"32%"}>
					<Paper
						sx={{
							backgroundColor: "#D9D9D9",
							height:210,
							borderRadius:4,
							p:2
						}}
					>
						<Stack>
							<Box pb={2} px={1}>
								<Typography>
									نوشتن پیام یادداشت برای کاربران
								</Typography>
							</Box>
							<TextField
								label={'عنوان'}
								color={'warning'}
								onChange={(event) => setTitle(event.target.value)}
								sx={{
									[`& .${outlinedInputClasses.root}`]: {
										borderRadius:20,
										backgroundColor:'white',
										height: 30
									},
									"& label": {
										fontSize: 15,
										transform: `translate(14px, 3px) scale(1)`,
										"&.MuiInputLabel-shrink"  : {
											fontSize: 13,
											transform: `translate(14px, -11px) scale(1)`,
											pt:0.2
										},
									},
								}}
							/>
							<Stack
								sx={{
									mt:1,
									backgroundColor:'white',
									borderRadius:4,
									pb:0.5
								}}
							>
								<TextField
									label={'متن پیام'}
									onChange={(event) => setContentMassage(event.target.value)}
									variant="standard"
									InputProps={{disableUnderline: true}}
									color={'warning'}
									multiline
									rows={2}
									sx={{
										[`& .${outlinedInputClasses.root}`]: {
											backgroundColor:'white'
										},
										[`& .${inputClasses.input}`]: {
											px:2
										},
										"& label": {
											fontSize: 15,
											transform: `translate(14px, 3px) scale(1)`,
											"&.MuiInputLabel-shrink"  : {
												fontSize: 13,
												transform: `translate(14px, -1px) scale(1)`,
												pt:0.2
											},
										},
									}}
								/>
								<Stack width={'15%'} alignSelf={'flex-end'} mr={4}>
									<Button
										onClick={handleClickSaveChanges}
										variant={'contained'}
										sx={{
											height:25,
											backgroundColor: "#E86942",
											'&:hover': {
												backgroundColor: "#8f3e04"
											},
										}}
									>
										ارسال
									</Button>
								</Stack>
							</Stack>
						</Stack>
					</Paper>
				</Stack>
			</Stack>
		</>
	)
}