import {Button, Dialog, DialogContent, DialogTitle, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {styled} from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from "react";
import {
	useLazyRejectResearcherQuery,
	useLazyRejectQuestionQuery,
	useLazyUpdateChatMsgQuery
} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";

interface IUser {
	chat_id: number;
	id: number;
	question: string;
	questioner_id: number;
	researcherId: string | null
}

interface IProps {
	open: boolean,
	onClose: () => void;
	userDataForEditOrReject :{type:string , user:IUser} | undefined,
	setUpdateRequestData: Dispatch<SetStateAction<any>>
}

const CustomScrollbarRoot = styled('div')({
	position: 'relative',
	width: '100%',
	height: "100%", // Set a specific height to ensure scrolling
	overflowY: 'auto',
	overflowX: 'hidden', // Disable horizontal scroll
	direction: 'rtl',
	'& > *': {
		direction: 'ltr',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: '#D9D9D9',
		borderRadius: '10px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#a4a4a4',
		borderRadius: '10px',
	},
});

export default function EditOrRejectQuestionPopup({open , onClose , userDataForEditOrReject , setUpdateRequestData}:IProps) {
	const [text , setText] = useState<string>();
	const [rejectQuestionTrigger , {data: rejectQuestionData}] = useLazyRejectQuestionQuery();
	const [rejectResearcherTrigger , {data: rejectResearcherData}] = useLazyRejectResearcherQuery();
	const [updateChatTrigger , {data: updateChatData}] = useLazyUpdateChatMsgQuery();
	const {token} = useSelector((state: RootState) => state.app);

	useEffect(() => {
		if(!setUpdateRequestData) return;

		setUpdateRequestData(rejectResearcherData)
	}, [rejectResearcherData]);

	useEffect(() => {
		if(!setUpdateRequestData) return;

		setUpdateRequestData(rejectQuestionData)
	}, [rejectQuestionData]);

	useEffect(() => {
		if(!setUpdateRequestData) return;

		setUpdateRequestData(updateChatData)
	}, [updateChatData]);

	function handleClick() {
		if(!token || !text || !userDataForEditOrReject) return;

		if(userDataForEditOrReject.type === "reject") {
			rejectQuestionTrigger({
				token: token,
				question_id: userDataForEditOrReject.user.id,
				chat_id: userDataForEditOrReject.user.chat_id,
				desc:text
			})
		}
		if(userDataForEditOrReject.type === "rejectResearcher") {
			rejectResearcherTrigger({
				token: token,
				user_id: String(userDataForEditOrReject.user.id)
			})
		}
		else if(userDataForEditOrReject.type === "edit") {
			updateChatTrigger({
				token: token,
				question_id: userDataForEditOrReject.user.id,
				chat_id: userDataForEditOrReject.user.chat_id,
				desc:text
			})
		}
	}

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setText(event.target.value)
	};

	return(
		<>
			<Dialog open={open} onClose={onClose}>
				<DialogTitle sx={{ backgroundColor: "#D9D9D9" }}>
					<Box>
						<Typography>
							{(userDataForEditOrReject?.type === "reject")?
								"دلیل رد سوال":
								(userDataForEditOrReject?.type === "rejectResearcher")?
									"نوشتن دلیل رد پژوهشگر" :
									"اصلاح سوال"
							}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1, overflow: 'hidden' }}>
					<CustomScrollbarRoot>
						<Stack
							justifyContent={"space-between"}
							sx={{p: 1.5}}
							spacing={2}
						>
							<Stack
								sx={{
									backgroundColor: "white",
									p: 1.5,
									borderRadius: 6
								}}
							>
								{(userDataForEditOrReject?.type === "reject")?
									<Typography>
										{userDataForEditOrReject?.user.questioner_id}
									</Typography>:
									(userDataForEditOrReject?.type === "rejectResearcher")?
										<Typography>
											{userDataForEditOrReject.user.researcherId}
										</Typography>:
									<TextField
										value={userDataForEditOrReject?.user.questioner_id}
									/>
								}
							</Stack>
							<Stack
								sx={{
									backgroundColor: "white",
									p: 1.5,
									borderRadius: 4
								}}
							>
								<Box>
									<TextField
										value={text}
										defaultValue={(userDataForEditOrReject?.type === "edit")?userDataForEditOrReject?.user.question:null}
										onChange={handleInputChange}
										multiline
										rows={5}
										sx={{
											height:150,
											width:450,
											'& .MuiOutlinedInput-root': {
												'& fieldset': {
													border: 'none'
												},
											},
										}}
									/>
								</Box>
								<Stack alignSelf={"flex-end"}>
									<Button
										onClick={handleClick}
										variant={"contained"}
										sx={{
											minWidth:80,
											height:32,
											backgroundColor: "#E86942",
											'&:hover': {
												backgroundColor: "#8f3e04"
											},
											boxShadow: "0px 5px 10px 3px #656464"
										}}
									>
										تایید
									</Button>
								</Stack>
							</Stack>
						</Stack>
					</CustomScrollbarRoot>
				</DialogContent>
			</Dialog>
		</>
	)
}