import {Avatar, Dialog, DialogContent, DialogTitle, Divider, paperClasses, Typography} from "@mui/material";
import ResearcherSearch from "./ResearcherSearch";
import Stack from "@mui/material/Stack";
import React, {useEffect} from "react";
import {useLazyGetAllResearchersBySubjectsQuery} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

export interface SimpleDialogProps {
	open: boolean;
	onClose: (value: string) => void;
}

export default function ResearcherPopup({open , onClose}: SimpleDialogProps) {
	const [getResearchersTrigger , {data: researchersData}] = useLazyGetAllResearchersBySubjectsQuery();
	const { token , searchedResearcher } = useSelector((state: RootState) => state.app);

	const navigate = useNavigate();

	useEffect(() => {

		getResearchersTrigger({
			token: ''
		})
	}, []);

	// useEffect(() => {
	// 	if(researchersData && researchersData.code === 603) {
	// 		localStorage.removeItem('ConsultingPlatfotmToken')
	// 	}
	// }, [researchersData]);

	console.log(researchersData)

	const sortedResearchersData = (Array.isArray(researchersData?.msg))?researchersData?.msg?.map(subjectData => {
		return {
			...subjectData,
			researchers: [...subjectData.researchers].sort((a, b) => {
				const nameA = a.name.split(" ")[1].toLowerCase();
				const nameB = b.name.split(" ")[1].toLowerCase();

				if (nameA < nameB) {
					return -1;
				}
				if (nameA > nameB) {
					return 1;
				}
				return 0;
			})
		};
	}): []

	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					[`& .${paperClasses.root}`]: {
						borderRadius:3
					}
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor: "#0067A5",
						color: "white",
						display:'flex',
						justifyContent:'center'
					}}
				>
					<Typography fontWeight={'bold'} fontSize={20}>
						فهرست پژوهشگران
					</Typography>
				</DialogTitle>
				<DialogContent sx={{backgroundColor: "#EDEDED"}}>
					<Stack>
						<ResearcherSearch researchersData={researchersData}/>
					</Stack>
					<Divider sx={{pt:3}}/>
					<Stack pt={2}>
						{Array.isArray(sortedResearchersData) ? sortedResearchersData?.map((data , index) => {
							return(
								<Stack
									justifyContent={"center"}
									alignItems={"center"}
									key={index}
								>
									<Stack
										direction={"row"}
										justifyContent={"center"}
										alignItems={"center"}
										width={"100%"}
										pt={2}
									>
										<Stack width={"15%"}>
											<Box
												sx={{
													backgroundColor: "#E86942",
													borderRadius: "50%",
													width: 30,
													height: 30
												}}
											/>
										</Stack>
										<Box width={"70%"}>
											<Typography>
												{`پژوهشگران حوزه ` + `${data.subject}`+ ' به ترتیب الفبا'}
											</Typography>
										</Box>
									</Stack>
									<Stack width={"100%"}>
										{data.researchers.map((researcher:any , index:number) => {
											return(
												<Stack
													justifyContent={"center"}
													alignItems={"center"}
													direction={"row"}
													pt={2}
													key={index}
													onClick={() => {navigate("/general-researcher-page" , {state: researcher.id})}}
													sx={{cursor: "pointer"}}
												>
													<Stack width={"15%"}>
														<Avatar
															src={`https://api.matnavis.com/files/${researcher.photo}`}
															sx={{
																width:30,
																height:30,
																transition: 'width 0.3s ease',
															}}
														/>
													</Stack>
													<Box width={"70%"}>
														<Typography>
															{researcher.name}
														</Typography>
													</Box>
												</Stack>
											)
										})}
									</Stack>
									<Stack pt={3}>
										{(index < sortedResearchersData.length - 1)?
											<Divider sx={{width: 250}}/>: null
										}
									</Stack>
								</Stack>
							)
						}): <Typography>داده‌ای یافت نشد.</Typography>}
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	)
}