import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import React, {useEffect, useState} from "react";
import AuthenticationProvider from "../../../components/AuthenticationProvider";
import Stack from "@mui/material/Stack";
import {Divider, Paper, Typography, useMediaQuery} from "@mui/material";
import theme from "../../../lib/theme.const";
import CashbackResearcher from "./coponents/CashbackResearcher";
import OnOrOfPendingTable from "./coponents/OnOrOfPendingTable";


function Page() {
	const {financeStatus} = useSelector((state: RootState) => state.app);
	const [status , setStatus] = useState<string>('transactions');
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	useEffect(() => {
		if(financeStatus) {
			setStatus(financeStatus)
		}
	}, [financeStatus]);

	return(
		<>
			<Stack direction={'row'} sx={{backgroundColor:'white'}}>
				{(!downOfmd)?
					<Stack
						width={'25%'}
						alignItems={'center'}
						sx={{
							background: 'linear-gradient(to top, #ffffff, #319DD3)',
							// backgroundColor: "#319DD3",
							minHeight:600
						}}
					>
						<Stack pt={3} spacing={5} width={'100%'}>
							<Stack alignItems={'center'} justifyContent={'center'} width={"70%"}>
								<Typography
									fontWeight={"bold"}
									fontSize={33}
									sx={{
										color: "white",
									}}
								>
									حساب
								</Typography>
							</Stack>
							<Stack spacing={2} width={'100%'} pl={5}>
								<Stack
									onClick={() => setStatus('cashback')}
									direction="row"
									spacing={2}
									sx={{ cursor: "pointer" }}
								>
									<Stack
										justifyContent="center"
										width={'100%'}
										sx={{
											backgroundColor:(status === 'cashback')?'white':undefined,
											py:1,
											pl:2,
											borderTopLeftRadius:25,
											borderBottomLeftRadius:25
										}}
									>
										<Typography fontSize={20} sx={{ color:(status !== 'cashback')?'white':undefined }}>
											برداشت از حساب
										</Typography>
									</Stack>
								</Stack>
								<Stack
									onClick={() => setStatus('transactions')}
									direction="row"
									spacing={2}
									sx={{ cursor: "pointer" }}
								>
									<Stack
										justifyContent="center"
										width={'100%'}
										sx={{
											backgroundColor:(status === 'transactions')?'white':undefined,
											py:1,
											pl:2,
											borderTopLeftRadius:25,
											borderBottomLeftRadius:25
										}}
									>
										<Typography fontSize={20} sx={{ color:(status !== 'transactions')?'white':undefined }}>
											صورت حساب
										</Typography>
									</Stack>
								</Stack>
							</Stack>
						</Stack>
					</Stack>:
					null
				}
				<Stack width={(downOfmd)?'100%':'75%'}>
					{(status === 'cashback')?
						<CashbackResearcher/>:
						<OnOrOfPendingTable/>
					}
				</Stack>
			</Stack>
		</>
	)
}

export default function FinancialPage() {
	return(
		<AuthenticationProvider>
			<Page/>
		</AuthenticationProvider>
	)
}