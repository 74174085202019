import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
	Button,
	Divider,
	Typography,
	useMediaQuery
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useEffect, useState} from "react";
import SetResearcherPopup from "../../components/SetResearcherPopup";
import {
	financeStatusChanged, generalDataChanged,
	openConversationDrawerChanged, personalPageDataChanged,
	roleChanged,
	sectionSettingQuestionerChanged,
	tokenChanged
} from "../../features/appSlice";
import {
	useLazyGetGeneralBaseDataQuery,
	useLazyGetPersonalPageDataQuery,
	useLazyLoginQuery
} from "../../features/apiSlice";
import MenuIcon from '@mui/icons-material/Menu';
import theme from "../../lib/theme.const";
import Stack from "@mui/material/Stack";
import SearchHeader1 from "../../components/SearchHeader/SearchHeader1";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import LogoutIcon from '@mui/icons-material/Logout';
import Cookies from "js-cookie";
import SearchHeader from "../../components/generalSearch/SearchHeader";

export default function Header() {
	// const [trigger, { data: loginData }] = useLazyLoginQuery();
	const [generalTrigger, { data: generalData }] = useLazyGetGeneralBaseDataQuery();
	const [personalDataTrigger, { data: personalData }] = useLazyGetPersonalPageDataQuery();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [researcherPopupOpen , setResearcherPopupOpen] = useState<boolean>(false);
	const [mobileMoreSettingAnchorEl, setMobileMoreSettingAnchorEl] = React.useState<null | HTMLElement>(null);
	const [mobileMoreFinancePageAnchorEl , setMobileMoreFinancePageAnchorEl] = useState<null | HTMLElement>(null);
	const isMobileMenuFinancePageOpen = Boolean(mobileMoreFinancePageAnchorEl);
	const isMobileMenuSettingOpen = Boolean(mobileMoreSettingAnchorEl);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const {
		openConversationDrawer ,
		personalPageData ,
		role ,
		token ,
		refreshSettingData ,
		openMatnavisHeaderDrawer ,
		refreshGeneralData ,
		checkRegulations
	} = useSelector((state: RootState) => state.app);
	const isMenuOpen = Boolean(anchorEl);
	const downOfNumberForHamburgerMenu = useMediaQuery(theme.breakpoints.down(990));
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const loginDataRole = Cookies.get('ConsultingPlatfotmRole')
	const loginDataToken = Cookies.get('ConsultingPlatfotmToken')

	useEffect(() => {
		if(checkRegulations === 'confirmed') {
			setResearcherPopupOpen(true)
		}
	}, [checkRegulations]);

	useEffect(() => {
		dispatch(tokenChanged(loginDataToken));
	}, [dispatch , loginDataToken]);

	useEffect(() => {
		if(!loginDataRole) {
			return
		}
		dispatch(roleChanged(loginDataRole));
	}, [dispatch , loginDataRole]);

	useEffect(() => {
		generalTrigger({
			token: ""
		})
	}, [generalTrigger , refreshGeneralData]);

	useEffect(() => {
		dispatch(generalDataChanged(generalData))
	}, [generalData]);

	useEffect(() => {
		if(!token) return;

		personalDataTrigger({
			token: token
		})
	}, [token , refreshSettingData]);

	useEffect(() => {
		if(!personalData || !token) return;

		dispatch(personalPageDataChanged(personalData))
	}, [personalData , token]);

	// useEffect(() => {
	// 	if(checkRegulations) {
	// 		setResearcherPopupOpen(true)
	// 	}
	// }, [checkRegulations]);

	const handleMobileMenuSettingClose = () => {
		setMobileMoreSettingAnchorEl(null);
	};

	const handleMobileMenuFinanceClose = () => {
		setMobileMoreFinancePageAnchorEl(null);
	};

	// function handleLogin() {
	// 	trigger({
	// 		// username: "test1",
	// 		// password: "123456"
	// 		// username: "shayan100",
	// 		// password: "123456"
	// 		username: "test0",
	// 		password: "123456"
	// 	});
	// }

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleQuestionerSettingMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreSettingAnchorEl(event.currentTarget);
	};

	const handleFinanceMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setMobileMoreFinancePageAnchorEl(event.currentTarget);
	};

	const handleMenuQuestioner = () => {
		navigate("/personal")
		setAnchorEl(null);
	};

	const handleMenuResearcher = () => {
		if(!role) return;

		if(role === "researcher") {
			if(!personalPageData) return;

			navigate("/general-researcher-page" , {state:personalPageData.msg.profile.id})
		}
		else if(role === "questioner") {
			setResearcherPopupOpen(true)
		}
		setAnchorEl(null);
	};

	const handleClose = () => {
		setResearcherPopupOpen(false)
	};

	const handleClickQuestionerMobileMenu = (value: string) => {
		dispatch(sectionSettingQuestionerChanged(value))
		handleMobileMenuSettingClose();
	}

	function handleLogout() {
		Cookies.remove('ConsultingPlatfotmRole' , {domain: '.testavis.ir'})
		Cookies.remove('ConsultingPlatfotmToken', {domain: '.testavis.ir'})
		dispatch(tokenChanged(null))
		dispatch(personalPageDataChanged(undefined))
		window.location.reload();
	}

	const menuId = 'primary-search-account-menu';
	const renderMenu = (
		<Menu
			sx={{
				mt:8,
				"& .MuiPopover-paper": {
					backgroundColor: "#D9D9D9",
					width: 170,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: 3
				}
			}}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={menuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={() => setAnchorEl(null)}
		>
			<MenuItem
				onClick={handleMenuQuestioner}
				sx={{
					padding: "6px 16px 0px 16px"
				}}
			>
				صفحه شخصی
			</MenuItem>
			<Divider
				sx={{
					height: "70%"
				}}
			/>
			<MenuItem
				onClick={handleMenuResearcher}
				sx={{
					padding: "0px 16px 6px 16px"
				}}
			>
				پروفایل پژوهشگر
			</MenuItem>
		</Menu>
	);

	const mobileMenuId = 'primary-search-account-menu-mobile';
	const renderMobileMenuSetting = (
		<Menu
			sx={{
				mt:8,
				"& .MuiPopover-paper": {
					backgroundColor: "#D9D9D9",
					width: 230,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: 3
				}
			}}
			anchorEl={mobileMoreSettingAnchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={mobileMenuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMobileMenuSettingOpen}
			onClose={handleMobileMenuSettingClose}
		>
			<MenuItem
				onClick={() => handleClickQuestionerMobileMenu("section1")}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box>
					<Typography>
						مشخصات
					</Typography>
				</Box>
			</MenuItem>
			<Divider
				sx={{
					backgroundColor: "black",
					width: 200
				}}
			/>
			<MenuItem
				onClick={() => handleClickQuestionerMobileMenu("section2")}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box>
					<Typography>
						اعلان ها
					</Typography>
				</Box>
			</MenuItem>
			<Divider
				sx={{
					backgroundColor: "black",
					width: 200
				}}
			/>
			<MenuItem
				onClick={() => handleClickQuestionerMobileMenu("section3")}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box>
					<Typography>
						کیف پول
					</Typography>
				</Box>
			</MenuItem>
			<Divider
				sx={{
					backgroundColor: "black",
					width: 200
				}}
			/>
			<MenuItem
				onClick={() => navigate('/financialPage')}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box>
					<Typography>
						حساب
					</Typography>
				</Box>
			</MenuItem>
		</Menu>
	);


	const mobileMenuFinance = 'primary-search-account-menu-mobile-finance';
	const renderMobileMenuFinance = (
		<Menu
			sx={{
				mt:8,
				"& .MuiPopover-paper": {
					backgroundColor: "#D9D9D9",
					width: 230,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: 3
				}
			}}
			anchorEl={mobileMoreFinancePageAnchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={mobileMenuFinance}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMobileMenuFinancePageOpen}
			onClose={handleMobileMenuFinanceClose}
		>
			<MenuItem
				onClick={()  => dispatch(financeStatusChanged('cashback'))}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box>
					<Typography>
						برداشت از حساب
					</Typography>
				</Box>
			</MenuItem>
			<Divider
				sx={{
					backgroundColor: "black",
					width: 200
				}}
			/>
			<MenuItem
				onClick={()  => dispatch(financeStatusChanged('transactions'))}
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box>
					<Typography>
						صورت حساب
					</Typography>
				</Box>
			</MenuItem>
		</Menu>
	);

	return (
		<Stack>
			<AppBar
				sx={{
					backgroundColor: "#D9D9D9" ,
					top:
						(downOfNumberForHamburgerMenu && openMatnavisHeaderDrawer)?299
							:(downOfsm && !openMatnavisHeaderDrawer)?56
								:64
				}}
			>
				<Toolbar>
					<IconButton
						onClick={() => navigate("/")}
					>
						<img
							src={"./logoHeader.png"}
							width={(downOfsm)?"30px":"40px"}
							height={(downOfsm)?"30px":"40px"}
							alt={'matnavis icon'}
						/>
					</IconButton>
					<SearchHeader/>
					<Box sx={{ flexGrow: 1 }} />
					<Box>
						{(loginDataToken)?
							<Stack direction={"row"}>
								<IconButton
									size="large"
									edge="end"
									aria-label="account of current user"
									aria-controls={menuId}
									aria-haspopup="true"
									onClick={handleProfileMenuOpen}
									color="inherit"
								>
									<ExpandMoreIcon
										height={20}
										sx={{color: "#E86942"}}
									/>
									<PersonIcon
										sx={{
											width: (downOfsm)?30:35,
											height: (downOfsm)?30:35,
											border: 1,
											borderColor: "#3C3F48",
											borderRadius: "50%",
											color: "#E86942"
										}}
									/>
								</IconButton>
								<IconButton>
									<LogoutIcon color={"warning"} onClick={handleLogout}/>
								</IconButton>
								{(downOfsm && (window.window.document.location.pathname === "/setting-personal"))?
									<IconButton onClick={handleQuestionerSettingMobileMenuOpen}>
										<MenuIcon sx={{color: "#E86942" , ml:-1}}/>
									</IconButton>:
									(downOfsm && !openConversationDrawer && (window.window.document.location.pathname === "/conversation-personal"))?
										<IconButton onClick={() => dispatch(openConversationDrawerChanged(true))} sx={{ml:-1}}>
											<CloseTwoToneIcon/>
										</IconButton>:
										(downOfmd && (window.window.document.location.pathname === "/financialPage"))?
											<IconButton onClick={handleFinanceMobileMenuOpen}>
												<MenuIcon sx={{color: "#E86942" , ml:-1}}/>
											</IconButton>:
											null
								}
							</Stack>:
							null
						}
					</Box>
				</Toolbar>
			</AppBar>
			{renderMenu}
			{renderMobileMenuSetting}
			{renderMobileMenuFinance}
			<SetResearcherPopup open={researcherPopupOpen} onClose={handleClose}/>
		</Stack>
	);
}
