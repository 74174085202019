import Stack from "@mui/material/Stack";
import {Box, Grid, Paper, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import theme from "../../../../lib/theme.const";


export default function PointPaper() {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	return(
		<>
			<Paper
				sx={{
					backgroundColor: "#D9D9D9",
					borderRadius: 9,
					pt: 4,
					// pb: 7,
					px: 2,
					height:"100%",
					width:(downOfsm)?undefined:(downOfmd)?500:undefined
				}}
			>
				<Stack justifyContent={"center"} alignItems={"center"}>
					<Box pb={6} pt={3}>
						<Typography fontWeight={"bold"} fontSize={18}>
							آخرین امتیازات
						</Typography>
					</Box>
					<Grid
						container
						spacing={1}
						direction={(downOfmd)?"row":"column"}
						justifyContent={"center"}
						pl={(downOfsm)?2.5:(downOfmd)?4:0}
						pb={3}
					>
						<Grid item xs={(downOfmd)?12/3:12}>
							<Paper
								sx={{
									width: (downOfsm)?80:105,
									height: (downOfsm)?65:90,
									borderRadius: 5,
									mt: 2,
									backgroundColor: "#FBF8F8"
								}}
							>

							</Paper>
						</Grid>
						<Grid item xs={(downOfmd)?12/3:12}>
							<Paper
								sx={{
									width: (downOfsm)?80:105,
									height: (downOfsm)?65:90,
									borderRadius: 5,
									mt: 2,
									backgroundColor: "#FBF8F8"
								}}
							>

							</Paper>
						</Grid>
						<Grid item xs={(downOfmd)?12/3:12}>
							<Paper
								sx={{
									width: (downOfsm)?80:105,
									height: (downOfsm)?65:90,
									borderRadius: 5,
									mt: 2,
									backgroundColor: "#FBF8F8"
								}}
							>

							</Paper>
						</Grid>
						<Grid item xs={(downOfmd)?12/3:12}>
							<Paper
								sx={{
									width: (downOfsm)?80:105,
									height: (downOfsm)?65:90,
									borderRadius: 5,
									mt: 2,
									backgroundColor: "#FBF8F8"
								}}
							>

							</Paper>
						</Grid>
						<Grid item xs={(downOfmd)?12/3:12}>
							<Paper
								sx={{
									width: (downOfsm)?80:105,
									height: (downOfsm)?65:90,
									borderRadius: 5,
									mt: 2,
									backgroundColor: "#FBF8F8"
								}}
							>

							</Paper>
						</Grid>
					</Grid>
				</Stack>
			</Paper>
		</>
	)
}