import {Avatar, Button, Divider, dividerClasses, Paper, Typography, useMediaQuery} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import theme from "../lib/theme.const";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";
import {useNavigate} from "react-router-dom";
import NoQuestionImageStepper from "./NoQuestionImageStepper";

interface IProps {
	data: {
		"questioner_profile": {
			"id": number,
			"username": string,
			"name": string,
			"photo": string
		} | undefined,
		"question": string | undefined
	}[] | undefined,
	allQuestions: boolean,
	handleClose: () => void
}

export default function ImageStepper({ data }:IProps) {
	const { token  , refreshGeneralData } = useSelector((state: RootState) => state.app);
	const [startIndex, setStartIndex] = useState(0);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const navigate  = useNavigate();

	// const imagesPerPage = (data?.length! <= 5)? data?.length: (downOfsm)?1:(downOfmd)?3:5

	const imagesPerPage =
		(downOfsm)? 1
			: (downOfmd)? ( (data?.length! <= 3)? data?.length:3 )
				// : (downOflg)? ((data?.length! <= 4)? data?.length:4)
					: ( (data?.length! <= 5)? data?.length: 5 )


	const getShortenedText = (text:string | undefined) => {
		if(!text) return;

		const words = text.split(' ');
		return words.length > 5 ? words.slice(0, 5).join(' ') + '...' : text;
	};

	// useEffect(() => {
	// 	//دیتایی که داری میفرستی رم بعدا باید اصلاح کنی
	// 	if(!searchData) return;
	//
	// 	navigate("/allComments", { state: {data:searchData.msg.questions[0] , id:1} });
	// }, [searchData]);

	const handleNext = () => {
		if(!imagesPerPage || !data) return;

		if(startIndex + imagesPerPage < data.length!) {
			setStartIndex(startIndex + imagesPerPage!);
		}
	};

	const handleBack = () => {
		if(!imagesPerPage) return;

		if(startIndex - imagesPerPage >= 0) {
			setStartIndex(startIndex - imagesPerPage)
		}
	};

	const handleClickSelectQuestion = useCallback((question: string | undefined) => {
		// این سرچ باید عوض بشه و بجاش آیدی سوال قرار بگیره
		if(!token) return;

		navigate("/allComments", { state: {data:question , id:1} });

	} , [refreshGeneralData , token])

	// console.log((cards?.length - startIndex >= 5)?imagesPerPage - 1:(cards?.length - startIndex)-1)

	return(
		<>
			<Stack
				direction={"row"}
				justifyContent={"center"}
				alignItems={"center"}
				mt={'20px'}
			>
				<Button
					onClick={handleBack}
					disabled={startIndex === 0}
					sx={{
						width: "10%",
						minWidth: 20
					}}
				>
					<ArrowForwardIosIcon
						sx={{
							stroke: "#808080",
							strokeWidth: 3
						}}
					/>
				</Button>
				<Paper
					sx={{
						height: '160px',
						width: (downOfsm)?"60%":(downOflg)?'90%':"100%",
						backgroundColor: (!data && downOfsm)?"#747474":(downOfsm)?"#E86942":"#D9D9D9",
						borderRadius: "15px",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					{(!data || data.length === 0)?
						<NoQuestionImageStepper/>:
						<Stack
							direction={"row"}
							justifyContent={"center"}
							alignItems={"center"}
							width={'100%'}
						>
							{data.slice(startIndex, startIndex + imagesPerPage!).map((content, index) => {
								return(
									<Stack
										key={index}
										width={(downOfsm)?'100%':(downOfmd)?'33%':'20%'}
										justifyContent={'center'}
										alignItems={'center'}
									>
										<Stack
											direction={"row"}
											justifyContent={(downOfsm)?'center':"space-between"}
											alignItems={'center'}
											width={'100%'}
										>
											{(!downOfsm) && (data?.length === 1) ?
												<Divider
													orientation="vertical"
													sx={{
														borderWidth: "135px 0px 0px thin",
														backgroundColor: "#3C3F48"
													}}
												/>
												: null
											}
											<Stack
												onClick={() => handleClickSelectQuestion(content.question)}
												alignItems={"center"}
												// justifyContent={"center"}
												sx={{
													// width: {md:'20%' , lg:'20%' , xs:220},
													pt:1,
													width:'100%',
													height:140,
													position: 'relative',
													cursor: "pointer",
													"&:hover": {
														justifyContent:'center',
														backgroundColor: (downOfsm)?undefined:"#E86942",
														borderRadius: (downOfsm)?undefined:1,
														width:300,
														height: (downOfsm)?undefined:200,
														mx: (downOfsm)?undefined:-1
													},
												}}
												spacing={'2%'}
											>
												<Box>
													<Avatar
														alt={'user avatar'}
														src={`https://api.matnavis.com/files/${content.questioner_profile?.photo}`}
														sx={{
															width:"55px",
															height:"55px",
															transition: 'width 0.3s ease',
														}}
													/>
												</Box>
												<Box mt={1} height={"15%"}>
													<Typography variant={"body1"}>
														{content.questioner_profile?.username}
													</Typography>
												</Box>
												<Box px={2} sx={{minHeight:'15%' , maxHeight:'40%'}}>
													<Typography variant={"body1"}>
														{/*{`${content.question?.slice(0, 32)}...`}*/}
														{getShortenedText(content.question)}
													</Typography>
												</Box>
											</Stack>
											{
												(!downOfsm)
													&&
												index <
													(
														(data?.length - startIndex >= 5)?imagesPerPage! - 1:
															(data.length === 1)?1:
																(data?.length - startIndex)-1
													) ?
												<Divider
													orientation="vertical"
													sx={{
														borderWidth: "135px 0px 0px thin",
														backgroundColor: "#3C3F48",
														"&:hover": {
															display: "none"
														},
													}}
												/>
												: null
											}
										</Stack>
									</Stack>
								)
							})}
						</Stack>
					}
				</Paper>
				<Button
					onClick={handleNext}
					disabled={startIndex + imagesPerPage! >= data?.length!}
					sx={{
						width: "10%",
						minWidth: 20
					}}
				>
					<ArrowBackIosNewIcon
						sx={{
							stroke: "#808080",
							strokeWidth: 3
					}}
					/>
				</Button>
			</Stack>
		</>
	)
}