import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {Avatar, Divider, Drawer, drawerClasses, modalClasses, Typography} from "@mui/material";
import * as React from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import IGetResentChatsContent from "../../../interface/IGetResentChatsContent";
import {openConversationDrawerChanged} from "../../../features/appSlice";
import SettingsIcon from "@mui/icons-material/Settings";
import {useLocation, useNavigate} from "react-router-dom";
import IGetPersonalPageData from "../../../interface/IGetPersonalPageData";

interface IProps {
	chatId: number | undefined;
	setChatId: (chatId:number | undefined) => void;
	chats: IGetResentChatsContent[] | undefined;
	personalPageData: IGetPersonalPageData
}

export default function DrawerConversationMobile({chatId , setChatId , chats}:IProps) {
	const {role , openConversationDrawer} = useSelector((state: RootState) => state.app);
	const dispatch = useDispatch();
	const Navigate = useNavigate();
	const personalPageData = useLocation().state;

	return(
		<>
			<Drawer
				open={openConversationDrawer}
				sx={{
					mt: "50px",
					[`& .${drawerClasses.paper}`]: {
						width: "100%",
						mt:7,
					},
					[`& .${modalClasses.backdrop}`]: {
						mt:7,
						width: "100%",
					}
				}}
			>
				<Stack
					sx={{background: 'linear-gradient(to top, #f0f0f0 , #afafaf)'}}
					width={"100%"}
					pt={3}
					minHeight={1000}
					alignItems={"center"}
				>
					<Stack>
						{chats?.map((content , index) => {
							return(
								<Stack key={index}>
									<Stack
										sx={{cursor:"pointer"}}
										onClick={() => {
											dispatch(openConversationDrawerChanged(false))
											setChatId(content.id)
										}}
									>
										<Stack
											direction={"row"}
											width={"100%"}
											py={2}
											px={3}
											sx={{background: (content.id === chatId)?'linear-gradient(to right, #ffffff , #c2c2c2)':undefined}}
										>
											<Stack spacing={1} width={"75%"}>
												<Box>
													<Typography>
														{(role === "researcher")?content.questioner_profile.name:content.researcher_profile.name}
													</Typography>
												</Box>
												<Box>
													<Typography>
														{content.title}
													</Typography>
												</Box>
												<Stack direction={"row"} width={"100%"}>
													<Box>
														<Typography>
															{content.subjects.join(', ')}
														</Typography>
													</Box>
												</Stack>
											</Stack>
											<Stack justifySelf={"flex-end"} width={"25%"}>
												<Avatar
													src={
														(role === "researcher")?
															`https://api.matnavis.com/files/${content.questioner_profile.photo}`
															:`https://api.matnavis.com/files/${content.researcher_profile.photo}`
													}
												/>
											</Stack>
										</Stack>
										<Stack px={2}>
											<Divider sx={{width:"95%" , backgroundColor:"black"}}/>
										</Stack>
									</Stack>
								</Stack>
							)
						})}
					</Stack>
					<Stack mt={5}>
						<Stack
							direction={"row"}
							justifyContent={"space-around"}
							alignItems={"center"}
							sx={{
								backgroundColor: "#E86942D4",
								borderRadius: 4,
								width: 300,
								height: 60
							}}
						>
							<Stack width={"25%"} alignItems={"center"} justifyContent={"center"}>
								<Avatar src={"./payment.png"} sx={{width:30 , height:30}}/>
							</Stack>
							<Divider
								orientation="vertical"
								sx={{
									height: "75%",
									width:"0.7%",
									backgroundColor:"white"
								}}
							/>
							<Stack width={"25%"} alignItems={"center"} justifyContent={"center"}>
								<SettingsIcon
									onClick={() => Navigate("/setting-personal")}
									sx={{
										color:"white",
										width:30,
										height:30
									}}
								/>
							</Stack>
							<Divider
								orientation="vertical"
								sx={{
									height: "75%",
									width:"0.7%",
									backgroundColor:"white"
								}}
							/>
							<Stack width={"25%"} alignItems={"center"} justifyContent={"center"}>
								<Avatar
									src={`https://api.matnavis.com/files/${personalPageData?.msg.profile.photo}`}
									sx={{width:30 , height:30}}
								/>
							</Stack>
						</Stack>
					</Stack>
				</Stack>
			</Drawer>
		</>
	)
}