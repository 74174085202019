import { Navigate } from "react-router-dom";
import React, {PropsWithChildren, useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";

export default function AuthenticationProvider({
  children,
}: PropsWithChildren) {
  const {token} = useSelector((state: RootState) => state.app);

  if (token) {
    return <>{children}</>;
  }
  return <Navigate to={"/"} />;
  // return <>{children}</>
}
