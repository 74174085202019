import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {Avatar, Button, Divider, Typography, useMediaQuery} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {Star, StarBorder} from "@mui/icons-material";
import * as React from "react";
import {useEffect, useState} from "react";
import IGetResearcherPageData from "../../../interface/IGetResearcherPageData";
import theme from "../../../lib/theme.const";
import {
	useLazyAddRecommendationQuery,
	useLazyFollowQuery,
	useLazyRemoveRecommendationQuery
} from "../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import ChooseTypeOfQuestionPopup from "../../../components/SetQuestion/ChooseTypeOfQuestionPopup";

interface IProps {
	researcherData: IGetResearcherPageData | undefined
}

export default function Profile({researcherData}:IProps) {
	const [selected, setSelected] = useState<boolean | undefined>();
	const [openChooseTypeOfQuestion , setOpenChooseTypeOfQuestion] = useState<boolean>(false);
	const [FollowTrigger] = useLazyFollowQuery();
	const [AddRecommendationTrigger] = useLazyAddRecommendationQuery();
	const [RemoveRecommendationTrigger] = useLazyRemoveRecommendationQuery();
	const [checkedAvailableOrSelectedResearcher, setCheckedAvailableOrSelectedResearcher] = useState<number>();
	const { token } = useSelector((state: RootState) => state.app);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if(checkedAvailableOrSelectedResearcher === 3) {
			setCheckedAvailableOrSelectedResearcher(undefined)
		}
	}, [checkedAvailableOrSelectedResearcher]);

	useEffect(() => {
		if(!researcherData || !token || selected === undefined) return;

		if(selected) {
			AddRecommendationTrigger({
				token: token,
				recommending_id: String(researcherData.msg.profile.id)
			})
		}
		else if(!selected) {
			RemoveRecommendationTrigger({
				token: token,
				recommending_id: String(researcherData.msg.profile.id)
			})
		}
	}, [selected , token , researcherData]);

	function handleCloseChooseTypeOfQuestion() {
		setOpenChooseTypeOfQuestion(false)
	}

	function handleClick() {
		setSelected(!selected);
	}

	function handleClickFollow() {
		if(!researcherData || !token) return;

		FollowTrigger({
			token: token,
			following_id: String(researcherData.msg.profile.id)
		})
	}

	return(
		<>
			<Stack direction={"row"} spacing={3} px={5} py={5} sx={{backgroundColor:'#F9F9FF'}}>
				<Stack spacing={2} justifyContent={"center"} alignItems={"center"}>
					<Box>
						<Avatar
							alt={'researcher profile'}
							src={`https://api.matnavis.com/files/${researcherData?.msg.profile.photo}`}
							sx={{
								width:"90px",
								height:"90px",
								transition: 'width 0.3s ease',
							}}
						/>
					</Box>
					<Box>
						<Typography>
							{`${researcherData?.msg.profile.followers_count} دنبال کننده`}
						</Typography>
					</Box>
					<Stack direction={"row"}>
						<Box mt={1.3}>
							<Button
								onClick={handleClickFollow}
								variant={"contained"}
								sx={{
									backgroundColor: "#E86942",
									'&:hover': {
										backgroundColor: "#8f3e04"
									}
								}}
							>
								درخواست دنبال کردن
							</Button>
						</Box>
						<Box>
							<IconButton onClick={handleClick}>
								{selected ?
									<Star
										style={{
											color: "#E86942",
											width: 40,
											height: 40
										}}
									/>
									:
									<StarBorder
										sx={{
											width: 40,
											height: 40
										}}
									/>
								}
							</IconButton>
						</Box>
					</Stack>
				</Stack>
				<Stack>
					<Divider
						orientation="vertical"
						sx={{
							borderWidth: "230px 0px 0px thin",
						}}
					/>
				</Stack>
				<Stack spacing={2} justifyContent={"center"} width={'75%'}>
					<Stack direction={"row"} spacing={5}>
						<Stack alignSelf={"flex-start"}>
							<Typography fontWeight={"bold"} fontSize={22}>
								{researcherData?.msg.profile.name}
							</Typography>
						</Stack>
						<Stack alignSelf={"flex-end"}>
							<Typography fontSize={13}>
								{researcherData?.msg.profile.username}
							</Typography>
						</Stack>
					</Stack>
					<Box>
						<Typography>
							پژوهشگر مدعو
						</Typography>
					</Box>
					<Box>
						<Typography>
							{researcherData?.msg.profile.education}
						</Typography>
					</Box>
					{(downOfsm)?null:
						<Stack spacing={2}>
							<Stack
								sx={{
									border: 1,
									borderRadius: 6,
									p: 1,
									// background: 'linear-gradient(to right bottom, #ffffff, #c9c9c9)',
									backgroundColor: 'white',
									width:'100%'
								}}
							>
								<Typography>
									{`بایو: ${researcherData?.msg.profile.bio}`}
								</Typography>
							</Stack>
							<Box>
								<Button
									onClick={() => setOpenChooseTypeOfQuestion(true)}
									variant={"contained"}
									sx={{
										backgroundColor: "#E86942",
										'&:hover': {
											backgroundColor: "#8f3e04"
										},
										width:300,
										height:45,
										borderRadius: 6
									}}
								>
									سوال دارم
								</Button>
							</Box>
						</Stack>
					}
				</Stack>
			</Stack>
			{(downOfsm)?
				<Stack pt={3} spacing={2} alignItems={"center"}>
					<Stack
						sx={{
							border: 1,
							borderRadius: 6,
							p: 1,
							// background: 'linear-gradient(to right bottom, #ffffff, #c9c9c9)',
							backgroundColor: 'white',
							width:300
						}}
					>
						<Typography>
							{`بایو: ${researcherData?.msg.profile.bio}`}
						</Typography>
					</Stack>
					<Box>
						<Button
							onClick={() => setOpenChooseTypeOfQuestion(true)}
							variant={"contained"}
							sx={{
								backgroundColor: "#E86942",
								'&:hover': {
									backgroundColor: "#8f3e04"
								},
								width:300,
								height:45,
								borderRadius: 6
							}}
						>
							سوال دارم
						</Button>
					</Box>
				</Stack>:
				null
			}
			<ChooseTypeOfQuestionPopup
				open={openChooseTypeOfQuestion}
				onClose={handleCloseChooseTypeOfQuestion}
				checkedAvailableOrSelectedResearcher={checkedAvailableOrSelectedResearcher}
				setCheckedAvailableOrSelectedResearcher={setCheckedAvailableOrSelectedResearcher}
			/>
		</>
	)
}