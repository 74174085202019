import {Navigate, Route, Routes} from "react-router-dom";
import Home from "./pages/Home";
import PersonalPage from "./pages/PersonalPage/PersonalPage";
import AllComments from "./pages/AllComments/AllComments";
import Header from "./pages/components/Header";
import Setting from "./pages/PersonalPage/settings/Setting";
import Conversations from "./pages/PersonalPage/conversationPage/Conversations";
import Footer from "./pages/components/Footer/Footer";
import GeneralResearcherPage from "./pages/GeneralResearcherPage/GeneralResearcherPage";
import ReportPersonalPageData from "./pages/PersonalPage/reportPersonalPageData/ReportPersonalData";
import AdminPanel from "./pages/AdminPanel/AdminPanel";
import GeneralQuestionerPage from "./pages/GeneralQuestionerPage/GeneralQuestionerPage";
import FinancialPage from "./pages/PersonalPage/Financial/FinancialPage";
import AdoptionOfRulesAndRegulations from "./components/AdoptionOfRulesAndRegulations";
import Stack from "@mui/material/Stack";
import MatnavisHeader from "./pages/components/MatnavisHeader";
import * as React from "react";
import {useMediaQuery} from "@mui/material";
import theme from "./lib/theme.const";
import Contact from "./pages/components/Footer/Contact";

const AppRoutes = () => {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	return(
		<Stack>
			<Stack>
				<MatnavisHeader/>
				<Header/>
			</Stack>
			<Stack sx={{backgroundColor:'#007FA8' , p:"25px" , mt:(downOfsm)?14:16.1}}>
				<Routes>
					<Route path={"/"} element={<Home/>} />
					<Route path={"/personal"} element={<PersonalPage/>} />
					<Route path={"/report-personal-data"} element={<ReportPersonalPageData/>}/>
					<Route path={"/setting-personal"} element={<Setting/>}/>
					<Route path={"/conversation-personal"} element={<Conversations/>}/>
					<Route path={"/general-researcher-page"} element={<GeneralResearcherPage/>} />
					<Route path={"/general-questioner-page"} element={<GeneralQuestionerPage/>}/>
					<Route path={"/allComments"} element={<AllComments/>} />
					<Route path={"/admin-pannel"} element={<AdminPanel/>}/>
					<Route path={"/financialPage"} element={<FinancialPage/>}/>
					<Route path={'/adoption-of-rules'} element={<AdoptionOfRulesAndRegulations/>}/>
					<Route path={"*"} element={<Navigate to="/"/>} />
				</Routes>
				<Contact/>
			</Stack>
			<Footer/>
		</Stack>
	)
};

export default AppRoutes;
