import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {Dialog, DialogContent, Divider, Paper, Typography, useMediaQuery} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import TransactionsResearcher from "./TransactionsResearcher";
import TransactionsQuestioner from "./TransactionsQuestioner";
import React, {useState} from "react";
import theme from "../../../../lib/theme.const";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";


export default function OnOrOfPendingTable() {
	const [pending , setPending] = useState<boolean>(false);
	const [popupSetPageNumber , setPopupSetPageNumber] = useState<boolean>(false);
	const [selectedNumber , setSelectedNumber] = useState<number>(4);
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	function handleCloseSetPageNumberPopup() {
		setPopupSetPageNumber(false)
	}

	return(
		<>
			<Stack px={(downOfmd)?2:7} pt={5}>
				<Stack width={'100%'}>
					<Box width={'35%'} sx={{backgroundColor:'#E86942', borderRadius:2.5 , zIndex:11}}>
						<Stack alignSelf={'flex-start'} p={0.5}>
							<Typography sx={{color:'white' , pl:1}}>
								صورت حساب
							</Typography>
						</Stack>
					</Box>
					<Paper
						sx={{
							backgroundColor: '#D9D9D9',
							p:4,
							borderRadius: 2.5,
							mt:-2,
							zIndex:10
						}}
					>
						<Stack direction={'row'} spacing={9}>
							<Typography>
								برداشت در حال انجام
							</Typography>
							<Box onClick={() => setPending(!pending)}>
								<CheckIcon
									sx={{
										stroke: (pending)?"#E86942":'white',
										strokeWidth: 4
									}}
								/>
							</Box>
						</Stack>
					</Paper>
				</Stack>
				<Stack>
					<Stack
						width={(downOfmd)?'70%':'30%'}
						alignItems={'center'}
						alignSelf={'flex-end'}
						height={'100%'}
						justifyContent={'center'}
						pt={10}
						pb={1}
					>
						<Stack
							sx={{backgroundColor: '#E86942' , borderRadius:4}}
							direction={'row'}
							alignItems={'center'}
							onClick={() => setPopupSetPageNumber(!popupSetPageNumber)}
							py={1}
							px={2}
						>
							<Typography fontSize={13} sx={{color:'white'}}>
								{'نمایش ' + selectedNumber + ' مورد در صفحه'}
							</Typography>
							{(popupSetPageNumber)?
								<ExpandLessIcon sx={{color:'white'}}/>:
								<ExpandMoreIcon sx={{color:'white'}}/>
							}
						</Stack>
						<Dialog
							open={popupSetPageNumber}
							onClose={handleCloseSetPageNumberPopup}
							sx={{
								pt:1,
								borderRadius:4
							}}
							PaperProps={{
								style: {
									position: 'absolute',
									left: "7.8%",
									margin: 0,
									marginTop:70,
								},
							}}
						>
							<DialogContent
								sx={{
									backgroundColor:'#D9D9D9',
									width:'100%',
									px:2,
									py:1
								}}
							>
								<Stack alignItems={'center'} justifyContent={'center'}>
									<Box py={1} sx={{cursor:'pointer'}} onClick={() => {
										setSelectedNumber(20)
										handleCloseSetPageNumberPopup()
									}}>
										<Typography fontSize={14}>
											نمایش 20 مورد در صفحه
										</Typography>
									</Box>
									<Divider sx={{width:'95%'}}/>
									<Box py={1} sx={{cursor:'pointer'}} onClick={() => {
										setSelectedNumber(30)
										handleCloseSetPageNumberPopup()
									}}>
										<Typography fontSize={14}>
											نمایش 30 مورد در صفحه
										</Typography>
									</Box>
									<Divider sx={{width:'95%'}}/>
									<Box py={1} sx={{cursor:'pointer'}} onClick={() => {
										setSelectedNumber(5)
										handleCloseSetPageNumberPopup()
									}}>
										<Typography fontSize={14}>
											نمایش 5 مورد در صفحه
										</Typography>
									</Box>
									<Divider sx={{width:'95%'}}/>
									<Box py={1} sx={{cursor:'pointer'}} onClick={() => {
										setSelectedNumber(4)
										handleCloseSetPageNumberPopup()
									}}>
										<Typography fontSize={14}>
											نمایش 4 مورد در صفحه
										</Typography>
									</Box>
								</Stack>
							</DialogContent>
						</Dialog>
					</Stack>
					{(pending)?
						<TransactionsResearcher selectedNumber={selectedNumber}/>:
						<TransactionsQuestioner selectedNumber={selectedNumber}/>
					}
				</Stack>
			</Stack>
		</>
	)
}