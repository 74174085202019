import Stack from "@mui/material/Stack";
import {
	Button,
	useMediaQuery
} from "@mui/material";
import ReactQuill, {Quill} from "react-quill";
import * as React from "react";
import {Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from "react";
import INotestype from "../interface/INotestype";
import IconButton from "@mui/material/IconButton";
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import { makeStyles } from "@mui/styles";
import theme from "../lib/theme.const";

const Font = Quill.import('formats/font');
Font.whitelist = [
	'Vazirmatn',
	'dana',
	'Cairo' ,
	'Almarai' ,
	'IBM-Plex-Sans-Arabic' ,
	'Amiri' ,
	'Noto-Naskh-Arabic',
	'Lalezar',
	'Mirza'
];
Quill.register(Font, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '40px'];
Quill.register(Size, true);


const useStyles = makeStyles({
	quill: {
		'& .ql-editor': {
			[theme.breakpoints.down('xl')]: {
				marginTop: 45,
			},
			[theme.breakpoints.down('lg')]: {
				marginTop: 40,
			},
			[theme.breakpoints.down('sm')]: {
				marginTop: 55,
			}
		},
		'& .ql-container': {
			zIndex:10,
			border: 'none',
			direction: 'rtl',
			[theme.breakpoints.down('xl')]: {
				width: '100%'
			},
			[theme.breakpoints.down('lg')]: {
				width: "100%"
			},
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		},
		'& .ql-toolbar': {
			backgroundColor:"white",
			[theme.breakpoints.down('xl')]: {
				width: 610
			},
			[theme.breakpoints.down('lg')]: {
				width: "85%"
			},
			[theme.breakpoints.down('sm')]: {
				width: 260,
			},
			direction: 'rtl'
		},
		'& .ql-toolbar.ql-snow': {
			'margin-top':-90,
			[theme.breakpoints.down('lg')]: {
				'margin-top':-110
			},
			[theme.breakpoints.down('sm')]: {
				'margin-top':-138
			},
		},
		'& .ql-picker-item[data-value]::before': { display:"none"},
		'& .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options':{
			zIndex:15,
		},
		// Define custom font styles
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Vazirmatn"]': { fontFamily: 'Vazirmatn' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Dana"]': { fontFamily: 'Dana' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Cairo"]': { fontFamily: 'Cairo' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Almarai"]': { fontFamily: 'Almarai' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="IBM-Plex-Sans-Arabic"]': { fontFamily: 'IBM Plex Sans Arabic' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Amiri"]': { fontFamily: 'Amiri' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Noto-Naskh-Arabic"]': { fontFamily: 'Noto Naskh Arabic' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Lalezar"]': { fontFamily: 'Lalezar' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Mirza"]': { fontFamily: 'Mirza' },
	},
});

interface IProps {
	setNoteContent: (noteContent: string | undefined) => void;
	noteForEdit?: INotestype | undefined;
	handleClickSaveNote?: () => void;
	isExpanded: boolean,
	setIsExpanded: Dispatch<SetStateAction<boolean>>
}

export default function ConversationTextField({
	setNoteContent ,
	noteForEdit ,
	handleClickSaveNote ,
	isExpanded,
	setIsExpanded
}:IProps) {
	const [content, setContent] = useState<string>('');
	const quillRef = useRef<ReactQuill | null>(null);

	const [selectedFont, setSelectedFont] = useState<string>('Vazirmatn');
	const classes = useStyles();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if(!noteForEdit) return;

		setContent(noteForEdit.body)
	}, [noteForEdit]);

	useEffect(() => {
		if(!isExpanded) return;
		if (quillRef.current) {
			const quill = quillRef.current.getEditor();
			quill.format('align', 'right');
		}
	}, [quillRef , isExpanded]);

	useEffect(() => {
		const updateSizeOptions = () => {
			const sizeOptions = document.querySelectorAll('.ql-size .ql-picker-item');
			sizeOptions.forEach(option => {
				const sizeValue = option.getAttribute('data-value');
				if (sizeValue) {
					option.textContent = sizeValue;
				}
			});
		};

		// Update size options on render
		updateSizeOptions();

		// Optionally, observe changes if the toolbar is dynamically updated
		const observer = new MutationObserver(updateSizeOptions);
		observer.observe(document.querySelector('.ql-toolbar')!, { childList: true, subtree: true });

		return () => observer.disconnect();
	}, [document]);

	useEffect(() => {
		const span = document.querySelector('.ql-snow .ql-picker.ql-size .ql-picker-label') as HTMLElement;
		span.textContent = span.dataset.value!
	}, []);

	useEffect(() => {
		const style = document.createElement('style');
		style.textContent = `
      .ql-snow .ql-picker.ql-size .ql-picker-label::before {
        content: none !important;
      }
    `;
		document.head.appendChild(style);

		// حذف استایل هنگام unmount
		return () => {
			document.head.removeChild(style);
		};
	}, []);

	useEffect(() => {

		// انتخاب عنصر اصلی span
		const span = document.querySelector('.ql-snow .ql-picker.ql-size .ql-picker-label') as HTMLElement;

		// انتخاب لیست آپشن‌ها
		const options = document.querySelectorAll('.ql-snow .ql-picker.ql-size .ql-picker-options .ql-picker-item');

		options.forEach(option => {
			option.addEventListener('click', () => {
				// دریافت مقدار data-value آپشن انتخاب شده
				const value = option.getAttribute('data-value');
				if (value) {
					// تغییر مقدار data-value در عنصر span
					span.setAttribute('data-value', value);
					span.textContent = value
				}
			});
		});

		// حذف رویداد گوش‌دهنده‌ها هنگام unmount
		// return () => {
		// 	options.forEach(option => {
		// 		option.removeEventListener('click', () => {
		// 			// نیاز به تعریف تابع برای حذف رویداد
		// 			// در اینجا حذف تابع کلیک نیاز به مدیریت دقیق‌تری دارد
		// 		});
		// 	});
		// };
	}, []);

	useEffect(() => {
		const updateFontOptions = () => {
			const fontOptions = document.querySelectorAll('.ql-font .ql-picker-item');
			fontOptions.forEach(option => {
				const fontValue = option.getAttribute('data-value');
				if (fontValue) {
					option.textContent = fontValue;
				}
			});
		};

		// Update size options on render
		updateFontOptions();

		// Optionally, observe changes if the toolbar is dynamically updated
		const observer = new MutationObserver(updateFontOptions);
		observer.observe(document.querySelector('.ql-toolbar')!, { childList: true, subtree: true });

		return () => observer.disconnect();
	}, [document]);

	useEffect(() => {
		const span = document.querySelector('.ql-snow.ql-toolbar .ql-picker-label') as HTMLElement;
		span.textContent = span.dataset.value!
	}, []);

	useEffect(() => {
		const style = document.createElement('style');
		style.textContent = `
      .ql-snow .ql-picker.ql-font .ql-picker-label::before {
        content: none !important;
      }
    `;
		document.head.appendChild(style);

		// حذف استایل هنگام unmount
		return () => {
			document.head.removeChild(style);
		};
	}, []);

	useEffect(() => {

		// انتخاب عنصر اصلی span
		const span = document.querySelector('.ql-snow.ql-toolbar .ql-picker-label') as HTMLElement;

		// انتخاب لیست آپشن‌ها
		const options = document.querySelectorAll('.ql-snow .ql-picker.ql-font .ql-picker-options .ql-picker-item');

		options.forEach(option => {
			option.addEventListener('click', () => {
				// دریافت مقدار data-value آپشن انتخاب شده
				const value = option.getAttribute('data-value');
				if (value) {
					// تغییر مقدار data-value در عنصر span
					span.setAttribute('data-value', value);
					span.textContent = value;

					setSelectedFont(value);
					// const editor = quillRef.current?.getEditor();
					// if (editor) {
					// 	// Apply the font to the entire content
					// 	editor.format('font', value);
					// 	// Apply the font to new text typed in the editor
					// 	editor.on('text-change', () => {
					// 		editor.format('font', value);
					// 	});
					// }
				}
			});
		});
	},[])

	useEffect(() => {
		const editorElement = document.querySelector('.ql-editor') as HTMLElement;
		if (editorElement) {
			editorElement.style.fontFamily = selectedFont;
		}
	}, [selectedFont]);

	const modules = useMemo(() => ({
		toolbar: {
			container: [
				[{ 'font': [
						'Vazirmatn',
						'Dana',
						'Cairo' ,
						'Almarai' ,
						'IBM-Plex-Sans-Arabic' ,
						'Amiri' ,
						'Noto-Naskh-Arabic',
						'Lalezar',
						'Mirza'
					]
				}],
				[{ 'size': ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '40px'] }],
				[{ 'color': [] }, { 'background': [] }],
				[{ 'bold': true }, { 'italic': true }, { 'underline': true }, { 'strike': true }],
				[{ 'list': 'bullet' }, { 'list': 'ordered' }],
				[{ 'align': [] }],
				['image']
			],
		}
	}), []);

	const formats = useMemo(() => [
		'font', 'size', 'color', 'background',
		'bold', 'italic', 'underline', 'strike',
		'list', 'bullet', 'align',
		'link', 'image'
	], []);

	useEffect(() => {
		const toolbarElement = document.querySelector('.ql-toolbar') as HTMLElement;

		if (toolbarElement) {
			toolbarElement.style.display = isExpanded ? 'block' : 'none';
		}
	}, [isExpanded]);

	function handleFocusReactQuill() {
		if(!isExpanded) {
			setIsExpanded(true)
		}
	}

	// const handleBlur = () => {
	// 	setTimeout(() => {
	// 		if (document.querySelector('.ql-toolbar:hover')) {
	// 			return;
	// 		}
	// 		setIsExpanded(false);
	// 	}, 2000);
	// };

	return(
		<Stack>
			<Stack
				// direction={"row"}
				sx={{
					border:1,
					borderRadius:6,
					px:2,
					py:1,
					background: 'linear-gradient(to right, #dfdfdf, #ffffff)',
					// minWidth: 300
				}}
				onClick={handleFocusReactQuill}
			>
				<Stack direction={"row"} justifyContent={'space-between'}>
					<Stack alignSelf={"flex-start"} pr={1}>
						<Button
							onClick={handleClickSaveNote}
							variant={"contained"}
							sx={{
								// backgroundColor: "#EE8D6F",
								backgroundColor: '#319DD3',
								minWidth: 80,
								'&:hover': {
									// backgroundColor: "#8f3e04"
									backgroundColor: '#1c5d7d'
								},
							}}
						>
							ارسال
						</Button>
					</Stack>
					<Stack alignSelf={"flex-end"} sx={{zIndex:11}}>
						{(isExpanded)?
							<IconButton onClick={() => setIsExpanded(false)}>
								<CloseTwoToneIcon/>
							</IconButton>:
							null
						}
					</Stack>
				</Stack>
				<Stack>
					<Stack>
						<ReactQuill
							theme="snow"
							// onKeyPress={handleFocusReactQuill}
							className={classes.quill}
							onFocus={handleFocusReactQuill}
							// onBlur={handleBlur}
							ref={quillRef}
							value={content}
							onChange={(value, delta, source, editor) => {
								setContent(value);
								setNoteContent(editor.getText());
							}}
							modules={modules}
							formats={formats}
							style={{ height: 210 , display:(isExpanded)?'block':'none' }}
						/>
					</Stack>
				</Stack>
			</Stack>
		</Stack>
	)
}