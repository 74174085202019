import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import Stack from "@mui/material/Stack";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import {Typography} from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import * as React from "react";

interface IProps {
	handleFirstPage: () => void,
	handleLastPage: () => void,
	handleNextPage: () => void,
	handlePrevPage: () => void,
	page: number
}

export default function Pagination({
	handleFirstPage ,
	handlePrevPage ,
	handleNextPage ,
	handleLastPage ,
	page
}:IProps) {

	return(
		<Stack
			height={'100%'}
			direction={"row"}
		>
			<IconButton onClick={handleFirstPage}>
				<KeyboardDoubleArrowRightIcon color={"warning"}/>
			</IconButton>
			<Stack direction={"row"} alignItems={"center"}>
				<IconButton onClick={handlePrevPage}>
					<NavigateNextIcon color={"warning"}/>
				</IconButton>
				<Typography>
					{page}
				</Typography>
				<IconButton onClick={handleNextPage}>
					<NavigateBeforeIcon color={"warning"}/>
				</IconButton>
			</Stack>
			<IconButton onClick={handleLastPage}>
				<KeyboardDoubleArrowLeftIcon color={"warning"}/>
			</IconButton>
		</Stack>
	)
}