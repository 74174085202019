import {
	Box,
	Button,
	Checkbox,
	Dialog,
	DialogContent,
	Divider,
	Input,
	paperClasses,
	TextField,
	Typography
} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import Stack from "@mui/material/Stack";
import {useLazySendResearcherRequestQuery} from "../features/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../app/store";
import AdoptionOfRulesAndRegulations from "./AdoptionOfRulesAndRegulations";
import {useNavigate} from "react-router-dom";
import {checkRegulationsChanged} from "../features/appSlice";

export interface SimpleDialogProps {
	open: boolean;
	onClose: () => void;
}

type UploadedImage = string | null;

type FileUploadHandler = (event: ChangeEvent<HTMLInputElement>) => void;

export default function SetResearcherPopup({open , onClose}: SimpleDialogProps) {
	const [openRequest , setOpenRequest] = useState<boolean>(false);
	const [uploadedImage, setUploadedImage] = useState<UploadedImage>(null);
	const [checked, setChecked] = React.useState(false);
	const [sendResearcherRequestTrigger , {data: sendResearcherRequestData}] = useLazySendResearcherRequestQuery();
	const { token , personalPageData , checkRegulations } = useSelector((state: RootState) => state.app);
	// const [openAdoptionOfRulesAndRegulations , setOpenAdoptionOfRulesAndRegulations] = useState<boolean>(false);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setChecked(event.target.checked);
	};

	const handleFileUpload: FileUploadHandler = (event) => {
		const file = event.target.files?.[0];
		const reader = new FileReader();

		reader.onload = () => {
			if (typeof reader.result === 'string') {
				setUploadedImage(reader.result);
			}
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	function handleClose() {
		setOpenRequest(false)
		onClose()
	}

	function handleClickSendRequest() {
		if(!token || !uploadedImage || !checkRegulations) return;

		sendResearcherRequestTrigger({
			token: token ,
			resume: uploadedImage
		}).then(() => {
			dispatch(checkRegulationsChanged(undefined))
			onClose()
		})
	}

	// function handleCloseAdoptionOfRulesAndRegulations() {
	// 	setOpenAdoptionOfRulesAndRegulations(false)
	// }

	return(
		<>
			<Dialog
				onClose={handleClose}
				open={open}
				sx={{
					[`& .${paperClasses.root}`]: {
						position: "fixed",
						top: 40,
						// height: 685,
						width: "70%",
						borderRadius:5,
						backgroundColor: 'transparent'
					}
				}}
			>
				<Stack
					sx={{
						borderRadius: 5,
						backgroundColor: "#E86942",
						justifyContent: "center"
					}}
				>
					<Button
						onClick={() => setOpenRequest(true)}
						sx={{
							color: "white"
						}}
					>
						درخواست پژوهشگر شدن
					</Button>
				</Stack>
				{(openRequest)?
					<Box
						sx={{
							backgroundColor: "#D9D9D9",
							borderRadius: 4,
							mt: 1
						}}
					>
						<DialogContent
							sx={{
								px:5,
								height: "100%"
							}}
						>
							<Stack>
								<Box>
									<TextField
										variant={"standard"}
										value={personalPageData?.msg.profile.username}
										color={'warning'}
										fullWidth
										label={
											<Box>
												<Typography sx={{color:'#E86942'}}>
													نام کاربری
												</Typography>
											</Box>
										}
									/>
								</Box>
								<Stack mt={4}>
									<Box>
										<Typography>
											بارگذاری رزومه شخصی
										</Typography>
									</Box>
									<Stack
										sx={{
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<Box
											sx={{
												borderStyle: 'dashed',
												borderWidth: 2,
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												padding: 2,
												marginTop: 2,
												width: "50%",
												height: 300
											}}
										>
											{uploadedImage ? (
												<img
													src={uploadedImage}
													alt="آپلود شده"
													style={{ maxWidth: '100%', maxHeight: '200px' }}
												/>
											) : (
												<span>عکسی آپلود نشده است</span>
											)}
										</Box>
										<Box mt={2}>
											<Input
												type="file"
												id="upload-button"
												style={{ display: 'none' }}
												onChange={handleFileUpload}
											/>
											<label htmlFor="upload-button">
												<Button
													variant={"contained"}
													component="span"
													sx={{
														px: 4,
														backgroundColor: "#E86942",
														'&:hover': {
															backgroundColor: "#8f3e04"
														},
														boxShadow: "0px 5px 10px 3px #656464"
													}}
												>
													آپلود
												</Button>
											</label>
										</Box>
										<Box mt={1}>
											<Typography>
												حداکثر حجم 5 مگابایت
											</Typography>
										</Box>
									</Stack>
								</Stack>
								<Divider
									sx={{
										mt: 2,
										backgroundColor: "#757373"
									}}
								/>
								<Stack direction={"row"}>
									<Checkbox
										checked={checked}
										onChange={handleChange}
										onClick={() => {
											if(!checked) {
												// setOpenAdoptionOfRulesAndRegulations(true)
												navigate('/adoption-of-rules')
												onClose()
											}
										}}
										inputProps={{ 'aria-label': 'controlled' }}
									/>
									<Box mt={1.5}>
										<Typography>
											پذیرش قوانین و مقررات
										</Typography>
									</Box>
								</Stack>
								<Box alignSelf={"flex-end"}>
									<Button
										onClick={handleClickSendRequest}
										variant={"contained"}
										sx={{
											px: 4,
											backgroundColor: "#E86942",
											'&:hover': {
												backgroundColor: "#8f3e04"
											},
											boxShadow: "0px 5px 10px 3px #656464"
										}}
									>
										ارسال
									</Button>
								</Box>
							</Stack>
						</DialogContent>
					</Box> :
					null
				}
			</Dialog>
			{/*<AdoptionOfRulesAndRegulations*/}
			{/*	open={openAdoptionOfRulesAndRegulations}*/}
			{/*	onClose={handleCloseAdoptionOfRulesAndRegulations}*/}
			{/*	setCheckRegulations={setCheckRegulations}*/}
			{/*/>*/}
		</>
	)
}