import {
	Button,
	Dialog,
	dialogClasses,
	DialogContent,
	DialogTitle, Grid,
	Paper,
	Typography,
	useMediaQuery
} from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import HelpUserPopup from "../../pages/components/messageButton/HelpUserPopup";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import SetQuestionPopup from "./SetQuestionPopup";
import theme from "../../lib/theme.const";

interface IProps {
	open: boolean,
	onClose: () => void,
	selectedSubject?: string | undefined,
	checkedAvailableOrSelectedResearcher: number | undefined,
	setCheckedAvailableOrSelectedResearcher: Dispatch<SetStateAction<number | undefined>>
}

export default function ChooseTypeOfQuestionPopup({
	open ,
	onClose ,
	selectedSubject ,
	setCheckedAvailableOrSelectedResearcher ,
	checkedAvailableOrSelectedResearcher
}:IProps) {
	const [openHelpMessage , setOpenHelpMessage] = useState(false);
	const [openSetQuesion , setOpenSetQuestion] = useState<boolean>(false);
	const { financeContext , token } = useSelector((state: RootState) => state.app);
	const [planId , setPlanId] = useState<number>();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const financeData = [
		{
			planId: 1,
			title: 'پاسخ کوتاه بدون منبع',
			tokenPrice: 10
		},
		{
			planId: 2,
			title: 'پاسخ کوتاه با منبع',
			tokenPrice: 10
		},
		{
			planId: 3,
			title: 'پرسش بلند بدون منبع',
			tokenPrice: 10
		},
		{
			planId: 4,
			title: 'پرسش بلند با منبع',
			tokenPrice: 10
		},
		{
			planId: 5,
			title: 'پرسش و پاسخ توافقی',
			tokenPrice: 10
		}
	]

	useEffect(() => {
		if(checkedAvailableOrSelectedResearcher === 3) {
			onClose()
		}
	}, [checkedAvailableOrSelectedResearcher]);

	const handleCloseHelpMessage = () => {
		setOpenHelpMessage(false)
	};

	function handleCloseSetQuestion() {
		setOpenSetQuestion(false)
	}

	function handleChooseTypeOfQuestion(planId:number) {
		if(!token) {
			window.location.href = 'https://auth.testavis.ir/'
			// window.location.href = 'http://localhost:8000/'
			return
		}
		setPlanId(planId)
		setOpenSetQuestion(true)
	}

	return(
		<>
			<Dialog
				open={open}
				onClose={onClose}
				sx={{
					[`& .${dialogClasses.paper}`]: {
						borderRadius:5,
						width: "100%",
						minWidth: (downOfsm)?undefined:(downOfmd)?700:950
					},
				}}
			>
				<DialogTitle sx={{backgroundColor:"#0067A5"}}>
					<Stack alignItems={"center"} justifyContent={"center"}>
						<Typography sx={{color:'white'}} fontWeight={'bold'} fontSize={20}>
							انتخاب نوع پاسخ
						</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent sx={{ background: 'linear-gradient(to bottom, #ffffff 50%, #70acd1)', pb:4}}>
					<Stack
						direction={'row'}
						justifyContent={"center"}
						alignItems={"canter"}
						pt={10}
						pb={2}
					>
						<Grid
							container
							spacing={(downOfsm)?0:(downOfmd)?2:0}
							sx={{
								display:'flex',
								alignItems:'center',
								justifyContent:(downOfmd)?'center':'space-between',
							}}
						>
							{financeData.map((content , index) => {
								return(
									<Grid
										item
										xs={(downOfsm)?12:(downOfmd)?12/3:undefined}
										width={(downOfsm)?"100%":undefined}
										key={index}
										sx={{
											display:'flex',
											alignItems:'center',
											justifyContent:'center',
											pb:3
										}}
									>
										<Paper
											sx={{
												p:2 ,
												width:(downOfsm)?'70%':(downOfmd)?'90%':'100%' ,
												height:'100%'
											}}
										>
											<Stack spacing={2} alignItems={"center"} justifyContent={"center"}>
												<Box>
													<img src={`./png/transaction/${content.planId}.png`} width={120} height={120}/>
												</Box>
												<Box>
													<Typography>
														{content.title}
													</Typography>
												</Box>
												<Box>
													<Typography fontWeight={"bold"} fontSize={20}>
														{content.tokenPrice + 'توکن'}
													</Typography>
												</Box>
												<Box>
													<Button
														onClick={() => handleChooseTypeOfQuestion(content.planId)}
														variant={"contained"}
														sx={{
															minWidth: 100,
															height:30,
															backgroundColor: "#E86942",
															'&:hover': {
																backgroundColor: "#8f3e04"
															},
														}}
													>
														بپرسید
													</Button>
												</Box>
											</Stack>
										</Paper>
									</Grid>
								)
							})}
						</Grid>
					</Stack>
					<Stack
						ml={4}
						direction={"row"}
						alignItems={"center"}
						spacing={2}
					>
						<Stack
							justifyContent={"center"}
							alignItems={"center"}
							sx={{
								borderRadius: "50%",
								width: 65,
								height: 65,
								backgroundColor: "#E86942",
								cursor: "pointer",
								'&:hover': {
									backgroundColor: "#8f3e04"
								},
							}}
						>
							<Stack
								justifyContent={"center"}
								alignItems={"center"}
								onClick={() => setOpenHelpMessage(true)}
							>
								<Box mt={0.5}>
									<img src={"./message.png"} width={43} height={43}/>
								</Box>
								<Box mt={-6.3}>
									<MoreHorizSharpIcon
										sx={{
											color: "white",
											fontSize: 35
										}}
									/>
								</Box>
							</Stack>
						</Stack>
						<Box>
							<Typography>
								چت راهنمای انتخاب سوال
							</Typography>
						</Box>
					</Stack>
				</DialogContent>
			</Dialog>
			<HelpUserPopup open={openHelpMessage} onClose={handleCloseHelpMessage}/>
			<SetQuestionPopup
				open={openSetQuesion}
				onClose={handleCloseSetQuestion}
				selectedSubject={selectedSubject}
				planId={planId}
				checkedAvailableOrSelectedResearcher={checkedAvailableOrSelectedResearcher}
				setCheckedAvailableOrSelectedResearcher={setCheckedAvailableOrSelectedResearcher}
			/>
		</>
	)
}