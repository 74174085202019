import {Button, Divider, Paper, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

interface IProps {
	handleClickOpen: (value:number | undefined) => void;
}

export default function SearchButtons({handleClickOpen}: IProps) {

	return(
		<>
			<Paper
				sx={{
					height: '160px',
					width: {lg: "67%" , md:"67%" , xs:"82%"},
					backgroundColor: "#EDEDED",
					borderRadius: "15px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-around",
					// boxShadow: "0px 70px 80px 20px #D9D9D9"
				}}
			>
				<Button
					sx={{
						width: "100%",
						height: "100%"
					}}
					onClick={() => handleClickOpen(1)}
				>
					<Stack
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Box
							sx={{
								height: 45,
								width: 45,
								borderRadius: "50%",
								backgroundColor: "#E86942"
							}}
						/>
						<Typography
							mt={2}
							fontSize={20}
							fontWeight={"bold"}
							sx={{
								color: "#808080"
							}}
						>
							مو‌ضوعی
						</Typography>
					</Stack>
				</Button>
				<Divider
					orientation="vertical"
					sx={{
						height: "90%",
						mt:1
					}}
				/>
				<Button
					sx={{
						width: "100%",
						height: "100%"
					}}
					onClick={() => handleClickOpen(2)}
				>
					<Stack
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Box
							sx={{
								height: 45,
								width: 45,
								borderRadius: "50%",
								backgroundColor: "#E86942"
							}}
						/>
						<Typography
							mt={2}
							fontSize={20}
							fontWeight={"bold"}
							sx={{
								color: "#808080"
							}}
						>
							براساس پژوهشگر
						</Typography>
					</Stack>
				</Button>
				<Divider
					orientation="vertical"
					sx={{
						height: "90%",
						mt:1
					}}
				/>
				<Button
					sx={{
						width: "100%",
						height: "100%"
					}}
					onClick={() => handleClickOpen(3)}
				>
					<Stack
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Box
							sx={{
								height: 45,
								width: 45,
								borderRadius: "50%",
								backgroundColor: "#E86942"
							}}
						/>
						<Typography
							mt={2}
							fontSize={20}
							fontWeight={"bold"}
							sx={{
								color: "#808080"
							}}
						>
							کلید واژه
						</Typography>
					</Stack>
				</Button>
			</Paper>
		</>
	)
}