import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import PersonIcon from "@mui/icons-material/Person";
import {Typography} from "@mui/material";
import * as React from "react";
import {
	useLazyAddDisLikeSubCommentQuery,
	useLazyAddLikeSubCommentQuery
} from "../../features/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {useEffect} from "react";
import {refreshGeneralDataChanged} from "../../features/appSlice";

interface IProps {
	questionId: number | undefined,
	commentId: number,
	content: {
		created_at: number,
		dislikes: number,
		id: number,
		likes: number,
		sub_comment: string,
		sub_commenter_profile: {
			id: number,
			username: string,
			name: string,
			photo: string
		}
	}[],
}

export default function ReplyComment({content , commentId , questionId}: IProps) {
	const [likeTrigger, { data: likeData }] = useLazyAddLikeSubCommentQuery();
	const [disLikeTrigger, { data: disLikeData }] = useLazyAddDisLikeSubCommentQuery();
	const { token } = useSelector((state: RootState) => state.app);
	const dispatch = useDispatch();

	console.log(likeData)

	useEffect(() => {
		dispatch(refreshGeneralDataChanged(likeData))
	}, [likeData]);

	useEffect(() => {
		dispatch(refreshGeneralDataChanged(disLikeData))
	}, [disLikeData]);

	function handleClickLike(subCommentId:number) {
		if(!token || !questionId) return;

		likeTrigger({
			token: token,
			question_id: questionId,
			comment_id: commentId,
			sub_comment_id: subCommentId
		})
	}

	function handleClickDisLike(subCommentId:number) {
		if(!token || !questionId) return;

		disLikeTrigger({
			token: token,
			question_id: questionId,
			comment_id: commentId,
			sub_comment_id: subCommentId
		})
	}

	return(
		<>
			{content.map((item) => {
				return(
					<Stack px={6} pt={5}>
						<Stack
							direction={"row"}
							justifyContent={"space-between"}
						>
							<Stack
								direction={"row"}
								spacing={1}
							>
								<Box>
									<PersonIcon
										sx={{
											width: 35,
											height: 35,
											border: 1,
											borderColor: "#3C3F48",
											borderRadius: "50%",
											color: "#E86942"
										}}
									/>
								</Box>
								<Stack direction={"column"}>
									<Box>
										<Typography fontWeight={"bold"}>
											{item.sub_commenter_profile.username}
										</Typography>
									</Box>
									{/*<Box>*/}
									{/*	<Rating*/}
									{/*		// defaultValue={1}*/}
									{/*		size="small"*/}
									{/*		value={1}*/}
									{/*		max={5}*/}
									{/*		readOnly*/}
									{/*		sx={{*/}
									{/*			color: "#E86942"*/}
									{/*		}}*/}
									{/*	/>*/}
									{/*</Box>*/}
								</Stack>
							</Stack>
						</Stack>
						<Box ml={6} mt={3}>
							<Typography>
								{item.sub_comment}
							</Typography>
						</Box>
						<Stack direction={"row"} ml={6} mt={3} spacing={1}>
							<Stack direction={"row"} spacing={0.5}>
								<Box pt={1}>
									<Typography fontSize={14}>
										{item.likes}
									</Typography>
								</Box>
								<Stack
									pt={1}
									sx={{cursor: "pointer"}}
									onClick={() => handleClickLike(item.id)}
								>
									👍
								</Stack>
							</Stack>
							<Stack direction={"row"} spacing={0.5}>
								<Stack
									pt={1.5}
									sx={{cursor: "pointer"}}
									onClick={() => handleClickDisLike(item.id)}
								>
									👎
								</Stack>
								<Box pt={1}>
									<Typography fontSize={14}>
										{item.dislikes}
									</Typography>
								</Box>
							</Stack>
						</Stack>
					</Stack>
				)
			})}
		</>
	)
}