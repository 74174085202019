import Stack from "@mui/material/Stack";
import {Box, useMediaQuery} from "@mui/material";
import * as React from "react";
import theme from "../lib/theme.const";


export default function NoQuestionImageStepper() {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	return(
		<Stack
			direction={"row"}
			justifyContent={"space-around"}
			alignItems={'center'}
			width={'100%'}
		>
			<Stack
				alignItems={"center"}
				justifyContent={"center"}
				sx={{
					"&:hover": {
						backgroundColor: (downOfsm)?undefined:"#747474",
						borderRadius: (downOfsm)?undefined:1,
						height: (downOfsm)?undefined:{md:210 , lg:220 , xs:200},
					},
				}}
			>
				<Box>
					<img
						alt={'No question'}
						src={"./png/1.png"}
						style={{
							width:200,
							height:250
						}}
					/>
				</Box>
			</Stack>
			{(!downOfsm)?
				<Stack
					alignItems={"center"}
					justifyContent={"center"}
					sx={{
						"&:hover": {
							backgroundColor: (downOfsm)?undefined:"#747474",
							borderRadius: (downOfsm)?undefined:1,
							height: (downOfsm)?undefined:{md:210 , lg:220 , xs:200},
						},
					}}
				>
					<Box>
						<img
							alt={'No question'}
							src={"./png/1.png"}
							style={{
								width:200,
								height:250
							}}
						/>
					</Box>
				</Stack>:
				null
			}
			{(!downOfsm)?
				<Stack
					alignItems={"center"}
					justifyContent={"center"}
					sx={{
						"&:hover": {
							backgroundColor: (downOfsm)?undefined:"#747474",
							borderRadius: (downOfsm)?undefined:1,
							height: (downOfsm)?undefined:{md:210 , lg:220 , xs:200},
						},
					}}
				>
					<Box>
						<img
							alt={'No question'}
							src={"./png/1.png"}
							style={{
								width:200,
								height:250
							}}
						/>
					</Box>
				</Stack>:
				null
			}
		</Stack>
	)
}