import Stack from "@mui/material/Stack";
import {Box, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import theme from "../../../../../../lib/theme.const";


export default function ReportHistory() {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	return(
		<>
			<Stack
				direction={(downOfsm)?"column":"row"}
				pt={4}
				justifyContent={"space-around"}
				spacing={(downOfsm)?2:-3}
			>
				<Stack direction={"row"}>
					<Box
						sx={{
							width: 10,
							height: 100,
							background: 'linear-gradient(to top, #E86942 , #ffffff)',
							borderRadius: 4
						}}
					/>
					<Stack
						pt={0.5}
						px={2}
						spacing={0.5}
					>
						<Box>
							<Typography fontWeight={"bold"} fontSize={15}>
								5 گفتگو در گفتگوی برگزیده
							</Typography>
						</Box>
						<Box>
							<Typography fontSize={14}>
								10 گفتگو تا سطح بعدی
							</Typography>
						</Box>
						<Stack
							direction={"row"}
							spacing={2}
							pt={1.5}
						>
							<Box
								sx={{
									width: 30,
									height: 30,
									borderRadius: 3,
									backgroundColor: "#E86942"
								}}
							/>
							<Box
								sx={{
									width: 30,
									height: 30,
									borderRadius: 3,
									backgroundColor: "#E86942"
								}}
							/>
						</Stack>
					</Stack>
				</Stack>
				<Stack direction={"row"}>
					<Box
						sx={{
							width: 10,
							height: 100,
							background: 'linear-gradient(to top, #E86942 , #ffffff)',
							borderRadius: 4
						}}
					/>
					<Stack
						pt={0.5}
						px={2}
						spacing={0.5}
					>
						<Box>
							<Typography fontWeight={"bold"} fontSize={15}>
								5 گفتگو در گفتگوی برگزیده
							</Typography>
						</Box>
						<Box>
							<Typography fontSize={14}>
								10 گفتگو تا سطح بعدی
							</Typography>
						</Box>
						<Stack
							direction={"row"}
							spacing={2}
							pt={1}
						>
							<Box
								sx={{
									width: 30,
									height: 30,
									borderRadius: 3,
									backgroundColor: "#E86942"
								}}
							/>
						</Stack>
					</Stack>
				</Stack>
				<Stack direction={"row"}>
					<Box
						sx={{
							width: 10,
							height: 100,
							background: 'linear-gradient(to top, #E86942 , #ffffff)',
							borderRadius: 4
						}}
					/>
					<Stack
						pt={0.5}
						px={2}
						spacing={0.5}
					>
						<Box>
							<Typography fontWeight={"bold"} fontSize={15}>
								5 گفتگو در گفتگوی برگزیده
							</Typography>
						</Box>
						<Box>
							<Typography fontSize={14}>
								10 گفتگو تا سطح بعدی
							</Typography>
						</Box>
						<Stack
							direction={"row"}
							spacing={2}
							pt={1}
						>
							<Box
								sx={{
									width: 30,
									height: 30,
									borderRadius: 3,
									backgroundColor: "#E86942"
								}}
							/>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</>
	)
}