import Stack from "@mui/material/Stack";
import {Box, Button, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import {useLazyRemoveNoteQuery} from "../../../../../features/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../app/store";
import theme from "../../../../../lib/theme.const";
import INotestype from "../../../../../interface/INotestype";
import {useEffect, useState} from "react";
import NewNotes from "./NewNotes";
import moment from "moment-jalaali";
import {refreshSettingDataChanged} from "../../../../../features/appSlice";

interface IProps{
	notes: INotestype[] | undefined
}

export default function UserNotes({notes}: IProps) {
	const [removeNoteTrigger, { data: removeNoteData }] = useLazyRemoveNoteQuery();
	const { token } = useSelector((state: RootState) => state.app);
	const [note , setNote] = useState<INotestype>();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const dispatch = useDispatch();

	useEffect(() => {
		if(!removeNoteData) return;

		dispatch(refreshSettingDataChanged(removeNoteData))
	}, [removeNoteData]);

	function convertUnixToJalali(unixTimestamp: number | undefined) {
		if(!unixTimestamp) return;
		// تبدیل تاریخ یونیکس به تاریخ میلادی
		const date = moment.unix(unixTimestamp);

		// تبدیل تاریخ میلادی به تاریخ شمسی با استفاده از jalali-moment
		return date.locale('fa').format('jYYYY/jMM/jDD');
	}

	function handleRemoveNote(id: number) {
		if(!token) return;

		removeNoteTrigger({
			token: token ,
			note_id: id
		})
	}

	return(
		<>
			<Stack
				alignItems={"center"}
				sx={{
					backgroundColor: "#D9D9D9",
					borderBottomLeftRadius: 15,
					borderBottomRightRadius: 15
				}}
			>
				{(note)?
					<NewNotes noteForEdit={note} setNoteForEdit={setNote}/>:
					<Stack
						width={'90%'}
						spacing={2}
						sx={{
							px:2,
							pt:2,
							pb:7
						}}
					>
						{(notes?.length === 0 || !notes)?
							<Stack>
								<Stack direction={"row"}>
									<Box width={(downOfsm)?"44.5%":(downOflg)?"57%":"70%"}/>
									<Stack
										width={(downOfsm)?"18%":(downOflg)?"13%":"10%"}
										alignSelf={"flex-end"}
										sx={{
											backgroundColor: "#CCCCCC33",
											borderTop: 10,
											borderColor: "#E86942",
											borderTopLeftRadius: 36,
											zIndex:10,
											mb:1.25,
											mr:(downOfsm)?-0.2:undefined
										}}
										height={5}
									/>
									<Stack
										width={(downOfsm)?"18%":(downOflg)?"13%":"10%"}
										sx={{
											borderBottom: 10,
											borderColor: "white",
											borderBottomRightRadius: 38,
											zIndex:10,
										}}
									>
										<Box
											sx={{
												backgroundColor: "#CCCCCC33",
												borderBottom: 10,
												borderColor: "#E86942",
												borderBottomRightRadius: 36,
											}}
											height={37}
										/>
									</Stack>
									<Stack width={(downOfsm)?"20%":(downOflg)?"17%":"10%"}>
										<Box
											sx={{
												backgroundColor: "white",
												borderTop: 10,
												borderLeft: 1,
												borderColor: "#E86942",
												borderTopLeftRadius: 75,
												borderTopRightRadius: 75,
												ml:-2.2
											}}
											height={(downOfsm)?38:37}
										/>
									</Stack>
								</Stack>
								<Stack
									sx={{
										backgroundColor: "white",
										// height: 60,
										mt:-1.3
									}}
									py={1}
								>
									<Stack px={2} alignItems={'center'} justifyContent={'center'}>
										<img src={'./png/3.png'} width={280} height={385}/>
									</Stack>
								</Stack>
							</Stack>:
							notes.map((content) => {
								return(
									<Stack>
										<Stack direction={"row"}>
											<Box width={(downOfsm)?"44.5%":(downOflg)?"57%":"70%"}/>
											<Stack
												width={(downOfsm)?"18%":(downOflg)?"13%":"10%"}
												alignSelf={"flex-end"}
												sx={{
													backgroundColor: "#CCCCCC33",
													borderTop: 10,
													borderColor: "#E86942",
													borderTopLeftRadius: 36,
													zIndex:10,
													mb:1.25,
													mr:(downOfsm)?-0.2:undefined
												}}
												height={5}
											/>
											<Stack
												width={(downOfsm)?"18%":(downOflg)?"13%":"10%"}
												sx={{
													borderBottom: 10,
													borderColor: "white",
													borderBottomRightRadius: 38,
													zIndex:10,
												}}
											>
												<Box
													sx={{
														backgroundColor: "#CCCCCC33",
														borderBottom: 10,
														borderColor: "#E86942",
														borderBottomRightRadius: 36,
													}}
													height={37}
												/>
											</Stack>
											<Stack width={(downOfsm)?"20%":(downOflg)?"17%":"10%"}>
												<Box
													sx={{
														backgroundColor: "white",
														borderTop: 10,
														borderLeft: 1,
														borderColor: "#E86942",
														borderTopLeftRadius: 75,
														borderTopRightRadius: 75,
														ml:-2.2
													}}
													height={(downOfsm)?38:37}
												/>
											</Stack>
										</Stack>
										<Stack
											sx={{
												backgroundColor: "white",
												// height: 60,
												mt:-1.3
											}}
											py={1}
										>
											<Box px={2}>
												<Typography>
													{content.title}
												</Typography>
											</Box>
											<Stack
												direction={"row"}
												justifyContent={"space-between"}
												mt={2}
											>
												<Button
													sx={{color: "#E86942"}}
													onClick={() => handleRemoveNote(content.id)}
												>
													حذف
												</Button>
												<Stack direction={"row"}>
													<Typography mt={1.5} sx={{color: "#E86942"}} fontSize={13}>
														{convertUnixToJalali(content.created_at)}
													</Typography>
													<Button
														sx={{color: "#E86942"}}
														onClick={() => setNote(content)}
													>
														ویرایش
													</Button>
												</Stack>
											</Stack>
										</Stack>
									</Stack>
								)
							})
						}
					</Stack>
				}
			</Stack>
		</>
	)
}