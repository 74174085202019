import Stack from "@mui/material/Stack";
import {Button, Divider, Paper, Typography, useMediaQuery} from "@mui/material";
import theme from "../../../lib/theme.const";
import {useState} from "react";
import ApprovalCashbackPopup from "./popups/ApprovalCashbackPopup";
import ApprovalResearcherPopup from "./popups/ApprovalResearcherPopup";
import Box from "@mui/material/Box";
import IGetAdminPageDataResult from "../../../interface/IGetAdminPageDataResult";

interface IProps {
	adminData: IGetAdminPageDataResult | undefined
}

export default function SecondPart({adminData}:IProps) {
	const [openCashbackRequestPopup , setOpenCashbackRequestPopup] = useState<boolean>(false);
	const [openResearcherPopup , setOpenResearcherPopup] = useState<boolean>(false);
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	function handleCloseCashbackRequestPopup() {
		setOpenCashbackRequestPopup(false)
	}

	function handleCloseResearcherPopup() {
		setOpenResearcherPopup(false)
	}

	console.log(adminData)

	return(
		<>
			<Stack width={(downOfmd)?"100%":"43%"} spacing={2}>
				<Stack>
					<Paper
						sx={{
							backgroundColor: "#D9D9D9",
							height:'100%',
							borderRadius:4,
							p:3
						}}
					>
						<Stack direction={'row'} spacing={'10%'}>
							<Stack width={'40%'}></Stack>
							<Stack width={'50%'}>
								<Paper sx={{borderRadius:2 , width:'100%' , height:'100%', backgroundColor: "#D9D9D9"}}>
									<Stack height={'100%'} pb={2}>
										<Box
											sx={{
												backgroundColor:'#E86942',
												height:28,
												borderTopRightRadius:10,
												borderTopLeftRadius:10
											}}
										/>
										<Stack justifyContent={'center'} alignItems={'center'}>
											<Typography fontWeight={'bold'} sx={{color:'#E86942'}}>
												موضوعات محبوب
											</Typography>
										</Stack>
										<Stack px={2}>
											<Stack alignItems={'center'}>
												<Divider sx={{width: '100%', pt: 0.5}}/>
											</Stack>
											<Stack
												direction={"row"}
												sx={{mt:0.5}}
												// width={210}
												// px={3}
												spacing={0.5}
											>
												<Stack
													width={'32%'}
													justifyContent={"center"}
													alignItems={"center"}
													sx={{
														backgroundColor: "#524E4E",
														borderRadius: 4,
														minHeight:17
													}}
												>
													<Typography sx={{color: "white"}} fontSize={9}>
														معماری
													</Typography>
												</Stack>
												<Stack
													width={"50%"}
													direction={"row"}
													alignItems={"center"}
													spacing={0.5}
												>
													<Box
														sx={{
															height: "4px",
															width: `${(adminData?.msg.subject_stats?.معماری as number)*10}%`,
															backgroundColor: "#E86942"
														}}
													/>
													<Box width={"5%"}>
														<Typography fontSize={9}>
															{adminData?.msg.subject_stats?.معماری}
														</Typography>
													</Box>
												</Stack>
											</Stack>
											<Stack alignItems={'center'}>
												<Divider sx={{width: '100%', pt: 0.5}}/>
											</Stack>
											<Stack
												direction={"row"}
												sx={{mt:0.5}}
												// width={210}
												// px={3}
												spacing={0.5}
											>
												<Stack
													width={'32%'}
													justifyContent={"center"}
													alignItems={"center"}
													sx={{
														backgroundColor: "#524E4E",
														borderRadius: 4,
														minHeight:17
													}}
												>
													<Typography sx={{color: "white"}} fontSize={9}>
														منظر
													</Typography>
												</Stack>
												<Stack
													width={"50%"}
													direction={"row"}
													alignItems={"center"}
													spacing={1}
												>
													<Box
														sx={{
															height: "4px",
															width: `${(adminData?.msg.subject_stats?.منظر as number)*10}%`,
															backgroundColor: "#E86942"
														}}
													/>
													<Box width={"5%"}>
														<Typography fontSize={9}>
															{adminData?.msg.subject_stats?.منظر}
														</Typography>
													</Box>
												</Stack>
											</Stack>
											<Stack alignItems={'center'}>
												<Divider sx={{width: '100%', pt: 0.5}}/>
											</Stack>
											<Stack
												direction={"row"}
												sx={{mt:0.5}}
												// width={210}
												// px={3}
												spacing={0.5}
											>
												<Stack
													width={'32%'}
													justifyContent={"center"}
													alignItems={"center"}
													sx={{
														backgroundColor: "#524E4E",
														borderRadius: 4,
														minHeight:17
													}}
												>
													<Typography sx={{color: "white"}} fontSize={9}>
														تاریخ شهر
													</Typography>
												</Stack>
												<Stack
													width={"50%"}
													direction={"row"}
													alignItems={"center"}
													spacing={1}
												>
													<Box
														sx={{
															height: "4px",
															width: `${(adminData?.msg?.subject_stats["تاریخ شهر"] as number)*10}%`,
															backgroundColor: "#E86942"
														}}
													/>
													<Box width={"5%"}>
														<Typography fontSize={9}>
															{adminData?.msg.subject_stats["تاریخ شهر"]}
														</Typography>
													</Box>
												</Stack>
											</Stack>
											<Stack alignItems={'center'}>
												<Divider sx={{width: '100%', pt: 0.5}}/>
											</Stack>
											<Stack
												direction={"row"}
												sx={{mt:0.5}}
												// width={210}
												// px={3}
												spacing={0.5}
											>
												<Stack
													width={'32%'}
													justifyContent={"center"}
													alignItems={"center"}
													sx={{
														backgroundColor: "#524E4E",
														borderRadius: 4,
														minHeight:17
													}}
												>
													<Typography sx={{color: "white"}} fontSize={9}>
														تاریخ معماری
													</Typography>
												</Stack>
												<Stack
													width={"50%"}
													direction={"row"}
													alignItems={"center"}
													spacing={1}
												>
													<Box
														sx={{
															height: "4px",
															width: `${(adminData?.msg.subject_stats["تاریخ معماری"] as number)*10}%`,
															backgroundColor: "#E86942"
														}}
													/>
													<Box width={"5%"}>
														<Typography fontSize={9}>
															{adminData?.msg.subject_stats["تاریخ معماری"]}
														</Typography>
													</Box>
												</Stack>
											</Stack>
											<Stack alignItems={'center'}>
												<Divider sx={{width: '100%', pt: 0.5}}/>
											</Stack>
											<Stack
												direction={"row"}
												sx={{mt:0.5}}
												// width={210}
												// px={3}
												spacing={0.5}
											>
												<Stack
													width={'32%'}
													justifyContent={"center"}
													alignItems={"center"}
													sx={{
														backgroundColor: "#524E4E",
														borderRadius: 4,
														minHeight:17
													}}
												>
													<Typography sx={{color: "white"}} fontSize={9}>
														تاریخ منظر
													</Typography>
												</Stack>
												<Stack
													width={"50%"}
													direction={"row"}
													alignItems={"center"}
													spacing={1}
												>
													<Box
														sx={{
															height: "4px",
															width: `${(adminData?.msg.subject_stats["تاریخ منظر"] as number)*10}%`,
															backgroundColor: "#E86942"
														}}
													/>
													<Box width={"5%"}>
														<Typography fontSize={9}>
															{adminData?.msg.subject_stats["تاریخ منظر"]}
														</Typography>
													</Box>
												</Stack>
											</Stack>
											<Stack alignItems={'center'}>
												<Divider sx={{width: '100%', pt: 0.5}}/>
											</Stack>
											<Stack
												direction={"row"}
												sx={{mt:0.5}}
												// width={210}
												// px={3}
												spacing={0.5}
											>
												<Stack
													width={'32%'}
													justifyContent={"center"}
													alignItems={"center"}
													sx={{
														backgroundColor: "#524E4E",
														borderRadius: 4,
														minHeight:17
													}}
												>
													<Typography sx={{color: "white"}} fontSize={9}>
														تاریخ هنر
													</Typography>
												</Stack>
												<Stack
													width={"50%"}
													direction={"row"}
													alignItems={"center"}
													spacing={1}
												>
													<Box
														sx={{
															height: "4px",
															width: `${(adminData?.msg.subject_stats["تاریخ هنر"] as number)*10}%`,
															backgroundColor: "#E86942"
														}}
													/>
													<Box width={"5%"}>
														<Typography fontSize={9}>
															{adminData?.msg.subject_stats["تاریخ هنر"]}
														</Typography>
													</Box>
												</Stack>
											</Stack>
										</Stack>
									</Stack>
								</Paper>
							</Stack>
						</Stack>
					</Paper>
				</Stack>
				<Stack>
					<Paper
						sx={{
							backgroundColor: "#D9D9D9",
							height:230,
							borderRadius:4
						}}
					></Paper>
				</Stack>
				<Stack direction={"row"} justifyContent={"space-between"}>
					<Stack width={"47%"}>
						<Paper
							sx={{
								height:110,
								width:"100%",
								backgroundColor: "#D9D9D9",
								borderRadius:4,
								p:2
							}}
						>
							<Stack spacing={4}>
								<Stack>
									<Typography fontWeight={"bold"}>
										تایید پژوهشگر
									</Typography>
								</Stack>
								<Stack alignSelf={"flex-end"}>
									<Button
										variant={"contained"}
										sx={{
											minWidth:50,
											height: 25,
											backgroundColor: "#E86942",
											'&:hover': {
												backgroundColor: "#8f3e04"
											},
											boxShadow: "0px 5px 10px 3px #656464"
										}}
										onClick={() => setOpenResearcherPopup(true)}
									>
										انتخاب
									</Button>
								</Stack>
							</Stack>
						</Paper>
					</Stack>
					<Stack width={"47%"}>
						<Paper
							sx={{
								height:110,
								width:"100%",
								backgroundColor: "#D9D9D9",
								borderRadius:4,
								p:2
							}}
						>
							<Stack spacing={4}>
								<Stack>
									<Typography fontWeight={"bold"}>
										درخواست های برداشت
									</Typography>
								</Stack>
								<Stack alignSelf={"flex-end"}>
									<Button
										variant={"contained"}
										sx={{
											minWidth:50,
											height: 25,
											backgroundColor: "#E86942",
											'&:hover': {
												backgroundColor: "#8f3e04"
											},
											boxShadow: "0px 5px 10px 3px #656464"
										}}
										onClick={() => setOpenCashbackRequestPopup(true)}
									>
										انتخاب
									</Button>
								</Stack>
							</Stack>
						</Paper>
					</Stack>
				</Stack>
			</Stack>
			<ApprovalCashbackPopup open={openCashbackRequestPopup} onClose={handleCloseCashbackRequestPopup}/>
			<ApprovalResearcherPopup
				open={openResearcherPopup}
				onClose={handleCloseResearcherPopup}
				setOpenResearcherPopup={setOpenResearcherPopup}
			/>
		</>
	)
}