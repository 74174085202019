import Stack from "@mui/material/Stack";
import {Box, Paper, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import theme from "../../../../../../lib/theme.const";
import {useState} from "react";


export default function Medals() {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const [likePercent , setLikePercent] = useState<number>(33);
	const [numberOfCommentPercent , setNumberOfCommentPercent] = useState<number>(43);
	const [questionsPercent , setQuestionsPercent] = useState<number>(53);
	const [presentPercent , setPresentPercent] = useState<number>(73);

	return(
		<>
			<Stack direction={(downOfsm)?"column":"row"} pt={2} justifyContent={"center"} spacing={3}>
				<Stack alignItems={"center"}>
					<Paper
						sx={{
							width:55,
							height:55,
							borderRadius:5,
							backgroundColor: "#E86942",
							zIndex:1
						}}
					/>
					<Paper
						sx={{
							width:155,
							height:135,
							borderRadius:7,
							backgroundColor: "#FBF8F8",
							mt:-5,
							zIndex:0
						}}
					>
						<Stack alignItems={"center"} mt={5.5}>
							<Box>
								<Typography fontSize={13}>
									لایک
								</Typography>
							</Box>
							<Box>
								<Typography fontSize={13}>
									12 روز
								</Typography>
							</Box>
							<Stack>
								<Box>
									<Typography fontSize={10}>
										{`${likePercent}%`}
									</Typography>
								</Box>
								<Stack
									direction={"row"}
									sx={{
										width:120,
										height: 10
									}}
								>
									<Box
										sx={{
											backgroundColor:"#D9D9D9",
											width: `${100-likePercent}%`
										}}
									/>
									<Box
										sx={{
											backgroundColor:"#E86942",
											width: `${likePercent}%`
										}}
									/>
								</Stack>
							</Stack>
							<Box pt={0.5}>
								<Typography fontSize={11}>
									18 روز تا هدف بعدی
								</Typography>
							</Box>
						</Stack>
					</Paper>
				</Stack>
				<Stack alignItems={"center"}>
					<Paper
						sx={{
							width:55,
							height:55,
							borderRadius:5,
							backgroundColor: "#E86942",
							zIndex:1
						}}
					/>
					<Paper
						sx={{
							width:155,
							height:135,
							borderRadius:7,
							backgroundColor: "#FBF8F8",
							mt:-5,
							zIndex:0
						}}
					>
						<Stack alignItems={"center"} mt={5.5}>
							<Box>
								<Typography fontSize={13}>
									تعداد نظرها
								</Typography>
							</Box>
							<Box>
								<Typography fontSize={13}>
									12 نظر
								</Typography>
							</Box>
							<Stack>
								<Box>
									<Typography fontSize={10}>
										{`${numberOfCommentPercent}%`}
									</Typography>
								</Box>
								<Stack
									direction={"row"}
									sx={{
										width:120,
										height: 10
									}}
								>
									<Box
										sx={{
											backgroundColor:"#D9D9D9",
											width: `${100-numberOfCommentPercent}%`
										}}
									/>
									<Box
										sx={{
											backgroundColor:"#E86942",
											width: `${numberOfCommentPercent}%`
										}}
									/>
								</Stack>
							</Stack>
							<Box pt={0.5}>
								<Typography fontSize={11}>
									18 نظر تا هدف بعدی
								</Typography>
							</Box>
						</Stack>
					</Paper>
				</Stack>
				<Stack alignItems={"center"}>
					<Paper
						sx={{
							width:55,
							height:55,
							borderRadius:5,
							backgroundColor: "#E86942",
							zIndex:1
						}}
					/>
					<Paper
						sx={{
							width:155,
							height:135,
							borderRadius:7,
							backgroundColor: "#FBF8F8",
							mt:-5,
							zIndex:0
						}}
					>
						<Stack alignItems={"center"} mt={5.5}>
							<Box>
								<Typography fontSize={13}>
									سوالات پرسیده شده
								</Typography>
							</Box>
							<Box>
								<Typography fontSize={13}>
									12 سوال
								</Typography>
							</Box>
							<Stack>
								<Box>
									<Typography fontSize={10}>
										{`${questionsPercent}%`}
									</Typography>
								</Box>
								<Stack
									direction={"row"}
									sx={{
										width:120,
										height: 10
									}}
								>
									<Box
										sx={{
											backgroundColor:"#D9D9D9",
											width: `${100-questionsPercent}%`
										}}
									/>
									<Box
										sx={{
											backgroundColor:"#E86942",
											width: `${questionsPercent}%`
										}}
									/>
								</Stack>
							</Stack>
							<Box pt={0.5}>
								<Typography fontSize={11}>
									18 سوال تا هدف بعدی
								</Typography>
							</Box>
						</Stack>
					</Paper>
				</Stack>
				<Stack alignItems={"center"}>
					<Paper
						sx={{
							width:55,
							height:55,
							borderRadius:5,
							backgroundColor: "#E86942",
							zIndex:1
						}}
					/>
					<Paper
						sx={{
							width:155,
							height:135,
							borderRadius:7,
							backgroundColor: "#FBF8F8",
							mt:-5,
							zIndex:0
						}}
					>
						<Stack alignItems={"center"} mt={5.5}>
							<Box>
								<Typography fontSize={13}>
									روزهای حضور مداوم
								</Typography>
							</Box>
							<Box>
								<Typography fontSize={13}>
									12 روز
								</Typography>
							</Box>
							<Stack>
								<Box>
									<Typography fontSize={10}>
										{`${presentPercent}%`}
									</Typography>
								</Box>
								<Stack
									direction={"row"}
									sx={{
										width:120,
										height: 10
									}}
								>
									<Box
										sx={{
											backgroundColor:"#D9D9D9",
											width: `${100-presentPercent}%`
										}}
									/>
									<Box
										sx={{
											backgroundColor:"#E86942",
											width: `${presentPercent}%`
										}}
									/>
								</Stack>
							</Stack>
							<Box pt={0.5}>
								<Typography fontSize={11}>
									18 روز تا هدف بعدی
								</Typography>
							</Box>
						</Stack>
					</Paper>
				</Stack>
			</Stack>
		</>
	)
}