import {
	useLazyGetAdminPageDataQuery,
	useLazyGetAllTransactionsQuery,
} from "../../features/apiSlice";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import Stack from "@mui/material/Stack";
import {Avatar, useMediaQuery} from "@mui/material";
import theme from "../../lib/theme.const";
import UsersPart from "./components/UsersPart";
import FirstPart from "./components/FirstPart";
import AuthenticationProvider from "../../components/AuthenticationProvider";
import RadicalLinearChart from "./components/RadicalLinearChart";
import SecondPart from "./components/SecondPart";
import ForthPart from "./components/ForthPart";

function Page() {
	const [adminTrigger , {data: adminData}] = useLazyGetAdminPageDataQuery();
	const [allTransactionsTrigger , {data:allTransactionsData}] = useLazyGetAllTransactionsQuery();
	const {token , personalPageData} = useSelector((state: RootState) => state.app);
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	useEffect(() => {
		if(!token) return;

		adminTrigger({
			token: token,
		})
	}, [token]);

	useEffect(() => {
		if(!token) return;

		allTransactionsTrigger({
			token: token
		})
	}, [token]);

	return(
		<>
			<Stack height={"100%"} pb={10} sx={{backgroundColor:'white'}}>
				<Stack
					alignItems={"center"}
					sx={{
						backgroundColor:"#E86942",
						width:"100%",
						height:260 ,
						borderBottomRightRadius: 110
					}}
				>
					<Stack alignSelf={'flex-start'} p={2}>
						<Avatar
							src={`https://api.matnavis.com/files/${personalPageData?.msg.profile.photo}`}
							sx={{
								width:80,
								height:80
							}}
						/>
					</Stack>
				</Stack>
				<Stack mt={-15} px={"6%"}>
					<FirstPart adminData={adminData}/>
					<Stack direction={(downOfmd)?"column":"row"} pt={8} justifyContent={"space-between"} spacing={1}>
						<SecondPart adminData={adminData}/>
						{(downOfmd)?null:
							<Stack width={"32%"}>
								<UsersPart adminData={adminData}/>
							</Stack>
						}
						<ForthPart adminData={adminData}/>
						{(!downOfmd)?null:<RadicalLinearChart adminData={adminData}/>}
						{(!downOfmd)?null:
							<Stack width={"100%"}>
								<UsersPart adminData={adminData}/>
							</Stack>
						}
					</Stack>
				</Stack>
			</Stack>
		</>
	)
}

export default function AdminPanel() {
	return(
		<AuthenticationProvider>
			<Page/>
		</AuthenticationProvider>
	)
}