import Stack from "@mui/material/Stack";
import * as React from "react";
import PointPaper from "./components/PointPaper";
import PointsMainPaper from "./components/PointsMainPaper/PointsMainPaper";
import {useMediaQuery} from "@mui/material";
import theme from "../../../lib/theme.const";
import ThirdPartOfPage from "./components/ThirdPartOfPage";
import {useLazyGetUserStatsQuery} from "../../../features/apiSlice";
import {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import AuthenticationProvider from "../../../components/AuthenticationProvider";

function Page() {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const [userStatsTrigger , { data: userStats }] = useLazyGetUserStatsQuery();
	const { token } = useSelector((state: RootState) => state.app);

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	useEffect(() => {
		if(!token) return;

		userStatsTrigger({
			token: token
		})
	}, [token]);

	console.log(userStats)

	return(
		<>
			<Stack
				sx={{background: 'linear-gradient(to top, #ffffff, #E86942)'}}
				pb={50}
			>
				<Stack
					direction={(downOfmd)?"column":"row"}
					pt={9}
					px={(downOfmd)?5:(downOflg)?2:8}
					justifyContent={"space-between"}
					alignItems={(downOfmd)?"center":undefined}
					// height={(downOfmd)?"100%":810}
					spacing={(downOfsm)?0:(downOfmd)?5:undefined}
				>
					<PointPaper/>
					<PointsMainPaper/>
					<ThirdPartOfPage userStats={userStats}/>
				</Stack>
			</Stack>
		</>
	)
}

export default function ReportPersonalPageData() {
	return(
		<AuthenticationProvider>
			<Page/>
		</AuthenticationProvider>
	)
}