import {Avatar, Box, Button, Divider, Paper, Typography, useMediaQuery} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Stack from "@mui/material/Stack";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import * as React from "react";
import {useEffect, useState} from "react";
import IGetPersonalPageData from "../../../interface/IGetPersonalPageData";
import theme from "../../../lib/theme.const";
import {useLazyFetchSearchQuestionQuery} from "../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import {useNavigate} from "react-router-dom";
import NoQuestionImageStepper from "../../../components/NoQuestionImageStepper";

interface IProps {
	personalPageData: IGetPersonalPageData | undefined
}

export default function PersonalPageImageStepper({personalPageData}: IProps) {
	const [searchTrigger, { data: searchData , error }] = useLazyFetchSearchQuestionQuery();
	const { token , role } = useSelector((state: RootState) => state.app);
	const [startIndex, setStartIndex] = useState(0);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const navigate  = useNavigate();

	// const cards = personalPageData?.msg.chat_history?.concat(new Array(7).fill({
	// 	id: 5874810901,
	// 	questioner_profile: {
	// 		id: 4147222897,
	// 		username: 'test1',
	// 		name: 'علی علوی',
	// 		photo: 'default'
	// 	},
	// 	researcher_profile: {
	// 		id: 9642534897,
	// 		name: "محمد محمدی",
	// 		photo: "default",
	// 		username: "test0"
	// 	},
	// 	title: "لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ0"
	// }))

	const cards = personalPageData?.msg.chat_history

	// const imagesPerPage = ((cards) && cards.length <= 5)? cards?.length: (downOfsm)?1:(downOfmd)?3:5

	const imagesPerPage =
		(downOfsm)? 1
			: (downOfmd)? ( (cards?.length! <= 3)? cards?.length:3 )
				: ( (cards?.length! <= 5)? cards?.length: 5 )

	const getShortenedText = (text:string | undefined) => {
		if(!text) return;

		const words = text.split(' ');
		return words.length > 8 ? words.slice(0, 5).join(' ') + '...' : text;
	};

	useEffect(() => {
		//دیتایی که داری میفرستی رم بعدا باید اصلاح کنی
		if(!searchData) return;

		navigate("/allComments", { state: {data:searchData.msg.questions[0].title , id:1} });
	}, [searchData]);

	const handleNext = () => {
		if(startIndex + imagesPerPage! < cards?.length!) {
			setStartIndex(startIndex + imagesPerPage!);
		}
	};

	const handleBack = () => {
		if(startIndex - imagesPerPage! >= 0) {
			setStartIndex(startIndex - imagesPerPage!)
		}
	};

	function handleClickSelectQuestion(question: string | undefined) {
		// این سرچ باید عوض بشه و بجاش آیدی سوال قرار بگیره

		searchTrigger({
			token: '',
			search: question
		})
	}

	return(
		<Stack
			px={(downOfsm)?3:(downOflg)?4:15}
			sx={{backgroundColor:'#D6DFEA'}}
			py={'40px'}
		>
			<Stack direction={"row"} spacing={"40%"}>
				<Stack>
					<Typography
						fontWeight={"bold"}
						fontSize={"24px"}
					>
						گفتگوهای انجام شده
					</Typography>
				</Stack>
			</Stack>
			<Stack
				direction={"row"}
				justifyContent={"center"}
				alignItems={"center"}
				mt={'20px'}
			>
				<Button
					onClick={handleBack}
					disabled={startIndex === 0}
					sx={{
						width: "10%",
						minWidth: 20
					}}
				>
					<ArrowForwardIosIcon
						sx={{
							stroke: "#EE8D6F",
							strokeWidth: 5
						}}
					/>
				</Button>
				<Paper
					sx={{
						height: 180,
						width: (downOfsm)?"60%":"100%",
						backgroundColor: (!cards && downOfsm)?"#747474":(downOfsm)?"#319DD3":"#CCCCCC",
						borderRadius: "15px",
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					{(cards)?
						<Stack
							direction={"row"}
							justifyContent={"center"}
							alignItems={"center"}
						>
							{cards.slice(startIndex, startIndex + imagesPerPage!).map((content, index) => {
								return(
									<Stack key={index}>
										<Stack
											direction={"row"}
											justifyContent={"space-between"}
										>
											{(!downOfsm) && (cards?.length === 1) ?
												<Divider
													orientation="vertical"
													sx={{
														borderWidth: "135px 0px 0px thin",
														backgroundColor: "#3C3F48",
														"&:hover": {
															display: "none"
														},
													}}
												/>
												: null
											}
											<Stack
												onClick={() => handleClickSelectQuestion(content.title)}
												alignItems={"center"}
												justifyContent={"center"}
												sx={{
													width: {md:170 , lg:190 , xs:150},
													height:140,
													position: 'relative',
													"&:hover": {
														backgroundColor: (downOfsm)?undefined:"#319DD3",
														borderRadius: (downOfsm)?undefined:1,
														// width: {md:150 , lg:210 , xs:140},
														height: (downOfsm)?undefined:{md:210 , lg:220 , xs:200},
														mx: (downOfsm)?undefined:-1
													},
												}}
											>
												<Box height={"40%"}>
													<Avatar
														src={
															(role === 'questioner')?
																`https://api.matnavis.com/files/${content.researcher_profile.photo}`:
																`https://api.matnavis.com/files/${content.questioner_profile.photo}`
														}
														sx={{
															width:"55px",
															height:"55px",
															transition: 'width 0.3s ease',
														}}
													/>
												</Box>
												<Box height={'20%'}>
													<Typography variant={"body1"}>
														{
															(role === 'questioner')?
																content.researcher_profile.name:
																content.questioner_profile.name
														}
													</Typography>
												</Box>
												<Box px={2} height={'20%'}>
													<Typography variant={"body1"}>
														{getShortenedText(content.title)}
													</Typography>
												</Box>
											</Stack>
											{
												(!downOfsm)
													&&
												index <
													(
														(cards?.length - startIndex >= 5)?imagesPerPage! - 1:
															(cards.length === 1)?1:
																(cards?.length - startIndex)-1
													) ?
												<Divider
													orientation="vertical"
													sx={{
														borderWidth: "135px 0px 0px thin",
														backgroundColor: "#3C3F48",
														"&:hover": {
															display: "none"
														},
													}}
												/>
												: null
											}
										</Stack>
									</Stack>
								)
							})}
						</Stack>:
						<NoQuestionImageStepper/>
					}
				</Paper>
				<Button
					onClick={handleNext}
					disabled={startIndex + imagesPerPage! >= cards?.length!}
					sx={{
						width: "10%",
						minWidth: 20
					}}
				>
					<ArrowBackIosNewIcon
						sx={{
							stroke: "#EE8D6F",
							strokeWidth: 5
						}}
					/>
				</Button>
			</Stack>
		</Stack>
	)
}