import {
	Box,
	Dialog,
	DialogContent,
	DialogTitle, Divider,
	paperClasses, Typography, useMediaQuery
} from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import NotificationsIcon from '@mui/icons-material/Notifications';
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../../lib/theme.const";
import IGetPersonalPageData from "../../../interface/IGetPersonalPageData";
import PersonIcon from '@mui/icons-material/Person';
import {styled} from "@mui/material/styles";

export interface SimpleDialogProps {
	open: boolean;
	onClose: () => void;
	personalPageData : IGetPersonalPageData | undefined
}

const CustomScrollbarRoot = styled('div')({
	position: 'relative',
	width: '100%',
	height: "100%",
	overflowY: 'auto',
	direction: 'rtl',
	'& > *': {
		direction: 'ltr',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: '#f1f1f1',
		borderRadius: '10px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#a4a4a4',
		borderRadius: '10px',
	},
});

export default function PopupNotification({open , onClose , personalPageData}: SimpleDialogProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	// const [scrollTop, setScrollTop] = useState(0);

	// const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
	// 	const newScrollTop: number = event.currentTarget.scrollTop;
	// 	setScrollTop(newScrollTop)
	// };

	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					mt: 15,
					ml: (downOfsm)?0:16,
					[`& .${paperClasses.root}`]: {
						height: "100%",
						width: (downOfsm)?"100%":530,
						borderRadius:3
					},
					"& .MuiDialog-container": {
						justifyContent: "unset"
					}
				}}
			>
				<DialogTitle>
					<Stack
						direction={"row"}
						justifyContent={"space-between"}
					>
						<Stack>
							<NotificationsIcon sx={{color: "#cb7158"}}/>
						</Stack>
						<Stack alignSelf={"flex-end"}>
							<CloseIcon
								sx={{color: "#ababab"}}
								onClick={() => onClose()}
							/>
						</Stack>
					</Stack>
				</DialogTitle>
				<DialogContent>
					{(!personalPageData || !personalPageData?.msg.notifs || personalPageData?.msg.notifs?.length === 0)?
						<Stack justifyContent={'center'} alignItems={'center'}>
							<Box>
								<img
									alt={'no notification'}
									src={'./png/4.png'}
									width={450}
									height={500}
								/>
							</Box>
							<Box mt={-20}>
								<Typography fontWeight={'bold'} fontSize={20}>
									نوتیفیکیشنی وجود ندارد
								</Typography>
							</Box>
						</Stack>:
						<CustomScrollbarRoot>
							<Stack direction={"row"}>
								<Stack sx={{direction: "ltr"}} width={"100%"}>
									{personalPageData.msg.notifs?.map((notif , index) => {
										return(
											<Stack
												direction={"row"}
												px={(downOfsm)?0:3}
												key={index}
											>
												<Stack
													width={"100%"}
													mb={"5%"}
													sx={{
														// backgroundColor: "#EE8D6F",
														backgroundColor: "rgba(49, 157, 211, 0.5)",
														minHeight: 180,
														borderRadius:6,
														zIndex:1,
														backdropFilter: 'blur(1px)'
													}}
													justifyContent={"space-between"}
													direction={"row"}
													alignItems={"center"}
												>
													<Stack
														width={"25%"}
														alignItems={"center"}
														alignSelf={"flex-end"}
														pb={2}
													>
														<PersonIcon
															sx={{
																width: 90,
																height: 90,
																color: "#808080"
															}}
														/>
													</Stack>
													<Stack width={"50%"} pt={4}>
														<Box>
															<Divider
																sx={{
																	backgroundColor: "black",
																	width: "95%",
																	"&:hover": {
																		display: "none"
																	},
																}}
															/>
														</Box>
														<Box pt={3}>
															<Typography>
																{notif.msg}
															</Typography>
														</Box>
													</Stack>
													<Stack
														width={"10%"}
														sx={{
															width: 20,
															height: 20,
															backgroundColor: "#D9D9D9",
															rotate: "45deg",
															mr: -1.3
														}}
													/>
												</Stack>
												<Box
													width={"100%"}
													ml={(downOfsm)?-20:-33}
													mt={"5%"}
													sx={{
														backgroundColor: "#D9D9D9",
														minHeight: 180,
														borderRadius: 6,
														zIndex:0,
													}}
												>
												</Box>
											</Stack>
										)
									})}
								</Stack>
							</Stack>
						</CustomScrollbarRoot>
					}
				</DialogContent>
			</Dialog>
		</>
	)
}