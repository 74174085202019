import Stack from "@mui/material/Stack";
import {Box, Button, Typography, useMediaQuery} from "@mui/material";
import {CartesianGrid, Line, LineChart, ResponsiveContainer, XAxis, YAxis} from "recharts";
import * as React from "react";
import {useState} from "react";
import theme from "../../../../../../lib/theme.const";


export default function ReportChart() {
	const [weeklyButton , setWeeklyButton] = useState<boolean>(false);
	const [monthlyButton , setMonthlyButton] = useState<boolean>(false);
	const [yearlyButton , setYearlyButton] = useState<boolean>(false);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	const persianLetters: string[] = ['ش', 'ی', 'د', 'س', 'چ', 'پ', 'ج'];
	const tickFormatter = (tick: number): string => persianLetters[tick];

	const chartData2 = [
		{
			test1: Math.random(),
			test2: Math.random()
		},
		{
			test1: Math.random() * 2,
			test2: Math.random() * 2
		},
		{
			test1: Math.random(),
			test2: Math.random()
		},
		{
			test1: Math.random(),
			test2: Math.random()
		},
		{
			test1: Math.random() * 2,
			test2: Math.random() * 2
		},
		{
			test1: Math.random(),
			test2: Math.random()
		},
		{
			test1: Math.random() * 2,
			test2: Math.random() * 2
		},
	]

	return(
		<>
			<Stack direction={"row"} spacing={1} pt={1} pl={7}>
				<Button
					onClick={() => {
						setWeeklyButton(true);
						setMonthlyButton(false);
						setYearlyButton(false)
					}}
					variant={(weeklyButton)?"contained":"outlined"}
					sx={{
						backgroundColor: (!weeklyButton)?undefined:"#EE8D6F",
						color: (weeklyButton)?"white":"#EE8D6F",
						borderColor:"#EE8D6F",
						height:30,
						'&:hover': {
							backgroundColor: (weeklyButton)?"#8f3e04":undefined,
							borderColor: (!weeklyButton)?"#E86942":undefined
						},
					}}
				>
					هفتگی
				</Button>
				<Button
					onClick={() => {
						setWeeklyButton(false);
						setMonthlyButton(true);
						setYearlyButton(false)
					}}
					variant={(monthlyButton)?"contained":"outlined"}
					sx={{
						backgroundColor: (!monthlyButton)?undefined:"#EE8D6F",
						color: (monthlyButton)?"white":"#EE8D6F",
						borderColor:"#EE8D6F",
						height:30,
						'&:hover': {
							backgroundColor: (monthlyButton)?"#8f3e04":undefined,
							borderColor: (!monthlyButton)?"#E86942":undefined
						},
					}}
				>
					ماهیانه
				</Button>
				<Button
					onClick={() => {
						setWeeklyButton(false);
						setMonthlyButton(false);
						setYearlyButton(true)
					}}
					variant={(yearlyButton)?"contained":"outlined"}
					sx={{
						backgroundColor: (!yearlyButton)?undefined:"#EE8D6F",
						color: (yearlyButton)?"white":"#EE8D6F",
						borderColor:"#EE8D6F",
						height:30,
						'&:hover': {
							backgroundColor: (yearlyButton)?"#8f3e04":undefined,
							borderColor: (!yearlyButton)?"#E86942":undefined
						},
					}}
				>
					سالیانه
				</Button>
			</Stack>
			<Stack alignItems={"center"}>
				<Stack
					px={(downOfsm)?5:7}
					pt={3}
					height={"100%"}
					ml={(downOfsm)?4:undefined}
					// sx={{width:{xs:350 , md:700 , lg:750}}}
					width={(downOfsm)?300:700}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<ResponsiveContainer aspect={(downOfsm)?0.8:5}>
						<LineChart data={chartData2}>
							<CartesianGrid strokeDasharray="3 3" />
							<Line
								dataKey={"test1"}
								fill={"#E86942"}
								stroke={"#E86942"}
							/>
							<Line
								dataKey={"test2"}
								fill={"#161515"}
								stroke={"#161515"}
							/>
							<XAxis
								tickFormatter={tickFormatter}
							/>
							<YAxis
								width={50}
								// domain={[0, 100]}
								ticks={[0 , 1 , 2]}
								tickCount={3}
								type={"number"}
								textAnchor={"start"}
							/>
							{/*<Legend/>*/}
						</LineChart>
					</ResponsiveContainer>
					<Stack direction={"row"} spacing={8} pt={3}>
						<Stack direction={"row"} spacing={1}>
							<Typography>
								میانگین
							</Typography>
							<Box
								sx={{
									width: 20,
									height: 20,
									borderRadius: "50%",
									backgroundColor: "black"
								}}
							/>
						</Stack>
						<Stack direction={"row"} spacing={1}>
							<Typography>
								آمار من
							</Typography>
							<Box
								sx={{
									width: 20,
									height: 20,
									borderRadius: "50%",
									backgroundColor: "#E86942"
								}}
							/>
						</Stack>
					</Stack>
				</Stack>
			</Stack>
		</>
	)
}