import Stack from "@mui/material/Stack";
import FloatingOrangeListQuestioner from "../FloatingOrangeListQuestioner";
import Specifications from "../Specifications";
import {Divider, useMediaQuery} from "@mui/material";
import SwitcherButtons from "../SwitcherButtons";
import Wallet from "../../wallet/Wallet";
import React from "react";
import theme from "../../../../../lib/theme.const";
import {useSelector} from "react-redux";
import {RootState} from "../../../../../app/store";
import IGetSettingPageData from "../../../../../interface/IGetSettingPageData";

interface IProps {
	settingPageData:IGetSettingPageData |undefined,
	handleItemClick: (sectionId:string) => void,
	handleClickSaveProfile: () => void
}

export default function SettingPageQuestioner({settingPageData , handleItemClick , handleClickSaveProfile}: IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const {role} = useSelector((state: RootState) => state.app);

	return(
		<Stack direction={"row"} sx={{backgroundColor:'white'}}>
			{(!downOfmd)?
				<Stack
					width={"25%"}
					sx={{
						// background: 'linear-gradient(to top, #ffffff, #E86942)',
						backgroundColor: "#E86942"
					}}
				>
					<FloatingOrangeListQuestioner
						handleItemClick={handleItemClick}
						handleClickSaveProfile={handleClickSaveProfile}
					/>
				</Stack>:
				null
			}
			<Stack width={(downOfmd)?"100%":"75%"}>
				<Stack id={"sectionQuestioner1"}>
					<Specifications data={settingPageData?.msg.profile}/>
				</Stack>
				<Stack
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Divider
						sx={{
							backgroundColor: "#bebebe",
							width: "85%"
						}}
					/>
				</Stack>
				<Stack
					spacing={3}
					alignItems={"center"}
					pt={15}
					id={"sectionQuestioner2"}
				>
					<SwitcherButtons notif={settingPageData?.msg.notif_pref}/>
				</Stack>
				<Stack
					px={(downOfsm)?3:(downOfmd)?15:(downOflg)?10:20}
					pt={25}
					pb={10}
					id={"sectionQuestioner3"}
				>
					<Wallet/>
				</Stack>
			</Stack>
		</Stack>
	)
}