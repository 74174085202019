import Stack from "@mui/material/Stack";
import {
	Container,
	FormControl,
	InputLabel,
	outlinedInputClasses,
	Select,
	SelectChangeEvent,
	TextField, useMediaQuery
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import ReactQuill, {Quill} from "react-quill";
import * as React from "react";
import {useEffect, useMemo, useRef, useState} from "react";
import INotestype from "../../../../../interface/INotestype";
import {makeStyles} from "@mui/styles";
import theme from "../../../../../lib/theme.const";

const Font = Quill.import('formats/font');
Font.whitelist = [
	'Vazirmatn',
	'dana',
	'Cairo' ,
	'Almarai' ,
	'IBM-Plex-Sans-Arabic' ,
	'Amiri' ,
	'Noto-Naskh-Arabic',
	'Lalezar',
	'Mirza'
];
Quill.register(Font, true);

const Size = Quill.import('attributors/style/size');
Size.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '40px'];
Quill.register(Size, true);


const useStyles = makeStyles({
	quill: {
		'& .ql-container': {
			direction: 'rtl'
		},
		'& .ql-toolbar': {
			backgroundColor:"white",
			direction: 'rtl'
		},
		// Define custom font styles
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Vazirmatn"]': { fontFamily: 'Vazirmatn' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Dana"]': { fontFamily: 'Dana' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Cairo"]': { fontFamily: 'Cairo' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Almarai"]': { fontFamily: 'Almarai' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="IBM-Plex-Sans-Arabic"]': { fontFamily: 'IBM Plex Sans Arabic' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Amiri"]': { fontFamily: 'Amiri' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Noto-Naskh-Arabic"]': { fontFamily: 'Noto Naskh Arabic' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Lalezar"]': { fontFamily: 'Lalezar' },
		'& .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Mirza"]': { fontFamily: 'Mirza' },
	},
});

interface IProps {
	setNoteContent: (noteContent: string | undefined) => void;
	noteForEdit?: INotestype;
	noteContent : string | undefined
}

export default function NoteTextField({setNoteContent , noteForEdit}:IProps) {
	const [content, setContent] = useState<string>('');
	const quillRef = useRef<ReactQuill | null>(null);
	const [selectedFont, setSelectedFont] = useState<string>('Vazirmatn');
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const classes = useStyles();

	useEffect(() => {
		if(!noteForEdit) return;

		setContent(noteForEdit.body)
		// setNoteContent(noteForEdit.body)
	}, [noteForEdit]);

	useEffect(() => {
		const updateSizeOptions = () => {
			const sizeOptions = document.querySelectorAll('.ql-size .ql-picker-item');
			sizeOptions.forEach(option => {
				const sizeValue = option.getAttribute('data-value');
				if (sizeValue) {
					option.textContent = sizeValue;
				}
			});
		};

		// Update size options on render
		updateSizeOptions();

		// Optionally, observe changes if the toolbar is dynamically updated
		const observer = new MutationObserver(updateSizeOptions);
		observer.observe(document.querySelector('.ql-toolbar')!, { childList: true, subtree: true });

		return () => observer.disconnect();
	}, [document]);

	useEffect(() => {
		const span = document.querySelector('.ql-snow .ql-picker.ql-size .ql-picker-label') as HTMLElement;
		span.textContent = span.dataset.value!
	}, []);

	useEffect(() => {
		const style = document.createElement('style');
		style.textContent = `
      .ql-snow .ql-picker.ql-size .ql-picker-label::before {
        content: none !important;
      }
    `;
		document.head.appendChild(style);

		// حذف استایل هنگام unmount
		return () => {
			document.head.removeChild(style);
		};
	}, []);

	useEffect(() => {

		// انتخاب عنصر اصلی span
		const span = document.querySelector('.ql-snow .ql-picker.ql-size .ql-picker-label') as HTMLElement;

		// انتخاب لیست آپشن‌ها
		const options = document.querySelectorAll('.ql-snow .ql-picker.ql-size .ql-picker-options .ql-picker-item');

		options.forEach(option => {
			option.addEventListener('click', () => {
				// دریافت مقدار data-value آپشن انتخاب شده
				const value = option.getAttribute('data-value');
				if (value) {
					// تغییر مقدار data-value در عنصر span
					span.setAttribute('data-value', value);
					span.textContent = value
				}
			});
		});

		// حذف رویداد گوش‌دهنده‌ها هنگام unmount
		// return () => {
		// 	options.forEach(option => {
		// 		option.removeEventListener('click', () => {
		// 			// نیاز به تعریف تابع برای حذف رویداد
		// 			// در اینجا حذف تابع کلیک نیاز به مدیریت دقیق‌تری دارد
		// 		});
		// 	});
		// };
	}, []);

	useEffect(() => {
		const updateFontOptions = () => {
			const fontOptions = document.querySelectorAll('.ql-font .ql-picker-item');
			fontOptions.forEach(option => {
				const fontValue = option.getAttribute('data-value');
				if (fontValue) {
					option.textContent = fontValue;
				}
			});
		};

		// Update size options on render
		updateFontOptions();

		// Optionally, observe changes if the toolbar is dynamically updated
		const observer = new MutationObserver(updateFontOptions);
		observer.observe(document.querySelector('.ql-toolbar')!, { childList: true, subtree: true });

		return () => observer.disconnect();
	}, [document]);

	useEffect(() => {
		const span = document.querySelector('.ql-snow.ql-toolbar .ql-picker-label') as HTMLElement;
		span.textContent = span.dataset.value!
	}, []);

	useEffect(() => {
		const style = document.createElement('style');
		style.textContent = `
      .ql-snow .ql-picker.ql-font .ql-picker-label::before {
        content: none !important;
      }
    `;
		document.head.appendChild(style);

		// حذف استایل هنگام unmount
		return () => {
			document.head.removeChild(style);
		};
	}, []);

	useEffect(() => {

		// انتخاب عنصر اصلی span
		const span = document.querySelector('.ql-snow.ql-toolbar .ql-picker-label') as HTMLElement;

		// انتخاب لیست آپشن‌ها
		const options = document.querySelectorAll('.ql-snow .ql-picker.ql-font .ql-picker-options .ql-picker-item');

		options.forEach(option => {
			option.addEventListener('click', () => {
				// دریافت مقدار data-value آپشن انتخاب شده
				const value = option.getAttribute('data-value');
				if (value) {
					// تغییر مقدار data-value در عنصر span
					span.setAttribute('data-value', value);
					span.textContent = value;

					setSelectedFont(value);
					// const editor = quillRef.current?.getEditor();
					// if (editor) {
					// 	// Apply the font to the entire content
					// 	editor.format('font', value);
					// 	// Apply the font to new text typed in the editor
					// 	editor.on('text-change', () => {
					// 		editor.format('font', value);
					// 	});
					// }
				}
			});
		});
	},[])

	useEffect(() => {
		const editorElement = document.querySelector('.ql-editor') as HTMLElement;
		if (editorElement) {
			editorElement.style.fontFamily = selectedFont;
		}
	}, [selectedFont]);

	const modules = useMemo(() => ({
		toolbar: {
			container: [
				[{ 'font': [
						'Vazirmatn',
						'Dana',
						'Cairo' ,
						'Almarai' ,
						'IBM-Plex-Sans-Arabic' ,
						'Amiri' ,
						'Noto-Naskh-Arabic',
						'Lalezar',
						'Mirza'
					]
				}],
				[{ 'size': ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '28px', '32px', '40px'] }],
				[{ 'color': [] }, { 'background': [] }],
				[{ 'bold': true }, { 'italic': true }, { 'underline': true }, { 'strike': true }],
				[{ 'list': 'bullet' }, { 'list': 'ordered' }],
				[{ 'align': [] }],
				['image']
			],
		}
	}), []);

	const formats = useMemo(() => [
		'font', 'size', 'color', 'background',
		'bold', 'italic', 'underline', 'strike',
		'list', 'bullet', 'align',
		'link', 'image'
	], []);

	// useEffect(() => {
	// 	if (quillRef.current) {
	// 		const quill = quillRef.current.getEditor();
	// 		quill.format('align', 'right');
	// 	}
	// }, [quillRef]);

	const handleFormatChange = (type: string, value: string) => {
		if (quillRef.current) {
			const quill = quillRef.current.getEditor();
			quill.format(type, value);
		}
	};

	return(
		<>
			<Container>
				<ReactQuill
					ref={quillRef}
					value={content}
					className={classes.quill}
					onChange={(value, delta, source, editor) => {
						setContent(value);
						setNoteContent(editor.getText());
					}}
					modules={modules}
					formats={formats}
					style={{ height:(downOfsm)?210:(downOflg)?260:280 }}
				/>
			</Container>
		</>
	)
}