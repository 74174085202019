import Stack from "@mui/material/Stack";
import {Box, Paper, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import ReportHistory from "./components/ReportHistory";
import ReportChart from "./components/ReportChart";
import Medals from "./components/Medals";
import theme from "../../../../../lib/theme.const";


export default function PointsMainPaper() {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));

	return(
		<>
			<Paper
				sx={{
					backgroundColor: "#D9D9D9",
					borderRadius: 15,
					pt: 3,
					pb: 3,
					px: (downOflg)?1:3,
					mt: (downOfsm)?5:undefined,
					height:"100%"
				}}
			>
				<Stack>
					<Box pt={3} pl={3}>
						<Typography fontWeight={"bold"} fontSize={18}>
							امتیازها
						</Typography>
					</Box>
					<ReportHistory/>
					<Stack pt={3}>
						<Stack
							justifyContent={"center"}
							alignItems={"center"}
						>
							<Typography
								fontWeight={"bolder"}
								fontSize={22}
								sx={{color: "#EE8D6F"}}
							>
								تعداد سوالات پرسیده شده
							</Typography>
						</Stack>
						<ReportChart/>
					</Stack>
					<Stack pt={(downOfsm)?8:2}>
						<Box pl={4}>
							<Typography fontWeight={"bold"} fontSize={18}>
								مدال ها
							</Typography>
						</Box>
						<Medals/>
					</Stack>
				</Stack>
			</Paper>
		</>
	)
}