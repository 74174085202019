import Stack from "@mui/material/Stack";
import {Avatar, Box, Divider, Paper, TextField, Typography, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {LocalizationProvider, StaticDatePicker} from '@mui/x-date-pickers';
import AdapterDateFnsJalali from '@date-io/date-fns-jalali';
import jMoment from 'moment-jalaali';
import {styled} from "@mui/material/styles";
import jalaali from 'jalaali-js';
import IGetUserStatsResult from "../../../../interface/IGetUserStatsResult";
import theme from "../../../../lib/theme.const";

interface IProps {
	userStats: IGetUserStatsResult | undefined
}

jMoment.loadPersian({ dialect: 'persian-modern', usePersianDigits: true });

const CustomStaticDatePicker = styled(StaticDatePicker)(({ theme }) => ({
	'& .MuiPickerStaticWrapper-content': {
		minWidth: "unset",
		width: "220px",
		borderRadius: "20px",
		boxShadow: "0px 7px 10px 0px #535353",
		height: 270
	},
	'& .MuiCalendarPicker-root': {
		backgroundColor: '#D9D9D9',
		width: "220px",
		margin: "0px 0px"
	},
	'& .MuiPickersCalendarHeader-root': {
		borderBottom: 'none',
		backgroundColor: "#E86942",
		marginTop: 0,
		maxHeight: "unset",
		height: "40px"
	},
	'& .MuiDayPicker-slideTransition': {
		margin: "8px",
	},
	'& .MuiDayPicker-header': {
		marginLeft: "8px",
		marginRight: "8px",
		marginTop: "-6px",
		marginBottom: "-6px"
	},
	'& .MuiPickersCalendarHeader-labelContainer': {
		color: "white"
	},
	'& .MuiSvgIcon-root': {
		color: "white"
	},
	'& .MuiPickersDay-root': {
		borderRadius: 0,
		height:27
	},
	"& .MuiPickersDay-root.Mui-selected:hover": {
		backgroundColor: "white"
	},
	"& .MuiPickersDay-root:hover": {
		backgroundColor: "white"
	}
}));

export default function ThirdPartOfPage({userStats}:IProps) {
	const [selectedDate, setSelectedDate] = useState<Date | null>(null);
	const [monthChange , setMonthChange] = useState<boolean>();
	const [yearChange , setYearChange] = useState<boolean>();
	const buttonData: (Element | null)[] = [];
	const currentDateJalali = jalaali.toJalaali(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate())
	const [currentMonth , setCurrentMonth] = useState<number>(currentDateJalali.jm);
	const [currentYear , setCurrentYear] = useState<number>(currentDateJalali.jy);
	const subjectStats = userStats?.msg?.subject_stats ?? {};
	const subjectStatsEntry = Object.entries(subjectStats);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	useEffect(() => {
		const buttonsData = window.document.getElementsByClassName("MuiButtonBase-root MuiPickersDay-root")
		const length = buttonsData.length

		for (let i=0; i < length; i++) {
			buttonData.push(buttonsData.item(i))
		}

		userStats?.msg.active_days.dates.map((value) => {
			const date = new Date(value.end_ts * 1000); // ضرب در 1000 برای تبدیل به میلی‌ثانیه
			const jalaaliDate = jalaali.toJalaali(date.getFullYear(), date.getMonth() + 1, date.getDate());
			const year = jalaaliDate.jy;
			const month = jalaaliDate.jm;
			const day = jalaaliDate.jd;

			if(currentYear === year && currentMonth === month) {
				// if(!value.active) return;
				buttonData.map((valueButton, indexButton) => {
					if(Number(valueButton?.textContent) === day) {
						if(valueButton === null) return;
						// @ts-ignore
						valueButton.style.backgroundColor = "#E86942"
					}
				})
			}
		})
	}, [monthChange , yearChange , userStats]);

	const handleDateChange = (date: any) => {
		// setSelectedDate(date as Date | null);
	};

	function handleMonthChange(date: any) {
		setMonthChange(!monthChange)
		let jalaaliDate = jalaali.toJalaali(date.getFullYear(), date.getMonth() + 1, date.getDate());
		setCurrentMonth(jalaaliDate.jm)
		setCurrentYear(jalaaliDate.jy)
	}

	function handleYearChange(date: any) {
		setYearChange(!yearChange)
		let jalaaliDate = jalaali.toJalaali(date.getFullYear(), date.getMonth() + 1, date.getDate());
		setCurrentYear(jalaaliDate.jy)
	}

	return(
		<>
			<Stack
				alignItems={"center"}
				direction={(downOfsm)?"column":(downOfmd)?"row":"column"}
				spacing={(downOfsm)?undefined:(downOfmd)?3:undefined}
			>
				<Stack alignItems={"center"}>
					<Box>
						<Avatar
							src={"./personBottom.png"}
							sx={{
								width:70,
								height:70
							}}
						/>
					</Box>
					<Paper
						sx={{
							borderRadius: 5,
							px: 3,
							py: 3,
							mt:-5.5
						}}
					>
						<Stack
							mt={3}
							justifyContent={"center"}
							alignItems={"center"}
							spacing={1}
						>
							<Box>
								<Typography fontWeight={"bold"}>
									نام کاربری
								</Typography>
							</Box>
							<Box>
								<Typography fontWeight={"bold"}>
									سطح امتیازات کاربر
								</Typography>
							</Box>
							<Stack alignItems={"center"} spacing={1} pt={1}>
								<Box>
									<Typography fontSize={13} fontWeight={"bold"}>
										دستاوردها
									</Typography>
								</Box>
								<Stack direction={"row"} spacing={1}>
									<Box
										sx={{
											width:25,
											height:25,
											backgroundColor: "#D9D9D9",
											borderRadius: 1.5
										}}
									/>
									<Box
										sx={{
											width:25,
											height:25,
											backgroundColor: "#D9D9D9",
											borderRadius: 1.5
										}}
									/>
									<Box
										sx={{
											width:25,
											height:25,
											backgroundColor: "#D9D9D9",
											borderRadius: 1.5
										}}
									/>
									<Box
										sx={{
											width:25,
											height:25,
											backgroundColor: "#D9D9D9",
											borderRadius: 1.5
										}}
									/>
									<Box
										sx={{
											width:25,
											height:25,
											backgroundColor: "#D9D9D9",
											borderRadius: 1.5
										}}
									/>
								</Stack>
							</Stack>
						</Stack>
					</Paper>
				</Stack>
				<Stack>
					<Stack px={0} pb={6}>
						<LocalizationProvider dateAdapter={AdapterDateFnsJalali}>
							<Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
								<CustomStaticDatePicker
									displayStaticWrapperAs="desktop"
									openTo="day"
									value={selectedDate}
									onMonthChange={handleMonthChange}
									onYearChange={handleYearChange}
									onChange={handleDateChange}
									renderInput={(params: any) => <TextField {...params} />}
									sx={{
										'& .MuiPickersDay-root.Mui-selected': {
											backgroundColor: "white",
											color: "black"
										},
										'& .MuiPickersDay-root:not(.Mui-selected)':{
											borderColor: "unset"
										},
									}}
								/>
							</Box>
						</LocalizationProvider>
					</Stack>
				</Stack>
				<Paper
					sx={{
						height: "100%",
						backgroundColor: '#D9D9D9',
						width: "220px",
						borderRadius: "20px",
						py:2
					}}
				>
					<Stack alignItems={"center"}>
						<Stack>
							<Typography
								fontSize={20}
								fontWeight={"bold"}
								sx={{color:"#E86942"}}
							>
								موضوعات محبوب
							</Typography>
						</Stack>
						<Box>
							<Divider
								sx={{
									width: 210,
									pt: 1
								}}
							/>
						</Box>
						{subjectStatsEntry.map((content , index) => {
							return(
								<Stack key={index}>
									<Stack
										direction={"row"}
										sx={{mt:1}}
										width={210}
										// px={3}
										spacing={1}
									>
										<Stack
											width={60}
											justifyContent={"center"}
											alignItems={"center"}
											sx={{
												backgroundColor: "#524E4E",
												// px:2,
												borderRadius: 4,
											}}
										>
											<Typography sx={{color: "white"}} fontSize={9}>
												{content[0]}
											</Typography>
										</Stack>
										<Stack
											width={"70%"}
											direction={"row"}
											alignItems={"center"}
											spacing={1}
										>
											<Box
												sx={{
													height: "4px",
													width: `${(content[1] as number)*10}%`,
													backgroundColor: "#E86942"
												}}
											/>
											<Box width={"10%"}>
												<Typography>
													{content[1] as number}
												</Typography>
											</Box>
										</Stack>
									</Stack>
									{(index < subjectStatsEntry.length-1)?
										<Box>
											<Divider
												sx={{
													width: 210,
													pt: 1
												}}
											/>
										</Box>:
										null
									}
								</Stack>
							)
						})}
					</Stack>
				</Paper>
			</Stack>
		</>
	)
}