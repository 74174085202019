import {Avatar, Box, Card, CardContent, Divider, Typography, useMediaQuery} from "@mui/material";
import Stack from "@mui/material/Stack";
import * as React from "react";
import theme from "../lib/theme.const";
import moment from "moment-jalaali";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from "@mui/icons-material/Notifications";
import SettingsIcon from "@mui/icons-material/Settings";
import {useNavigate} from "react-router-dom";
import IGetPersonalPageData from "../interface/IGetPersonalPageData";

interface IProps {
	data: {
		name: string | undefined,
		username: string | undefined,
		created_at?: number | undefined,
		education: string | undefined,
		questions?: number | undefined,
		bio: string | undefined,
		picture: string | undefined,
		createDate?: { year: string; month: string; day: string; } | undefined
	}
	handleNotificationClick?: () => void;
	personalData?: IGetPersonalPageData | undefined
}

export default function CardPersonalProfile({data , handleNotificationClick , personalData}:IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const navigate = useNavigate();

	function convertUnixToJalali(unixTimestamp: number | undefined) {
		if(!unixTimestamp) return;
		// تبدیل تاریخ یونیکس به تاریخ میلادی
		const date = moment.unix(unixTimestamp);

		// تبدیل تاریخ میلادی به تاریخ شمسی با استفاده از jalali-moment
		return date.locale('fa').format('jYYYY/jMM/jDD');
	}

	return(
		<>
			<Card
				sx={{
					backgroundColor: "#D9D9D9",
					borderRadius: 4,
					height:(downOfmd)?270:260,
					display:"flex",
					alignItems:"center",
					width:(downOfsm)?300:480
				}}
			>
				<CardContent sx={{width:"100%",px:(downOfmd)?1:undefined}}>
					<Stack>
						{(handleNotificationClick)?
							<Stack
								direction={"row"}
								justifyContent={"space-between"}
							>
								<IconButton
									sx={{width: "10%"}}
									onClick={() => navigate("/conversation-personal" , {state:personalData})}
								>
									<img
										alt={'conversation icon'}
										src={"./massageIconColorFull.png"}
										style={{
											width:22,
											height:22
										}}
									/>
								</IconButton>
								<Stack direction={"row"}>
									<IconButton onClick={handleNotificationClick}>
										<NotificationsIcon
											sx={{color: "#E86942"}}
										/>
									</IconButton>
									<IconButton onClick={() => navigate("/setting-personal", {state: data})}>
										<SettingsIcon
											sx={{color: "#E86942"}}
										/>
									</IconButton>
								</Stack>
							</Stack>:
							null
						}
						<Stack direction={"row"}>
							<Stack justifyContent={"center"} alignItems={"center"} px={2} width={(downOfmd)?'40%':"30%"}>
								<Avatar
									alt={'questioner picture'}
									src={`https://api.matnavis.com/files/${data?.picture}`}
									sx={{
										width:"90px",
										height:"90px",
										transition: 'width 0.3s ease',
									}}
								/>
							</Stack>
							<Divider
								orientation="vertical"
								sx={{
									borderWidth: "170px 0px 0px thin",
									backgroundColor: "#3C3F48",
								}}
							/>
							<Stack
								justifyContent={"center"}
								pl={2}
								width={"60%"}
								spacing={2}
							>
								<Box>
									<Box>
										<Typography
											fontWeight={"bold"}
											fontSize={18}
										>
											{(data?.name)??''}
										</Typography>
									</Box>
									<Box>
										<Typography
											fontSize={14}
										>
											{(data?.username)??''}
										</Typography>
									</Box>
								</Box>
								<Box>
									{(data?.created_at)?
										<Typography>
											{` تاریخ عضویت: ${(convertUnixToJalali(data?.created_at))??''}`}
										</Typography>:
										(data?.createDate)?
											<Typography>
												{data?.createDate.day + data?.createDate.month + data?.createDate.year}
											</Typography>
										:null
									}
								</Box>
								<Box>
									<Typography>
										{`تحصیلات: ${(data?.education)??''}`}
									</Typography>
								</Box>
								<Stack
									sx={{
										border: 1,
										borderRadius: 6,
										p: 1,
										background: 'linear-gradient(to right bottom, #ffffff, #c9c9c9)',
										width: "90%"
									}}
								>
									<Typography>
										{`بایو: ${(data?.bio)??''}`}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
				</CardContent>
			</Card>
		</>
	)
}