import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import * as React from "react";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import {Typography, useMediaQuery} from "@mui/material";
import theme from "../../lib/theme.const";
import {useEffect, useState} from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useDispatch} from "react-redux";
import {openMatnavisHeaderDrawerChanged} from "../../features/appSlice";

export default function MatnavisHeader() {
	const [openProjectPaper , setOpenProjectPaper] = useState<null | HTMLElement>(null);
	const isMenuProjectPaperOpen = Boolean(openProjectPaper);
	const downOfNumberForHamburgerMenu = useMediaQuery(theme.breakpoints.down(990));
	const [openDrawer , setOpenDrawer] = useState<boolean>(false);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(openMatnavisHeaderDrawerChanged(openDrawer))
	}, [openDrawer]);

	const handleProjectPaperClose = () => {
		setOpenProjectPaper(null);
	};

	const handleProjectPaperMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setOpenProjectPaper(event.currentTarget);
	};

	const mobileMenuFinance = 'primary-search-account-menu-mobile-finance';
	const renderMobileMenuFinance = (
		<Menu
			sx={{
				mt:5,
				"& .MuiPopover-paper": {
					// backgroundColor: "#D9D9D9",
					width: 165,
					display: "flex",
					alignItems: "center",
					borderRadius: 2
				},
				'.MuiMenu-list': {
					width: '100%'
				}
			}}
			anchorEl={openProjectPaper}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			id={mobileMenuFinance}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			open={isMenuProjectPaperOpen}
			onClose={handleProjectPaperClose}
		>
			<MenuItem
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Box onClick={() => window.location.href = 'https://matnavis.com/coming-soon'}>
					<Typography>
						هستان شناسی‌ها
					</Typography>
				</Box>
			</MenuItem>
			<MenuItem
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Box onClick={() => window.location.href = 'https://matnavis.com/coming-soon'}>
					<Typography>
						سینتوپیکون
					</Typography>
				</Box>
			</MenuItem>
			<MenuItem
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Box onClick={() => window.location.href = 'https://matnavis.com/coming-soon'}>
					<Typography>
						پایگاه پیرنیا
					</Typography>
				</Box>
			</MenuItem>
		</Menu>
	);

	return(
		<>
			<Box>
				<AppBar sx={{backgroundColor: "rgba(248, 249, 250)" , opacity:1}}>
					<Toolbar>
						<Stack width={'100%'}>
							<Stack px={'12.5%'} direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
								<Stack pt={(downOfNumberForHamburgerMenu && openDrawer)?1.5:undefined}>
									{(downOfNumberForHamburgerMenu)?
										<Stack
											onClick={() => setOpenDrawer(!openDrawer)}
											alignItems={'center'}
											justifyContent={'center'}
											sx={{
												border: '1px',
												borderColor:'rgba(0, 0, 0, .1)',
												borderRadius:'0.375rem',
												py:'0.25rem',
												px:'0.75rem',
												borderStyle: 'solid',
												maxWidth: 54
											}}
										>
											<img src={'./hamburgerMenu.png'}/>
										</Stack>:
										null
									}
									{(downOfNumberForHamburgerMenu && !openDrawer)?
										null:
										<Stack
											direction={(downOfNumberForHamburgerMenu && openDrawer)?'column-reverse':'row'}
											py={(downOfNumberForHamburgerMenu && openDrawer)?2:undefined}
											// alignItems={'center'}
											// justifyContent={'space-between'}
											// height={30}
											spacing={2}
											width={'100%'}
										>
											<Box
												sx={{cursor:'pointer'}}
												onClick={() => window.location.href = 'https://matnavis.com/'}
											>
												<Typography
													sx={{color:'#427a93'}}
													fontWeight={'bolder'}
													fontSize={15}
												>
													خانه
												</Typography>
											</Box>
											<Box
												sx={{cursor:'pointer'}}
												onClick={() => {
													window.location.href = 'https://auth.testavis.ir/'
													// window.location.href = 'http://localhost:8000/'
												}}
											>
												<Typography sx={{color:'rgba(0, 0, 0, .55)'}} fontSize={15}>
													ورود | ثبت نام
												</Typography>
											</Box>
											<Box
												sx={{cursor:'pointer'}}
												onClick={() => window.location.href = 'https://matnavis.com/events'}
											>
												<Typography sx={{color:'rgba(0, 0, 0, .55)'}} fontSize={15}>
													رویداد ها
												</Typography>
											</Box>
											<Box
												sx={{cursor:'pointer'}}
												onClick={() => window.location.href = 'https://matnavis.com/blogs'}
											>
												<Typography sx={{color:'rgba(0, 0, 0, .55)'}} fontSize={15}>
													یادداشت
												</Typography>
											</Box>
											<Box
												sx={{cursor:'pointer'}}
												onClick={() => window.location.href = 'https://matnavis.com/image-card-home'}
											>
												<Typography sx={{color:'rgba(0, 0, 0, .55)'}} fontSize={15}>
													درس افزار
												</Typography>
											</Box>
											<Stack
												onClick={handleProjectPaperMenuOpen}
												direction={'row'}
												spacing={0.5}
												sx={{cursor:'pointer'}}
											>
												<Typography sx={{color:(isMenuProjectPaperOpen)?'black':'rgba(0, 0, 0, .55)'}} fontSize={15}>
													پروژه ها
												</Typography>
												<Stack
													alignSelf={'center'}
													sx={{
														color:(isMenuProjectPaperOpen)?'black':'rgba(0, 0, 0, .55)',
														borderBottom: 0,
														borderLeft: '.3em solid transparent',
														borderRight: '.3em solid transparent',
														borderTop: '.3em solid'
													}}
												/>
											</Stack>
										</Stack>
									}
								</Stack>
								<Stack alignSelf={'flex-start'}>
									<IconButton onClick={() => window.location.href = 'https://matnavis.com/'}>
										<img
											src={"./logoHeader.png"}
											width={"40px"}
											height={"40px"}
											alt={'matnavis icon'}
										/>
									</IconButton>
								</Stack>
							</Stack>
						</Stack>
					</Toolbar>
				</AppBar>
			</Box>
			{renderMobileMenuFinance}
			{/*{(openProjectPaper)?*/}
			{/*	<Stack zIndex={11} position={'fixed'} mt={7} ml={75}>*/}
			{/*		<Paper elevation={3} sx={{backgroundColor:'red' , height:30 , width:70}}>*/}
			{/*			dgbvdsfsgb*/}
			{/*		</Paper>*/}
			{/*	</Stack>:*/}
			{/*	null*/}
			{/*}*/}
		</>
	)
}