import {Button, Dialog, dialogClasses, DialogContent, Divider, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import TokenShopButton from "../TokenShopButton";
import {useEffect, useState} from "react";
import {useLazyConvertTokenToRialQuery} from "../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import Box from "@mui/material/Box";
import WaysToPayment from "../WaysToPayment";
import SaveChangesPopup from "./SaveChangesPopup";

export default function BuyTokenPopup() {
	const [ConvertTokenToRialTrigger, { data: ConvertTokenToRialData }] = useLazyConvertTokenToRialQuery();
	const { token } = useSelector((state: RootState) => state.app);
	const [selectedToken , setSelectedToken] = useState<number>();
	const [paymentWay , setPaymentWay] = useState<number>();
	const [openSaveChangesPopup , setOpenSaveChangesPopup] = useState<boolean>(false);

	useEffect(() => {
		if(!token) return;

		ConvertTokenToRialTrigger({
			token: token,
			tokens: selectedToken
		})
	}, [token , selectedToken]);

	function handleSelectToken(token:number) {
		setSelectedToken(token)
	}

	function handleSetPaymentWay(value: number) {
		setPaymentWay(value)
	}

	function handleCloseSaveChangesPopup() {
		setOpenSaveChangesPopup(false)
	}

	return(
		<>
			<Stack>
				{(selectedToken)?
					<Stack alignItems={'center'}>
						<Box height={70}>
							<Typography
								fontWeight={"bold"}
								fontSize={60}
								sx={{
									color: "#E86942",
								}}
							>
								{selectedToken}
							</Typography>
						</Box>
						<Box>
							<Typography
								fontWeight={"bold"}
								fontSize={20}
								sx={{
									color: "#E86942"
								}}
							>
								توکن انتخاب کرده‌اید
							</Typography>
						</Box>
						<Box>
							<Typography
								fontSize={15}
								sx={{
									color: "#E86942"
								}}
							>
								{ConvertTokenToRialData.msg + ' هزار تومان'}
							</Typography>
						</Box>
						<Box>
							<Divider sx={{width:200 , pt:2}}/>
						</Box>
					</Stack>:
					null
				}
				<TokenShopButton handleSelectToken={handleSelectToken}/>
				<Stack pt={5} pl={3} pb={2}>
					<WaysToPayment handleSetPaymentWay={handleSetPaymentWay}/>
				</Stack>
				<Stack justifyContent={'center'} alignItems={'center'} mt={3}>
					<Button
						onClick={() => setOpenSaveChangesPopup(true)}
						variant={'contained'}
						sx={{
							width:'15%',
							backgroundColor:'#E86942',
							color:'white',
							'&:hover': {
								backgroundColor: "#8f3e04"
							},
						}}
					>
						پرداخت
					</Button>
				</Stack>
			</Stack>
			<SaveChangesPopup
				open={openSaveChangesPopup}
				onClose={handleCloseSaveChangesPopup}
				selectedToken={selectedToken}
				paymentWay={paymentWay}
			/>
		</>
	)
}