import Stack from "@mui/material/Stack";
import {Box, Switch, Typography, useMediaQuery} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import theme from "../../../../lib/theme.const";
import {useFormik} from "formik";
import IValuesNotifsSetting from "../../../../interface/IValuesNotifsSetting";
import {useDispatch} from "react-redux";
import {notifsSettingDataChanged} from "../../../../features/appSlice";

export interface IProps {
	notif: IValuesNotifsSetting | undefined;
}

export default function SwitcherButtons({notif}:IProps) {
	const formik = useFormik<IValuesNotifsSetting>({
		initialValues: {
			answers: 0,
			blocked_users: 0,
			email: 0,
			follow_req: 0,
			likes: 0,
			system: 0,
		},
		onSubmit: handleSubmit,
	});
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const [notifsForm , setNotifsForm] = useState<IValuesNotifsSetting>();
	const prevNotifsForm = useRef<IValuesNotifsSetting>();
	const dispatch = useDispatch();

	useEffect(() => {
		if(!notif) return;

		setNotifsForm({
			answers: notif.answers,
			blocked_users: notif.blocked_users,
			email: notif.email,
			follow_req: notif.follow_req,
			likes: notif.likes,
			system: notif.system,
		})
	}, [notif]);

	useEffect(() => {
		if (!notifsForm) {
			return;
		}
		if (prevNotifsForm.current === notifsForm) {
			return;
		}

		prevNotifsForm.current = notifsForm;
		formik.setValues(notifsForm);
	}, [notifsForm, formik]);

	useEffect(() => {
		dispatch(notifsSettingDataChanged(notifsForm))
	}, [notifsForm]);


	function handleSubmit(values: IValuesNotifsSetting) {
		setNotifsForm({
			answers: (!!values.answers)?1:0,
			blocked_users: (!!values.blocked_users)?1:0,
			email: (!!values.email)?1:0,
			follow_req: (!!values.follow_req)?1:0,
			likes: (!!values.likes)?1:0,
			system: (!!values.system)?1:0,
		})
	}

	return(
		<>
			<form onChange={formik.handleSubmit}>
				<Stack
					direction={(downOfsm)?"column":"row"}
					spacing={(downOfsm)?3:15}
				>
					<Stack direction={"row"} spacing={7}>
						<Box width={180}>
							<Typography mt={1}>
								اعلان پاسخ به سوال
							</Typography>
						</Box>
						<Box>
							<Switch
								name={"answers"}
								value={!!formik.values.answers}
								checked={!!formik.values.answers}
								onChange={formik.handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
								color="warning"
							/>
						</Box>
					</Stack>
					<Stack direction={"row"} spacing={7}>
						<Box width={180}>
							<Typography mt={1}>
								یوزر‌های بلاک شده
							</Typography>
						</Box>
						<Box>
							<Switch
								name={"blocked_users"}
								value={!!formik.values.blocked_users}
								checked={!!formik.values.blocked_users}
								onChange={formik.handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
								color="warning"
							/>
						</Box>
					</Stack>
				</Stack>
				<Stack direction={(downOfsm)?"column":"row"} spacing={(downOfsm)?3:15}>
					<Stack direction={"row"} spacing={7}>
						<Box width={180}>
							<Typography mt={1}>
								اعلان پست الکترونیک
							</Typography>
						</Box>
						<Box>
							<Switch
								name={"email"}
								value={!!formik.values.email}
								checked={!!formik.values.email}
								onChange={formik.handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
								color="warning"
							/>
						</Box>
					</Stack>
					<Stack direction={"row"} spacing={7}>
						<Box width={180}>
							<Typography mt={1}>
								درخواست فالو
							</Typography>
						</Box>
						<Box>
							<Switch
								name={"follow_req"}
								value={!!formik.values.follow_req}
								checked={!!formik.values.follow_req}
								onChange={formik.handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
								color="warning"
							/>
						</Box>
					</Stack>
				</Stack>
				<Stack direction={(downOfsm)?"column":"row"} spacing={(downOfsm)?3:15}>
					<Stack direction={"row"} spacing={7}>
						<Box width={180}>
							<Typography mt={1}>
								اعلان لایک کردن کامنت
							</Typography>
						</Box>
						<Box>
							<Switch
								name={"likes"}
								value={!!formik.values.likes}
								checked={!!formik.values.likes}
								onChange={formik.handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
								color="warning"
							/>
						</Box>
					</Stack>
					<Stack direction={"row"} spacing={7}>
						<Box width={180}>
							<Typography mt={1}>
								پیام‌های سیستم
							</Typography>
						</Box>
						<Box>
							<Switch
								name={"system"}
								value={!!formik.values.system}
								checked={!!formik.values.system}
								onChange={formik.handleChange}
								inputProps={{ 'aria-label': 'controlled' }}
								color="warning"
							/>
						</Box>
					</Stack>
				</Stack>
			</form>
		</>
	)
}