import {
	Button,
	Dialog,
	dialogClasses,
	DialogContent,
	DialogTitle,
	Paper,
	Typography, useMediaQuery
} from "@mui/material";
import Stack from "@mui/material/Stack";
import * as React from "react";
import {useLazyAskQuestionQuery} from "../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import Box from "@mui/material/Box";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import CheckIcon from '@mui/icons-material/Check';
import theme from "../../lib/theme.const";
import NotEnoughTokenPopup from "./NotEnoughTokenPopup";

interface IProps {
	open: boolean,
	onClose: () => void,
	selectedSubject: string | undefined,
	noteContent: string | undefined,
	planId: number | undefined,
	titleNote: string | undefined,
	checkedAvailableOrSelectedResearcher: number | undefined,
	setCheckedAvailableOrSelectedResearcher: Dispatch<SetStateAction<number | undefined>>
}

export default function SaveNotePopup({
	open ,
	onClose ,
	selectedSubject ,
	noteContent ,
	planId ,
	titleNote ,
	setCheckedAvailableOrSelectedResearcher ,
	checkedAvailableOrSelectedResearcher
}:IProps) {
	const [askQuestionTrigger, { data: askQuestionData }] = useLazyAskQuestionQuery();
	const {token , financeContext} = useSelector((state: RootState) => state.app);
	const [openBuyTokenPopup , setOpenBuyTokenPopup] = useState<boolean>(false);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if(checkedAvailableOrSelectedResearcher === 3) {
			onClose()
		}
	}, [checkedAvailableOrSelectedResearcher]);

	useEffect(() => {
		if(askQuestionData && askQuestionData.code === 701) {
			setOpenBuyTokenPopup(true)
		}
	}, [askQuestionData]);

	function handleCloseBuyTokenPopup() {
		setOpenBuyTokenPopup(false)
	}

	function sendQuestionRequest() {
		if(!token || !noteContent || !titleNote || !financeContext || !checkedAvailableOrSelectedResearcher) return;

		if(financeContext.msg.has_asked_first_question === false) {
			if(selectedSubject) {
				askQuestionTrigger({
					token: token,
					title: titleNote,
					subjects: selectedSubject,
					first_chat: noteContent,
					ask_policy: checkedAvailableOrSelectedResearcher
				}).then(() =>{
					onClose()
					setCheckedAvailableOrSelectedResearcher(3)
				})
			}
			askQuestionTrigger({
				token: token,
				title: titleNote,
				first_chat: noteContent,
				ask_policy: checkedAvailableOrSelectedResearcher
			}).then(() =>{
				onClose()
				setCheckedAvailableOrSelectedResearcher(3)
			})
		}
		else {
			if(selectedSubject) {
				askQuestionTrigger({
					token: token,
					title: titleNote,
					subjects: selectedSubject,
					plan_id: planId,
					first_chat: noteContent,
					ask_policy: checkedAvailableOrSelectedResearcher
				}).then(() =>{
					onClose()
					setCheckedAvailableOrSelectedResearcher(3)
				})
			}
			askQuestionTrigger({
				token: token,
				title: titleNote,
				plan_id: planId,
				first_chat: noteContent,
				ask_policy: checkedAvailableOrSelectedResearcher
			}).then(() =>{
				onClose()
				setCheckedAvailableOrSelectedResearcher(3)
			})
			//کم کردن توکن رو اینجا یا جای دیگه ای باید قرار بدی
		}
	}

	return(
		<>
			<Dialog
				open={open}
				onClose={onClose}
				sx={{
					[`& .${dialogClasses.paper}`]: {
						borderRadius:5,
						width:(downOfsm)?'100%':'55%',
						minHeight:610
					},
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor: "#D9D9D9",
						height:'40%'
					}}
				>
					<Stack justifyContent={'center'} alignItems={'center'}>
						<img src={"./logoHeader.png"} width={"150px"} height={"150px"}/>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Stack p={3} spacing={7}>
						<Stack direction={'row'} justifyContent={'space-between'}>
							<Box>
								<Typography>
									از اولین پژوهشگر در دسترس پرسیده شود.
								</Typography>
							</Box>
							<Stack>
								<Paper
									onClick={() => {
										if(checkedAvailableOrSelectedResearcher !== 1) {
											setCheckedAvailableOrSelectedResearcher(1)
										}
										else {
											setCheckedAvailableOrSelectedResearcher(undefined)
										}
									}}
									sx={{
										backgroundColor: '#D9D9D9',
										width: 30,
										height: 30,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									{(checkedAvailableOrSelectedResearcher === 1)?
										<CheckIcon/>:
										null
									}
								</Paper>
							</Stack>
						</Stack>
						<Stack direction={'row'} justifyContent={'space-between'}>
							<Box>
								<Typography>
									از بین پژوهشگرانی که تایید اولیه کردند انتخاب کنید.
								</Typography>
							</Box>
							<Stack>
								<Paper
									onClick={() => {
										if(checkedAvailableOrSelectedResearcher !== 2) {
											setCheckedAvailableOrSelectedResearcher(2)
										}
										else {
											setCheckedAvailableOrSelectedResearcher(undefined)
										}
									}}
									sx={{
										backgroundColor: '#D9D9D9',
										width: 30,
										height: 30,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center'
									}}
								>
									{(checkedAvailableOrSelectedResearcher === 2)?
										<CheckIcon/>:
										null
									}
								</Paper>
							</Stack>
						</Stack>
						<Stack justifyContent={'center'} alignItems={'center'} spacing={5}>
							<Box>
								<Typography fontSize={28} fontWeight={'bold'}>
									ذخیره تغییرات
								</Typography>
							</Box>
							<Box>
								<Typography>
									آیا می خواهید تغییرات ذخیره شود؟
								</Typography>
							</Box>
							<Stack direction={'row'} spacing={4}>
								<Button
									onClick={sendQuestionRequest}
									variant={'contained'}
									sx={{
										backgroundColor:'#E86942',
										color:'white',
										minWidth:90,
										'&:hover': {
											backgroundColor: "#8f3e04"
										},
									}}
								>
									بله
								</Button>
								<Button
									onClick={() => onClose()}
									variant={'contained'}
									sx={{
										backgroundColor:'#4f4f4f',
										color:'white',
										minWidth:90,
										'&:hover': {
											backgroundColor: "#9f9f9f"
										},
									}}
								>
									خیر
								</Button>
							</Stack>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
			<NotEnoughTokenPopup open={openBuyTokenPopup} onClose={handleCloseBuyTokenPopup} planId={planId}/>
		</>
	)
}