import {Box, Button, outlinedInputClasses, Paper, TextField, Typography, useMediaQuery} from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";
import theme from "../lib/theme.const";

interface IProps {
	handleSelectToken: (token:number) => void
}

export default function TokenShopButton({handleSelectToken}:IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	return(
		<>
			<Paper
				sx={{
					mt:10,
					height: 130,
					width: (downOfsm)?340:(downOfmd)?400:(downOflg)?500:600 ,
					// width: {lg: "100%" , md:"67%" , xs:"82%"},
					borderRadius: "10px",
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-around",
				}}
			>
				<Button
					onClick={() => handleSelectToken(5)}
					sx={{
						width: "100%",
						height: "100%",
						borderRadius: 0,
						borderTopLeftRadius: "10px",
						borderTopRightRadius: 0,
						borderBottomLeftRadius: "10px",
						borderBottomRightRadius: 0,
						'&:hover': {
							backgroundColor: "white",
						},
					}}
				>
					<Stack>
						<Box height={60}>
							<Typography
								fontWeight={"bold"}
								fontSize={60}
								sx={{
									color: "#E86942",
								}}
							>
								5
							</Typography>
						</Box>
						<Box>
							<Typography
								fontWeight={"bold"}
								fontSize={20}
								sx={{
									color: "#E86942"
								}}
							>
								توکن
							</Typography>
						</Box>
						<Box>
							<Typography
								fontSize={15}
								sx={{
									color: "#E86942"
								}}
							>
								10 هزار تومان
							</Typography>
						</Box>
					</Stack>
				</Button>
				<Button
					onClick={() => handleSelectToken(10)}
					sx={{
						width: "100%",
						height: "100%",
						backgroundColor: "#D9D9D9",
						borderRadius: 0,
						'&:hover': {
							backgroundColor: "#D9D9D9",
						},
					}}
				>
					<Stack>
						<Box height={60}>
							<Typography
								fontWeight={"bold"}
								fontSize={60}
								sx={{
									color: "#E86942",
								}}
							>
								10
							</Typography>
						</Box>
						<Box>
							<Typography
								fontWeight={"bold"}
								fontSize={20}
								sx={{
									color: "#E86942"
								}}
							>
								توکن
							</Typography>
						</Box>
						<Box>
							<Typography
								fontSize={15}
								sx={{
									color: "#E86942"
								}}
							>
								20 هزار تومان
							</Typography>
						</Box>
					</Stack>
				</Button>
				<Button
					onClick={() => handleSelectToken(15)}
					sx={{
						width: "100%",
						height: "100%",
						borderRadius: 0,
						'&:hover': {
							backgroundColor: "white",
						},
					}}
				>
					<Stack>
						<Box height={60}>
							<Typography
								fontWeight={"bold"}
								fontSize={60}
								sx={{
									color: "#E86942",
								}}
							>
								15
							</Typography>
						</Box>
						<Box>
							<Typography
								fontWeight={"bold"}
								fontSize={20}
								sx={{
									color: "#E86942"
								}}
							>
								توکن
							</Typography>
						</Box>
						<Box>
							<Typography
								fontSize={15}
								sx={{
									color: "#E86942"
								}}
							>
								30 هزار تومان
							</Typography>
						</Box>
					</Stack>
				</Button>
				<Stack
					justifyContent={"center"}
					alignItems={"center"}
					sx={{
						width: "100%",
						height: "100%",
						backgroundColor: "#D9D9D9",
						borderTopLeftRadius: 0,
						borderTopRightRadius: "10px",
						borderBottomLeftRadius: 0,
						borderBottomRightRadius: "10px",
						'&:hover': {
							backgroundColor: "#D9D9D9",
						},
					}}
				>
					<Stack
						justifyContent={"center"}
						alignItems={"center"}
						spacing={1}
					>
						<Box width={(downOfsm)?"70%":undefined}>
							<Typography
								fontSize={21}
								sx={{
									color: "#E86942"
								}}
							>
								مقدار دلخواه
							</Typography>
						</Box>
						<Box px={2}>
							<TextField
								onChange={(event) => handleSelectToken(Number(event.target.value))}
								color={"warning"}
								sx={{
									backgroundColor: "white",
									[`& .${outlinedInputClasses.root}`]: {
										height: 32
									}
								}}
							/>
						</Box>
					</Stack>
				</Stack>
			</Paper>
		</>
	)
}