import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {Avatar, Dialog, dialogClasses, DialogContent, Divider, Typography} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import * as React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import IGetResentChatsContent from "../../../interface/IGetResentChatsContent";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import IGetPersonalPageData from "../../../interface/IGetPersonalPageData";
import IconButton from "@mui/material/IconButton";
import BuyTokenPopup from "../../../components/BuyTokens/BuyTokenPopup";
import {useState} from "react";

interface IProps {
	chatId: number | undefined;
	setChatId: (chatId:number | undefined) => void;
	chats: IGetResentChatsContent[] | undefined;
	personalPageData: IGetPersonalPageData
}

export default function DrawerConversation({chats , chatId , setChatId}:IProps) {
	const [openBuyTokenPopup , setOpenBuyTokenPopup] = useState<boolean>(false);
	const {role} = useSelector((state: RootState) => state.app);
	const Navigate = useNavigate();
	const personalPageData = useLocation().state;

	function handleCloseBuyTokenPopup() {
		setOpenBuyTokenPopup(false)
	}

	return(
		<>
			<Stack
				className={'heightDrawer'}
				sx={{background: 'linear-gradient(to top, #ebebeb , #afafaf 60%)'}}
				direction={"row"}
				width={"100%"}
				height={"100%"}
				minHeight={700}
				pt={3}
				pb={5}
			>
				<Stack
					pt={4}
					sx={{
						width:"65%",
						height:"100%"
					}}
				>
					{chats?.map((content , index) => {
						return(
							<Stack key={index}>
								<Stack
									sx={{cursor:"pointer"}}
									onClick={() => {
										if(chatId === content.id) {
											setChatId(undefined)
										}
										else {
											setChatId(content.id)
										}
									}}
								>
									<Stack
										direction={"row"}
										width={"100%"}
										py={2}
										px={3}
										sx={{background: (content.id === chatId)?'linear-gradient(to right, #ffffff , #afafaf 20%)':undefined}}
									>
										<Stack spacing={1} width={"75%"}>
											<Box>
												<Typography>
													{(role === "researcher")?content.questioner_profile.name:content.researcher_profile.name}
												</Typography>
											</Box>
											<Box>
												<Typography>
													{content.title}
												</Typography>
											</Box>
											<Stack direction={"row"} width={"100%"}>
												<Box>
													<Typography>
														{content.subjects.join(', ')}
													</Typography>
												</Box>
											</Stack>
										</Stack>
										<Stack justifySelf={"flex-end"} width={"25%"}>
											<Avatar
												src={
													(role === "researcher")?
														`https://api.matnavis.com/files/${content.questioner_profile.photo}`
														:`https://api.matnavis.com/files/${content.researcher_profile.photo}`
												}
											/>
										</Stack>
									</Stack>
									<Stack px={2}>
										<Divider sx={{width:"85%" , backgroundColor:"black"}}/>
									</Stack>
								</Stack>
							</Stack>
						)
					})}
				</Stack>
				<Stack
					sx={{
						backgroundColor: "#E86942D4",
						width:"30%",
						height:"100%",
						// minHeight:640,
						borderRadius:9
					}}
					alignItems={"center"}
					pt={5}
					justifyContent={"space-between"}
				>
					<Box>
						<Avatar src={`https://api.matnavis.com/files/${personalPageData?.msg.profile.photo}`} sx={{width:60 , height:60}}/>
					</Box>
					<IconButton onClick={() => setOpenBuyTokenPopup(true)}>
						<Avatar src={"./payment.png"} sx={{width:50 , height:50}}/>
					</IconButton>
					<IconButton>
						<SettingsIcon
							onClick={() => Navigate("/setting-personal")}
							sx={{
								color:"white",
								width:60,
								height:60
							}}
						/>
					</IconButton>
				</Stack>
			</Stack>
			<Dialog
				open={openBuyTokenPopup}
				onClose={handleCloseBuyTokenPopup}
				sx={{
					[`& .${dialogClasses.paper}`]: {
						borderRadius:5,
						maxWidth: 650
					},
				}}
			>
				<DialogContent sx={{background: 'linear-gradient(to top, #ffffff 40%, #737373)'}}>
					<BuyTokenPopup/>
				</DialogContent>
			</Dialog>
		</>
	)
}