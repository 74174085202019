import Box from "@mui/material/Box";
import {Button, Typography, useMediaQuery} from "@mui/material";
import Stack from "@mui/material/Stack";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import React from "react";
import theme from "../../../lib/theme.const";
import {useNavigate} from "react-router-dom";
import IGetGeneralBaseData from "../../../interface/IGetGeneralBaseData";

interface IProps {
	handleClickComment: (value: any) => void;
	handleClickMore: (value: any) => void;
	generalData: IGetGeneralBaseData | undefined
}

export default function ButtonsOfChosenQuestion({handleClickComment , handleClickMore , generalData}: IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const navigate = useNavigate();

	return(
		<>
			<Stack
				direction={"row"}
				spacing={(downOfsm)?2:6}
				pl={(downOfsm)?0:13}
				mt={'20px'}
			>
				<Box>
					<Button>
						<Stack
							sx={{
								backgroundColor: "#E86942",
								borderRadius: "50%",
								mt:-1
							}}
						>
							<MoreHorizSharpIcon
								onClick={handleClickMore}
								sx={{
									color: "white",
									fontSize: 45
								}}
							/>
						</Stack>
					</Button>
				</Box>
				<Box>
					<Button
						variant={"contained"}
						onClick={() => navigate("/allComments" , {state: {data:generalData?.msg.weekly_chosen_question.title , id:0}})}
						sx={{
							px: 3,
							py: 1,
							backgroundColor: "#E86942",
							'&:hover': {
								backgroundColor: "#8f3e04"
							},
							boxShadow: "0px 10px 20px 10px #D9D9D9"
						}}
					>
						<Typography
							fontWeight={"bold"}
							fontSize={16}
						>
							تمام متن
						</Typography>
					</Button>
				</Box>
				<Box>
					<Button
						variant={"contained"}
						onClick={handleClickComment}
						sx={{
							px: 3,
							py: 1,
							backgroundColor: "#E86942",
							'&:hover': {
								backgroundColor: "#8f3e04"
							},
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							boxShadow: "0px 10px 20px 10px #D9D9D9"
						}}
					>
						<Typography
							fontWeight={"bold"}
							fontSize={16}
						>
							نوشتن نظر
						</Typography>
					</Button>
				</Box>
			</Stack>
		</>
	)
}