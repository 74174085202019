import Stack from "@mui/material/Stack";
import {Box, Button, Typography} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WorkIcon from "@mui/icons-material/Work";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import SettingsIcon from '@mui/icons-material/Settings';
import {useNavigate} from "react-router-dom";

const FloatingIconWrapper = styled(Stack)(({ theme }) => ({
	position: 'fixed',
	left: theme.spacing('6%'),
	zIndex: 1000
}));

interface IProps {
	handleItemClick: (sectionId: string) => void;
	handleClickSaveProfile: () => void;
}

export default function FloatingOrangeListResearcher({handleItemClick , handleClickSaveProfile}:IProps) {
	const [scrollTop , setScrollTop] = useState<number>();
	const [activeSection, setActiveSection] = useState<string>("sectionResearcher1");
	const navigate = useNavigate();

	useEffect(() => {
		const sections = document.querySelectorAll('[id^="sectionResearcher"]');
		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setActiveSection(entry.target.id);
				}
			});
		}, { threshold: 0.7 });

		sections.forEach(section => observer.observe(section));

		return () => {
			sections.forEach(section => observer.unobserve(section));
		};
	}, []);

	useEffect(() => {
		window.addEventListener("scroll" , () => {
			setScrollTop(window.document.scrollingElement?.scrollTop)
		})
	}, []);

	function handleSectionClick(section:string) {
		handleItemClick(section)

		if(section === "sectionResearcher5") {
			navigate('/financialPage')
		}
	}

	return(
		<>
			<FloatingIconWrapper>
				{(scrollTop && (scrollTop >= 2200))?
					null:
					<Stack pt={5} spacing={5} alignItems={'center'}>
						<Box>
							<Typography
								fontWeight={"bold"}
								fontSize={23}
								sx={{
									color: "white",
								}}
							>
								تنظیمات کاربری
							</Typography>
						</Box>
						<Stack spacing={2}>
							{[
								{ id: "sectionResearcher1", icon: <PersonIcon sx={{color:"white"}}/>, text: "مشخصات" },
								{ id: "sectionResearcher2", icon: <NotificationsIcon sx={{color:"white"}}/>, text: "اعلان‌ها" },
								{ id: "sectionResearcher3", icon: <SettingsIcon sx={{color:"white"}}/>, text: "تنظیمات پنل" },
								{ id: "sectionResearcher4", icon: <WorkIcon sx={{color:"white"}}/>, text: "کیف پول" },
								{	id: "sectionResearcher5", icon: <img src={'./payment.png'} style={{width:25 , height:25 , marginTop:2}}/>, text: "حساب" },
							].map(({ id, icon, text }) => {
								return(
									<Stack
										key={id}
										direction="row"
										spacing={2}
										sx={{ cursor: "pointer" }}
										onClick={() => handleSectionClick(id)}
									>
										<Box width={10}>
											{activeSection === id && (
												<Box sx={{ width: 10, height: 50, backgroundColor: "white" }} />
											)}
										</Box>
										<Box>{icon}</Box>
										<Stack justifyContent="center" width={150}>
											<Typography fontSize={22} sx={{ color: "white" }}>
												{text}
											</Typography>
										</Stack>
									</Stack>
								)
							})}
						</Stack>
						<Stack>
							<Button
								onClick={handleClickSaveProfile}
								variant={"contained"}
								sx={{
									backgroundColor: "#ecd0c8",
									color: "black",
									'&:hover': {
										backgroundColor: "#cb7158"
									},
								}}
							>
								ذخیره تغییرات
							</Button>
						</Stack>
					</Stack>
				}
			</FloatingIconWrapper>
		</>
	)
}