import {
	Avatar,
	Button,
	Dialog,
	dialogClasses,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	useMediaQuery
} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import theme from "../../../../lib/theme.const";
import {useEffect, useState} from "react";
import {useLazyFetchSearchQuestionQuery, useLazySetWeeklyQuestionQuery} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import Pagination from "../../../../components/Pagination";
import * as React from "react";

interface IProps {
	open: boolean,
	onClose: () => void;
}

const CustomScrollbarRoot = styled('div')({
	position: 'relative',
	width: '100%',
	height: "100%", // Set a specific height to ensure scrolling
	overflowY: 'auto',
	overflowX: 'hidden', // Disable horizontal scroll
	direction: 'rtl',
	'& > *': {
		direction: 'ltr',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: '#D9D9D9',
		borderRadius: '10px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#a4a4a4',
		borderRadius: '10px',
	},
});

export default function QuestionSelectorPopup({open , onClose}:IProps) {
	const [questionsTrigger , {data: questionsData}] = useLazyFetchSearchQuestionQuery();
	const [weeklyQuestionTrigger , {data:weeklyQuestionData}] = useLazySetWeeklyQuestionQuery();
	const [page, setPage] = useState(1);
	const ITEMS_PER_PAGE = 5;
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const {token} = useSelector((state: RootState) => state.app);

	const handleFirstPage = () => setPage(1);
	const handleLastPage = () => setPage(Math.ceil(questionsData?.msg.questions.length! / ITEMS_PER_PAGE));
	const handleNextPage = () => setPage(prev => Math.min(prev + 1, Math.ceil(questionsData?.msg.questions.length! / ITEMS_PER_PAGE)));
	const handlePrevPage = () => setPage(prev => Math.max(prev - 1, 1));

	const startIndex = (page - 1) * ITEMS_PER_PAGE;
	const endIndex = startIndex + ITEMS_PER_PAGE;
	const currentItems = questionsData?.msg.questions.slice(startIndex, endIndex) || [];

	useEffect(() => {

		questionsTrigger({
			token: ''
		})
	}, [weeklyQuestionData]);

	function handleSelectQuestion(id: number) {
		if(!token) return;

		weeklyQuestionTrigger({
			token: token,
			question_id: id
		})
	}

	return(
		<>
			<Dialog
				open={open}
				onClose={onClose}
				sx={{
					[`& .${dialogClasses.paper}`]: {
						borderRadius: 6,
						width: '100%',
						maxWidth: '600px', // Optional: Limit the max width
						top: (downOfsm)?-95:0,
						height: (downOfsm)?650:"100%",
					},
				}}
			>
				<DialogTitle sx={{ backgroundColor: "#D9D9D9" }}>
					<Stack p={2} alignItems={'center'} justifyContent={'center'}>
						<Typography sx={{color:"#989898"}}>
							انتخاب پرسش برتر هفته
						</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1, overflow: 'hidden' }}>
					<CustomScrollbarRoot>
						<Box px={3}>
							<Stack justifyContent={"center"} alignItems={"center"}>
								<Grid container spacing={2}>
									{currentItems.map((user, index) => {
										return(
											<Grid item xs={12} key={index}>
												<Stack
													direction={(downOfsm)?"column":"row"}
													justifyContent={"space-between"}
													sx={{
														backgroundColor: "white",
														p: 1.5,
														borderRadius: 4
													}}
												>
													<Stack direction={"row"} spacing={1} alignItems={'center'}>
														<Box>
															<Avatar
																src={`https://api.matnavis.com/files/${user.questioner_profile.photo}`}
																sx={{
																	width: 75,
																	height: 75
																}}
															/>
														</Box>
														<Stack>
															<Typography>
																{user.title}
															</Typography>
														</Stack>
													</Stack>
													<Stack alignSelf={"flex-end"}>
														<Stack alignSelf={"flex-end"}>
															<Button
																onClick={() => handleSelectQuestion(user.id)}
																variant={"contained"}
																sx={{
																	height: 30,
																	minWidth: 75,
																	backgroundColor: "#E86942",
																	'&:hover': {
																		backgroundColor: "#8f3e04"
																	},
																	boxShadow: "0px 5px 10px 3px #656464"
																}}
															>
																انتخاب
															</Button>
														</Stack>
													</Stack>
												</Stack>
											</Grid>
										)
									})}
								</Grid>
							</Stack>
						</Box>
					</CustomScrollbarRoot>
				</DialogContent>
				<Stack alignItems={'center'} sx={{backgroundColor: "#D9D9D9"}}>
					<Pagination
						handleFirstPage={handleFirstPage}
						handleLastPage={handleLastPage}
						handleNextPage={handleNextPage}
						handlePrevPage={handlePrevPage}
						page={page}
					/>
				</Stack>
			</Dialog>
		</>
	)
}