import Stack from "@mui/material/Stack";
import * as React from "react";
import CardPersonalProfile from "../../components/CardPersonalProfile";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import {useLazyFetchQuestionerQuery} from "../../features/apiSlice";

export default function GeneralQuestionerPage() {
	const [searchQuestionerTrigger , {data: searchQuestionerData}] = useLazyFetchQuestionerQuery();
	const location = useLocation();
	const data = location.state

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	useEffect(() => {
		if(!data) return;

		searchQuestionerTrigger({
			token:'',
			id: data
		})
	}, [data]);

	console.log(searchQuestionerData)

	const cartData = {
		name: searchQuestionerData?.msg.questioners[0].name,
		username: searchQuestionerData?.msg.questioners[0].username,
		createDate: searchQuestionerData?.msg.questioners[0].created_at,
		education: searchQuestionerData?.msg.questioners[0].education,
		bio: searchQuestionerData?.msg.questioners[0].bio,
		picture: searchQuestionerData?.msg.questioners[0].photo
	}

	return(
		<>
			<Stack py={10} px={10}  sx={{backgroundColor:'white'}}>
				<CardPersonalProfile data={cartData}/>
			</Stack>
		</>
	)
}