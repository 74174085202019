import Stack from "@mui/material/Stack";
import {Button, Typography, useMediaQuery} from "@mui/material";
import ImageStepper from "../../../components/ImageStepper";
import React, {useState} from "react";
import theme from "../../../lib/theme.const";
import AllQuestionsPopup from "./AllQuestionsPopup";

interface IProps {
	data: {
		"questioner_profile": {
			"id": number,
			"username": string,
			"name": string,
			"photo": string
		} | undefined,
		"question": string | undefined
	}[]
}

export default function HomeImageStepper({ data }:IProps) {
	const [allQuestions , setAllQuestions] = useState(false);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	const handleClose = () => {
		if(allQuestions) {
			setAllQuestions(false)
		}
	};

	// const cards = data?.concat(new Array(5).fill({
	// 	"questioner_profile": {
	// 		"id": 1,
	// 		"username": "1",
	// 		"name": "1",
	// 		"photo": "1"
	// 	},
	// 	"question": "1"
	// }))

	return(
		<>
			<Stack>
				<Stack
					direction={"row"}
					width={"100%"}
					justifyContent={"space-between"}
					// px={(downOfsm)?5:20}
					px={(downOfmd)?4:14}
					mt={"40px"}
				>
					<Stack alignSelf={"flex-start"}>
						<Typography
							fontWeight={"bold"}
							fontSize={"24px"}
						>
							آخرین سوالات
						</Typography>
					</Stack>
					<Stack alignSelf={"flex-end"}>
						{(data)?
							<Button
								onClick={() => setAllQuestions(true)}
							>
								<Typography
									sx={{
										color: "black"
									}}
								>
									{"مشاهده همه"+ " " +`(${data?.length})`}
								</Typography>
							</Button>:
							null
						}
					</Stack>
				</Stack>
				<ImageStepper data={data} allQuestions={allQuestions} handleClose={handleClose}/>
			</Stack>
			<AllQuestionsPopup open={allQuestions} onClose={handleClose} data={data} title={"آخرین سوالات"}/>
		</>
	)
}