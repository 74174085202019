import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {Card, CardContent, Typography, useMediaQuery} from "@mui/material";
import IGetResearcherPageData from "../../../interface/IGetResearcherPageData";
import theme from "../../../lib/theme.const";
import * as React from "react";

interface IProps {
	researcherData: IGetResearcherPageData | undefined
}

export default function Fourth({researcherData}:IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	return(
		<>
			<Stack
				justifyContent={"space-around"}
				alignItems={"center"}
				spacing={5}
			>
				<Stack>
					<Card
						sx={{
							backgroundColor: "#319DD3",
							borderRadius: 4,
							height:'100%',
							width:'100%'
						}}
					>
						<CardContent>
							<Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
								<Box pl={2}>
									<Typography sx={{color: "#ffffff", cursor: "pointer"}}>
										آمارهای من
									</Typography>
								</Box>
								<Stack
									spacing={2}
									alignItems={"center"}
									justifyContent={'center'}
									direction={'row'}
								>
									<Stack width={'50%'} minWidth={100} justifyContent={'center'} alignItems={'center'}>
										<Stack
											height={60}
											width={60}
											alignItems={'center'}
											justifyContent={'center'}
											sx={{
												backgroundColor:'white',
												borderRadius:4
											}}
										>
											<Typography sx={{color:'#0068A6'}} fontWeight={'bolder'} fontSize={25}>
												{researcherData?.msg.answered_questions_count}
											</Typography>
										</Stack>
										<Box>
											<Typography sx={{color: "#ffffff"}}>
												<Typography fontSize={11}>
													سوال پاسخ داده شده
												</Typography>
											</Typography>
										</Box>
									</Stack>
									<Stack width={'50%'} minWidth={100} justifyContent={'center'} alignItems={'center'}>
										<Stack
											alignItems={'center'}
											justifyContent={'center'}
											sx={{
												backgroundColor:'white',
												borderRadius:4
											}}
										>
											<img src={'./statics.png'} height={60} width={60}/>
										</Stack>
										<Box>
											<Typography
												sx={{color: "#ffffff"}}
											>
												<Typography fontSize={11}>
													نمودار فعالیت
												</Typography>
											</Typography>
										</Box>
									</Stack>
									<Stack width={'50%'} minWidth={100} justifyContent={'center'} alignItems={'center'}>
										<Stack
											height={60}
											width={60}
											alignItems={'center'}
											sx={{
												backgroundColor:'white',
												borderRadius:4
											}}
										>
											<Box
												sx={{
													backgroundColor:'#E71D30' ,
													height:20 ,
													width:'100%',
													borderTopLeftRadius:'15px',
													borderTopRightRadius:'15px'
												}}
											/>
											<Typography sx={{color:'#E71D30'}} fontWeight={'bolder'} fontSize={25}>
												15
											</Typography>
										</Stack>
										<Box>
											<Typography
												sx={{color: "#ffffff"}}
											>
												<Typography fontSize={11}>
													روزهای حضور
												</Typography>
											</Typography>
										</Box>
									</Stack>
									<Stack width={'50%'} minWidth={100} justifyContent={'center'} alignItems={'center'}>
										<Stack
											alignItems={'center'}
											justifyContent={'center'}
											sx={{
												backgroundColor:'white',
												borderRadius:4
											}}
										>
											<img src={'./star.png'} height={60} width={60}/>
										</Stack>
										<Box>
											<Typography
												sx={{color: "#ffffff"}}
											>
												<Typography fontSize={11}>
													امتیاز های من
												</Typography>
											</Typography>
										</Box>
									</Stack>
								</Stack>
							</Stack>
						</CardContent>
					</Card>
				</Stack>
				<Stack
					sx={{
						border: 1,
						borderRadius: 3,
						p:3,
						width:(downOfsm)?350:430
					}}
				>
					<Box>
						<Typography fontWeight={"bold"}>
							منابع معرفی شده توسط پژوهشگر
						</Typography>
					</Box>
					<Stack>
						{researcherData?.msg.sources.map((content , index) => {
							return(
								<Stack
									key={index}
									direction={"row"}
									justifyContent={"space-between"}
									sx={{
										backgroundColor: "#D9D9D9",
										borderRadius: 2,
										mt:2,
										p:1
									}}
								>
									<Box>
										<Typography>
											{content.name}
										</Typography>
									</Box>
									<Stack direction={"row"}>
										<Box
											sx={{
												width: 0,
												height: 0,
												borderTop: '15px solid transparent',
												borderBottom: '15px solid transparent',
												borderRight: '8px solid #ffffff', // تغییر رنگ مثلث
												// margin: '20px auto' // مرکز کردن مثلث
											}}
										/>
										<Box
											sx={{
												backgroundColor: "white",
												width:25,
												height:30
											}}
										/>
									</Stack>
								</Stack>
							)
						})}
					</Stack>
				</Stack>
			</Stack>
		</>
	)
}