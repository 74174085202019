import Stack from "@mui/material/Stack";
import {Avatar, Box, Button, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import theme from "../../../../lib/theme.const";
import {useState} from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import IGetPersonalPageData from "../../../../interface/IGetPersonalPageData";
import NoLikedAndMarkedQuestion from "./NoLikedAndMarkedQuestion";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";

interface IProps {
	personalPageData: IGetPersonalPageData | undefined
}

export default function LikedAndMarkedQuestion({personalPageData}:IProps) {
	const [noteBoxValue , setNoteBoxValue] = useState<number>(1);
	const [startIndex, setStartIndex] = useState(0);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const {role} = useSelector((state: RootState) => state.app);

	const cards = (noteBoxValue === 1)?
		personalPageData?.msg.liked_questions :
		personalPageData?.msg.marked_questions

	const imagesPerPage = (downOfsm)?1:((cards) && cards.length <= 3)? cards?.length:3

	const handleNext = () => {
		if(startIndex + imagesPerPage < cards?.length!) {
			setStartIndex(startIndex + imagesPerPage);
		}
	};

	const handleBack = () => {
		if(startIndex - imagesPerPage >= 0) {
			setStartIndex(startIndex - imagesPerPage)
		}
	};

  return(
		<>
			<Stack px={(downOfsm)?4:10} py={'40px'}>
				<Stack
					// width={(downOfsm)?370:(downOfmd)?600:(downOflg)?470:850}
					width={"100%"}
					direction={"row"}
				>
					<Stack
						onClick={() => {
							setNoteBoxValue(1)
							setStartIndex(0)
						}}
						width={"50%"}
						height={(noteBoxValue === 1)?60:30}
						sx={{
							backgroundColor: (noteBoxValue === 1)?"#CCCCCC":"#808080",
							borderTopRightRadius: (noteBoxValue === 1)?15:undefined,
							borderTopLeftRadius: 15
						}}
						alignSelf={(noteBoxValue === 1)?undefined:"flex-end"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Typography sx={{color: (noteBoxValue === 1)?"#EE8D6F":"white"}}>
							امتیازهای من
						</Typography>
					</Stack>
					<Stack
						onClick={() => {
							setNoteBoxValue(2)
							setStartIndex(0)
						}}
						width={"50%"}
						height={(noteBoxValue === 2)?60:30}
						sx={{
							backgroundColor: (noteBoxValue === 2)?"#CCCCCC":"#808080",
							borderTopRightRadius: 15,
							borderTopLeftRadius: (noteBoxValue === 2)?15:undefined
						}}
						alignSelf={(noteBoxValue === 2)?undefined:"flex-end"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Typography sx={{color: (noteBoxValue === 2)?"#EE8D6F":"white"}}>
							علامت‌های من
						</Typography>
					</Stack>
				</Stack>
				<Stack>
					<Stack
						direction={"row"}
						sx={{
							backgroundColor: "#CCCCCC",
							px:(!cards || cards.length === 0)?3:5,
							py:'80px',
							borderBottomLeftRadius: 15,
							borderBottomRightRadius: 15
						}}
						justifyContent={"center"}
						alignItems={"center"}
					>
						{((!cards || cards.length === 0) && downOfsm)?
							null:
							<Button
								onClick={handleBack}
								disabled={startIndex === 0}
								sx={{
									width: "5%"
								}}
							>
								<ArrowForwardIosIcon
									sx={{
										stroke: "#EE8D6F",
										strokeWidth: 2
									}}
								/>
							</Button>
						}
						<Stack
							direction={"row"}
							justifyContent={"center"}
							alignItems={"center"}
							width={'100%'}
							spacing={3}
						>
							{(!cards || cards.length === 0)?
								<NoLikedAndMarkedQuestion noteBoxValue={noteBoxValue}/>:
								cards?.slice(startIndex, startIndex + imagesPerPage).map((content, index) => {
									return(
										<Stack key={index}>
											<Stack
												direction={"row"}
												// px={3}
											>
												<Stack
													sx={{
														backgroundColor: "#E86942",
														height: "100%",
														width:250,
														borderBottomRightRadius: 14,
														borderBottomLeftRadius: 14
													}}
													justifyContent={"center"}
													alignItems={"center"}
												>
													<Box
														width={"100%"}
														sx={{
															px:1.5,
															mt:-3,
															zIndex:10,
															backgroundColor: "#CCCCCC"
														}}
													>
														<Box
															sx={{
																backgroundColor: "white",
																height: 40,
																borderTopRightRadius: 14,
																borderTopLeftRadius: 14
															}}
														/>
													</Box>
													<Stack
														justifyContent={"center"}
														alignItems={"center"}
													>
														<Box
															sx={{
																backgroundColor: "white",
																borderRadius: "50%",
																p:1.5,
																mt:-4,
																zIndex:12
															}}
														>
															{(role === 'researcher')?
																<Avatar
																	src={`https://api.matnavis.com/files/${content.questioner_profile.photo}`}
																	sx={{
																		width: 45,
																		height: 45
																	}}
																/>:
																<Avatar
																	src={`https://api.matnavis.com/files/${content.researcher_profile.photo}`}
																	sx={{
																		width: 45,
																		height: 45
																	}}
																/>
															}
														</Box>
													</Stack>
													<Box px={4} pt={1} pb={3} sx={{minHeight: 110}}>
														<Typography
															sx={{
																color: "white"
															}}
														>
															{content.title}
														</Typography>
													</Box>
												</Stack>
											</Stack>
										</Stack>
									)
								})
							}
						</Stack>
						{((!cards || cards.length === 0) && downOfsm)?
							null:
							<Button
								onClick={handleNext}
								disabled={startIndex + imagesPerPage >= cards?.length!}
								sx={{
									width: "5%"
								}}
							>
								<ArrowBackIosNewIcon
									sx={{
										stroke: "#EE8D6F",
										strokeWidth: 2
									}}
								/>
							</Button>
						}
					</Stack>
				</Stack>
			</Stack>
		</>
  )
}