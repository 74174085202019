import Stack from "@mui/material/Stack";
import {Divider, Paper, Typography, useMediaQuery} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Pagination from "../../../../components/Pagination";
import Box from "@mui/material/Box";
import * as React from "react";
import {Dispatch, SetStateAction, useState} from "react";
import IGetUserCashbackRequests from "../../../../interface/IGetUserCashbackRequests";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import IGetUserTransactions from "../../../../interface/IGetUserTransactions";
import theme from "../../../../lib/theme.const";

interface IProps {
	setPage: Dispatch<SetStateAction<number>>,
	ITEMS_PER_PAGE: number,
	financeData?: IGetUserCashbackRequests | undefined,
	TransactionsData?: IGetUserTransactions | undefined,
	page: number
}

export default function TableFooter({
	setPage ,
	ITEMS_PER_PAGE ,
	financeData ,
	page,
	TransactionsData
}:IProps) {
	const {role} = useSelector((state: RootState) => state.app);
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	const data = (role === 'researcher')?financeData?.msg.requests:TransactionsData?.msg.transactions

	const handleFirstPage = () => setPage(1);
	const handleLastPage = () => setPage(Math.ceil(data?.length! / ITEMS_PER_PAGE));
	const handleNextPage = () => setPage(prev => Math.min(prev + 1, Math.ceil(data?.length! / ITEMS_PER_PAGE)));
	const handlePrevPage = () => setPage(prev => Math.max(prev - 1, 1));

	return(
		<Stack width={'100%'} pb={5} pt={2}>
			<Stack
				justifyContent={"center"}
				alignItems={"center"}
				// width={'35%'}
			>
				<Pagination
					handleFirstPage={handleFirstPage}
					handleLastPage={handleLastPage}
					handleNextPage={handleNextPage}
					handlePrevPage={handlePrevPage}
					page={page}
				/>
			</Stack>
		</Stack>
	)
}