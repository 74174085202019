import {
	Avatar, Button,
	Dialog,
	DialogContent,
	DialogTitle, outlinedInputClasses,
	paperClasses,
	Rating,
	TextField,
	Typography, useMediaQuery
} from "@mui/material";
import React, {ChangeEvent, SyntheticEvent, useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {useLazyAddCommentQuery, useLazyAddSubCommentQuery} from "../features/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../app/store";
import theme from "../lib/theme.const";
import {refreshGeneralDataChanged} from "../features/appSlice";

export interface SimpleDialogProps {
	open: boolean;
	onClose: (value: string) => void;
	questionId: number | undefined;
	subCommentId: number | undefined;
}

export default function SetCommentPopup({open , onClose , questionId , subCommentId}: SimpleDialogProps) {
	const [addCommentTrigger, { data: addCommentData }] = useLazyAddCommentQuery();
	const [addSubCommentTrigger, { data: addSubCommentData }] = useLazyAddSubCommentQuery();
	const [focused, setFocused] = React.useState(false);
	const [text, setText] = useState<string>('');
	const { token } = useSelector((state: RootState) => state.app);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const [rate , setRate] = useState<number | null>(null);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(refreshGeneralDataChanged(addCommentData))
	}, [addCommentData]);

	useEffect(() => {
		dispatch(refreshGeneralDataChanged(addSubCommentData))
	}, [addSubCommentData]);

	const handleFocusChange = (event: React.FocusEvent<HTMLInputElement>) => {
		if(event.type === "focus") {
			setFocused(true)
		}
		else {
			setFocused(false)
		}
	};

	const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
		setText(event.target.value);
	};

	const insertEmoji = (emoji: string) => {
		setText((prevText) => prevText + emoji);
	};

	function handleClickAddComment() {
		if(!token || !questionId || text.length === 0) return;

		setText('')
		onClose('')

		if(subCommentId) {
			addSubCommentTrigger({
				token: token,
				question_id: questionId,
				comment_id: subCommentId,
				sub_comment: text
			})
		}
		else {
			addCommentTrigger({
				token: token,
				question_id: questionId,
				comment: text,
				stars: String(rate)
			})
		}
	}

	function handleStarChange(event: SyntheticEvent<Element, Event>, value: number | null) {
		setRate(value)
	}

	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					[`& .${paperClasses.root}`]: {
						height: "100%",
						width: 600,
						borderRadius:3
					}
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor: "#D9D9D9",
						display: "flex",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					<Typography fontSize={20}>
						ثبت نظر
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Stack
						alignItems={"center"}
					>
						<Box mt={5}>
							<Typography>
								امتیاز خود را یا دیگران به اشتراک بگذارید
							</Typography>
						</Box>
						<Box mt={3}>
							<Rating
								size={"large"}
								defaultValue={0}
								onChange={handleStarChange}
								max={5}
								sx={{
									"& label": {
										color: "#E86942"
									},
								}}
							/>
						</Box>
						<Stack
							mt={5}
							direction={"row"}
							spacing={1}
							ml={(downOfsm)?3:7}
							justifyContent={"center"}
						>
							<Stack spacing={1} width={(downOfsm)?230:400}>
								<Stack>
									<Stack
										direction={"row"}
										sx={{
											justifyContent: "center",
											border: 0.5,
											borderColor: "black",
											backgroundColor: "white",
											borderRadius: 8,
											width: (downOfsm)?"65%":"40%",
											zIndex: 10,
											ml: 2
										}}
									>
										<Stack onClick={() => insertEmoji('😡')} sx={{mt:0.5}}>😡</Stack>
										<Stack onClick={() => insertEmoji('😢')} sx={{mt:0.5}}>😢</Stack>
										<Stack onClick={() => insertEmoji('😲')} sx={{mt:0.5}}>😲</Stack>
										<Stack onClick={() => insertEmoji('😆')} sx={{mt:0.5}}>😆</Stack>
										<Stack onClick={() => insertEmoji('💖')} sx={{mt:0.5}}>💖</Stack>
										<Stack onClick={() => insertEmoji('👍')} sx={{mt:0.5}}>👍</Stack>
									</Stack>
									<Stack
										alignItems={"center"}
									>
										<TextField
											value={text}
											onChange={handleInputChange}
											InputLabelProps={{ shrink: false }}
											label={
												(focused)||(text)?null:
													<Box>
														<Typography>
															نوشتن نظر
														</Typography>
													</Box>
											}
											multiline
											rows={(downOfsm)?8:4}
											color={"warning"}
											sx={{
												width:"100%",
												mt: -2,
												[`& .${outlinedInputClasses.input}`]: {
													borderRadius: "8px",
													// height: 70
												},
											}}
											onFocus={handleFocusChange}
											onBlur={handleFocusChange}
										/>
									</Stack>
								</Stack>
								<Box>
									<Button
										onClick={handleClickAddComment}
										variant={"contained"}
										sx={{
											width: "100%",
											borderRadius: "4px",
											backgroundColor: "#E86942",
											'&:hover': {
												backgroundColor: "#8f3e04"
											},
										}}
									>
										ارسال
									</Button>
								</Box>
							</Stack>
							<Avatar
								src={"./person2.png"}
								sx={{
									width:"50px",
									height:"50px",
									transition: 'width 0.3s ease',
								}}
							/>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	)
}