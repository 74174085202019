import {Divider, Paper, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {PieChart} from "@mui/x-charts/PieChart";
import {styled} from "@mui/material/styles";
import {useDrawingArea} from "@mui/x-charts/hooks";
import IGetAdminPageDataResult from "../../../interface/IGetAdminPageDataResult";

interface IProps {
	adminData: IGetAdminPageDataResult | undefined
}

const StyledText = styled('text')(({ theme }) => ({
	fill: theme.palette.text.primary,
	textAnchor: 'middle',
	dominantBaseline: 'central',
	fontSize: 20,
}));

function PieCenterLabel({ children }: { children: React.ReactNode }) {
	const { width, height, left, top } = useDrawingArea();
	return (
		<StyledText x={left + width / 2} y={top + height / 2}>
			{children}
		</StyledText>
	);
}

export default function RadicalLinearChart({adminData}:IProps) {

	const size = {
		width: 167,
		height: 130,
	};

	const DayA = 4000 //total
	const DayB = (adminData?.msg.transactions_count.D)??4000  //main value

	const DayData = [
		{ value: DayA-DayB, label: 'A', color:'#f9cfc3'},
		{ value: DayB, label: 'B', color:'#E86942'},
	]

	const monthA = 4000 //total
	const monthB = (adminData?.msg.transactions_count.M)??4000  //main value

	const monthData = [
		{ value: monthA-monthB, label: 'A', color:'#f9cfc3'},
		{ value: monthB, label: 'B', color:'#E86942'},
	];

	const yearA = 4000 //total
	const yearB = (adminData?.msg.transactions_count.Y)??4000  //main value

	const yearData = [
		{ value: yearA-yearB, label: 'A', color:'#f9cfc3'},
		{ value: yearB, label: 'B', color:'#E86942'},
	];

	const totalA = 4000 //total
	const totalB = (adminData?.msg.balance)??4000  //main value

	const totalData = [
		{ value: totalA-totalB, label: 'A', color:'#f9cfc3'},
		{ value: totalB, label: 'B', color:'#E86942'},
	];


	return(
		<>
			<Stack justifyContent={'center'}>
				<Paper
					sx={{
						width:'100%',
						minHeight: 400,
						backgroundColor: "#D9D9D9",
						borderRadius:4,
						overflow:'hidden',
					}}
				>
					<Stack py={1} spacing={1} justifyContent={'center'} alignItems={'center'} width={'100%'}>
						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}
							width={'100%'}
							spacing={'10%'}
							px={3}
						>
							<Stack
								sx={{
									borderRadius:8,
									maxWidth:90 ,
									maxHeight:90 ,
									backgroundColor:'white'
								}}
							>
								<PieChart
									series={[
										{
											data: DayData,
											innerRadius: 40 ,
											outerRadius:50 ,
											endAngle:210 ,
											startAngle:510,
										}
									]}
									sx={{
										// transform: "rotate(-150deg)",
										marginLeft:-1.5,
										// marginTop:3
									}}
									{...size}
									slotProps={{
										legend: { hidden: true },
									}}
								>
									<PieCenterLabel>{adminData?.msg.transactions_count.D}</PieCenterLabel>
								</PieChart>
							</Stack>
							<Stack>
								<Typography>
									تراکنش ماهانه
								</Typography>
							</Stack>
						</Stack>
						<Divider sx={{width:'90%'}}/>
						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}
							width={'100%'}
							spacing={'10%'}
							px={3}
						>
							<Stack
								sx={{
									borderRadius:8,
									maxWidth:90 ,
									maxHeight:90 ,
									backgroundColor:'white'
								}}
							>
								<PieChart
									series={[
										{
											data: monthData,
											innerRadius: 40 ,
											outerRadius:50 ,
											endAngle:210 ,
											startAngle:510,
										}
									]}
									sx={{
										// transform: "rotate(-150deg)",
										marginLeft:-1.5,
										// marginTop:3
									}}
									{...size}
									slotProps={{
										legend: { hidden: true },
									}}
								>
									<PieCenterLabel>{adminData?.msg.transactions_count.M}</PieCenterLabel>
								</PieChart>
							</Stack>
							<Stack>
								<Typography>
									تراکنش روزانه
								</Typography>
							</Stack>
						</Stack>
						<Divider sx={{width:'90%'}}/>
						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}
							width={'100%'}
							spacing={'10%'}
							px={3}
						>
							<Stack
								sx={{
									borderRadius:8,
									maxWidth:90 ,
									maxHeight:90 ,
									backgroundColor:'white'
								}}
							>
								<PieChart
									series={[
										{
											data: yearData,
											innerRadius: 40 ,
											outerRadius:50 ,
											endAngle:210 ,
											startAngle:510,
										}
									]}
									sx={{
										// transform: "rotate(-150deg)",
										marginLeft:-1.5,
										// marginTop:3
									}}
									{...size}
									slotProps={{
										legend: { hidden: true },
									}}
								>
									<PieCenterLabel>{adminData?.msg.transactions_count.Y}</PieCenterLabel>
								</PieChart>
							</Stack>
							<Stack>
								<Typography>
									تراکنش سالانه
								</Typography>
							</Stack>
						</Stack>
						<Divider sx={{width:'90%'}}/>
						<Stack
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}
							width={'100%'}
							spacing={'10%'}
							px={3}
						>
							<Stack
								sx={{
									borderRadius:8,
									maxWidth:90 ,
									maxHeight:90 ,
									backgroundColor:'white'
								}}
							>
								<PieChart
									series={[
										{
											data: totalData,
											innerRadius: 40 ,
											outerRadius:50 ,
											endAngle:210 ,
											startAngle:510,
										}
									]}
									sx={{
										// transform: "rotate(-150deg)",
										marginLeft:-1.5,
										// marginTop:3
									}}
									{...size}
									slotProps={{
										legend: { hidden: true },
									}}
								>
									<PieCenterLabel>{adminData?.msg.balance}</PieCenterLabel>
								</PieChart>
							</Stack>
							<Stack>
								<Typography>
									موجودی
								</Typography>
							</Stack>
						</Stack>
					</Stack>
				</Paper>
			</Stack>
		</>
	)
}