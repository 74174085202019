import {Button} from "@mui/material";
import {useLazyFetchSearchQuestionQuery} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import AllQuestionsPopup from "../../lastestQuestions/AllQuestionsPopup";
import {useState} from "react";
import IAllQuestionData from "../../../../interface/IAllQuestionData";


export default function AllQuestionOthers({subject}:{subject:string}) {
	const [allQuestions , setAllQuestions] = useState(false);
	const [searchTrigger, { data: searchData }] = useLazyFetchSearchQuestionQuery();
	const { token } = useSelector((state: RootState) => state.app);

	const AllQuestionData: IAllQuestionData[] = searchData?.msg.questions?.map(
		(
			question: {
				questioner_profile: {
					id: number;
					username: string;
					name: string;
					photo: string;
				};
				title: string
			}
		) => ({
		questioner_profile: question.questioner_profile,
		question: question.title
	})) || [];

	async function handleClick() {

		await searchTrigger({
			token: '',
			subjects: subject
		})
		setAllQuestions(true)
	}

	const handleClose = () => {
		setAllQuestions(false)
	};

	return(
		<>
			<Button
				onClick={handleClick}
				variant={"outlined"}
				color={"inherit"}
				sx={{
					borderColor: "#949494",
					borderRadius: "15px",
					width:"100%",
					height:50
				}}
			>
				مشاهده سوالات دیگران در این حوزه
			</Button>
			<AllQuestionsPopup open={allQuestions} onClose={handleClose} data={AllQuestionData} title={subject}/>
		</>
	)
}