import {Button, Dialog, dialogClasses, DialogContent, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseTwoToneIcon from "@mui/icons-material/CloseTwoTone";
import * as React from "react";
import {useLazyRemoveSourceQuery} from "../../../../../../features/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../../../../app/store";
import {useEffect} from "react";
import {refreshSettingDataChanged} from "../../../../../../features/appSlice";

interface IProps {
	open: boolean;
	handleClose: () => void;
	idForRemove: number | undefined;
}

export default function RemoveSourcePopup({open , handleClose , idForRemove}:IProps) {
	const [removeSourceTrigger, { data: removeSourcePageData }] = useLazyRemoveSourceQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const dispatch = useDispatch();

	useEffect(() => {
		if(!removeSourcePageData) return;
		dispatch(refreshSettingDataChanged(removeSourcePageData))
	}, [removeSourcePageData]);

	function handleClickRemoveSource() {
		if(!token || !idForRemove) return;

		removeSourceTrigger({
			token: token,
			source_id: idForRemove
		})
		handleClose()
	}

	return(
		<>
			<Dialog
				onClose={handleClose}
				open={open}
				sx={{
					[`& .${dialogClasses.paper}`]:{
						borderRadius:4,
						backgroundColor:"#D9D9D9",
						left:185
					}
				}}
			>
				<DialogContent
					sx={{
						width:300,
						height:120,
						p:0
					}}
				>
					<Stack>
						<Stack alignSelf={"flex-end"}>
							<IconButton onClick={handleClose}>
								<CloseTwoToneIcon/>
							</IconButton>
						</Stack>
						<Stack alignItems={"center"} spacing={1}>
							<Stack>
								<Typography>
									آیا از حذف این منبع مطمئن هستید؟
								</Typography>
							</Stack>
							<Stack direction={"row"} spacing={5}>
								<Button
									onClick={handleClickRemoveSource}
									color={"warning"}
									variant={"contained"}
									sx={{minWidth:80}}
								>
									بله
								</Button>
								<Button
									onClick={handleClose}
									variant={"contained"}
									sx={{
										minWidth:80,
										backgroundColor: "#808080",
										'&:hover':{
											backgroundColor: "#4b4b4b"
										}
									}}
								>
									خیر
								</Button>
							</Stack>
						</Stack>
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	)
}