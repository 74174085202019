import Stack from "@mui/material/Stack";
import {Button, Paper, Typography, useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import RadicalLinearChart from "./RadicalLinearChart";
import theme from "../../../lib/theme.const";
import {useState} from "react";
import ApprovalQuestionPopup from "./popups/ApprovalQuestionPopup";
import QuestionSelectorPopup from "./popups/QuestionSelectorPopup";
import IGetAdminPageDataResult from "../../../interface/IGetAdminPageDataResult";

interface IProps {
	adminData: IGetAdminPageDataResult | undefined
}

export default function ForthPart({adminData}:IProps) {
	const [openQuestionPopup , setOpenQuestionPopup] = useState<boolean>(false);
	const [openQuestionSelectorPopup , setOpenQuestionSelectorPopup] = useState<boolean>(false);
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	function handleCloseQuestionPopup() {
		setOpenQuestionPopup(false)
	}

	function handleCloseQuestionSelectorPopup() {
		setOpenQuestionSelectorPopup(false)
	}

	return(
		<>
			<Stack width={(downOfmd)?"100%":"22%"} spacing={2} direction={(downOfmd)?"row":"column"}>
				<Stack>
					<Paper
						onClick={() => setOpenQuestionPopup(true)}
						sx={{
							height:150,
							backgroundColor: "#D9D9D9",
							borderRadius:4,
							p:2,
							cursor:"pointer"
						}}
					>
						<Stack spacing={5}>
							<Stack direction={"row"} justifyContent={'space-between'}>
								<Box>
									<Typography fontSize={13} fontWeight={"bold"}>
										تایید سوالات توسط ادمین
									</Typography>
								</Box>
								<Box>
									<img
										alt={'approvalQuestionIcon'}
										src={'./tickColorFull.png'}
										width={28}
										height={28}
									/>
								</Box>
							</Stack>
							<Stack direction={"row"} alignItems={"center"} spacing={1}>
								<Box>
									<Typography fontWeight={'bold'} fontSize={35}>
										{adminData?.msg.pending_questions_count}
									</Typography>
								</Box>
								<Box>
									<Typography fontSize={11}>
										سوال نیاز به تایید دارد
									</Typography>
								</Box>
							</Stack>
						</Stack>
					</Paper>
				</Stack>
				{(downOfmd)?null:
					<>
						<Stack>
							<Paper
								sx={{
									height:90,
									backgroundColor: "#D9D9D9",
									borderRadius:4,
									p:2,
									cursor:"pointer"
								}}
							>
								<Stack spacing={2}>
									<Stack direction={"row"}>
										<Box>
											<Typography fontSize={13} fontWeight={"bold"}>
												سوال برگزیده هفته
											</Typography>
										</Box>
									</Stack>
									<Stack direction={"row"} alignItems={"center"} alignSelf={'flex-end'}>
										<Button
											onClick={() => setOpenQuestionSelectorPopup(true)}
											variant={'contained'}
											sx={{
												minWidth:50,
												height: 25,
												backgroundColor: "#E86942",
												'&:hover': {
													backgroundColor: "#8f3e04"
												},
												boxShadow: "0px 5px 10px 3px #656464"
											}}
										>
											انتخاب
										</Button>
									</Stack>
								</Stack>
							</Paper>
						</Stack>
						{(downOfmd)?null:<RadicalLinearChart adminData={adminData}/>}
					</>
				}
			</Stack>
			<ApprovalQuestionPopup
				open={openQuestionPopup}
				onClose={handleCloseQuestionPopup}
				// adminData={adminData?.msg!}
			/>
			<QuestionSelectorPopup open={openQuestionSelectorPopup} onClose={handleCloseQuestionSelectorPopup}/>
		</>
	)
}