import Stack from "@mui/material/Stack";
import {Box, Card, CardContent, Typography, useMediaQuery} from "@mui/material";
import * as React from "react";
import theme from "../lib/theme.const";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";
import {useNavigate} from "react-router-dom";
import CardPersonalProfile from "./CardPersonalProfile";
import IGetPersonalPageData from "../interface/IGetPersonalPageData";

interface IProps {
	data: {
		name: string | undefined,
		username: string | undefined,
		created_at: number | undefined,
		education: string | undefined,
		questions: number | undefined,
		bio: string | undefined,
		picture: string | undefined
	},
	handleNotificationClick?: () => void;
	personalData: IGetPersonalPageData | undefined
}

export default function ProfileCards({data , handleNotificationClick , personalData}: IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const { role } = useSelector((state: RootState) => state.app);
	const navigate = useNavigate();

	return(
		<Stack
			direction={(downOfmd)?"column":"row"}
			justifyContent={"center"}
			alignItems={"center"}
			spacing={4}
			py={'40px'}
			sx={{backgroundColor:'#F9F9FF'}}
			// px={(downOfsm)?3:(downOflg)?4:15}
		>
			<Stack>
				<CardPersonalProfile data={data} handleNotificationClick={handleNotificationClick} personalData={personalData}/>
			</Stack>
			<Stack>
				<Card
					sx={{
						backgroundColor: "#319DD3",
						borderRadius: 4,
						// height:'100%'
						height:(downOfmd)?270:260,
						// width:(downOfmd)?'100%':'55%'
						width:260
					}}
				>
					<CardContent>
						<Stack spacing={2.5} justifyContent={'center'} alignItems={'center'}>
							<Box pl={2}>
								<Typography
									sx={{
										color: "#ffffff",
										cursor: "pointer"
									}}
									onClick={() => navigate("/report-personal-data")}
								>
									آمارهای من
								</Typography>
							</Box>
							<Stack
								spacing={2}
								alignItems={"center"}
								justifyContent={'center'}
							>
								<Stack
									direction={"row"}
									spacing={2}
									width={'100%'}
								>
									<Stack width={'50%'} minWidth={100} justifyContent={'center'} alignItems={'center'}>
										<Stack
											height={60}
											width={60}
											alignItems={'center'}
											justifyContent={'center'}
											sx={{
												backgroundColor:'white',
												borderRadius:4
											}}
										>
											<Typography sx={{color:'#0068A6'}} fontWeight={'bolder'} fontSize={25}>
												{data?.questions}
											</Typography>
										</Stack>
										<Box>
												<Typography
													sx={{color: "#ffffff"}}
												>
													{(role === "questioner")?
														<Typography fontSize={11}>
															 سوال پرسیده شده
														</Typography>:
														<Typography fontSize={11}>
															سوال پاسخ داده شده
														</Typography>
													}
												</Typography>
										</Box>
									</Stack>
									<Stack width={'50%'} minWidth={100} justifyContent={'center'} alignItems={'center'}>
										<Stack
											alignItems={'center'}
											justifyContent={'center'}
											sx={{
												backgroundColor:'white',
												borderRadius:4
											}}
										>
											<img src={'./statics.png'} height={60} width={60}/>
										</Stack>
										<Box>
											<Typography
												sx={{color: "#ffffff"}}
											>
												<Typography fontSize={11}>
													نمودار فعالیت
												</Typography>
											</Typography>
										</Box>
									</Stack>
								</Stack>
								<Stack
									direction={"row"}
									spacing={2}
									width={'100%'}
									// sx={{
									// 	height:(downOfmd)?78:undefined
									// }}
								>
									<Stack width={'50%'} minWidth={100} justifyContent={'center'} alignItems={'center'}>
										<Stack
											height={60}
											width={60}
											alignItems={'center'}
											sx={{
												backgroundColor:'white',
												borderRadius:4
											}}
										>
											<Box
												sx={{
													backgroundColor:'#E71D30' ,
													height:20 ,
													width:'100%',
													borderTopLeftRadius:'15px',
													borderTopRightRadius:'15px'
												}}
											/>
											<Typography sx={{color:'#E71D30'}} fontWeight={'bolder'} fontSize={25}>
												15
											</Typography>
										</Stack>
										<Box>
											<Typography
												sx={{color: "#ffffff"}}
											>
												<Typography fontSize={11}>
													روزهای حضور
												</Typography>
											</Typography>
										</Box>
									</Stack>
									<Stack width={'50%'} minWidth={100} justifyContent={'center'} alignItems={'center'}>
										<Stack
											alignItems={'center'}
											justifyContent={'center'}
											sx={{
												backgroundColor:'white',
												borderRadius:4
											}}
										>
											<img src={'./star.png'} height={60} width={60}/>
										</Stack>
										<Box>
											<Typography
												sx={{color: "#ffffff"}}
											>
												<Typography fontSize={11}>
													امتیاز های من
												</Typography>
											</Typography>
										</Box>
									</Stack>
								</Stack>
							</Stack>
						</Stack>
					</CardContent>
				</Card>
			</Stack>
		</Stack>
	)
}