import Stack from "@mui/material/Stack";
import {Avatar, Button, Divider, Rating, Typography, useMediaQuery} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import React, {useState} from "react";
import IGetGeneralBaseData from "../../../../interface/IGetGeneralBaseData";
import PartialTextDisplay from "./PartialTextDisplay";
import theme from "../../../../lib/theme.const";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import moment from "moment-jalaali";

interface IProps {
	generalData: IGetGeneralBaseData
}

export default function UserComments({ generalData }:IProps) {
	const [startIndex, setStartIndex] = useState(0);
	const navigate = useNavigate();
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const cards = generalData?.msg.weekly_chosen_question.comments
	// const cards = generalData?.msg.weekly_chosen_question.comments?.concat(new Array(4).fill({
	// 	"id": 2,
	// 	"comment": "1",
	// 	"commenter_profile": {
	// 		"id": 3,
	// 		"username": "gg",
	// 		"name": "gg",
	// 		"photo": "default"
	// 	},
	// 	"created_at": 1717219053
	// }))

	const imagesPerPage = (cards?.length <= 4)? cards?.length: ((downOfsm)?1:(downOfmd)?3:4)

	const handleNext = () => {
		if(startIndex + imagesPerPage < cards?.length) {
			setStartIndex(startIndex + imagesPerPage);
		}
	};

	const handleBack = () => {
		if(startIndex - imagesPerPage >= 0) {
			setStartIndex(startIndex - imagesPerPage)
		}
	};

	function handleClickAllComments() {
		navigate("/allComments" , {state: {data:generalData.msg.weekly_chosen_question.title , id:0}})
	}

	return(
		<>
			<Stack>
				<Stack
					direction={"row"}
					justifyContent={"space-between"}
					spacing={(downOfsm)?"10%":"40%"}
					px={(downOfmd)?4:14}
					mt={'40px'}
				>
					<Stack alignSelf={"flex-start"}>
						<Typography
							fontWeight={"bold"}
							fontSize={"24px"}
						>
							نظرات کاربران
						</Typography>
					</Stack>
					<Stack alignSelf={"flex-end"}>
						{(cards)?
							<Button
								onClick={handleClickAllComments}
							>
								<Typography
									sx={{
										color: "black"
									}}
								>
									{"مشاهده همه"+ " " +`(${cards?.length})`}
								</Typography>
							</Button>:
							null
						}
					</Stack>
				</Stack>
				<Stack
					direction={"row"}
					justifyContent={"center"}
					alignItems={"center"}
					mt={'20px'}
				>
					<Button
						onClick={handleBack}
						disabled={startIndex === 0}
						sx={{
							width: "10%",
							minWidth: 20
						}}
					>
						<ArrowForwardIosIcon
							sx={{
								// color: "#808080",
								stroke: "#808080",
								strokeWidth: 3
							}}
						/>
					</Button>
					{(cards)?
						<Stack
							direction={"row"}
							justifyContent={"center"}
							alignItems={"center"}
							width={"100%"}
						>
							{cards?.slice(startIndex, startIndex + imagesPerPage).map((content, index) => {
								return(
									<Stack key={index}>
										<Stack
											direction={"row"}
											justifyContent={"space-between"}
										>
											<Stack sx={{height: 180}}>
												<Stack
													direction={"row"}
													justifyContent={"space-around"}
												>
													<Stack
														direction={"row"}
														spacing={1}
													>
														<Box>
															<Avatar
																alt={'user avatar'}
																src={`https://api.matnavis.com/files/${content.commenter_profile.photo}`}
																sx={{width: 35, height: 35}}
															/>
														</Box>
														<Stack direction={"column"}>
															<Box>
																<Typography fontWeight={"bold"}>
																	{content.commenter_profile.username}
																</Typography>
															</Box>
															<Box>
																<Rating
																	// defaultValue={1}
																	size="small"
																	value={content.stars}
																	max={5}
																	readOnly
																	sx={{
																		color: "#E86942"
																	}}
																/>
															</Box>
														</Stack>
													</Stack>
													<Stack>
														<Typography
															fontSize={12}
															fontWeight={"bold"}
															mt={"50%"}
														>
															{moment(content.created_at, 'X').utc().format('jYYYY/jMM/jDD')}
														</Typography>
													</Stack>
												</Stack>
												<Stack
													alignItems={"center"}
													justifyContent={"center"}
													sx={{
														width: {md:220 , lg:290 , xs:120},
														padding: 2
													}}
												>
													<PartialTextDisplay text={content.comment}/>
												</Stack>
											</Stack>
											{(!downOfsm) && index < ((cards?.length - startIndex >= 5)?imagesPerPage - 1:(cards?.length - startIndex)-1) ?
												<Divider
													orientation="vertical"
													sx={{
														borderWidth: "170px 0px 0px thin",
														backgroundColor: "#808080",
													}}
												/>
												: null
											}
										</Stack>
									</Stack>
								)
							})}
						</Stack>:
						<Stack
							direction={"row"}
							justifyContent={"center"}
							alignItems={"center"}
							width={"100%"}
						>
							<Stack
								direction={"row"}
								justifyContent={"space-between"}
								sx={{height: 180}}
							>
								<Stack
									alignItems={"center"}
									justifyContent={"center"}
									sx={{
										width: {md:170 , lg:220 , xs:160},
										position: 'relative'
									}}
								>
									<Box>
										<img
											alt={'No comment'}
											src={"./png/1.png"}
											style={{
												width:200,
												height:250
											}}
										/>
									</Box>
								</Stack>
							</Stack>
						</Stack>
					}
					<Button
						onClick={handleNext}
						disabled={startIndex + imagesPerPage >= cards?.length!}
						sx={{
							width: "10%",
							minWidth: 20
						}}
					>
						<ArrowBackIosNewIcon
							sx={{
								// color: "#808080",
								stroke: "#808080",
								strokeWidth: 3
							}}
						/>
					</Button>
				</Stack>
			</Stack>
		</>
	)
}