import Stack from "@mui/material/Stack";
import {
	Divider,
	Typography,
	useMediaQuery
} from "@mui/material";
import * as React from "react";
import {useState} from "react";
import theme from "../../../../../lib/theme.const";
import IGetSettingPageData from "../../../../../interface/IGetSettingPageData";
import AddNewSource from "./components/AddNewSource";
import AddBooksResearcher from "./components/AddBooksResearcher";
import AddKeyword from "./components/AddKeyword";
import FloatingOrangeListResearcher from "../FloatingOrangeListResearcher";
import FloatingOrangeListQuestioner from "../FloatingOrangeListQuestioner";
import Specifications from "../Specifications";
import SwitcherButtons from "../SwitcherButtons";
import Wallet from "../../wallet/Wallet";

interface IProps {
	settingPageData:IGetSettingPageData |undefined,
	handleItemClick: (sectionId:string) => void,
	handleClickSaveProfile: () => void
}

export default function SettingPageResearcher({settingPageData , handleClickSaveProfile , handleItemClick}: IProps) {
	const [noteBoxValue , setNoteBoxValue] = useState<number>(1);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	return(
		<Stack direction={"row"} sx={{backgroundColor:'white'}}>
			{(!downOfmd)?
				<Stack
					width={"25%"}
					sx={{
						// background: 'linear-gradient(to top, #ffffff, #E86942)',
						backgroundColor: "#E86942"
					}}
				>
					<FloatingOrangeListResearcher
						handleItemClick={handleItemClick}
						handleClickSaveProfile={handleClickSaveProfile}
					/>
				</Stack>:
				null
			}
			<Stack width={(downOfmd)?"100%":"75%"}>
				<Stack id={"sectionResearcher1"}>
					<Specifications data={settingPageData?.msg.profile}/>
				</Stack>
				<Stack
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Divider
						sx={{
							backgroundColor: "#bebebe",
							width: "85%"
						}}
					/>
				</Stack>
				<Stack
					spacing={3}
					alignItems={"center"}
					pt={15}
					id={"sectionResearcher2"}
				>
					<SwitcherButtons notif={settingPageData?.msg.notif_pref}/>
				</Stack>
				<Stack
					pt={15}
					justifyContent={"center"}
					alignItems={"center"}
				>
					<Divider
						sx={{
							backgroundColor: "#bebebe",
							width: "85%"
						}}
					/>
				</Stack>
				<Stack
					alignItems={"center"}
					id={"sectionResearcher3"}
				>
					<Stack py={7}>
						<Stack
							width={(downOfsm)?370:(downOfmd)?600:(downOflg)?470:650}
							direction={"row"}
						>
							<Stack
								onClick={() => setNoteBoxValue(1)}
								width={"50%"}
								height={(noteBoxValue === 1)?80:55}
								sx={{
									backgroundColor: (noteBoxValue === 1)?"#808080":"#D9D9D9",
									borderTopRightRadius: (noteBoxValue === 1)?15:undefined,
									borderTopLeftRadius: 15
								}}
								alignSelf={(noteBoxValue === 1)?undefined:"flex-end"}
								justifyContent={"center"}
								alignItems={"center"}
							>
								<Typography sx={{color: (noteBoxValue === 1)?"white":"#808080"}}>
									اضافه کردن منبع جدید
								</Typography>
							</Stack>
							<Stack
								onClick={() => setNoteBoxValue(2)}
								width={"50%"}
								height={(noteBoxValue === 2)?80:55}
								sx={{
									backgroundColor: (noteBoxValue === 2)?"#808080":"#D9D9D9",
									borderTopRightRadius: 15,
									borderTopLeftRadius: (noteBoxValue === 2)?15:undefined
								}}
								alignSelf={(noteBoxValue === 2)?undefined:"flex-end"}
								justifyContent={"center"}
								alignItems={"center"}
							>
								<Typography sx={{color: (noteBoxValue === 2)?"white":"#808080"}} width={(downOfsm)?120:undefined}>
									اضافه کردن کتاب‌‌های پژوهشگر
								</Typography>
							</Stack>
						</Stack>
						<Stack>
							{(noteBoxValue === 1)?
								<AddNewSource settingPageData={settingPageData}/>:
								<AddBooksResearcher settingPageData={settingPageData}/>
							}
						</Stack>
					</Stack>
					<Stack>
						<AddKeyword settingPageData={settingPageData}/>
					</Stack>
				</Stack>
				<Stack
					px={(downOfsm)?3:(downOfmd)?15:(downOflg)?10:20}
					pt={25}
					pb={10}
					id={"sectionResearcher4"}
				>
					<Wallet/>
				</Stack>
			</Stack>
		</Stack>
	)
}