import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import ILoginQuery from "../interface/ILoginQuery";
import ILoginResult from "../interface/ILoginResult";
import IGetGeneralBaseData from "../interface/IGetGeneralBaseData";
import IGetResearcherPageData from "../interface/IGetResearcherPageData";
import IGetPersonalPageData from "../interface/IGetPersonalPageData";
import IAddCommentQuery from "../interface/IAddCommentQuery";
import INoteQuery from "../interface/INoteQuery";
import IGetUserStatsResult from "../interface/IGetUserStatsResult";
import IFetchSearchQuestionQuery from "../interface/IFetchSearchQuestionQuery";
import ISubjectiveSearchResearcherQuery from "../interface/ISubjectiveSearchResearcherQuery";
import IAddSubCommentQuery from "../interface/IAddSubCommentQuery";
import IFetchResearchers from "../interface/IFetchResearchers";
import IFetchSearchQuestionResult from "../interface/IFetchSearchQuestionResult";
import IQuestionCitationResult from "../interface/IQuestionCitationResult";
import IFetchResearcherResult from "../interface/IFetchResearcherResult";
import IGetSettingPageData from "../interface/IGetSettingPageData";
import ISaveProfileSetting from "../interface/ISaveProfileSetting";
import IGetResentChatsResult from "../interface/IGetResentChatsResult";
import IGetAdminPageDataResult from "../interface/IGetAdminPageDataResult";
import IGetPendingQuestions from "../interface/IGetPendingQuestions";
import IFetchQuestionerResult from "../interface/IFetchQuestionerResult";
import IQuestionsFullTextSearchResult from "../interface/IQuestionsFullTextSearchResult";
import IGetAllSubjectiveResearcher from "../interface/IGetAllSubjectiveResearcher";
import ISubjectiveSearchResearcherResult from "../interface/ISubjectiveSearchResearcherResult";
import IGetFinanceContextResult from "../interface/IGetFinanceContextResult";
import AskQuestionQuery from "../interface/AskQuestionQuery";
import IGetUserTransactions from "../interface/IGetUserTransactions";
import IGetUserCashbackRequests from "../interface/IGetUserCashbackRequests";
import ISearchUsers from "../interface/ISearchUsers";
import IGetAllCashbackRequests from "../interface/IGetAllCashbackRequests";
import IGetResearcherRequestResult from "../interface/IGetResearcherRequestResult";
import IGeneralSearchResult from "../interface/IGeneralSearchResult";

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_API_URL,
  }),
  endpoints: (builder) => {
    return {
      login: builder.query<ILoginResult, ILoginQuery>({
        query: (query) => ({
          url: "/login",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: query,
        }),
      }),
      getGeneralBaseData: builder.query<IGetGeneralBaseData, {token: string}>({
        query: (query) => ({
          url: "/get_general_page_data",
          method: "POST",
          headers: { "Content-Type": "application/json" },
          // body: query,
        }),
      }),
      getResearcherPageData: builder.query<IGetResearcherPageData, {token:string , user_id:number}>({
        query: ({token , user_id}) => ({
          url: "/get_researcher_page_data",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {user_id},
        }),
      }),
      getPersonalPageData: builder.query<IGetPersonalPageData, {token: string}>({
        query: ({token}) => ({
          url: "/get_personal_page_data",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        }),
      }),
      getAdminPageData: builder.query<
        IGetAdminPageDataResult,
        {token: string}
      >({
        query: ({token}) => ({
          url: "/get_admin_page_data",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        }),
      }),
      getSettingPageData: builder.query<IGetSettingPageData, {token:string}>({
        query: ({token}) => ({
          url: "/get_settings_page_data",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          }
        }),
      }),
      getResentChats: builder.query<IGetResentChatsResult, {token: string}>({
        query: ({token}) => ({
          url: "/get_recent_chats",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        }),
      }),
      getFinanceContext: builder.query<IGetFinanceContextResult, {token: string}>({
        query: ({token}) => ({
          url: "/get_finance_context",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        }),
      }),
      getUserCashbackRequests: builder.query<IGetUserCashbackRequests, {token: string , page?:number , count?:number , status?:string}>({
        query: ({token , page , count , status}) => ({
          url: "/get_user_cashback_requests",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {page , count , status}
        }),
      }),
      getUserTransactions: builder.query<IGetUserTransactions, {token: string , page?:number , count?:number , confirmed_only?:string}>({
        query: ({token , page , count , confirmed_only}) => ({
          url: "/get_user_transactions",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {page , count , confirmed_only}
        }),
      }),
      getAllCashbackRequests: builder.query<IGetAllCashbackRequests, {token: string , page?:number , count?:number , status?:string}>({
        query: ({token , page , count , status}) => ({
          url: "/get_all_cashback_requests",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {page , count , status}
        }),
      }),
      getAllTransactions: builder.query<any, {token: string , page?:number , count?:number , confirmed_only?:string}>({
        query: ({token , page , count , confirmed_only}) => ({
          url: "/get_all_transactions",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {page , count , confirmed_only}
        }),
      }),
      initTransaction: builder.query<any, {token: string , gateway_id:number , tokens?:number , plan_id?:number}>({
        query: ({token , gateway_id , tokens , plan_id}) => ({
          url: "/init_transaction",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {gateway_id , tokens , plan_id}
        }),
      }),
      convertTokenToRial: builder.query<any, {token: string , tokens?:number}>({
        query: ({token , tokens}) => ({
          url: "/convert_token__to__rial",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {tokens}
        }),
      }),
      addComment: builder.query<any, IAddCommentQuery>({
        query: ({token , comment , question_id , stars}) => ({
          url: "/add_comment",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id, comment , stars},
        }),
      }),
      addSubComment: builder.query<any, IAddSubCommentQuery>({
        query: ({token , sub_comment , question_id , comment_id}) => ({
          url: "/add_sub_comment",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id, sub_comment , comment_id},
        }),
      }),
      markQuestion: builder.query<any, {token:string , question_id:number}>({
        query: ({token , question_id}) => ({
          url: "/mark_question",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id},
        }),
      }),
      unMarkQuestion: builder.query<any, {token:string , question_id:number}>({
        query: ({token , question_id}) => ({
          url: "/unmark_question",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id},
        }),
      }),
      addLike: builder.query<any, {token:string , question_id:number}>({
        query: ({token , question_id}) => ({
          url: "/add_like",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id},
        }),
      }),
      addLikeComment: builder.query<any, {token:string , question_id:number , comment_id:number}>({
        query: ({token , question_id , comment_id}) => ({
          url: "/add_like_to_comment",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id , comment_id},
        }),
      }),
      addDisLikeComment: builder.query<any, {token:string , question_id:number , comment_id:number}>({
        query: ({token , question_id , comment_id}) => ({
          url: "/add_dislike_to_comment",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id , comment_id},
        }),
      }),
      addLikeSubComment: builder.query<any, {token:string , question_id:number , comment_id:number , sub_comment_id:number}>({
        query: ({token , question_id , comment_id , sub_comment_id}) => ({
          url: "/add_like_to_sub_comment",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id , comment_id , sub_comment_id},
        }),
      }),
      addDisLikeSubComment: builder.query<any, {token:string , question_id:number , comment_id:number , sub_comment_id:number}>({
        query: ({token , question_id , comment_id , sub_comment_id}) => ({
          url: "/add_dislike_to_sub_comment",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id , comment_id , sub_comment_id},
        }),
      }),
      deleteLike: builder.query<any, {token:string , question_id:number}>({
        query: ({token , question_id}) => ({
          url: "/delete_like",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id},
        }),
      }),
      removeNote: builder.query<any, {token:string , note_id:number}>({
        query: ({token , note_id}) => ({
          url: "/remove_note",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {note_id},
        }),
      }),
      editNote: builder.query<any, INoteQuery>({
        query: ({token , note_id , body , title}) => ({
          url: "/edit_note",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {
            title ,
            body ,
            note_id ,
          },
        }),
      }),
      addNote: builder.query<any, {
        token:string ,
        title: string ,
        body: string
      }>({
        query: ({token , body , title}) => ({
          url: "/add_note",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {
            title ,
            body ,
          },
        }),
      }),
      getUserStats: builder.query<IGetUserStatsResult, {token: string}>({
        query: ({token}) => ({
          url: "/get_user_stats",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          }
        }),
      }),
      generalSearch: builder.query<IGeneralSearchResult, {token: string , query: string , researcher_only?:boolean , questioner_only?:boolean , question_only?:boolean}>({
        query: ({token , query , question_only , questioner_only , researcher_only}) => ({
          url: "/general_search",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {query , question_only , questioner_only , researcher_only}
        }),
      }),
      fetchSearchQuestion: builder.query<IFetchSearchQuestionResult, IFetchSearchQuestionQuery>({
        query: ({token , question_id , count , page , researcher_id , search , subjects}) => ({
          url: "/fetch_questions",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "accept": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {
            question_id ,
            count ,
            page ,
            researcher_id ,
            search ,
            subjects
          },
        }),
      }),
      subjectiveSearchResearcher: builder.query<ISubjectiveSearchResearcherResult, ISubjectiveSearchResearcherQuery>({
        query: ({count , page , subject , token}) => ({
          url: "/get_subject_researchers",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {
            count ,
            page ,
            subject
          },
        }),
      }),
      getQuestionCitation: builder.query<IQuestionCitationResult, {token:string , question_id:number}>({
        query: ({token , question_id}) => ({
          url: "/get_question_citation_string",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id},
        }),
      }),
      sendResearcherRequest: builder.query<any, {token:string , resume: string}>({
        query: ({token , resume}) => ({
          url: "/send_researcher_request",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {resume},
        }),
      }),
      getQuestionCitationRis: builder.query<any, {token:string , question_id: number, style: string}>({
        query: ({token , question_id , style}) => ({
          url: "/get_question_citation_ris",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id , style},
        }),
      }),
      fetchResearchers: builder.query<IFetchResearcherResult, IFetchResearchers>({
        query: ({id , page , subject , count , name , token}) => ({
          url: "/fetch_researchers",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {id , page , subject , count , name},
        }),
      }),
      fetchQuestioner: builder.query<IFetchQuestionerResult, IFetchResearchers>({
        query: ({id , page , count , name , token}) => ({
          url: "/fetch_questioners",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {id , page , count , name},
        }),
      }),
      saveProfileSetting: builder.query<any, ISaveProfileSetting>({
        query: ({bio , sheba , token , lname , fname , notif_pref , education , phone , email}) => ({
          url: "/save_profile",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {fname , bio , sheba , lname , education , phone , email , notif_pref},
        }),
      }),
      follow: builder.query<any, {token:string , following_id: string}>({
        query: ({token , following_id}) => ({
          url: "/follow",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {following_id},
        }),
      }),
      AddRecommendation: builder.query<any, {token:string , recommending_id: string}>({
        query: ({token , recommending_id}) => ({
          url: "/add_recommendation",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {recommending_id},
        }),
      }),
      RemoveRecommendation: builder.query<any, {token:string , recommending_id: string}>({
        query: ({token , recommending_id}) => ({
          url: "/remove_recommendation",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {recommending_id},
        }),
      }),
      removeSource: builder.query<any, {token:string , source_id: number}>({
        query: ({token , source_id}) => ({
          url: "/remove_source",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {source_id},
        }),
      }),
      removeBook: builder.query<any, {token:string , book_id: number}>({
        query: ({token , book_id}) => ({
          url: "/remove_book",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {book_id},
        }),
      }),
      removeKeyword: builder.query<any, {token:string , keyword_id: number}>({
        query: ({token , keyword_id}) => ({
          url: "/remove_keyword",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {keyword_id},
        }),
      }),
      addChat: builder.query<any, {token:string , question_id?: number , msg:string}>({
        query: ({token , question_id , msg}) => ({
          url: "/add_chat",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {question_id , msg},
        }),
      }),
      getResearcherRequest: builder.query<IGetResearcherRequestResult, {token: string}>({
        query: ({token}) => ({
          url: "/get_researcher_requests",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        }),
      }),
      getPendingQuestions: builder.query<IGetPendingQuestions, {token: string}>({
        query: ({token}) => ({
          url: "/get_pending_questions",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        }),
      }),
      acceptQuestion: builder.query<any, {token: string , question_id:number , chat_id:number}>({
        query: ({token , question_id , chat_id}) => ({
          url: "/accept_question",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {question_id , chat_id},
        }),
      }),
      rejectQuestion: builder.query<any, {token: string , question_id:number , chat_id:number , desc:string}>({
        query: ({token , question_id , chat_id , desc}) => ({
          url: "/reject_question",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {question_id , chat_id , desc},
        }),
      }),
      updateChatMsg: builder.query<any, {token: string , question_id:number , chat_id:number , desc:string}>({
        query: ({token , question_id , chat_id , desc}) => ({
          url: "/update_chat_msg",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {question_id , chat_id , desc},
        }),
      }),
      rejectResearcher: builder.query<any, {token: string , user_id:string}>({
        query: ({token , user_id}) => ({
          url: "/reject_researcher_request",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {user_id},
        }),
      }),
      acceptResearcher: builder.query<any, {token: string , user_id:string}>({
        query: ({token , user_id}) => ({
          url: "/reject_researcher_request",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {user_id},
        }),
      }),
      questionsFullTextSearch: builder.query<IQuestionsFullTextSearchResult, {token: string , search:string}>({
        query: ({token , search}) => ({
          url: "/questions_full_text_search",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {search},
        }),
      }),
      getAllResearchersBySubjects: builder.query<
        {
          code: number;
          msg: IGetAllSubjectiveResearcher[];
          status: string
        },
        {token: string}
      >({
        query: ({token}) => ({
          url: "/get_all_researchers_by_subjects",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
        }),
      }),
      askQuestion: builder.query<any, AskQuestionQuery>({
        query: ({token , title , subjects , plan_id , first_chat , ask_policy}) => ({
          url: "/ask_question",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {title , subjects , plan_id , first_chat , ask_policy},
        }),
      }),
      searchUsers: builder.query<ISearchUsers, {token:string , days?:string , question_count?:string , search?:string}>({
        query: ({token , days , search , question_count}) => ({
          url: "/search_users",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {days , search , question_count},
        }),
      }),
      notifyUsers: builder.query<any, {token:string , msg?:string}>({
        query: ({token , msg}) => ({
          url: "/notify_users",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {msg},
        }),
      }),
      setWeeklyQuestion: builder.query<any, {token:string , question_id?:number}>({
        query: ({token , question_id}) => ({
          url: "/set_weekly_question",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {question_id},
        }),
      }),
      makeCashbackRequest: builder.query<any, {token:string , amount:number , iban:number}>({
        query: ({token , amount , iban}) => ({
          url: "/make_cashback_request",
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`
          },
          body: {amount , iban},
        }),
      }),
      acceptCashbackRequest: builder.query<any, {token:string , request_id:number}>({
        query: ({token , request_id}) => ({
          url: "/accept_cashback_request",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {request_id},
        }),
      }),
      rejectCashbackRequest: builder.query<any, {token:string , request_id:number}>({
        query: ({token , request_id}) => ({
          url: "/reject_cashback_request",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {request_id},
        }),
      }),
      addSource: builder.mutation<any, {token:string , name:string}>({
        query: ({token , name}) => ({
          url: "/add_source",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {name},
        }),
      }),
      editSource: builder.query<any, {token:string , name:string , source_id:number}>({
        query: ({token , name , source_id}) => ({
          url: "/edit_source",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {name , source_id},
        }),
      }),
      addBook: builder.query<any, {token:string , name:string}>({
        query: ({token , name}) => ({
          url: "/add_book",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {name},
        }),
      }),
      editBook: builder.query<any, {token:string , name:string , book_id:number}>({
        query: ({token , name , book_id}) => ({
          url: "/edit_book",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {name , book_id},
        }),
      }),
      addKeyword: builder.query<any, {token:string , name:string}>({
        query: ({token , name}) => ({
          url: "/add_keyword",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {name},
        }),
      }),
      editKeyword: builder.query<any, {token:string , name:string , keyword_id:number}>({
        query: ({token , name , keyword_id}) => ({
          url: "/edit_keyword",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {name , keyword_id},
        }),
      }),
      sendContactToUsMessage: builder.query<any, {token:string , name:string , email:string , message:string}>({
        query: ({token , name , message , email}) => ({
          url: "/send_contact_to_us_message",
          method: "POST",
          headers: {
            "Content-Type": "application/json" ,
            "Authorization": `Bearer ${token}`
          },
          body: {name , email , message},
        }),
      }),
    }
  },
});

export const {
  useLazyLoginQuery ,
  useLazyGetGeneralBaseDataQuery ,
  useLazyGetResearcherPageDataQuery ,
  useLazyGetPersonalPageDataQuery ,
  useLazyAddCommentQuery ,
  useLazyMarkQuestionQuery ,
  useLazyUnMarkQuestionQuery ,
  useLazyAddLikeQuery ,
  useLazyDeleteLikeQuery ,
  useLazyRemoveNoteQuery ,
  useLazyEditNoteQuery ,
  useLazyAddNoteQuery ,
  useLazyGetUserStatsQuery ,
  useLazyFetchSearchQuestionQuery ,
  useLazySubjectiveSearchResearcherQuery ,
  useLazyAddSubCommentQuery ,
  useLazyAddLikeCommentQuery ,
  useLazyAddDisLikeCommentQuery,
  useLazyAddDisLikeSubCommentQuery ,
  useLazyAddLikeSubCommentQuery ,
  useLazyGetQuestionCitationQuery ,
  useLazySendResearcherRequestQuery ,
  useLazyGetQuestionCitationRisQuery ,
  useLazyFetchResearchersQuery ,
  useLazyGetSettingPageDataQuery ,
  useLazySaveProfileSettingQuery ,
  useLazyFollowQuery ,
  useLazyAddRecommendationQuery ,
  useLazyRemoveRecommendationQuery ,
  useLazyRemoveSourceQuery ,
  useLazyRemoveBookQuery ,
  useLazyRemoveKeywordQuery ,
  useLazyGetResentChatsQuery ,
  useLazyAddChatQuery ,
  useLazyGetAdminPageDataQuery ,
  useLazyGetResearcherRequestQuery ,
  useLazyGetPendingQuestionsQuery ,
  useLazyAcceptQuestionQuery ,
  useLazyRejectQuestionQuery ,
  useLazyRejectResearcherQuery ,
  useLazyAcceptResearcherQuery ,
  useLazyUpdateChatMsgQuery ,
  useLazyGetAllResearchersBySubjectsQuery ,
  useLazyFetchQuestionerQuery ,
  useLazyQuestionsFullTextSearchQuery ,
  useLazyGetFinanceContextQuery ,
  useLazyAskQuestionQuery ,
  useLazyGetUserCashbackRequestsQuery ,
  useLazyGetUserTransactionsQuery ,
  useLazyConvertTokenToRialQuery ,
  useLazyInitTransactionQuery ,
  useLazySearchUsersQuery ,
  useLazyGetAllCashbackRequestsQuery ,
  useLazyGetAllTransactionsQuery ,
  useLazyNotifyUsersQuery ,
  useLazySetWeeklyQuestionQuery ,
  useLazyMakeCashbackRequestQuery ,
  useLazyAcceptCashbackRequestQuery ,
  useLazyRejectCashbackRequestQuery ,
  useAddSourceMutation ,
  useLazyEditSourceQuery ,
  useLazyAddBookQuery ,
  useLazyEditBookQuery ,
  useLazyAddKeywordQuery ,
  useLazyEditKeywordQuery ,
  useLazyGeneralSearchQuery ,
  useLazySendContactToUsMessageQuery
} = apiSlice;
