import {Avatar, Box, Divider, Paper, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import {useState} from "react";
import Pagination from "../../../components/Pagination";

interface IProps {
	data: {
		id: number ,
		name: string ,
		photo: string ,
		username: string
	}[] | undefined
}

export default function FollowingBoxQuestioner({data}:IProps) {
	const [page, setPage] = useState(1);
	const ITEMS_PER_PAGE = 4;
	const handleFirstPage = () => setPage(1);
	const handleLastPage = () => setPage(Math.ceil(data?.length! / ITEMS_PER_PAGE));
	const handleNextPage = () => setPage(prev => Math.min(prev + 1, Math.ceil(data?.length! / ITEMS_PER_PAGE)));
	const handlePrevPage = () => setPage(prev => Math.max(prev - 1, 1));

	const startIndex = (page - 1) * ITEMS_PER_PAGE;
	const endIndex = startIndex + ITEMS_PER_PAGE;
	const currentItems = data?.slice(startIndex, endIndex) || [];

	return(
		<>
			<Stack>
				<Paper
					sx={{
						backgroundColor: "#D9D9D9",
						borderRadius: 3
					}}
				>
					<Box
						sx={{
							py:1,
							px:3
						}}
					>
						پژوهشگرانی که دنبال میکنید
					</Box>
					<Divider
						sx={{
							backgroundColor: "#EE8D6F",
						}}
					/>
					<Stack width={'100%'} justifyContent={'center'}>
						{(!currentItems || currentItems.length === 0)?
							<Stack justifyContent={'center'} alignItems={'center'} p={'10%'}>
								<Stack
									justifyContent={'center'}
									sx={{
										background: 'linear-gradient(to top, #D9D9D9, #524E4E)',
										borderRadius:7
									}}
								>
									<img
										src={'./png/2.png'}
										width={'100%'}
										height={400}
									/>
								</Stack>
							</Stack>:
							<Stack
								sx={{
									pt:2,
									px:2,
									// pr:17,
									pb:5
								}}
							>
								{currentItems?.map((content , index) => {
									return(
										<Stack mt={2} key={index}>
											<Stack
												direction={"row"}
												alignItems={'center'}
												spacing={3}
												mb={2}
												width={'100%'}
											>
												<Box>
													<Avatar
														src={`https://api.matnavis.com/files/${content.photo}`}
														sx={{
															width:"55px",
															height:"55px",
															transition: 'width 0.3s ease',
														}}
													/>
												</Box>
												<Stack>
													<Box>
														<Typography>
															{content.name}
														</Typography>
													</Box>
													<Box>
														<Typography>
															پژوهشگر حوزه معماری
														</Typography>
													</Box>
												</Stack>
											</Stack>
											{index < (currentItems.length - 1) ?
												<Divider
													sx={{
														mt:1,
														backgroundColor: "#EE8D6F",
													}}
												/>
												: null
											}
										</Stack>
									)
								})}
							</Stack>
						}
					</Stack>
					<Stack alignItems={'center'}>
						<Pagination
							handleFirstPage={handleFirstPage}
							handleLastPage={handleLastPage}
							handleNextPage={handleNextPage}
							handlePrevPage={handlePrevPage}
							page={page}
						/>
					</Stack>
				</Paper>
			</Stack>
		</>
	)
}