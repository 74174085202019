import {useLazyGetUserTransactionsQuery} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import {
	Paper,
	Table,
	TableBody,
	TableCell,
	tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography, useMediaQuery
} from "@mui/material";
import {styled} from "@mui/material/styles";
import * as React from "react";
import TableFooter from "./TableFooter";
import theme from "../../../../lib/theme.const";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#E86942',
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

interface IProps {
	selectedNumber: number
}

export default function TransactionsQuestioner({selectedNumber}:IProps) {
	const [TransactionsTrigger, { data: TransactionsData }] = useLazyGetUserTransactionsQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const [page, setPage] = useState(1);
	const ITEMS_PER_PAGE = (selectedNumber)?selectedNumber:4;
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	const startIndex = (page - 1) * ITEMS_PER_PAGE;
	const endIndex = startIndex + ITEMS_PER_PAGE;
	const currentItems = TransactionsData?.msg.transactions?.slice(startIndex, endIndex) || [];

	useEffect(() => {
		if(!token) return;

		TransactionsTrigger({
			token: token
		})
	}, [token]);

	console.log(TransactionsData)

	return(
		<>
			<Stack>
				<Stack alignItems={'center'} justifyContent={'center'}>
					<TableContainer component={Paper} sx={{borderRadius:5}}>
						<Table>
							<TableHead>
								<TableRow>
									<StyledTableCell>
										<Typography fontSize={12}>
											ردیف
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											تاریخ درخواست
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											شماره فاکتور
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											مبلغ واحد
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											مبلغ کل
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											مبلغ تخفیف
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											مالیات
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											مبلغ(تومان)
										</Typography>
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody sx={{backgroundColor:'#D9D9D9'}}>
								{currentItems.map((content , index) => {
									return(
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row">
												{index+1 + startIndex}
											</TableCell>
											<TableCell align="right">{content.created_at}</TableCell>
											<TableCell align="right">{content.id}</TableCell>
											<TableCell align="right">{content.t2rr}</TableCell>
											<TableCell align="right">{content.tokens * content.t2rr}</TableCell>
											<TableCell align="right">0</TableCell>
											<TableCell align="right">0</TableCell>
											<TableCell align="right">{content.tokens * content.t2rr}</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
						{(currentItems?.length !== 0)?
							null:
							<Stack
								sx={{backgroundColor:'#D9D9D9'}}
								width={'100%'}
								alignItems={'center'}
								justifyContent={'center'}
								pb={3}
							>
								<img src={'./png/3.png'} width={300} height={380}/>
								<Typography fontWeight={'bold'} fontSize={23} sx={{color: "#808080"}}>
									هیچ تاریخچه ای وجود ندارد
								</Typography>
							</Stack>
						}
					</TableContainer>
					<TableFooter
						setPage={setPage}
						ITEMS_PER_PAGE={ITEMS_PER_PAGE}
						TransactionsData={TransactionsData}
						page={page}
					/>
				</Stack>
			</Stack>
		</>
	)
}