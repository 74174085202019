import Stack from "@mui/material/Stack";
import {Paper, useMediaQuery} from "@mui/material";
import theme from "../../../../lib/theme.const";

interface IProps {
	noteBoxValue: number
}

export default function NoLikedAndMarkedQuestion({noteBoxValue}:IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	return(
		<>
			<Stack
				direction={'row'}
				justifyContent={(downOfsm)?'center':'space-between'}
				spacing={'10%'}
				width={'100%'}
				px={'5%'}
			>
				<Paper sx={{borderRadius:4}}>
					<Stack>
						<img
							src={(noteBoxValue === 2)?'./png/5.png':'./png/6.png'}
							width={(downOfsm)?190:'100%'}
							// width={(downOfsm)?190:280}
							height={(downOfsm)?230:250}
						/>
					</Stack>
				</Paper>
				{(!downOfsm)?
					<Paper sx={{borderRadius:4}}>
						<Stack>
							<img
								src={(noteBoxValue === 2)?'./png/5.png':'./png/6.png'}
								width={(downOfsm)?190:'100%'}
								// width={280}
								height={250}
							/>
						</Stack>
					</Paper>:
					null
				}
				{(!downOfsm)?
					<Paper sx={{borderRadius:4}}>
						<Stack>
							<img
								src={(noteBoxValue === 2)?'./png/5.png':'./png/6.png'}
								width={(downOfsm)?190:'100%'}
								// width={280}
								height={250}
							/>
						</Stack>
					</Paper>:
					null
				}
			</Stack>
		</>
	)
}