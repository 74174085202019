import Stack from "@mui/material/Stack";
import {Box, Button, Typography} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WorkIcon from "@mui/icons-material/Work";
import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import {useNavigate} from "react-router-dom";
import IconButton from "@mui/material/IconButton";

const FloatingIconWrapper = styled(Stack)(({ theme }) => ({
	position: 'fixed',
	left: theme.spacing('6%'),
	zIndex: 1000
}));

interface IProps {
	handleItemClick: (sectionId: string) => void;
	handleClickSaveProfile: () => void;
}

export default function FloatingOrangeListQuestioner({handleItemClick , handleClickSaveProfile}:IProps) {
	const [scrollTop , setScrollTop] = useState<number>();
	const [activeSection, setActiveSection] = useState<string>("sectionQuestioner1");
	const navigate = useNavigate();

	useEffect(() => {
		const sections = document.querySelectorAll('[id^="sectionQuestioner"]');
		const observer = new IntersectionObserver((entries) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					setActiveSection(entry.target.id);
				}
			});
		}, { threshold: 0.7 });

		sections.forEach(section => observer.observe(section));

		return () => {
			sections.forEach(section => observer.unobserve(section));
		};
	}, []);

	useEffect(() => {
		window.addEventListener("scroll" , () => {
			setScrollTop(window.document.scrollingElement?.scrollTop)
		})
	}, []);

	function handleSectionClick(section:string) {
		handleItemClick(section)

		if(section === "sectionQuestioner5") {
			navigate('/financialPage')
		}
	}

	return(
		<>
			<FloatingIconWrapper>
				{(scrollTop && (scrollTop >= 1000))?
					null:
					<Stack
						justifyContent={"center"}
						alignItems={"center"}
						pt={5}
						spacing={5}
					>
						<Box>
							<Typography
								fontWeight={"bold"}
								fontSize={23}
								sx={{
									color: "white",
								}}
							>
								تنظیمات کاربری
							</Typography>
						</Box>
						<Stack spacing={2}>
							{[
								{ id: "sectionQuestioner1", icon: <PersonIcon sx={{color:"white"}}/>, text: "مشخصات" },
								{ id: "sectionQuestioner2", icon: <NotificationsIcon sx={{color:"white"}}/>, text: "اعلان‌ها" },
								{ id: "sectionQuestioner3", icon: <WorkIcon sx={{color:"white"}}/>, text: "کیف پول" },
								{ id: "sectionQuestioner5", icon: <img src={'./payment.png'} style={{width:25 , height:25 , marginTop:2}}/>, text: "حساب"},
							].map(({ id, icon, text }) => {
								return(
									<Stack
										key={id}
										direction="row"
										spacing={2}
										sx={{ cursor: "pointer" }}
										onClick={() => handleSectionClick(id)}
									>
										<Box width={10}>
											{activeSection === id && (
												<Box sx={{ width: 10, height: 50, backgroundColor: "white" }} />
											)}
										</Box>
										<Box>{icon}</Box>
										<Stack justifyContent="center" width={150}>
											<Typography fontSize={22} sx={{ color: "white" }}>
												{text}
											</Typography>
										</Stack>
									</Stack>
								)
							})}
						</Stack>
						{/*<Stack spacing={2}>*/}
						{/*	<Stack*/}
						{/*		direction={"row"}*/}
						{/*		spacing={2}*/}
						{/*		sx={{cursor: "pointer"}}*/}
						{/*		onClick={() => handleItemClick("section1")}*/}
						{/*	>*/}
						{/*		<Box width={10}>*/}
						{/*			{((scrollTop !== undefined) && (scrollTop < 563))?*/}
						{/*				<Box*/}
						{/*					sx={{*/}
						{/*						width:10,*/}
						{/*						height:50,*/}
						{/*						backgroundColor: "white"*/}
						{/*					}}*/}
						{/*				/>:*/}
						{/*				null*/}
						{/*			}*/}
						{/*		</Box>*/}
						{/*		<Box>*/}
						{/*			<PersonIcon*/}
						{/*				sx={{*/}
						{/*					color: "white",*/}
						{/*					width: 45,*/}
						{/*					height: 45*/}
						{/*				}}*/}
						{/*			/>*/}
						{/*		</Box>*/}
						{/*		<Stack justifyContent={"center"} width={100}>*/}
						{/*			<Typography*/}
						{/*				fontSize={22}*/}
						{/*				sx={{color: "white"}}*/}
						{/*			>*/}
						{/*				مشخصات*/}
						{/*			</Typography>*/}
						{/*		</Stack>*/}
						{/*	</Stack>*/}
						{/*	<Stack*/}
						{/*		direction={"row"}*/}
						{/*		spacing={2}*/}
						{/*		sx={{cursor: "pointer"}}*/}
						{/*		onClick={() => handleItemClick("section2")}*/}
						{/*	>*/}
						{/*		<Box width={10}>*/}
						{/*			{(scrollTop && (scrollTop > 563 && scrollTop < 797))?*/}
						{/*				<Box*/}
						{/*					sx={{*/}
						{/*						width:10,*/}
						{/*						height:50,*/}
						{/*						backgroundColor: "white"*/}
						{/*					}}*/}
						{/*				/>:*/}
						{/*				null*/}
						{/*			}*/}
						{/*		</Box>*/}
						{/*		<Stack>*/}
						{/*			<NotificationsIcon*/}
						{/*				sx={{*/}
						{/*					color: "white",*/}
						{/*					width: 45,*/}
						{/*					height: 45*/}
						{/*				}}*/}
						{/*			/>*/}
						{/*		</Stack>*/}
						{/*		<Stack justifyContent={"center"} width={100}>*/}
						{/*			<Typography*/}
						{/*				fontSize={22}*/}
						{/*				sx={{color: "white"}}*/}
						{/*			>*/}
						{/*				اعلان‌ها*/}
						{/*			</Typography>*/}
						{/*		</Stack>*/}
						{/*	</Stack>*/}
						{/*	<Stack*/}
						{/*		direction={"row"}*/}
						{/*		spacing={2}*/}
						{/*		sx={{cursor: "pointer"}}*/}
						{/*		onClick={() => handleItemClick("section3")}*/}
						{/*	>*/}
						{/*		<Box width={10}>*/}
						{/*			{(scrollTop && (scrollTop > 797 && scrollTop < 1173))?*/}
						{/*				<Box*/}
						{/*					sx={{*/}
						{/*						width:10,*/}
						{/*						height:50,*/}
						{/*						backgroundColor: "white"*/}
						{/*					}}*/}
						{/*				/>:*/}
						{/*				null*/}
						{/*			}*/}
						{/*		</Box>*/}
						{/*		<Stack>*/}
						{/*			<WorkIcon*/}
						{/*				sx={{*/}
						{/*					color: "white",*/}
						{/*					width: 45,*/}
						{/*					height: 45*/}
						{/*				}}*/}
						{/*			/>*/}
						{/*		</Stack>*/}
						{/*		<Stack justifyContent={"center"} width={100}>*/}
						{/*			<Typography*/}
						{/*				fontSize={22}*/}
						{/*				sx={{color: "white"}}*/}
						{/*			>*/}
						{/*				کیف پول*/}
						{/*			</Typography>*/}
						{/*		</Stack>*/}
						{/*	</Stack>*/}
						{/*	<Stack*/}
						{/*		direction={"row"}*/}
						{/*		spacing={2}*/}
						{/*		sx={{cursor: "pointer"}}*/}
						{/*		onClick={() => handleItemClick("section4")}*/}
						{/*	>*/}
						{/*		<Box width={10}>*/}
						{/*			{(scrollTop && (scrollTop > 1173))?*/}
						{/*				<Box*/}
						{/*					sx={{*/}
						{/*						width:10,*/}
						{/*						height:50,*/}
						{/*						backgroundColor: "white"*/}
						{/*					}}*/}
						{/*				/>:*/}
						{/*				null*/}
						{/*			}*/}
						{/*		</Box>*/}
						{/*		<Stack>*/}
						{/*			<WorkIcon*/}
						{/*				sx={{*/}
						{/*					color: "white",*/}
						{/*					width: 45,*/}
						{/*					height: 45*/}
						{/*				}}*/}
						{/*			/>*/}
						{/*		</Stack>*/}
						{/*		<Stack justifyContent={"center"} width={100}>*/}
						{/*			<Typography*/}
						{/*				fontSize={22}*/}
						{/*				sx={{color: "white"}}*/}
						{/*			>*/}
						{/*				حساب*/}
						{/*			</Typography>*/}
						{/*		</Stack>*/}
						{/*	</Stack>*/}
						{/*</Stack>*/}
						<Stack>
							<Button
								onClick={handleClickSaveProfile}
								variant={"contained"}
								sx={{
									backgroundColor: "#ecd0c8",
									color: "black",
									'&:hover': {
										backgroundColor: "#cb7158"
									},
								}}
							>
								ذخیره تغییرات
							</Button>
						</Stack>
					</Stack>
				}
			</FloatingIconWrapper>
		</>
	)
}