import {useLazyFetchResearchersQuery} from "../../../../features/apiSlice";
import {useDispatch} from "react-redux";
import {Autocomplete, autocompleteClasses, outlinedInputClasses, TextField, useMediaQuery} from "@mui/material";
import theme from "../../../../lib/theme.const";
import {memo, PropsWithChildren, useCallback, useEffect, useState} from "react";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Stack from "@mui/material/Stack";
import {searchedResearcherChanged} from "../../../../features/appSlice";
import {useNavigate} from "react-router-dom";
import IGetAllSubjectiveResearcher from "../../../../interface/IGetAllSubjectiveResearcher";

interface Option {
	id: number;
	name: string;
	photo: string;
	username: string;
}

interface IProps {
	researchersData: {
		code: number;
		msg: IGetAllSubjectiveResearcher[];
		status: string
	} | undefined,
}

export default function ResearcherSearch({researchersData}:IProps) {
	const [ResearcherTrigger, { data: researcherData }] = useLazyFetchResearchersQuery();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const [options, setOptions] = useState<Option[]>([]);
	const [inputValue, setInputValue] = useState<string>("");
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {

		ResearcherTrigger({
			token: '',
			name: inputValue
		})
	}, [inputValue]);

	useEffect(() => {
		if (!researcherData) {
			return;
		}

		if (researcherData.status === "OK") {
			let newOptions: any[] = [];

			newOptions = [...newOptions, ...researcherData.msg.researchers].filter(
				(val, idx, self) =>
					self.map((x , index) => {
						if(val.title === x.title) {
							if(idx === index) {
								return true
							}
						}
						else {
							return false
						}
					})
			);

			if(inputValue === ""){
				setOptions([])
			}
			else {
				setOptions(newOptions);
			}
		}
	}, [researcherData, inputValue]);

	const handleSearchClick = useCallback(() => {

	}, []);

	const handleInputChange = useCallback((_: any, newValue: any) => {
		setInputValue(newValue)
	}, [researcherData]);

	const handleChange = useCallback(
		(_: React.SyntheticEvent, newValue: any| null) => {
			dispatch(searchedResearcherChanged(newValue))
			setOptions(newValue ? [newValue, ...options] : options);
			navigate("/general-researcher-page" , {state: newValue.id})
		},
		[options]
	);

	const InputEndAdornment = memo(
		(props: PropsWithChildren<{ reactNode: React.ReactNode }>) => {
			// eslint-disable-next-line react/prop-types
			const root = props.reactNode as JSX.Element;
			const elements = React.Children.toArray(root.props.children);

			// eslint-disable-next-line react/prop-types
			React.Children.forEach(props.children, (child, key) => {
				if (React.isValidElement(child))
					elements.push(React.cloneElement(child, { key }));
			});

			return React.cloneElement(root, {}, elements);
		}
	);

	return(
		<Stack alignItems={"center"} pt={3}>
			<Autocomplete
				autoComplete
				// value={selectedOption}
				options={options}
				popupIcon={false}
				includeInputInList
				filterSelectedOptions
				inputValue={inputValue}
				onChange={handleChange}
				onInputChange={handleInputChange}
				filterOptions={(x) => x}
				getOptionLabel={(option) => option.name}
				renderOption={(props, option) => <li {...props}>{option.name}</li>}
				sx={{
					width: downOfsm ? 200 : 400,
					[`& .${autocompleteClasses.endAdornment}`]: {
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center'
					}
				}}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={'جست‌وجو'}
						sx={{
							[`& .${outlinedInputClasses.root}`]: {
								height: 45,
								borderRadius: 8,
							}
						}}
						color={'warning'}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<InputEndAdornment reactNode={params.InputProps.endAdornment}>
									<IconButton
										size={'small'}
										sx={{ mt: -0.5 }}
										onClick={handleSearchClick}
									>
										<SearchIcon />
									</IconButton>
								</InputEndAdornment>
							),
						}}
					/>
				)}
			/>
		</Stack>
	)
}