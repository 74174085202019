import Box from "@mui/material/Box";
import {FormControl, FormControlLabel, Radio, RadioGroup, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useSelector} from "react-redux";
import {RootState} from "../app/store";

interface IProps {
	handleSetPaymentWay: (value: number) => void
}

export default function WaysToPayment({handleSetPaymentWay}:IProps) {
	const {financeContext} = useSelector((state: RootState) => state.app);

	return(
		<>
			<Stack spacing={2}>
				<Box>
					<Typography>
						برای شارژ اکانت خود روش پرداخت را انتخاب کنید
					</Typography>
				</Box>
				<Stack>
					<FormControl>
						<RadioGroup>
							<Stack direction={'row'}>
								<FormControlLabel
									value={financeContext?.msg.gateways_list[0].gateway_id}
									control={<Radio />}
									label={financeContext?.msg.gateways_list[0].title}
									onChange={(event, checked) => {
										if(checked){
											handleSetPaymentWay(1)
										}
									}}
								/>
								<img
									width={40}
									height={40}
									src={`https://api.matnavis.com/files/${financeContext?.msg.gateways_list[0].image}`}
								/>
							</Stack>
							<Stack direction={'row'}>
								<FormControlLabel
									value={financeContext?.msg.gateways_list[1].gateway_id}
									control={<Radio />}
									label={financeContext?.msg.gateways_list[1].title}
									onChange={(event, checked) => {
										if(checked){
											handleSetPaymentWay(2)
										}
									}}
								/>
								<img
									width={40}
									height={40}
									src={`https://api.matnavis.com/files/${financeContext?.msg.gateways_list[1].image}`}
								/>
							</Stack>
						</RadioGroup>
					</FormControl>
				</Stack>
			</Stack>
		</>
	)
}