import {Avatar, Box, Dialog, dialogClasses, DialogContent, Divider, Paper, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import * as React from "react";
import {useState} from "react";
import IGetPersonalPageData from "../../../../interface/IGetPersonalPageData";
import Pagination from "../../../../components/Pagination";

interface IProps {
	selectedValue: number | undefined,
	onClose: () => void,
	data: IGetPersonalPageData | undefined
}

export default function FollowingsOrRecommendationPopup({selectedValue , onClose , data}:IProps) {
	//  data?.msg.following باید  فقط برای حالت اول باشه ، باید عوض شه و شرطی شه
	const [page, setPage] = useState(1);
	const conditionalData = (selectedValue === 1)?data?.msg.following:(selectedValue === 2)?data?.msg.recommending:null
	const ITEMS_PER_PAGE = 4;
	const handleFirstPage = () => setPage(1);
	const handleLastPage = () => setPage(Math.ceil(conditionalData?.length! / ITEMS_PER_PAGE));
	const handleNextPage = () => setPage(prev => Math.min(prev + 1, Math.ceil(conditionalData?.length! / ITEMS_PER_PAGE)));
	const handlePrevPage = () => setPage(prev => Math.max(prev - 1, 1));

	const startIndex = (page - 1) * ITEMS_PER_PAGE;
	const endIndex = startIndex + ITEMS_PER_PAGE;
	const currentItems = conditionalData?.slice(startIndex, endIndex) || [];

	return(
		<Paper
			sx={{
				backgroundColor: "#D9D9D9",
				borderRadius:5,
				maxWidth:392
			}}
		>
			<DialogContent>
				<Stack>
					<Box sx={{py:1, px:3}}>
						{(selectedValue === 1)?
							<Typography>
								پژوهشگرانی که دنبال میکنید
							</Typography>:
							<Typography>
								پژوهشگرانی که پیشنهاد میکنید
							</Typography>
						}
					</Box>
					<Divider
						sx={{
							backgroundColor: "#EE8D6F",
						}}
					/>
					<Stack sx={{py:2, px:2}} alignSelf={'flex-start'}>
						{currentItems?.map((content , index) => {
							return(
								<Stack mt={2} key={index}>
									<Stack
										direction={"row"}
										justifyContent={"center"}
										spacing={3}
										mb={2}
									>
										<Box>
											<Avatar
												src={`https://api.matnavis.com/files/${content.photo}`}
												sx={{
													width:"55px",
													height:"55px",
													transition: 'width 0.3s ease',
												}}
											/>
										</Box>
										<Stack
											width={150}
											justifyContent={"space-around"}
										>
											<Box>
												<Typography>
													{content.name}
												</Typography>
											</Box>
											<Box>
												<Typography>
													پژوهشگر حوزه معماری
												</Typography>
											</Box>
										</Stack>
									</Stack>
									{index < (currentItems.length - 1) ?
										<Divider
											sx={{
												mt:1,
												backgroundColor: "#EE8D6F",
											}}
										/>
										: null
									}
								</Stack>
							)
						})}
					</Stack>
					<Stack alignItems={'center'}>
						<Pagination
							handleFirstPage={handleFirstPage}
							handleLastPage={handleLastPage}
							handleNextPage={handleNextPage}
							handlePrevPage={handlePrevPage}
							page={page}
						/>
					</Stack>
				</Stack>
			</DialogContent>
		</Paper>
	)
}