import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	paperClasses, Snackbar,
	Typography,
	useMediaQuery
} from "@mui/material";
import React, {useEffect, useState} from "react";
import theme from "../../lib/theme.const";
import {useLazyGetQuestionCitationQuery, useLazyGetQuestionCitationRisQuery} from "../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import Stack from "@mui/material/Stack";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { saveAs } from 'file-saver';

export interface SimpleDialogProps {
	open: boolean;
	onClose: (value: string) => void;
	questionId: number | undefined
}

export default function QuestionCitationPopup({open , onClose , questionId}: SimpleDialogProps) {
	const [selected , setSelected] = useState<number>();
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const [questionCitationTrigger, { data: questionCitationData }] = useLazyGetQuestionCitationQuery();
	const [questionCitationRisTrigger, { data: questionCitationRisData , error }] = useLazyGetQuestionCitationRisQuery();
	const { token } = useSelector((state: RootState) => state.app);
	const [oopen, setOpen] = useState(false);
	const textToCopy =
		(selected === 1)?questionCitationData?.msg.MLA:
			(selected === 2)?questionCitationData?.msg.APA:
				(selected === 3)?questionCitationData?.msg.Chicago:
					(selected === 4)?questionCitationData?.msg.Harvard:
						(selected === 5)?questionCitationData?.msg.Vancouver:null;


	useEffect(() => {
		if(!questionCitationRisData) return;

		saveAs(`https://api.matnavis.com/files/${questionCitationRisData.msg}`, questionCitationRisData.msg);
	}, [questionCitationRisData]);

	useEffect(() => {
		if(!token || !questionId) return;

		questionCitationTrigger({
			token: token ,
			question_id: questionId
		})
	}, [token , questionId]);

	function handleClickDownload() {
		if(!token || !questionId) return;

		if(selected === 1) {
			questionCitationRisTrigger({
				token: token,
				question_id: questionId,
				style: "MLA"
			})
		}
		else if(selected === 2) {
			questionCitationRisTrigger({
				token: token,
				question_id: questionId,
				style: "APA"
			})
		}
		else if(selected === 3) {
			questionCitationRisTrigger({
				token: token,
				question_id: questionId,
				style: "Chicago"
			})
		}
		else if(selected === 4) {
			questionCitationRisTrigger({
				token: token,
				question_id: questionId,
				style: "Harvard"
			})
		}
		else if(selected === 5) {
			questionCitationRisTrigger({
				token: token,
				question_id: questionId,
				style: "Vancouver"
			})
		}
	}

	const handleClick = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					mt: 8,
					[`& .${paperClasses.root}`]: {
						height: "100%",
						width: "100%",
						borderRadius:3,
						maxWidth: (downOfmd)?650:850
					}
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor: "#D9D9D9"
					}}
				>
					<Stack justifyContent={"center"} alignItems={"center"}>
						<Typography>
							Cite
						</Typography>
					</Stack>
				</DialogTitle>
				<DialogContent
					sx={{
						backgroundColor: "white",
						p:0,
						height: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						flexDirection: "column"
					}}
				>
					<Stack
						direction={"row"}
						justifyContent={"center"}
						width={"100%"}
					>
						<Stack
							width={"70%"}
							justifyContent={"space-around"}
							height={"100%"}
							sx={{py:4}}
						>
							<Stack
								onClick={() => setSelected(1)}
								justifyContent={"center"}
								px={2}
								sx={{
									backgroundColor: (selected === 1)?"#d1d1d1":undefined,
									borderBottomLeftRadius: 20,
									borderTopLeftRadius:20,
									height: 80,
									width: "100%",
									cursor: "pointer"
								}}
							>
								<Stack alignItems={"center"}>
									<Typography>
										{questionCitationData?.msg.MLA}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								onClick={() => setSelected(2)}
								justifyContent={"center"}
								px={2}
								sx={{
									backgroundColor: (selected === 2)?"#d1d1d1":undefined,
									borderBottomLeftRadius: 20,
									borderTopLeftRadius:20,
									height: 80,
									mt:0.2,
									width: "100%",
									cursor: "pointer"
								}}
							>
								<Stack alignItems={"center"}>
									<Typography>
										{questionCitationData?.msg.APA}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								onClick={() => setSelected(3)}
								justifyContent={"center"}
								px={2}
								sx={{
									backgroundColor: (selected === 3)?"#d1d1d1":undefined,
									borderBottomLeftRadius: 20,
									borderTopLeftRadius:20,
									height: 80,
									mt:0.1,
									width: "100%",
									cursor: "pointer"
								}}
							>
								<Stack alignItems={"center"}>
									<Typography>
										{questionCitationData?.msg.Chicago}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								onClick={() => setSelected(4)}
								justifyContent={"center"}
								px={2}
								sx={{
									backgroundColor: (selected === 4)?"#d1d1d1":undefined,
									borderBottomLeftRadius: 20,
									borderTopLeftRadius:20,
									height: 80,
									mt:0.1,
									width: "100%",
									cursor: "pointer"
								}}
							>
								<Stack alignItems={"center"}>
									<Typography>
										{questionCitationData?.msg.Harvard}
									</Typography>
								</Stack>
							</Stack>
							<Stack
								onClick={() => setSelected(5)}
								justifyContent={"center"}
								px={2}
								sx={{
									backgroundColor: (selected === 5)?"#d1d1d1":undefined,
									borderBottomLeftRadius: 20,
									borderTopLeftRadius:20,
									height: 80,
									mt:0.1,
									width: "100%",
									cursor: "pointer"
								}}
							>
								<Stack alignItems={"center"}>
									<Typography>
										{questionCitationData?.msg.Vancouver}
									</Typography>
								</Stack>
							</Stack>
						</Stack>
						<Stack
							justifyContent={"space-around"}
							// alignItems={"center"}
							width={"20%"}
							height={"100%"}
							sx={{
								backgroundColor: "#E8694280",
								borderRadius: 5,
								py:4
							}}
						>
							<Stack
								onClick={() => setSelected(1)}
								justifyContent={"center"}
								sx={{
									backgroundColor: (selected === 1)?"#d1d1d1":undefined,
									borderTopRightRadius: 20,
									height: 80,
									width: (downOfmd)?110:145,
									cursor: "pointer"
								}}
							>
								<Stack
									alignSelf={"flex-end"}
									width={(downOfmd)?90:120}
									alignItems={"center"}
								>
									<Typography>
										MLA
									</Typography>
								</Stack>
							</Stack>
							<Stack alignItems={"center"}>
								<Divider
									sx={{
										width: "70%",
										backgroundColor: "#949494"
									}}
								/>
							</Stack>
							<Stack
								onClick={() => setSelected(2)}
								justifyContent={"center"}
								sx={{
									backgroundColor: (selected === 2)?"#d1d1d1":undefined,
									height: 80,
									width: (downOfmd)?110:145,
									cursor: "pointer"
								}}
							>
								<Stack
									alignSelf={"flex-end"}
									alignItems={"center"}
									width={(downOfmd)?90:120}
								>
									<Typography>
										API
									</Typography>
								</Stack>
							</Stack>
							<Stack alignItems={"center"}>
								<Divider
									sx={{
										width: "70%",
										backgroundColor: "#949494"
									}}
								/>
							</Stack>
							<Stack
								onClick={() => setSelected(3)}
								justifyContent={"center"}
								sx={{
									backgroundColor: (selected === 3)?"#d1d1d1":undefined,
									height: 80,
									width: (downOfmd)?110:145,
									cursor: "pointer"
								}}
							>
								<Stack
									alignSelf={"flex-end"}
									alignItems={"center"}
									width={(downOfmd)?90:120}
								>
									<Typography>
										Cicago
									</Typography>
								</Stack>
							</Stack>
							<Stack alignItems={"center"}>
								<Divider
									sx={{
										width: "70%",
										backgroundColor: "#949494"
									}}
								/>
							</Stack>
							<Stack
								onClick={() => setSelected(4)}
								justifyContent={"center"}
								sx={{
									backgroundColor: (selected === 4)?"#d1d1d1":undefined,
									height: 80,
									width: (downOfmd)?110:145,
									cursor: "pointer"
								}}
							>
								<Stack
									alignSelf={"flex-end"}
									alignItems={"center"}
									width={(downOfmd)?90:120}
								>
									<Typography>
										Harvard
									</Typography>
								</Stack>
							</Stack>
							<Stack alignItems={"center"}>
								<Divider
									sx={{
										width: "70%",
										backgroundColor: "#949494"
									}}
								/>
							</Stack>
							<Stack
								onClick={() => setSelected(5)}
								justifyContent={"center"}
								sx={{
									backgroundColor: (selected === 5)?"#d1d1d1":undefined,
									borderBottomRightRadius: 20,
									height: 80,
									width: (downOfmd)?110:145,
									cursor: "pointer"
								}}
							>
								<Stack
									alignSelf={"flex-end"}
									alignItems={"center"}
									width={(downOfmd)?90:120}
								>
									<Typography>
										Vancover
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					</Stack>
					<Stack direction={"row"} py={2} spacing={3}>
						<Stack>
							<Button
								onClick={handleClickDownload}
								variant={"contained"}
								sx={{
									backgroundColor: "#EE8D6F",
									borderRadius: 15,
									minWidth: 120,
									py:2,
									color: "black",
									'&:hover': {
										backgroundColor: "#8f3e04"
									},
								}}
							>
								Download RIS
							</Button>
						</Stack>
						<Stack>
							<CopyToClipboard text={textToCopy!} onCopy={handleClick}>
								<Button
									variant={"contained"}
									sx={{
										backgroundColor: "#EE8D6F",
										borderRadius: 15,
										minWidth: 120,
										py:2,
										color: "black",
										'&:hover': {
											backgroundColor: "#8f3e04"
										},
									}}
								>
									Copy Citation
								</Button>
							</CopyToClipboard>
						</Stack>
					</Stack>
					<Snackbar
						open={oopen && !!selected}
						autoHideDuration={2000}
						onClose={handleClose}
						message="متن کپی شد!"
					/>
				</DialogContent>
			</Dialog>
		</>
	)
}