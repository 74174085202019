import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Divider,
	paperClasses,
	Typography, useMediaQuery
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import theme from "../../../../lib/theme.const";
import EditOrRejectQuestionPopup from "./EditOrRejectQuestionPopup";
import {useLazyAcceptResearcherQuery} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import IRequestResearcherData from "../../../../interface/IRequestResearcherData";

interface IProps {
	open: boolean,
	onClose: () => void;
	selectedResearcherRequest: IRequestResearcherData | undefined;
	setUpdateResearcherRequestData: Dispatch<SetStateAction<any>>
}

interface IUser {
	chat_id: number;
	id: number;
	question: string;
	questioner_id: number;
	researcherId: string | null
}

export default function ApprovalCVResearcherPopup({open , onClose , selectedResearcherRequest , setUpdateResearcherRequestData}:IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const imageURL = `https://api.matnavis.com/files/${selectedResearcherRequest?.resume_address}`;
	const [openEditOrRejectQuestion , setOpenEditOrRejectQuestion] = useState<boolean>(false);
	const [userDataForEditOrReject , setUserDataForEditOrReject] = useState<{type:string , user:IUser}>();
	const [acceptResearcherTrigger , {data: acceptResearcherData}] = useLazyAcceptResearcherQuery();
	const {token} = useSelector((state: RootState) => state.app);

	useEffect(() => {
		setUpdateResearcherRequestData(acceptResearcherData)
	}, [acceptResearcherData]);

	const embedStyle: React.CSSProperties = {
		width: '100%',
		height: '335px',
		border: '1px solid #ccc',
		position: 'relative',
		backgroundColor: '#f0f0f0'
	};

	const embedWrapperStyle: React.CSSProperties = {
		width: '100%',
		height: '100%',
		overflow: 'hidden',
		position: 'relative'
	};

	function handleCloseEditOrRejectQuestion() {
		setOpenEditOrRejectQuestion(false)
	}

	function handleRejectQuestion() {
		setUserDataForEditOrReject({
			type:"rejectResearcher" ,
			user:{
				chat_id: Number(selectedResearcherRequest?.profile.id),
				id: Number(selectedResearcherRequest?.profile.id),
				question: "",
				questioner_id: 0,
				researcherId: selectedResearcherRequest?.profile.name!
			}
		})
		setOpenEditOrRejectQuestion(true)
	}

	function handleAcceptQuestion() {
		if(!token || !selectedResearcherRequest) return;

		acceptResearcherTrigger({
			token: token,
			user_id: String(selectedResearcherRequest.profile.id)
		})
	}

	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					[`& .${paperClasses.root}`]: {
						position: "fixed",
						top: 0,
						width: "70%",
						backgroundColor: 'transparent'
					}
				}}
			>
				<Stack
					sx={{
						borderRadius: 5,
						backgroundColor: "#E86942",
						justifyContent: "center",
						alignItems: "center",
						py:1,
						px:(downOfsm)?3:15
					}}
				>
					<Typography sx={{color: "white"}}>
						تایید درخواست پژوهشگر
					</Typography>
				</Stack>
				<Box
					sx={{
						backgroundColor: "#D9D9D9",
						// borderRadius: 4,
						mt: 1
					}}
				>
					<DialogContent
						sx={{
							px:(downOfsm)?2:5,
							height: "100%"
						}}
					>
						<Stack>
							<Stack>
								<Typography>
									{selectedResearcherRequest?.profile.name}
								</Typography>
								<Divider
									sx={{
										mt: 1,
										backgroundColor: "#757373"
									}}
								/>
							</Stack>
							<Stack mt={3}>
								<Box>
									<Typography>
										رزومه شخصی
									</Typography>
								</Box>
								<Stack
									sx={{
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<Box
										sx={{
											borderStyle: 'dashed',
											borderWidth: 2,
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											padding: 2,
											marginTop: 1,
											width: "89%",
											height: "100%"
										}}
									>
										<div style={embedStyle}>
											<div style={embedWrapperStyle}>
												<embed
													src={imageURL}
													type="application/pdf"
													width="100%"
													height="100%"
												/>
											</div>
										</div>
									</Box>
									<Box mt={2}>
										<Button
											variant={"contained"}
											component="a"
											// onClick={() => setViewCV(!viewCV)}
											href={imageURL}
											target="_blank"
											sx={{
												px: 4,
												backgroundColor: "#E86942",
												'&:hover': {
													backgroundColor: "#8f3e04"
												},
												boxShadow: "0px 5px 10px 3px #656464"
											}}
										>
											دیدن
										</Button>
									</Box>
								</Stack>
							</Stack>
							<Divider
								sx={{
									mt: 2,
									backgroundColor: "#757373"
								}}
							/>
							<Stack direction={"row"} alignSelf={"flex-end"} spacing={2} pt={5}>
								<Box>
									<Button
										variant={"contained"}
										onClick={handleRejectQuestion}
										sx={{
											minWidth:90,
											backgroundColor: "#E86942",
											'&:hover': {
												backgroundColor: "#8f3e04"
											},
											boxShadow: "0px 5px 10px 3px #656464"
										}}
									>
										رد
									</Button>
								</Box>
								<Box>
									<Button
										variant={"contained"}
										onClick={handleAcceptQuestion}
										sx={{
											minWidth:90,
											backgroundColor: "#E86942",
											'&:hover': {
												backgroundColor: "#8f3e04"
											},
											boxShadow: "0px 5px 10px 3px #656464"
										}}
									>
										تایید
									</Button>
								</Box>
							</Stack>
						</Stack>
					</DialogContent>
				</Box>
			</Dialog>
			<EditOrRejectQuestionPopup
				open={openEditOrRejectQuestion}
				onClose={handleCloseEditOrRejectQuestion}
				userDataForEditOrReject={userDataForEditOrReject}
				setUpdateRequestData={setUpdateResearcherRequestData}
			/>
		</>
	)
}