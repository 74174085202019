import Stack from "@mui/material/Stack";
import SearchButtons from "./components/searchButtons/SearchButtons";
import {
	useLazyGetFinanceContextQuery,
	useLazyGetGeneralBaseDataQuery
} from "../features/apiSlice";
import React, {useEffect, useState} from "react";
import TopicsPopup from "./components/searchButtons/topicsPopup/TopicsPopup";
import ResearcherPopup from "./components/searchButtons/researcherPopup/ResearcherPopup";
import SubjectPopup from "./components/searchButtons/SubjectPopup/SubjectPopup";
import ChosenQuestion from "./components/ChosenQuestion/ChosenQuestion";
import UserComments from "./components/ChosenQuestion/UserComments/UserComments";
import ButtonsOfChosenQuestion from "./components/ChosenQuestion/ButtonsOfChosenQuestion";
import HelpMessageButton from "./components/messageButton/HelpMessageButton";
import SetCommentPopup from "../components/SetCommentPopup";
import MoreIconPopup from "../components/MoreOptionsPopup/MoreIconPopup";
import HomeImageStepper from "./components/lastestQuestions/HomeImageStepper";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../app/store";
import {financeContextChanged} from "../features/appSlice";
import {useMediaQuery} from "@mui/material";
import theme from "../lib/theme.const";

const Page = () => {
	const [generalTrigger, { data: generalData }] = useLazyGetGeneralBaseDataQuery();
	const [financeTrigger, { data: financeData }] = useLazyGetFinanceContextQuery();
	const [topicsOpen, setTopicsOpen] = useState(false);
	const [researcherOpen, setResearcherOpen] = useState(false);
	const [thematicOpen , setThematicOpen] = useState(false);
	const [commentOpen , setCommentOpen] = useState(false);
	const [moreOpen , setMoreOpen] = useState(false);
	const {token , refreshGeneralData} = useSelector((state: RootState) => state.app);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const dispatch = useDispatch();

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	useEffect(() => {
		generalTrigger({
			token: ""
		})
	}, [generalTrigger , refreshGeneralData]);

	useEffect(() => {
		if(!token) return;
		financeTrigger({
			token: token
		})
	}, [token]);

	useEffect(() => {
		dispatch(financeContextChanged(financeData))
	}, [financeData]);

	const handleClickOpen = (value:number | undefined) => {
		if(value === 3) {
			setTopicsOpen(true);
		}
		else if(value === 2) {
			setResearcherOpen(true)
		}
		else if(value === 1) {
			setThematicOpen(true)
		}
	};

	const handleClickCommentOpen = (value: any) => {
		setCommentOpen(true)
	}

	const handleClickMoreOpen = (value: any) => {
		setMoreOpen(true)
	}

	const handleClose = () => {
		if(topicsOpen) {
			setTopicsOpen(false);
		}
		else if(researcherOpen) {
			setResearcherOpen(false)
		}
		else if(thematicOpen) {
			setThematicOpen(false)
		}
		else if(commentOpen) {
			setCommentOpen(false)
		}
		else if(moreOpen) {
			setMoreOpen(false)
		}
	};

	return(
		<Stack
			pb={'40px'}
			width={"100%"}
			overflow={"hidden"}
			sx={{backgroundColor:'#FFFFFF'}}
		>
			<Stack
				// height={'400px'}

				sx={{
					width:'100%',
					height:(downOfsm)?'50vh':'60vh',
					// overflow:'hidden'
				}}
			>
				<img
					// height={'100%'}
					src={'./paper.jpg'}
					style={{
						width:'100%',
						height:'100%',
						maxWidth:'100%',
						// maxHeight:(downOfsm)?'50vh':'60vh',
						objectFit:'cover'
					}}
					alt={'home page paper'}
				/>
			</Stack>
			<Stack
				mt={-10}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<SearchButtons handleClickOpen={handleClickOpen}/>
				<TopicsPopup open={topicsOpen} onClose={handleClose}/>
				<ResearcherPopup open={researcherOpen} onClose={handleClose}/>
				<SubjectPopup open={thematicOpen} onClose={handleClose}/>
			</Stack>
			<Stack>
				<HomeImageStepper data={(generalData?.msg.latest_questions)!}/>
			</Stack>
			<Stack
				sx={{backgroundColor:'#D6DFEA', mt:'40px' , py:'40px', height:'100%'}}
				alignItems={'center'}
				justifyContent={'center'}
			>
				<Stack
					alignItems={'center'}
					justifyContent={"center"}
					px={'5%'}
					sx={{width: (downOfsm)?"60%":(downOflg)?'90%':"94%"}}
					// px={(downOfmd)?'10%':'9%'}
					// sx={{background: 'linear-gradient(to bottom, #D9D9D9, #ffffff)'}}
				>
					<ChosenQuestion generalData={generalData}/>
				</Stack>
				<Stack alignSelf={'flex-start'}>
					<ButtonsOfChosenQuestion
						handleClickComment={handleClickCommentOpen}
						handleClickMore={handleClickMoreOpen}
						generalData={generalData}
					/>
				</Stack>
			</Stack>
			<Stack>
				<Stack>
					<UserComments generalData={generalData!}/>
				</Stack>
				<SetCommentPopup
					open={commentOpen}
					onClose={handleClose}
					questionId={generalData?.msg.weekly_chosen_question.id}
					subCommentId={undefined}
				/>
				<MoreIconPopup
					open={moreOpen}
					onClose={handleClose}
					questionId={generalData?.msg.weekly_chosen_question.id}
				/>
			</Stack>
			<Stack
				pt={10}
				pl={3}
			>
				<HelpMessageButton/>
			</Stack>
		</Stack>
	)
}

const Home = () => (
	<Page />
);

export default Home;