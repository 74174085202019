import {Button, Typography, useMediaQuery} from "@mui/material";
import {useState} from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import theme from "../../../../lib/theme.const";

interface PartialTextDisplayProps {
	text: string
}

export default function PartialTextDisplay({ text }: PartialTextDisplayProps) {
	const [expanded, setExpanded] = useState(false);
	const downOfLg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const maxLines = 5;
	const maxLength = (downOfmd)?49:(downOfLg)?95:100;

	const getShortenedText = (text:string | undefined) => {
		if(!text) return;

		const words = text.split(' ');
		return words.length > 25 ? words.slice(0, 25).join(' ') + '...' : text;
	};

	const displayText = expanded ? text : getShortenedText(text);

	const shouldShowMoreButton = !expanded && (text.length > maxLength! || text.split('\n').length > maxLines!);

	const toggleExpanded = () => {
		setExpanded(!expanded);
	};

	return(
		<>
			<Typography
				variant="body1"
				component="div"
				sx={{color: "#808080"}}
			>
				<Stack>
					<Box
						width={(downOfsm)?160:undefined}
						ml={(downOfsm)?8:undefined}
					>
						{`${displayText}...`}
					</Box>
					{/*<Box>*/}
					{/*	{shouldShowMoreButton*/}
					{/*		&& (*/}
					{/*		<Button onClick={toggleExpanded} color="primary" endIcon={<ExpandMoreIcon />}>*/}
					{/*			More*/}
					{/*		</Button>*/}
					{/*	)}*/}
					{/*</Box>*/}
				</Stack>
			</Typography>
		</>
	)
}