import Stack from "@mui/material/Stack";
import {Box, Button, outlinedInputClasses, Paper, TextField, Typography, useMediaQuery} from "@mui/material";
import theme from "../../../../lib/theme.const";
import {useLazyMakeCashbackRequestQuery} from "../../../../features/apiSlice";
import {useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";


export default function CashbackResearcher() {
	const [makeCashbackRequestTrigger , {data:makeCashbackRequestData}] = useLazyMakeCashbackRequestQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const [amount , setAmount] = useState<number>();
	const [iban , setIban] = useState<number>();

	function handleClickSetRequest() {
		if(!token || !amount || !iban) return;

		makeCashbackRequestTrigger({
			token: token,
			amount: amount,
			iban: iban
		})
	}

	return(
		<>
			<Stack pt={8} pb={20} width={'100%'} justifyContent={'center'} alignItems={'center'}>
				<Stack direction={'row'} alignSelf={'flex-start'}>
					<img src={'./png/cashback.png'} width={220} height={110}/>
					<Box pt={3}>
						<Typography fontWeight={'bold'} fontSize={18}>برداشت</Typography>
					</Box>
				</Stack>
				<Stack width={(downOfsm)?'80%':(downOfmd)?'60%':'45%'} pt={5} spacing={5}>
					<Paper sx={{backgroundColor:'#D9D9D9' , p:3 , borderRadius:3}}>
						<Stack direction={'row'} justifyContent={'space-between'} px={1}>
							<Typography>
								مبلغ قابل برداشت
							</Typography>
							<Typography>
								0000تومان
							</Typography>
						</Stack>
					</Paper>
					<Paper
						sx={{
							p:3,
							borderRadius:3,
							boxShadow: "0px 70px 70px 10px #D9D9D9"
						}}
					>
						<Stack width={'100%'} spacing={4}>
							<Stack spacing={1} pb={5}>
								<Box>
									<Typography fontWeight={'bold'}>مبلغ مورد نظر برداشتی</Typography>
								</Box>
								<Box>
									<TextField
										fullWidth
										color={'warning'}
										onChange={(event) => setAmount(Number(event.target.value))}
										sx={{
											[`& .${outlinedInputClasses.root}`]: {
												height: 40,
												backgroundColor: "#D9D9D9",
											}
										}}
									/>
								</Box>
							</Stack>
							<Stack spacing={1}>
								<Box>
									<Typography fontWeight={'bold'}>شماره شبا</Typography>
								</Box>
								<Box>
									<TextField
										fullWidth
										color={'warning'}
										onChange={(event) => setIban(Number(event.target.value))}
										sx={{
											[`& .${outlinedInputClasses.root}`]: {
												height: 40,
												backgroundColor: "#D9D9D9",
											}
										}}
									/>
								</Box>
							</Stack>
							<Stack>
								<Button
									onClick={handleClickSetRequest}
									fullWidth
									variant={'contained'}
									sx={{
										backgroundColor:'#E86942',
										borderRadius:2,
										'&:hover': {
											backgroundColor: "#8f3e04"
										},
									}}
								>
									ثبت درخواست
								</Button>
							</Stack>
						</Stack>
					</Paper>
				</Stack>
			</Stack>
		</>
	)
}