import {
	Button,
	Dialog,
	DialogContent,
	DialogTitle,
	InputAdornment,
	inputBaseClasses,
	paperClasses,
	TextField,
	Typography, useMediaQuery
} from "@mui/material";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import theme from "../../../lib/theme.const";

export interface SimpleDialogProps {
	open: boolean;
	onClose: (value: string) => void;
}

export default function HelpUserPopup({open , onClose}: SimpleDialogProps) {
	// const [ResearcherTrigger, { data: personalPageData }] = useLazyGetResearcherPageDataQuery();
	const { token } = useSelector((state: RootState) => state.app);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	// useEffect(() => {
	// 	if(!token) return;
	// 	ResearcherTrigger({
	// 		token: token
	// 	})
	// }, [token , ResearcherTrigger]);


	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					// mt: 18,
					[`& .${paperClasses.root}`]: {
						height: 600,
						width: 450,
						borderRadius:3
					}
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor: "#E86942",
						color: "white"
					}}
				>
					<Stack
						justifyContent={"center"}
						alignItems={"center"}
						py={1}
					>
						<Box>
							<Typography
								fontWeight={"bold"}
								fontSize={28}
							>
								به کمک نیاز دارید ؟
							</Typography>
						</Box>
						<Box>
							<Typography
								fontWeight={"lighter"}
							>
								زمان پاسخ گویی: حداکثر 30 دقیقه
							</Typography>
						</Box>
					</Stack>
				</DialogTitle>
				<DialogContent>
					<Stack>
						<Stack
							alignItems={"center"}
							pt={5}
							spacing={1}
						>
							<Box>
								<Typography fontSize={20}>
									موضوع سوال خود را انتخاب کنید
								</Typography>
							</Box>
							<Box>
								<Stack
									justifyContent={"center"}
									alignItems={"center"}
									width={300}
									height={40}
									sx={{
										// backgroundColor: "#E86942",
										backgroundColor:'#0068A6',
										borderRadius: "30px",
										cursor: "pointer"
									}}
								>
									<Typography
										sx={{color: "white"}}
									>
										راهنمایی برای انتخاب مشاور
									</Typography>
								</Stack>
							</Box>
							<Box>
								<Stack
									justifyContent={"center"}
									alignItems={"center"}
									borderRadius={"50%"}
									width={300}
									height={40}
									sx={{
										// backgroundColor: "#E86942",
										backgroundColor:'#0068A6',
										borderRadius: "30px",
										cursor: "pointer"
									}}
								>
									<Typography
										sx={{color: "white"}}
									>
										مشکل در پرداخت
									</Typography>
								</Stack>
							</Box>
							<Box>
								<Stack
									justifyContent={"center"}
									alignItems={"center"}
									borderRadius={"50%"}
									width={300}
									height={40}
									sx={{
										// backgroundColor: "#E86942",
										backgroundColor:'#0068A6',
										borderRadius: "30px",
										cursor: "pointer"
									}}
								>
									<Typography
										sx={{color: "white"}}
									>
										راهنمای استفاده از سامانه
									</Typography>
								</Stack>
							</Box>
							<Box>
								<Stack
									justifyContent={"center"}
									alignItems={"center"}
									borderRadius={"50%"}
									width={300}
									height={40}
									sx={{
										// backgroundColor: "#E86942",
										backgroundColor:'#0068A6',
										borderRadius: "30px",
										cursor: "pointer"
									}}
								>
									<Typography
										sx={{color: "white"}}
									>
										موضوعات دیگر
									</Typography>
								</Stack>
							</Box>
						</Stack>
						<Box
							sx={{
								position: "fixed",
								bottom: 100
							}}
						>
							<TextField
								variant="standard"
								placeholder={"پیام خود را اینجا بنویسید"}
								// label={
								// 	<Typography
								// 		fontSize={13}
								// 		ml={10}
								// 		sx={{
								// 			zIndex: 100
								// 		}}
								// 	>
								// 		آدرس جست‌و‌‌جو شده
								// 	</Typography>
								// }
								InputProps={{
									disableUnderline: true,
									style: {
										paddingLeft: 5
									},
									startAdornment: (
										<InputAdornment
											position="end"
										>
											<Button
												variant={"contained"}
												sx={{
													backgroundColor: "#E86942",
													height: 25,
													color: "black",
													'&:hover': {
														backgroundColor: "#8f3e04"
													},
												}}
											>
												ارسال
											</Button>
										</InputAdornment>
									),
									inputComponent: 'input',
								}}
								sx={{
									[`& .${inputBaseClasses.root}`]: {
										height: 40,
										width: (downOfsm)?300:400,
										backgroundColor: "#D9D9D9",
										borderRadius: "30px"
									},
									[`& .${inputBaseClasses.input}`]: {
										pl: 5
									}
								}}
							/>
						</Box>
					</Stack>
				</DialogContent>
			</Dialog>
		</>
	)
}