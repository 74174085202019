import Stack from "@mui/material/Stack";
import * as React from "react";
import {Typography} from "@mui/material";
import Box from "@mui/material/Box";

interface Option {
	question_id: number,
	result_chunk: string,
}

interface IProps {
	// props: HTMLAttributes<HTMLLIElement>;
	handleChange: (event: any, newValue: Option | null) => void;
	options: Option[];
	// children: ReactNode;
	children: any;
	inputValue: string | undefined
}

const highlightText = (text: string, keyword: string) => {
	const parts = text.split(new RegExp(`(${keyword})`, 'gi'));
	return parts.map((part, index) =>
		part.toLowerCase() === keyword.toLowerCase() ? (
			<span key={index} style={{ color: 'orange', fontWeight: 'bold' }}>{part}</span>
		) : (
			part
		)
	);
};

const getSurroundingText = (text: string, keyword: string, before = 2, after = 2) => {
	if(!text || !keyword) return;
	const words = text.split(' ');
	const keywordIndex = words.findIndex(word => word.toLowerCase().includes(keyword.toLowerCase()));

	if (keywordIndex === -1) return text;

	const start = Math.max(0, keywordIndex - before);
	const end = Math.min(words.length, keywordIndex + after + 1);
	const surroundingText = words.slice(start, end).join(' ');

	return highlightText(surroundingText, keyword);
};

export default function SearchPaperResult({options , handleChange , inputValue}:IProps) {

	return (
		<>
			<Stack spacing={3} alignSelf={"flex-start"} pt={3}>
				<Stack px={3}>
					{(options.length !== 0)?
						<Box>
							<Typography>
								نتایج:
							</Typography>
						</Box>:
						null
					}
					<Box component="ul" sx={{ listStyle: "none", p: 0, m: 0 }}>
						{options.map((option , index) => {
							return(
								<Stack
									key={index}
									sx={{
										border: 1,
										mt: 2,
										borderRadius: 5,
										borderColor: "rgba(128, 128, 128, 0.5)",
										'&:hover': {
											backgroundColor: "rgba(128, 128, 128, 0.5)"
										}
									}}
								>
									<li
										onClick={() => { handleChange(null, option) }}
										style={{ cursor: "pointer", marginBottom: "8px", marginTop: "8px" }}
									>
										<Typography sx={{ px: 1 }}>
											{/*{option.result_chunk}*/}
											{getSurroundingText(option.result_chunk, inputValue!)}
										</Typography>
									</li>
								</Stack>
							)
						})}
					</Box>
				</Stack>
			</Stack>
		</>
	)
}