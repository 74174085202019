import Stack from "@mui/material/Stack";
import React from "react";
import {Avatar, Box, Typography, useMediaQuery} from "@mui/material";
import theme from "../../../lib/theme.const";
import IGetGeneralBaseData from "../../../interface/IGetGeneralBaseData";
import {useNavigate} from "react-router-dom";

interface IProps {
	generalData: IGetGeneralBaseData | undefined
}

export default function ChosenQuestion({generalData}:IProps) {
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const navigate = useNavigate();

	const getShortenedText = (text:string | undefined) => {
		if(!text) return;

		const words = text.split(' ');
		return words.length > 60 ? words.slice(0, 60).join(' ') + '...' : text;
	};

	return(
		<>
			<Stack
				direction={(downOfsm)?"column":"row"}
				justifyContent={"space-between"}
				alignItems={'center'}
				id="chat-container"
				// sx={{width: "100%"}}
				// spacing={'10%'}
			>
				<Stack
					alignSelf={'flex-start'}
					sx={{
						width: (downOfmd)?"100%":'50%'
					}}
				>
					<Box>
						<Typography
							sx={{
								color: "#3C3C3C",
								fontSize: "24px",
								fontWeight: 700
							}}
						>
							گفتگوی برگزیده هفته
						</Typography>
					</Box>
					{generalData?.msg.weekly_chosen_question.chats.map((content , index) => {
						return(
							<Stack key={index}>
								<Stack>
									{(content.sender === "questioner")?
										<Stack mt={'20px'} alignSelf={"flex-start"}>
											<Box
												sx={{
													height: "100%",
													width: '80%',
													// backgroundColor: "#dfdfdf",
													backgroundColor: '#FFFFFF',
													borderRadius: 4,
													px: 1.5,
													py: 2
												}}
											>
												{getShortenedText(content.msg)}
											</Box>
											<Box mt={0.5} ml={0.5}>
												{
													(
														generalData?.msg.weekly_chosen_question.chats[index+1]?.sender
														!== generalData?.msg.weekly_chosen_question.chats[index]?.sender
													)?
													<Avatar
														alt={'questioner avatar'}
														onClick={() => navigate("/general-questioner-page" , {state: generalData?.msg.weekly_chosen_question.questioner_profile})}
														src={`https://api.matnavis.com/files/${generalData?.msg.weekly_chosen_question.questioner_profile.photo}`}
														sx={{
															width:"35px",
															height:"35px",
															transition: 'width 0.3s ease',
														}}
													/>:
													null
												}
											</Box>
										</Stack>:
										null
									}
								</Stack>
								<Stack>
									{(content.sender === "researcher")?
										<Stack mt={1}>
											<Box
												alignSelf={"flex-end"}
												sx={{
													height: "100%",
													width: "80%",
													display: "flex",
													// backgroundColor: "#f2a992",
													backgroundColor: '#85CDF9',
													borderRadius: 4,
													px: 1.5,
													py: 2
												}}
											>
												{getShortenedText(content.msg)}
											</Box>
											<Box
												alignSelf={"flex-end"}
												mt={0.5}
											>
												{
													(
														generalData?.msg.weekly_chosen_question.chats[index+1]?.sender
														!== generalData?.msg.weekly_chosen_question.chats[index]?.sender
													) ||
													(!generalData?.msg.weekly_chosen_question.chats[index+1])?
														<Avatar
															alt={'researcher avatar'}
															onClick={() => navigate("/general-researcher-page" , {state:generalData?.msg.weekly_chosen_question.researcher_profile})}
															src={`https://api.matnavis.com/files/${generalData?.msg.weekly_chosen_question.researcher_profile.photo}`}
															sx={{
																width:"35px",
																height:"35px",
																transition: 'width 0.3s ease',
															}}
														/>:
														null
												}
											</Box>
										</Stack>:
										null
									}
								</Stack>
							</Stack>
						)
					})}
				</Stack>
				<Stack
					alignSelf={"flex-start"}
					justifySelf={'flex-end'}
					justifyItems={'end'}
					sx={{
						// position: (downOfmd)?undefined:"absolute",
						// top:1300,
						width: (downOfmd)?"100%":(downOflg)?'40%':'35%',
						// right: 50,
						py:(downOfmd)?6:0
					}}
				>
					<img
						src={'./chosenConversation.png'}
						width={(downOfmd)?350:400}
						height={(downOfmd)?350:400}
						alt={'chosen conversation picture'}
					/>
				</Stack>
			</Stack>
		</>
	)
}