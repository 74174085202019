import {
	useLazyAcceptCashbackRequestQuery,
	useLazyGetAllCashbackRequestsQuery,
	useLazyRejectCashbackRequestQuery
} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {useEffect} from "react";
import {
	Avatar, Button,
	Dialog,
	dialogClasses,
	DialogContent,
	DialogTitle,
	Paper, Typography,
	useMediaQuery
} from "@mui/material";
import {styled} from "@mui/material/styles";
import theme from "../../../../lib/theme.const";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import * as React from "react";

interface IProps {
	open: boolean,
	onClose: () => void
}

const CustomScrollbarRoot = styled('div')({
	position: 'relative',
	width: '100%',
	height: "100%", // Set a specific height to ensure scrolling
	overflowY: 'auto',
	overflowX: 'hidden', // Disable horizontal scroll
	direction: 'rtl',
	'& > *': {
		direction: 'ltr',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: '#D9D9D9',
		borderRadius: '10px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#a4a4a4',
		borderRadius: '10px',
	},
});

export default function ApprovalCashbackPopup({open , onClose}:IProps) {
	const [allCashbackRequestTrigger , {data:allCashbackRequestData}] = useLazyGetAllCashbackRequestsQuery();
	const [acceptCashbackRequestTrigger , {data:acceptCashbackRequestData}] = useLazyAcceptCashbackRequestQuery();
	const [rejectCashbackRequestTrigger , {data:rejectCashbackRequestData}] = useLazyRejectCashbackRequestQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	console.log(allCashbackRequestData)

	useEffect(() => {
		if(!token) return;

		allCashbackRequestTrigger({
			token: token
		})
	}, [token , acceptCashbackRequestData , rejectCashbackRequestData]);

	function handleAcceptCashbackRequest(id:number) {
		if(!token) return;

		acceptCashbackRequestTrigger({
			token: token,
			request_id: id
		})
	}

	function handleRejectCashbackRequest(id:number) {
		if(!token) return;

		rejectCashbackRequestTrigger({
			token: token,
			request_id: id
		})
	}

	return(
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				[`& .${dialogClasses.paper}`]: {
					borderRadius: 6,
					background: 'linear-gradient(to top, #b5b5b5, #777777)',
					top: (downOfsm)?-95:0,
					height: (downOfsm)?650:"100%",
				},
			}}
		>
			<DialogTitle>
				<Stack
					sx={{
						backgroundColor:'white',
						borderRadius: 8,
						p:1,
						mt:1,
						mb:2
					}}
					alignItems={'center'}
				>
					<Typography>
						درخواست برداشت وجه
					</Typography>
				</Stack>
			</DialogTitle>
			<DialogContent>
				<CustomScrollbarRoot sx={{overflow: 'hidden'}}>
					<Stack spacing={2} pl={1}>
						{allCashbackRequestData?.msg.requests?.map((content , index) => {
							console.log(content)
							return(
								<Stack key={index}>
									<Paper sx={{p:2}}>
										<Stack direction={'row'} spacing={3}>
											<Stack alignItems={'center'} justifyContent={'center'} width={'22%'}>
												<Avatar
													src={`https://api.matnavis.com/files/${content.profile.photo}`}
													sx={{
														width:"60px",
														height:"60px",
														transition: 'width 0.3s ease',
													}}
												/>
											</Stack>
											<Stack width={'58%'} height={'100%'} justifyContent={'center'}>
												<Box>
													<Typography>
														{content.profile.name}
													</Typography>
												</Box>
												<Box>
													<Typography>
														{'مبلغ ' + content.amount + ' تومان'}
													</Typography>
												</Box>
												<Box>
													<Typography>
														{content.iban}
													</Typography>
												</Box>
											</Stack>
											<Stack
												alignSelf={'flex-end'}
												// sx={{minWidth:60}}
												direction={'row'}
												spacing={1}
											>
												<Stack>
													<Button
														onClick={() => handleAcceptCashbackRequest(content.id)}
														variant={"contained"}
														sx={{
															height:25,
															// minWidth: (downOfsm)?70:100,
															backgroundColor: "#E86942",
															'&:hover': {
																backgroundColor: "#8f3e04"
															},
														}}
													>
														تایید
													</Button>
												</Stack>
												<Stack>
													<Button
														onClick={() => handleRejectCashbackRequest(content.id)}
														variant={"contained"}
														sx={{
															height:25,
															// minWidth: (downOfsm)?70:100,
															backgroundColor: "#E86942",
															'&:hover': {
																backgroundColor: "#8f3e04"
															},
														}}
													>
														رد
													</Button>
												</Stack>
												{/*<Stack*/}
												{/*	alignItems={'center'}*/}
												{/*	sx={{*/}
												{/*		backgroundColor:'#E86942',*/}
												{/*		borderRadius:4,*/}
												{/*		height:23,*/}
												{/*		width:'100%',*/}
												{/*		px:1*/}
												{/*	}}*/}
												{/*>*/}
												{/*	<PersonIcon*/}
												{/*		sx={{*/}
												{/*			color:'white',*/}
												{/*			display:'flex',*/}
												{/*			alignSelf:'flex-end',*/}
												{/*		}}*/}
												{/*	/>*/}
												{/*</Stack>*/}
											</Stack>
										</Stack>
									</Paper>
								</Stack>
							)
						})}
					</Stack>
				</CustomScrollbarRoot>
			</DialogContent>
		</Dialog>
	)
}