import {Avatar, Dialog, DialogContent, DialogTitle, Paper, paperClasses, Typography} from "@mui/material";
import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import IAllQuestionData from "../../../interface/IAllQuestionData";

export interface SimpleDialogProps {
	open: boolean;
	onClose: (value: string) => void;
	data: IAllQuestionData[] | undefined,
	title: string
}

export default function AllQuestionsPopup({open , onClose , data , title}: SimpleDialogProps) {

	return(
		<>
			<Dialog
				onClose={onClose}
				open={open}
				sx={{
					// mt: 18,
					[`& .${paperClasses.root}`]: {
						// height: "100%",
						// width: 600,
						borderRadius:3
					}
				}}
			>
				<DialogTitle
					sx={{
						backgroundColor: "#E86942",
						color: "white"
					}}
				>
					<Stack justifyContent={"center"} alignItems={"center"}>
						{title}
					</Stack>
				</DialogTitle>
				<DialogContent
					sx={{
						backgroundColor: "#EDEDED",
						p:0,
						height: "100%"
					}}
				>
					{data?.map((value , index) => {
						return(
							<Stack
								key={index}
								justifyContent={"space-between"}
								alignItems={"center"}
								m={2}
							>
								<Paper
									sx={{
										px: 5,
										py: 3,
										width: "98%",
										borderRadius: 0
									}}
								>
									<Stack direction={"row"}>
										<Box mr={4}>
											<Avatar
												src={`https://api.matnavis.com/files/${value.questioner_profile?.photo}`}
												sx={{
													width:"55px",
													height:"55px",
													transition: 'width 0.3s ease',
												}}
											/>
										</Box>
										<Box>
											<Typography
												fontSize={"12px"}
												fontWeight={"400px"}
												sx={{
													lineHeight: "14.52px"
												}}
											>
												{value.question}
											</Typography>
										</Box>
									</Stack>
								</Paper>
							</Stack>
						)
					})}
				</DialogContent>
			</Dialog>
		</>
	)
}