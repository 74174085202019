import Stack from "@mui/material/Stack";
import {Avatar, Box, Divider, outlinedInputClasses, TextField, Typography, useMediaQuery} from "@mui/material";
import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import theme from "../../../../lib/theme.const";
import {useFormik} from "formik";
import IValuesProfileSetting from "../../../../interface/IValuesProfileSetting";
import {useDispatch, useSelector} from "react-redux";
import {profileSettingDataChanged} from "../../../../features/appSlice";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {RootState} from "../../../../app/store";

interface IProfile {
	bio: string;
	created_at: number;
	education: string;
	email: string;
	fname: string;
	id: number;
	lname: string;
	phone: string;
	photo: string;
	sheba: string;
	username: string;
}

interface IProps {
	data: IProfile | undefined;
}

export default function Specifications({data}: IProps) {
	const formik = useFormik<IValuesProfileSetting>({
		initialValues: {
			fname: "",
			lname: "",
			phone: "",
			email: "",
			education: "",
			bio: "",
			sheba: ""
		},
		onSubmit: handleSubmit,
	});
	const [profileData , setProfileData] = useState<IValuesProfileSetting>();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const prevProfileData = useRef<IValuesProfileSetting>();
	const dispatch = useDispatch();
	const [avatarSrc, setAvatarSrc] = useState<string>(); // Default avatar path
	const { role } = useSelector((state: RootState) => state.app);

	useEffect(() => {
		if(!data) return;

		setProfileData({
			fname: data.fname,
			lname: data.lname,
			phone: data.phone,
			email: data.email,
			education: data.education,
			bio: data.bio,
			sheba: data.sheba
		})
	}, [data]);

	useEffect(() => {
		if (!profileData) {
			return;
		}
		if (prevProfileData.current === profileData) {
			return;
		}

		prevProfileData.current = profileData;
		formik.setValues(profileData);
	}, [profileData, formik]);

	useEffect(() => {
		dispatch(profileSettingDataChanged(profileData))
	}, [profileData]);

	function handleSubmit(values: IValuesProfileSetting) {
		setProfileData(values)
	}

	const handleAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.files && event.target.files[0]) {
			const reader = new FileReader();
			reader.onload = (e) => {
				if (typeof e.target?.result === 'string') {
					setAvatarSrc(e.target.result);
				}
			};
			reader.readAsDataURL(event.target.files[0]);
		}
	};

	return(
		<>
			<Stack
				direction={"row"}
				pl={7}
				pt={5}
				alignItems={"center"}
			>
				<Box>
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<input
							accept="image/*"
							style={{ display: 'none' }}
							id="upload-avatar"
							type="file"
							onChange={handleAvatarChange}
						/>
						<label htmlFor="upload-avatar">
							<Stack direction={"row"}>
								<Avatar src={(avatarSrc)?avatarSrc:`https://api.matnavis.com/files/${data?.photo}`} alt="Avatar" style={{ width: 90, height: 90 }} />
								<IconButton
									component="span"
									sx={{
										borderRadius: "50%",
										backgroundColor: "#E86942",
										p:0.1,
										height: "20%",
										display: "flex",
										alignSelf:"flex-end",
										ml:-3.5,
										'&:hover': {
											backgroundColor: "#8f3e04"
										},
									}}
								>
									<EditIcon style={{ padding: '2px' , color:"white" }} />
								</IconButton>
							</Stack>
						</label>
					</div>
				</Box>
				<Box pl={2}>
					<Divider
						orientation="vertical"
						sx={{
							borderWidth: "80px 0px 0px thin",
							backgroundColor: "#3C3F48",
							"&:hover": {
								display: "none"
							},
						}}
					/>
				</Box>
				<Stack pl={2}>
					<Stack direction={"row"}>
						<Typography fontSize={18}>
							{data?.fname}
						</Typography>
						<Typography>
							{data?.lname}
						</Typography>
					</Stack>
					<Box>
						<Typography fontSize={13}>
							{data?.username}
						</Typography>
					</Box>
				</Stack>
			</Stack>
			<Stack
				p={8}
				spacing={3}
				justifyContent={"center"}
				alignItems={"center"}
			>
				<form onChange={formik.handleSubmit}>
					<Stack spacing={2}>
						<Stack direction={(downOfsm)?"column":"row"} spacing={4}>
							<Stack>
								<Box pl={2}>
									<Typography>
										نام
									</Typography>
								</Box>
								<TextField
									name={"fname"}
									color={"warning"}
									value={formik.values.fname}
									onChange={formik.handleChange}
									sx={{
										[`& .${outlinedInputClasses.root}`]: {
											minWidth:(downOflg)?300:400,
											borderRadius: 5,
											backgroundColor: "#D9D9D9",
										},
									}}
								/>
							</Stack>
							<Stack>
								<Box pl={2}>
									<Typography pl={2}>
										نام خانوادگی
									</Typography>
								</Box>
								<TextField
									name={"lname"}
									color={"warning"}
									value={formik.values.lname}
									onChange={formik.handleChange}
									sx={{
										[`& .${outlinedInputClasses.root}`]: {
											minWidth:(downOflg)?300:400,
											borderRadius: 5,
											backgroundColor: "#D9D9D9",
										},
									}}
								/>
							</Stack>
						</Stack>
						<Stack direction={(downOfsm)?"column":"row"} spacing={4}>
							<Stack>
								<Box>
									<Typography pl={2}>
										شماره تماس
									</Typography>
								</Box>
								<TextField
									name={"phone"}
									color={"warning"}
									value={formik.values.phone}
									onChange={formik.handleChange}
									sx={{
										[`& .${outlinedInputClasses.root}`]: {
											minWidth:(downOflg)?300:400,
											borderRadius: 5,
											backgroundColor: "#D9D9D9",
										},
									}}
								/>
							</Stack>
							<Stack>
								<Box>
									<Typography pl={2}>
										ایمیل
									</Typography>
								</Box>
								<TextField
									name={"email"}
									color={"warning"}
									value={formik.values.email}
									onChange={formik.handleChange}
									sx={{
										[`& .${outlinedInputClasses.root}`]: {
											minWidth:(downOflg)?300:400,
											borderRadius: 5,
											backgroundColor: "#D9D9D9",
										},
									}}
								/>
							</Stack>
						</Stack>
						<Stack direction={(downOfsm)?"column":"row"} spacing={4}>
							<Stack>
								<Box>
									<Typography pl={2}>
										تحصیلات
									</Typography>
								</Box>
								<TextField
									name={"education"}
									color={"warning"}
									value={formik.values.education}
									onChange={formik.handleChange}
									sx={{
										[`& .${outlinedInputClasses.root}`]: {
											minWidth:(downOflg)?300:400,
											borderRadius: 5,
											backgroundColor: "#D9D9D9",
										},
									}}
								/>
							</Stack>
							<Stack>
								<Box>
									<Typography pl={2}>
										بایو
									</Typography>
								</Box>
								<TextField
									name={"bio"}
									color={"warning"}
									value={formik.values.bio}
									onChange={formik.handleChange}
									sx={{
										[`& .${outlinedInputClasses.root}`]: {
											minWidth:(downOflg)?300:400,
											borderRadius: 5,
											backgroundColor: "#D9D9D9",
										},
									}}
								/>
							</Stack>
						</Stack>
						{(role === "researcher")?
							<Stack>
								<Box>
									<Typography pl={2}>
										شماره شبا
									</Typography>
								</Box>
								<TextField
									fullWidth
									name={"sheba"}
									color={"warning"}
									value={formik.values.sheba}
									onChange={formik.handleChange}
									sx={{
										[`& .${outlinedInputClasses.root}`]: {
											// minWidth:(downOflg)?300:400,
											borderRadius: 5,
											backgroundColor: "#D9D9D9",
										},
									}}
								/>
							</Stack>:
							null
						}
					</Stack>
				</form>
			</Stack>
		</>
	)
}