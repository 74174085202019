import Stack from "@mui/material/Stack";
import * as React from "react";
import Box from "@mui/material/Box";
import {Button, Divider, Rating, Typography, useMediaQuery} from "@mui/material";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import Chats from "./Chats";
import theme from "../../lib/theme.const";
import SetCommentPopup from "../../components/SetCommentPopup";
import ReplyComment from "./ReplyComment";
import {
	useLazyAddDisLikeCommentQuery,
	useLazyAddLikeCommentQuery, useLazyFetchSearchQuestionQuery
} from "../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import moment from "moment-jalaali";


interface CommentType {
	"created_at": number,
	"dislikes": number,
	"likes": number,
	"stars": number,
	"id": number,
	"comment": string,
	"commenter_profile": {
		"id": number,
		"username": string,
		"name": string,
		"photo": string,
	},
	"sub_comments": {
		"created_at": number,
		"dislikes": number,
		"id": number,
		"likes": number,
		"sub_comment": string,
		"sub_commenter_profile": {
			id: number,
			username: string,
			name: string,
			photo: string
		}
	}[]
}

function Page() {
	const [searchTrigger, { data: searchData }] = useLazyFetchSearchQuestionQuery();
	const [likeTrigger , {data:likeData}] = useLazyAddLikeCommentQuery();
	const [disLikeTrigger , {data:disLikeData}] = useLazyAddDisLikeCommentQuery();
	const { token , refreshGeneralData} = useSelector((state: RootState) => state.app);
	const [commentOpen , setCommentOpen] = useState(false);
	const [subCommentId , setSubCommentId] = useState<number>();
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const location = useLocation();
	const data = location.state;

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	useEffect(() => {
		if(!data) return;

		searchTrigger({
			token: '',
			search: data.data,
			// question_id: 1
		})
	}, [data , likeData , disLikeData , refreshGeneralData]);

	const handleClickCommentOpen = (value: number) => {
		setSubCommentId(value)
		setCommentOpen(true)
	}

	const handleClose = () => {
		if(commentOpen) {
			setCommentOpen(false)
		}
	};

	function handleClickLike(commentId:number) {
		if(!token || !searchData) return;

		likeTrigger({
			token: token,
			question_id: searchData.msg.questions[0].id,
			comment_id: commentId
		})
	}

	function handleClickDisLike(commentId:number) {
		if(!token || !searchData) return;

		disLikeTrigger({
			token: token,
			question_id: searchData.msg.questions[0].id,
			comment_id: commentId
		})
	}

	return(
		<>
			<Stack overflow={"hidden"} sx={{backgroundColor:'white'}}>
				<Stack pt={5} spacing={5}>
					<Chats data={{data: searchData?.msg.questions[0] , id:data.id}}/>
					<Stack>
						<Box px={"8%"} py={5}>
							<Typography fontWeight={"bold"} fontSize={23}>
								نظرات کاربران
							</Typography>
						</Box>
						{searchData?.msg.questions[0].comments.map((content: CommentType) => {
							return(
								<Stack
									sx={{
										mb:20,
										px:10,
										pt:4,
										height: "100%",
										background: 'linear-gradient(to top, #ffffff, #D9D9D9)',
									}}
								>
									<Stack>
										<Stack
											direction={"row"}
											justifyContent={"space-between"}
										>
											<Stack
												direction={"row"}
												spacing={1}
											>
												<Box>
													<PersonIcon
														sx={{
															width: 35,
															height: 35,
															border: 1,
															borderColor: "#3C3F48",
															borderRadius: "50%",
															color: "#E86942"
														}}
													/>
												</Box>
												<Stack direction={"column"}>
													<Box>
														<Typography fontWeight={"bold"}>
															{content.commenter_profile.username}
														</Typography>
													</Box>
													<Box>
														<Rating
															// defaultValue={1}
															size="small"
															value={content.stars}
															max={5}
															readOnly
															sx={{
																color: "#E86942"
															}}
														/>
													</Box>
												</Stack>
											</Stack>
											<Stack>
												<Typography
													fontSize={12}
													fontWeight={"bold"}
													mt={"50%"}
												>
													{moment(content.created_at, 'X').utc().format('jYYYY/jMM/jDD')}
												</Typography>
											</Stack>
										</Stack>
										<Box ml={6} mt={3}>
											<Typography>
												{content.comment}
											</Typography>
										</Box>
										<Stack direction={"row"} ml={6} mt={3} spacing={1}>
											<Stack direction={"row"} spacing={0.5}>
												<Box pt={1}>
													<Typography fontSize={14}>
														{content.likes}
													</Typography>
												</Box>
												<Stack
													pt={1}
													sx={{cursor: "pointer"}}
													onClick={() => handleClickLike(content.id)}
												>
													👍
												</Stack>
											</Stack>
											<Stack direction={"row"} spacing={0.5}>
												<Stack
													pt={1.5}
													sx={{cursor: "pointer"}}
													onClick={() => handleClickDisLike(content.id)}
												>
													👎
												</Stack>
												<Box pt={1}>
													<Typography fontSize={14}>
														{content.dislikes}
													</Typography>
												</Box>
											</Stack>
											<Box>
												<Button
													onClick={() => handleClickCommentOpen(content.id)}
													variant={"contained"}
													sx={{
														// backgroundColor: "#E86942",
														backgroundColor: '#319DD3',
														'&:hover': {
															// backgroundColor: "#8f3e04"
															backgroundColor: '#206486'
														},
													}}
												>
													پاسخ به کامنت
												</Button>
											</Box>
										</Stack>
									</Stack>
									<Stack
										alignItems={"center"}
										sx={{
											pt: 5
										}}
									>
										<Divider
											sx={{
												borderWidth: "2px 0px 0px thin",
												width: (downOfsm)?250:(downOflg)?650:1000,
												backgroundColor: "#D9D9D9"
											}}
										/>
									</Stack>
									<ReplyComment
										content={content.sub_comments}
										commentId={content.id}
										questionId={searchData.msg.questions[0].id}
									/>
								</Stack>
							)
						})}
					</Stack>
				</Stack>
				<SetCommentPopup
					open={commentOpen}
					onClose={handleClose}
					questionId={searchData?.msg.questions[0].id}
					subCommentId={subCommentId}
				/>
			</Stack>
		</>
	)
}

export default function AllComments() {
	return(
		<Page/>
	)
}