import {
	Avatar,
	Button,
	Dialog,
	dialogClasses,
	DialogContent,
	DialogTitle,
	Grid,
	Typography,
	useMediaQuery
} from "@mui/material";
import {
	useLazyAcceptQuestionQuery,
	useLazyGetPendingQuestionsQuery
} from "../../../../features/apiSlice";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {styled} from "@mui/material/styles";
import EditOrRejectQuestionPopup from "./EditOrRejectQuestionPopup";
import theme from "../../../../lib/theme.const";
import Pagination from "../../../../components/Pagination";
import * as React from "react";

interface IProps {
	open: boolean,
	onClose: () => void;
}

interface IUserEditOrRejectQuestion {
	chat_id: number;
	id: number;
	question: string;
	questioner_id: number;
	researcherId: string | null
}

interface IUser {
	chat_id: number;
	id: number;
	question: string;
	questioner_id: number;
}

const CustomScrollbarRoot = styled('div')({
	position: 'relative',
	width: '100%',
	height: "100%", // Set a specific height to ensure scrolling
	overflowY: 'auto',
	overflowX: 'hidden', // Disable horizontal scroll
	direction: 'rtl',
	'& > *': {
		direction: 'ltr',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
	},
	'&::-webkit-scrollbar-track': {
		backgroundColor: '#D9D9D9',
		borderRadius: '10px',
	},
	'&::-webkit-scrollbar-thumb': {
		backgroundColor: '#a4a4a4',
		borderRadius: '10px',
	},
});

export default function ApprovalQuestionPopup({open , onClose}:IProps) {
	const [questionsRequestTrigger , {data: questionsRequestData}] = useLazyGetPendingQuestionsQuery();
	const [acceptQuestionTrigger , {data: acceptQuestionData}] = useLazyAcceptQuestionQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const [page, setPage] = useState(1);
	const [userDataForEditOrReject , setUserDataForEditOrReject] = useState<{type:string , user:IUserEditOrRejectQuestion}>();
	const [openEditOrRejectQuestion , setOpenEditOrRejectQuestion] = useState<boolean>(false);
	const [updateQuestionsData , setUpdateQuestionsData] = useState<any>();
	const ITEMS_PER_PAGE = 5;
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));

	useEffect(() => {
		if(!token) return;

		questionsRequestTrigger({
			token: token
		})
	}, [token , updateQuestionsData]);

	console.log(questionsRequestData)

	useEffect(() => {
		setUpdateQuestionsData(acceptQuestionData)
	}, [acceptQuestionData]);

	const handleFirstPage = () => setPage(1);
	const handleLastPage = () => setPage(Math.ceil(questionsRequestData?.msg.questions.length! / ITEMS_PER_PAGE));
	const handleNextPage = () => setPage(prev => Math.min(prev + 1, Math.ceil(questionsRequestData?.msg.questions.length! / ITEMS_PER_PAGE)));
	const handlePrevPage = () => setPage(prev => Math.max(prev - 1, 1));

	const startIndex = (page - 1) * ITEMS_PER_PAGE;
	const endIndex = startIndex + ITEMS_PER_PAGE;
	const currentItems = questionsRequestData?.msg.questions?.slice(startIndex, endIndex) || [];

	function handleAcceptQuestion(user:IUser) {
		if(!token) return;

		acceptQuestionTrigger({
			token: token,
			question_id: user.id,
			chat_id: user.chat_id
		})
	}

	function handleRejectQuestion(user:IUser) {
		setUserDataForEditOrReject({type:"reject" , user:{...user , researcherId:null}})
		setOpenEditOrRejectQuestion(true)
	}

	function handleCloseEditOrRejectQuestion() {
		setOpenEditOrRejectQuestion(false)
	}

	function handleEditQuestion(user:IUser) {
		setUserDataForEditOrReject({type:"edit" , user:{...user , researcherId:null}})
		setOpenEditOrRejectQuestion(true)
	}

	return(
		<>
			<Dialog
				open={open}
				onClose={onClose}
				sx={{
					[`& .${dialogClasses.paper}`]: {
						borderRadius: 6,
						width: '100%',
						maxWidth: '600px', // Optional: Limit the max width
						top: (downOfsm)?-95:0,
						height: (downOfsm)?650:"100%",
					},
				}}
			>
				<DialogTitle sx={{ backgroundColor: "#D9D9D9" }}>
					<Box p={2}>
						<Typography>
							تایید سوالات
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent sx={{ backgroundColor: "#D9D9D9", padding: 1, overflow: 'hidden' }}>
					<CustomScrollbarRoot>
						<Box px={3}>
							<Stack justifyContent={"center"} alignItems={"center"}>
								<Grid container spacing={2}>
									{currentItems.map((user, index) => {
										return(
											<Grid item xs={12} key={index}>
												<Stack
													direction={(downOfsm)?"column":"row"}
													justifyContent={"space-between"}
													sx={{
														backgroundColor: "white",
														p: 1.5,
														borderRadius: 4
													}}
												>
													<Stack direction={"row"}>
														<Box>
															<Avatar
																// src={`https://api.matnavis.com/files/${user.}`}
																sx={{
																	width: 75,
																	height: 75
																}}
															/>
														</Box>
														<Stack alignSelf={"flex-end"} pl={2}>
															{(downOfsm)?null:
																<Button
																	onClick={() => handleRejectQuestion(user)}
																	variant={"contained"}
																	sx={{
																		minWidth:50,
																		height:28,
																		backgroundColor: "#E86942",
																		'&:hover': {
																			backgroundColor: "#8f3e04"
																		},
																		boxShadow: "0px 5px 10px 3px #656464"
																	}}
																>
																	رد
																</Button>
															}
														</Stack>
													</Stack>
													<Stack alignSelf={"flex-end"}>
														<Stack alignSelf={"flex-end"}>
															<Typography>
																{user.question}
															</Typography>
														</Stack>
														<Stack alignSelf={"flex-end"} direction={"row"} spacing={2}>
															{(downOfsm)?
																<Button
																	onClick={() => handleRejectQuestion(user)}
																	variant={"contained"}
																	sx={{
																		minWidth:50,
																		height:28,
																		backgroundColor: "#E86942",
																		'&:hover': {
																			backgroundColor: "#8f3e04"
																		},
																		boxShadow: "0px 5px 10px 3px #656464"
																	}}
																>
																	رد
																</Button>:
																null
															}
															<Button
																onClick={() => handleEditQuestion(user)}
																variant={"contained"}
																sx={{
																	height: 30,
																	minWidth: 75,
																	backgroundColor: "#E86942",
																	'&:hover': {
																		backgroundColor: "#8f3e04"
																	},
																	boxShadow: "0px 5px 10px 3px #656464"
																}}
															>
																اصلاح
															</Button>
															<Button
																onClick={() => handleAcceptQuestion(user)}
																variant={"contained"}
																sx={{
																	height: 30,
																	minWidth: 75,
																	backgroundColor: "#E86942",
																	'&:hover': {
																		backgroundColor: "#8f3e04"
																	},
																	boxShadow: "0px 5px 10px 3px #656464"
																}}
															>
																تایید
															</Button>
														</Stack>
													</Stack>
												</Stack>
											</Grid>
										)
									})}
								</Grid>
							</Stack>
						</Box>
					</CustomScrollbarRoot>
				</DialogContent>
				<Stack alignItems={'center'} sx={{backgroundColor: "#D9D9D9"}}>
					<Pagination
						handleFirstPage={handleFirstPage}
						handleLastPage={handleLastPage}
						handleNextPage={handleNextPage}
						handlePrevPage={handlePrevPage}
						page={page}
					/>
				</Stack>
			</Dialog>
			<EditOrRejectQuestionPopup
				open={openEditOrRejectQuestion}
				onClose={handleCloseEditOrRejectQuestion}
				userDataForEditOrReject={userDataForEditOrReject}
				setUpdateRequestData={setUpdateQuestionsData}
			/>
		</>
	)
}