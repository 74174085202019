import Stack from "@mui/material/Stack";
import {
	Typography,
	useMediaQuery
} from "@mui/material";
import * as React from "react";
import theme from "../../../../lib/theme.const";
import {useState} from "react";
import 'react-quill/dist/quill.snow.css';
import NewNotes from "./components/NewNotes";
import UserNotes from "./components/UserNotes";
import INotestype from "../../../../interface/INotestype";

interface IProps {
	notes: INotestype[] | undefined
}

export default function NotesBox({notes}:IProps) {
	const [noteBoxValue , setNoteBoxValue] = useState<number>(1);
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const downOflg = useMediaQuery(theme.breakpoints.down("lg"));
	const downOfmd = useMediaQuery(theme.breakpoints.down("md"));

	return(
		<>
			<Stack py={'40px'}>
				<Stack
					width={'100%'}
					direction={"row"}
				>
					<Stack
						onClick={() => setNoteBoxValue(1)}
						width={"50%"}
						height={(noteBoxValue === 1)?60:30}
						sx={{
							backgroundColor: (noteBoxValue === 1)?"#D9D9D9":"#808080",
							borderTopRightRadius: (noteBoxValue === 1)?15:undefined,
							borderTopLeftRadius: 15
						}}
						alignSelf={(noteBoxValue === 1)?undefined:"flex-end"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Typography sx={{color: (noteBoxValue === 1)?"#0068A6":"white"}}>
							یادداشت جدید
						</Typography>
					</Stack>
					<Stack
						onClick={() => setNoteBoxValue(2)}
						width={"50%"}
						height={(noteBoxValue === 2)?60:30}
						sx={{
							backgroundColor: (noteBoxValue === 2)?"#D9D9D9":"#808080",
							borderTopRightRadius: 15,
							borderTopLeftRadius: (noteBoxValue === 2)?15:undefined
						}}
						alignSelf={(noteBoxValue === 2)?undefined:"flex-end"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<Typography sx={{color: (noteBoxValue === 2)?"#0068A6":"white"}}>
							یادداشت من
						</Typography>
					</Stack>
				</Stack>
				<Stack>
					{(noteBoxValue === 1)? <NewNotes/>:<UserNotes notes={notes}/>}
				</Stack>
			</Stack>
		</>
	)
}