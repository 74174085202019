import {Button, Paper, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {checkRegulationsChanged} from "../features/appSlice";

export default function AdoptionOfRulesAndRegulations() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	return(
		<Stack sx={{p:5 , backgroundColor:'white'}}>
			<Paper sx={{p:3 , backgroundColor:'#D9D9D9' , height:'100%' , boxShadow: "0px 30px 30px 5px #D9D9D9"}}>
				<Stack justifyContent={'center'} alignItems={'center'}>
					<Stack spacing={1}>
						<Typography>
							شرایط و قوانین استفاده از سرویس‌ها و خدمات سایت matnavis.com  و پلتفرم مشاوره شرکت مان تحقیق و نظر آویس که ازین پس به اختصار« متن آویس »نامیده می‌شود.

							ورود کاربران به پلتفرم مشاوره متن‌آویس هنگام استفاده از پروفایل شخصی، و سایر خدمات ارائه شده در این پلتفرم به معنای آگاه بودن و پذیرفتن شرایط، قوانین و همچنین نحوه استفاده از سرویس‌‏ها و خدمات است.
							مشارکت در تعامل‌های پلتفرم در هر زمان به معنی پذیرفتن کامل کلیه شرایط و قوانین از سوی کاربر است.
						</Typography>
						<Typography fontWeight={'bold'} pt={2}>
							قوانین عمومی
						</Typography>
						<Typography>
							کلیة اصول و رویه‏‌های پلتفرم مشاوره متن آویس منطبق با قوانین جمهوری اسلامی ایران، قانون تجارت الکترونیک و قانون حمایت از حقوق مصرف کننده است و متعاقباً کاربر نیز موظف به رعایت قوانین مربوطه است.
							در صورتی که در قوانین مندرج، رویه‏‌ها و سرویس‏‌ها تغییری ایجاد شود، در همین صفحه منتشر و به روز رسانی می شود و کاربر توافق می‏‌کند که استفاده مستمر از سایت به معنی پذیرش هرگونه تغییر است.
						</Typography>
						<Typography fontWeight={'bold'} pt={2}>
							تعریف مشتری یا کاربر
						</Typography>
						<Typography>
							مشتری یا کاربر به شخصی گفته می‌شود که با اطلاعات کاربری خود که در فرم ثبت نام درج کرده است، به ثبت درخواست یا هرگونه استفاده از خدمات پلتفرم مشاوره متن آویس اقدام کند.
						</Typography>
						<Typography fontWeight={'bold'} pt={2}>
							تعریف فروشنده
						</Typography>
						<Typography>
							شرکت مان تحقیق و نظر آویس فراهم آورندة خدمات و پشتیبانی فنی پلتفرم مشاورۀ متن آویس بوده، و در این جایگاه مسئول تأمین و مدیریت فنی خدمات این پلتفرم و صحت فروش توکن‌های مشاوره و هزینه اشتراک است.
						</Typography>
						<Typography fontWeight={'bold'} pt={2}>
							ارتباطات الکترونیکی
						</Typography>
						<Typography>
							هنگامی که شما از خدمات پلتفرم مشاوره متن آویس استفاده می‏‌کنید، درخواست اینترنتی خود را ثبت یا خرید می‏‌کنید، این ارتباطات به صورت الکترونیکی انجام می‏‌شود و در صورتی که درخواست شما با رعایت کلیة اصول و رویه‏‌ها باشد، شما موافقت می‌‏کنید که پلتفرم متن آویس در قالب صفحات تعامل یا مشاوره پژوهشی به درخواست شما پاسخ دهد.
							همچنین آدرس ایمیل و تلفن‌هایی که مشتری در پروفایل خود ثبت می‌کند، تنها آدرس ایمیل و تلفن‌های رسمی و مورد تأیید مشتری است.
							جهت اطلاع رسانی رویدادها، خدمات و سرویس‌های ویژه یا پروموشن‌ها، امکان دارد پلتفرم مشاوره متن آویس برای اعضای وب سایت ایمیل یا پیامک ارسال نماید.
							در صورتی که کاربران تمایل به دریافت این‌گونه ایمیل و پیامک‌ها نداشته باشند، می‌توانند عضویت دریافت خبرنامه پلتفرم متن آویس را در پروفایل خود لغو کنند.
							همچنین ممکن است  پلتفرم متن آویس برای برخی کاربران یا مشتریان خود، سوال نظرسنجی ارسال کند.
						</Typography>
						<Typography fontWeight={'bold'} pt={2}>
							سیاست‏‌های رعایت حریم شخصی
						</Typography>
						<Typography>
							پلتفرم مشاوره متن آویس به اطلاعات خصوصی اشخاصى که از خدمات سایت استفاده می‏‌کنند، احترام گذاشته و از آن محافظت می‏‌کند.
							پلتفرم مشاوره متن آویس متعهد می‏‌شود در حد توان از حریم شخصی شما دفاع کند و در این راستا، تکنولوژی مورد نیاز برای هرچه مطمئن‏‌تر و امن‏‌تر شدن استفاده شما از سایت را توسعه دهد. با استفاده از پلتفرم مشاوره متن آویس، شما رضایت خود را از این سیاست نشان می‏‌دهید.
							مطالب در دسترس از طریق خدمات مشاوره متن آویس، از قبیل متن، گرافیک، آرم، آیکون دکمه، تصاویر، ویدئوهای تصویری، موارد قابل دانلود و کپی، داده‌ها و کلیة محتوای تولید شده در پرسش و پاسخ‌های پلتفرم جزئی از دارایی‌های شرکت مان تحقیق و نظر آویس محسوب می‏‌شود و حق استفاده و نشر این مطالب در انحصار این شرکت است و هرگونه استفاده  بدون کسب مجوز کتبی، می‌تواند موضوع پیگرد قانونی باشد. حقوق مادی محتوای متنی، صوتی یا چندرسانه‌ای حاصل از خدمات مشاوره در پلتفرم مشاوره متن آویس متعقل به مان تحقیق و نظر آویس بوده، اما نسخه برداری یا نقل این محتوا توسط افراد با ذکر مرجع بلامانع است.
							علاوه بر این، اسکریپت‌ها، و اسامی خدمات قابل ارائه از طریق هر یک از خدمات ایجاد شده توسط پلتفرم مشاوره متن آویس و علائم تجاری ثبت شده نیز در انحصار مان تحقیق و نظر آویس است و هر گونه استفاده با مقاصد تجاری پیگرد قانونی دارد.
							کاربران مجاز به بهره‌‏برداری و استفاده از لیست محصولات، مشخصات فنی، قیمت و هر گونه استفاده از مشتقات پلتفرم مشاوره متن آویس و یا هر یک از خدمات و یا مطالب، دانلود یا کپی کردن اطلاعات با مقاصد تجاری، هر گونه استفاده از داده کاوی، روبات، یا روش‌‏های مشابه مانند جمع آوری داده‌‏ها و ابزارهای استخراج نیستند و کلیه این حقوق به صراحت برای مان تحقیق و نظر آویس محفوظ است.
							در صورت استفاده از هر یک از خدمات پلتفرم مشاوره متن آویس، کاربران مسئول حفظ محرمانه بودن حساب و رمز عبور خود هستند و تمامی مسئولیت فعالیت‌‏هایی که تحت حساب کاربری و یا رمز ورود انجام می‏‌پذیرد به عهده کاربران است.
						</Typography>
						<Typography fontWeight={'bold'} pt={2}>
							ثبت، پردازش و پاسخ درخواست
						</Typography>
						<Stack spacing={1}>
							<Typography>
								-	پلتفرم مشاورة متن آویس به کاربران خود در ۷ روز هفته و ۲۴ ساعت در روز امکان ثبت درخواست می‌‏دهد.
							</Typography>
							<Typography>
								-	...کاربران متن آویس در سه گروه پرسشگر،  پژوهشگر و کاربرعادی هستند. برای پرسشگران در تمامی ساعات روزهای هفته امکان ثبت پرسش در پروفایل پژوهشگر مورد نظر خود فراهم شده است.
							</Typography>
							<Typography>
								-	پرسش‌های ثبت شده تنها پس از تأیید ادمین سایت متن آویس به پژوهشگران انتخاب شده ارسال می‌شود.
							</Typography>
							<Typography>
								-	حق پذیرش یا رد درخواست مشاوره یا پرسش برای پژوهشگران در پلتفرم مشاوره متن آویس محفوظ است.
							</Typography>
							<Typography>
								-	در صورت رد درخواست مشاوره، یا پذیرفته نشدن پرسش پژوهشی توسط پژوهشگر منتخب، کاربر  مجاز است که از پرسش منصرف شود و یا از مشاوره تیم متن‌آویس برای انتخاب پژوهشگر مناسب بهره‌مند شود.
							</Typography>
							<Typography>
								-	مبلغ مورد نیاز برای مشاوره پژوهشی در قالب پرسش و پاسخ از طریق توکن‌های پلتفرم و تنها پس از پذیرش پژوهشگر و تأیید نوع پاسخ پرداخت می‌شود.
							</Typography>
							<Typography>
								-	پلتفرم مشاوره متن آویس مجاز است بدون اطلاع قبلی نسبت به توقف سفارش‌‏گیری جدید، اقدام و خدمات خود را متوقف کند و کلیة سفارشات ثبت شده قبل از توقف سفارش‌‏گیری، پردازش و ارسال می‌‏شود. حق قطع فروش کلیه و یا بخشی از خدمات به هر دلیلی بدون اطلاع قبلی، برای پلتفرم مشاوره متن آویس محفوظ است.
							</Typography>
							<Typography>
								-	در صورت بروز هرگونه خطا نسبت به ارائة خدمات موجود در پلتفرم مشاورة متن آویس، حق بلا اثر نمودن سفارش کاربران، برای پلتفرم مشاوره متن آویس محفوظ است. پلتفرم مشاوره متن آویس در اسرع وقت وجوه دریافتی را به حساب اعلام شده توسط کاربر واریز و عودت می‌نماید و کاربر با ورود به پلتفرم مشاوره متن آویس می‌پذیرد از این امر آگاهی داشته و در این خصوص ادعایی نخواهد داشت.
							</Typography>
							<Typography>
								-	کاربران باید هنگام ثبت نام در پلتفرم، فرم مشخصات را با اطلاعات صحیح و به طور کامل تکمیل کنند. بدیهی است درصورت ورود اطلاعات ناقص یا نادرست، پلتفرم مسئول بروز مشکلات در پردازش و پیگیری سفارش کاربر نخواهد بود.
							</Typography>
						</Stack>
						<Typography fontWeight={'bold'} pt={2}>
							محتوا
						</Typography>
						<Stack>
							<Typography>
								-	کاربران پلتفرم مشاوره متن آویس، با ایجاد حساب کاربری و استفاده از خدمات این پلتفرم می‌پذیرند که حقوق مادی محتوای حاصل از خدمات مشاوره پژوهشی و پرسش و پاسخ‌ها متعلق به متن آویس بوده و مان تحقیق و نظر آویس مجاز است در هر زمان از این محتوا در چارچوب اساسنامة شرکت استفاده نماید.
							</Typography>
							<Typography>
								-	کاربرانی که در جایگاه پژوهشگر در پلتفرم به تعامل می‌پردازند، مجاز به قرار دادن محتوای پژوهشی تولید شده در خارج از تعاملات پلتفرم در پنل خود هستند. حقوق مادی این محتوا متعلق به پژوهشگران باقی خواهد ماند و مان تحقیق و نظر آویس هیچ ادعایی در خصوص حقوق مادی آثاری که خارج از تعاملات پلتفرم ایجاد شده است ندارد. بدیهی است که مسئولیت صحت محتوای این اقلام نیز بر عهدة خود پژوهشگر بوده و هیچ مسئولیتی از این بابت متوجه مان تحقیق و نظر آویس نخواهد بود.
							</Typography>
							<Typography>
								-	پلتفرم مشاورة متن آویس هیچ مسئولیتی در قبال صحت علمی محتوای پرسش و پاسخ‌ها در مشاوره پژوهشی ندارد و مسئولیت صحت این محتوا بر عهدة پژوهشگرانی است که درخواست مشاوره یا پاسخ به پرسش را پذیرفته‌اند.
							</Typography>
							<Typography>
								-	پلتفرم مشاورة متن آویس تحت هیچ شرایطی مسئولیت  تأخیر یا عدم کارکرد سایت را که می‌تواند ناشى از حوادث قهری، عوامل طبیعى، نیروى انسانی، مشکلات اینترنتى، خرابی تجهیزات کامپیوترى، مخابراتى و غیره باشد بر عهده ندارد.
							</Typography>
						</Stack>
						<Typography fontWeight={'bold'} pt={2}>
							قوه قهریه
						</Typography>
						<Typography>
							تمامی شرایط و قوانین مندرج، در شرایط عادی قابل اجرا است و در صورت بروز هرگونه قوه قهریه، پلتفرم مشاوره متن آویس هیچ گونه مسئولیتی ندارد.
						</Typography>
					</Stack>
					<Stack alignSelf={'flex-end'}>
						<Button
							onClick={() => {
								dispatch(checkRegulationsChanged('confirmed'))
								navigate('/')
							}}
							variant={'contained'}
							sx={{
								mt:5,
								minWidth: 100,
								backgroundColor: "#E86942",
								'&:hover': {
									backgroundColor: "#8f3e04"
								},
							}}
						>
							تایید
						</Button>
					</Stack>
				</Stack>
			</Paper>
		</Stack>
	)
}