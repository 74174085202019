import {useLazyGetUserCashbackRequestsQuery} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import {
	Paper,
	Table,
	TableBody,
	TableCell, tableCellClasses,
	TableContainer,
	TableHead,
	TableRow,
	Typography, useMediaQuery
} from "@mui/material";
import * as React from "react";
import {styled} from "@mui/material/styles";
import TableFooter from "./TableFooter";
import Box from "@mui/material/Box";
import CheckIcon from '@mui/icons-material/Check';
import theme from "../../../../lib/theme.const";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#E86942',
		color: theme.palette.common.white,
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14,
	},
}));

interface IProps {
	selectedNumber: number
}

export default function TransactionsResearcher({selectedNumber}:IProps) {
	const [financeTrigger, { data: financeData }] = useLazyGetUserCashbackRequestsQuery();
	const {token} = useSelector((state: RootState) => state.app);
	const [page, setPage] = useState<number>(1);
	const ITEMS_PER_PAGE = (selectedNumber)?selectedNumber:4;

	const startIndex = (page - 1) * ITEMS_PER_PAGE;
	const endIndex = startIndex + ITEMS_PER_PAGE;
	// const currentItems =
	// 	(pending)?
	// 		financeData?.msg.requests.slice(startIndex, endIndex).filter((content) => content.status === 'pending') || []
	// 		:financeData?.msg.requests.slice(startIndex, endIndex) || [];

	const currentItems = financeData?.msg.requests.slice(startIndex, endIndex) || []

	useEffect(() => {
		if(!token) return;

		financeTrigger({
			token: token
		})
	}, [token]);

	console.log(financeData)

	return(
		<>
			<Stack spacing={8}>
				<Stack alignItems={'center'} justifyContent={'center'}>
					<Stack alignSelf={'flex-start'} pb={1} pl={2}>
						<Typography fontSize={18}>
							برداشت در حال انجام
						</Typography>
					</Stack>
					<TableContainer component={Paper} sx={{borderRadius:5}}>
						<Table>
							<TableHead>
								<TableRow>
									<StyledTableCell>
										<Typography fontSize={12}>
											ردیف
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											تاریخ درخواست
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											تاریخ پرداخت
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											وضعیت
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											شماره شبا
										</Typography>
									</StyledTableCell>
									<StyledTableCell align="right">
										<Typography fontSize={12}>
											مبلغ(تومان)
										</Typography>
									</StyledTableCell>
								</TableRow>
							</TableHead>
							<TableBody sx={{backgroundColor:'#D9D9D9'}}>
								{currentItems?.map((content , index) => {
									return(
										<TableRow
											key={index}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
										>
											<TableCell component="th" scope="row">
												{index+1 + startIndex}
											</TableCell>
											<TableCell align="right">{content.created_at}</TableCell>
											<TableCell align="right">{content.reviewed_at}</TableCell>
											<TableCell align="right">{content.status}</TableCell>
											<TableCell align="right">{content.iban}</TableCell>
											<TableCell align="right">{content.amount}</TableCell>
										</TableRow>
									)
								})}
							</TableBody>
						</Table>
						{(currentItems?.length !== 0)?
							null:
							<Stack
								sx={{backgroundColor:'#D9D9D9'}}
								width={'100%'}
								alignItems={'center'}
								justifyContent={'center'}
								pb={3}
							>
								<img src={'./png/3.png'} width={300} height={380}/>
								<Typography fontWeight={'bold'} fontSize={23} sx={{color: "#808080"}}>
									هیچ تاریخچه ای وجود ندارد
								</Typography>
							</Stack>
						}
					</TableContainer>
					<TableFooter
						setPage={setPage}
						ITEMS_PER_PAGE={ITEMS_PER_PAGE}
						financeData={financeData}
						page={page}
					/>
				</Stack>
			</Stack>
		</>
	)
}