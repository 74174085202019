import Stack from "@mui/material/Stack";
import {Box, Button, Divider, Paper, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import TokenShopButton from "../../../../components/TokenShopButton";
import WaysToPayment from "../../../../components/WaysToPayment";
import {useLazyConvertTokenToRialQuery} from "../../../../features/apiSlice";
import {useSelector} from "react-redux";
import {RootState} from "../../../../app/store";
import SaveChangesPopup from "../../../../components/BuyTokens/SaveChangesPopup";
import BuyTokenPopup from "../../../../components/BuyTokens/BuyTokenPopup";

export default function Wallet() {

	return(
		<>
			<Paper
				sx={{
					// height: 300,
					pb: 5,
					borderRadius: 3,
					border: 0.5,
					borderColor: "#b9b9b9",
					boxShadow: "0px 90px 80px 20px #D9D9D9"
				}}
			>
				<Stack justifyContent={"center"} alignItems={"center"}>
					<BuyTokenPopup/>
				</Stack>
			</Paper>
		</>
	)
}