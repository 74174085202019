import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {
	Avatar,
	Divider,
	Select,
	useMediaQuery
} from "@mui/material";
import {useLazyAddChatQuery, useLazyGetResentChatsQuery, useLazyMarkQuestionQuery} from "../../../features/apiSlice";
import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../../app/store";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Menu from "@mui/material/Menu";
import * as React from "react";
import IconButton from "@mui/material/IconButton";
import ConversationTextField from "../../../components/ConversationTextField";
import AuthenticationProvider from "../../../components/AuthenticationProvider";
import theme from "../../../lib/theme.const";
import DrawerConversationMobile from "./DrawerConversationMobile";
import DrawerConversation from "./DrawerConversation";
import {useLocation} from "react-router-dom";


function Page() {
	const [getResentChatTrigger , {data: resentChatData}] = useLazyGetResentChatsQuery();
	const [addChatTrigger , {data: addChatData}] = useLazyAddChatQuery();
	const [markChatTrigger , {data: markChatData}] = useLazyMarkQuestionQuery();
	const {token , role} = useSelector((state: RootState) => state.app);
	const topics = ["تاریخ منظر" , "تاریخ هنر" , "تاریخ معماری" , "معماری" , "منظر"];
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const isMenuOpen = Boolean(anchorEl);
	const [chatId , setChatId] = useState<number>();
	const [noteContent , setNoteContent] = useState<string>();
	const [selectedTopic , setSelectedTopic] = useState<string>();
	const [heightPage , setHeightPage] = useState<number>();
	const downOfsm = useMediaQuery(theme.breakpoints.down("sm"));
	const personalPageData = useLocation();
	const chats = selectedTopic
		? resentChatData?.msg.filter((data) => data.subjects.some((subject) => subject === selectedTopic))
		: resentChatData?.msg;

	console.log(chats)

	useEffect(() => {
		window.scrollTo(0, 0); // Scroll to the top of the page
	}, []);

	useEffect(() => {
		if(!token) return;

		getResentChatTrigger({
			token: token
		})
	}, [token  , addChatData]);

	const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	function handleClickSaveNote() {
		if(!token || !noteContent || noteContent === '\n' || noteContent.length === 0) return;

		if(chatId) {
			addChatTrigger({
				token: token,
				question_id: chatId,
				msg: noteContent
			})
		}
		else {
			addChatTrigger({
				token: token,
				msg: noteContent
			})
		}
	}

	function handleMarkChat() {
		if(!token || !chatId) return;

		markChatTrigger({
			token: token,
			question_id: chatId
		})
	}

	function handleSaveChat() {

	}

	useEffect(() => {
		const heightElement = document.getElementsByClassName('heightDrawer') as HTMLCollectionOf<HTMLElement>;

		if (heightElement.length > 0) {
			const observer = new MutationObserver(() => {
				setHeightPage(heightElement.item(0)?.clientHeight)
			});

			observer.observe(heightElement.item(0)!, {
				attributes: true, // برای مشاهده تغییرات در صفات مانند style
				childList: true, // برای مشاهده تغییرات در فرزندان
				subtree: true, // برای مشاهده تغییرات در تمام زیر درخت
			});
		} else {
			console.log('No elements with class heightDrawer found.');
		}
	}, []);

	const renderMenu = (
		<Menu
			sx={{
				mt:8,
				"& .MuiPopover-paper": {
					backgroundColor: "#D9D9D9",
					width: 170,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: 3
				}
			}}
			anchorEl={anchorEl}
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			// id={menuId}
			keepMounted
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			open={isMenuOpen}
			onClose={() => setAnchorEl(null)}
		>
			<MenuItem
				onClick={handleSaveChat}
				sx={{
					padding: "6px 16px 0px 16px"
				}}
			>
				ارسال کردن متن
			</MenuItem>
			<Divider
				sx={{
					height: "70%"
				}}
			/>
			<MenuItem
				onClick={handleMarkChat}
				sx={{
					padding: "0px 16px 6px 16px"
				}}
			>
				علامت دار کردن متن
			</MenuItem>
		</Menu>
	);

	return(
		<>
			<Stack direction={"row"} height={'100%'} sx={{backgroundColor:'white'}}>
				<Stack
					height={'100%'}
					width={(downOfsm)?"100%":"70%"}
					px={(downOfsm)?3:8}
				>
					<Stack
						sx={{
							// backgroundColor: "#D9D9D9",
							backgroundColor: '#df9e8a',
							borderRadius:7,
							mt:2
						}}
						width={"100%"}
						direction={"row"}
						alignItems={"center"}
						justifyContent={"space-between"}
						pr={1}
						pl={3}
					>
						<IconButton onClick={handleProfileMenuOpen}>
							<MoreHorizIcon sx={{color:"white" , width:40 , height:40}}/>
						</IconButton>
						<Stack sx={{ p: 1 }}>
							<Select
								color={"warning"}
								IconComponent={(props) => (
									<ExpandMoreIcon {...props} sx={{ color: 'black', left: '10px' }} />
								)}
								MenuProps={{
									PaperProps: {
										sx: {
											'& .MuiMenuItem-root': {
												justifyContent: 'flex-start',
											}
										}
									}
								}}
								sx={{
									ml: 1,
									width: 120,
									borderRadius: 8,
									backgroundColor: "rgb(249,249,249)",
									'& .MuiSelect-icon': {
										right: '10px',
										color: 'black'
									}
								}}
							>
								<Box>
									{topics.map((topic , index) => {
										return(
											<MenuItem
												onClick={() => setSelectedTopic(topic)}
												key={index}
												value={index+1}
											>
												{topic}
											</MenuItem>
										)
									})}
									<MenuItem onClick={() => setSelectedTopic(undefined)}>
										همه
									</MenuItem>
								</Box>
							</Select>
						</Stack>
					</Stack>
					<Stack>
						{resentChatData?.msg.map((content , index) => {
							return(
								<Stack key={index}>
									{(content.id === chatId)?
										<Box>
											{content.chats.map((chat , index) => {
												return(
													<Stack key={index}>
														<Stack>
															{(chat.sender === "questioner")?
																<Stack mt={6} alignSelf={(role === 'questioner')?"flex-start":'flex-end'}>
																	<Box
																		sx={{
																			height: "100%",
																			width: {lg:400 , md:300},
																			backgroundColor: "#dfdfdf",
																			borderRadius: 4,
																			px: 1.5,
																			py: 2
																		}}
																	>
																		{chat.msg}
																	</Box>
																	<Box mt={0.5} ml={0.5} alignSelf={(role === 'questioner')?"flex-start":'flex-end'}>
																		{
																			(
																				content.chats[index+1]?.sender
																				!== content.chats[index]?.sender
																			)?
																				<Avatar
																					src={`https://api.matnavis.com/files/${content.questioner_profile.photo}`}
																					sx={{
																						width:"35px",
																						height:"35px",
																						transition: 'width 0.3s ease',
																					}}
																				/>:
																				null
																		}
																	</Box>
																</Stack>:
																null
															}
														</Stack>
														<Stack>
															{(chat.sender === "researcher")?
																<Stack mt={1}>
																	<Box
																		alignSelf={(role === 'researcher')?'flex-start':"flex-end"}
																		sx={{
																			height: "100%",
																			width: "80%",
																			display: "flex",
																			// backgroundColor: "#f2a992",
																			backgroundColor:'#85CDF9ED',
																			borderRadius: 4,
																			px: 1.5,
																			py: 2
																		}}
																	>
																		{chat.msg}
																	</Box>
																	<Box
																		alignSelf={(role === 'researcher')?'flex-start':"flex-end"}
																		mt={0.5}
																	>
																		{
																			(
																				content.chats[index+1]?.sender
																				!== content.chats[index]?.sender
																			) ||
																			(!content.chats[index+1])?
																				<Avatar
																					src={`https://api.matnavis.com/files/${content.researcher_profile.photo}`}
																					sx={{
																						width:"35px",
																						height:"35px",
																						transition: 'width 0.3s ease',
																					}}
																				/>:
																				null
																		}
																	</Box>
																</Stack>:
																null
															}
														</Stack>
													</Stack>
												)
											})}
										</Box>:
										null
									}
								</Stack>
							)
						})}
					</Stack>
					<Stack
						sx={{
							top:(isExpanded)?heightPage!-220:heightPage!,
							position:(chatId)?"inherit":'absolute',
							py:(chatId)?5:0
						}}
						width={(chatId)?'100%':'55%'}
					>
						<ConversationTextField
							setNoteContent={setNoteContent}
							handleClickSaveNote={handleClickSaveNote}
							isExpanded={isExpanded}
							setIsExpanded={setIsExpanded}
						/>
					</Stack>
				</Stack>
				{(!downOfsm)?
					<Stack width={"30%"}>
						<DrawerConversation chatId={chatId} setChatId={setChatId} chats={chats} personalPageData={personalPageData.state}/>
					</Stack>:
					<Stack>
						<DrawerConversationMobile chats={chats} chatId={chatId} setChatId={setChatId} personalPageData={personalPageData.state}/>
					</Stack>
				}
			</Stack>
			{renderMenu}
		</>
	)
}

export default function Conversations() {
	return(
		<AuthenticationProvider>
			<Page/>
		</AuthenticationProvider>
	)
}