import Box from "@mui/material/Box";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import Stack from "@mui/material/Stack";
import React, {useRef, useState} from "react";
import HelpUserPopup from "./HelpUserPopup";
import {Fab} from "@mui/material";
import {styled} from "@mui/material/styles";

const FloatingIconWrapper = styled(Fab)(({ theme }) => ({
	position: 'fixed',
	bottom: theme.spacing(2),
	// right: theme.spacing(2),
	zIndex: 1000,
}));

export default function HelpMessageButton() {
	const [open , setOpen] = useState(false);

	const handleClose = () => {
		setOpen(false)
	};

	return(
		<>
			<FloatingIconWrapper
				sx={{
					borderRadius: "50%",
					width: 80,
					height: 80,
					backgroundColor: "#E86942",
					cursor: "pointer",
					'&:hover': {
						backgroundColor: "#8f3e04"
					},
				}}
			>
				<Stack
					justifyContent={"center"}
					alignItems={"center"}
					onClick={() => setOpen(true)}
				>
					<Box mt={1}>
						<img
							src={"./message.png"}
							width={50}
							height={50}
							alt={'Helper button photo'}
						/>
					</Box>
					<Box mt={-7.5}>
						<MoreHorizSharpIcon
							sx={{
								color: "white",
								fontSize: 45
							}}
						/>
					</Box>
				</Stack>
			</FloatingIconWrapper>
			<HelpUserPopup open={open} onClose={handleClose}/>
		</>
	)
}