import Box from "@mui/material/Box";
import {Avatar, Button, Typography} from "@mui/material";
import Stack from "@mui/material/Stack";
import MoreHorizSharpIcon from "@mui/icons-material/MoreHorizSharp";
import * as React from "react";
import {useState} from "react";
import SetCommentPopup from "../../components/SetCommentPopup";
import MoreIconPopup from "../../components/MoreOptionsPopup/MoreIconPopup";
import {useNavigate} from "react-router-dom";
import IChatsData from "../../interface/IChatsData";

export default function Chats({data}:{data: {data:IChatsData | undefined , id:number}}) {
	const [commentOpen , setCommentOpen] = useState(false);
	const [moreOpen , setMoreOpen] = useState(false);
	const navigate = useNavigate();


	// console.log(data)

	// if(!data) {
	// 	navigate("/")
	// }

	const handleClickCommentOpen = () => {
		setCommentOpen(true)
	}

	const handleClickMoreOpen = (value: any) => {
		setMoreOpen(true)
	}

	const handleClose = () => {
		if(commentOpen) {
			setCommentOpen(false)
		}
		else if(moreOpen) {
			setMoreOpen(false)
		}
	};

	return(
		<>
			<Stack
				spacing={4}
				px={"8%"}
			>
				<Box>
					{(data?.id === 0)?
						<Typography fontWeight={"bold"}>
							گفتگوی برگزیده هفته
						</Typography> :
						<Typography fontWeight={"bold"}>
							{data?.data?.title}
						</Typography>
					}
				</Box>
				<Stack spacing={5}>
					{data?.data?.chats.map((content , index) => {
						return(
							<Stack key={index}>
								<Stack alignSelf={"flex-start"} width={"80%"}>
									{(content.sender === "questioner")?
										<Stack>
											<Box
												sx={{
													// minHeight: 100,
													height: "100%",
													backgroundColor: "#dfdfdf",
													borderRadius: 4,
													px: 1.5,
													py: 2
												}}
											>
												{content.msg}
											</Box>
											<Stack
												sx={{mt: 0.5}}
											>
												{
													(
														data?.data?.chats[index+1]?.sender
														!== data?.data?.chats[index]?.sender
													)?
														<Avatar
															onClick={() => navigate("/general-questioner-page" , {state: data.data?.questioner_profile})}
															src={`https://api.matnavis.com/files/${data.data?.questioner_profile.photo}`}
															sx={{
																width:"35px",
																height:"35px",
																transition: 'width 0.3s ease',
															}}
														/>:
														null
												}
											</Stack>
										</Stack>:
										null
									}
								</Stack>
								<Stack alignSelf={"flex-end"} width={"80%"}>
									{(content.sender === "researcher")?
										<Stack>
											<Box
												sx={{
													// minHeight: 100,
													height: "100%",
													display: "flex",
													backgroundColor: "#f2a992",
													borderRadius: 4,
													px: 1.5,
													py: 2
												}}
											>
												{content.msg}
											</Box>
											<Stack
												onClick={() => navigate("/general-researcher-page" , {state:data.data?.researcher_profile.id})}
												alignSelf={"flex-end"}
												sx={{mt: 0.5}}
											>
												{
													(
														data.data?.chats[index+1]?.sender
														!== data.data?.chats[index]?.sender
													) ||
													(!data.data?.chats[index+1])?
														<Avatar
															onClick={() => navigate("/general-researcher-page" , {state:data.data?.researcher_profile})}
															src={`https://api.matnavis.com/files/${data.data?.researcher_profile.photo}`}
															sx={{
																width:"35px",
																height:"35px",
																transition: 'width 0.3s ease',
															}}
														/>:
														null
												}
											</Stack>
										</Stack>:
										null
									}
								</Stack>
							</Stack>
						)
					})}
					<Stack
						direction={"row"}
						spacing={3}
					>
						<Box>
							<Button>
								<Stack
									sx={{
										backgroundColor: "#E86942",
										borderRadius: "50%",
										mt:-1
									}}
								>
									<MoreHorizSharpIcon
										onClick={handleClickMoreOpen}
										sx={{
											color: "white",
											fontSize: 35
										}}
									/>
								</Stack>
							</Button>
						</Box>
						<Box>
							<Button
								variant={"contained"}
								onClick={handleClickCommentOpen}
								sx={{
									px: 2,
									// backgroundColor: "#E86942",
									backgroundColor: '#319DD3',
									'&:hover': {
										// backgroundColor: "#8f3e04"
										backgroundColor: '#206486'
									},
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									boxShadow: "0px 10px 20px 10px #D9D9D9"
								}}
							>
								<Typography
									fontWeight={"bold"}
									fontSize={16}
								>
									نوشتن نظر
								</Typography>
							</Button>
						</Box>
					</Stack>
				</Stack>
			</Stack>
			<SetCommentPopup
				open={commentOpen}
				onClose={handleClose}
				questionId={data?.data?.id}
				subCommentId={undefined}
			/>
			<MoreIconPopup open={moreOpen} onClose={handleClose} questionId={data?.data?.id}/>
		</>
	)
}