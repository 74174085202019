import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {Typography} from "@mui/material";
import {useState} from "react";
import FollowingsOrRecommendationPopup from "./FollowingsOrRecommendationPopup";
import IGetPersonalPageData from "../../../../interface/IGetPersonalPageData";

interface IProps {
	personalPageData: IGetPersonalPageData | undefined
}

export default function FollowingsOrRecommendationForResearcher({personalPageData}:IProps) {
	const [selectedValue , setSelectedValue] = useState<number>();

	function handleClosePopup() {
		setSelectedValue(undefined)
	}

	return(
		<>
			<Stack spacing={3}>
				<Stack
					direction={'row'}
					alignItems={'center'}
					justifyContent={'center'}
					spacing={2}
					sx={{
						backgroundColor: '#E86942',
						py:2,
						borderRadius:5,
						cursor:'pointer'
					}}
					onClick={() => {
						if(selectedValue === 1) {
							setSelectedValue(undefined)
						}
						else {
							setSelectedValue(1)
						}
					}}
				>
					{(selectedValue === 1)?
						<Box
							sx={{
								mt:0.5,
								width: 0,
								height: 0,
								borderLeft: '10px solid transparent',
								borderRight: '10px solid transparent',
								borderBottom: '20px solid #ffd5ac', // رنگ و ارتفاع مثلث
							}}
						/>:
						<Box
							sx={{
								mt:0.5,
								width: 0,
								height: 0,
								borderLeft: '10px solid transparent',
								borderRight: '10px solid transparent',
								borderTop: '20px solid #ffd5ac', // رنگ و ارتفاع مثلث
							}}
						/>
					}
					<Box>
						<Typography sx={{color:'white'}} fontSize={19}>
							پژوهشگرانی که دنبال میکنید
						</Typography>
					</Box>
				</Stack>
				<Stack
					direction={'row'}
					alignItems={'center'}
					justifyContent={'center'}
					spacing={2}
					sx={{
						backgroundColor: '#E86942',
						py:2,
						borderRadius:5,
						cursor:'pointer'
					}}
					onClick={() => {
						if(selectedValue === 2) {
							setSelectedValue(undefined)
						}
						else {
							setSelectedValue(2)
						}
					}}
				>
					{(selectedValue === 2)?
						<Box
							sx={{
								mt:0.5,
								width: 0,
								height: 0,
								borderLeft: '10px solid transparent',
								borderRight: '10px solid transparent',
								borderBottom: '20px solid #ffd5ac', // رنگ و ارتفاع مثلث
							}}
						/>:
						<Box
							sx={{
								mt:0.5,
								width: 0,
								height: 0,
								borderLeft: '10px solid transparent',
								borderRight: '10px solid transparent',
								borderTop: '20px solid #ffd5ac', // رنگ و ارتفاع مثلث
							}}
						/>
					}
					<Box>
						<Typography sx={{color:'white'}} fontSize={19}>
							افرادی که شما پیشنهاد میکنید
						</Typography>
					</Box>
				</Stack>
			</Stack>
			{(selectedValue)?
				<Stack mt={(selectedValue === 1)?-10:0.5}>
					<FollowingsOrRecommendationPopup
						selectedValue={selectedValue}
						onClose={handleClosePopup}
						data={personalPageData}
					/>
				</Stack>:
				null
			}
		</>
	)
}